import { Intent, Tag } from '@blueprintjs/core';
import React from 'react';


export type AsyncTagProps = {
  label: string | JSX.Element;
  onClick: (() => Promise<void>) | (() => void);
  onDone?: (err: any | undefined) => void;
  cssClass?: string;
  intent?: Intent;
  minimal?: boolean;
};

export type AsyncTagState = {
  error: Error | any;
  isLoading: boolean;
};

export class AsyncTag extends React.Component<AsyncTagProps, AsyncTagState> {

  constructor(props: AsyncTagProps) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
    };
  }

  private onClick = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });

    try {
      await this.props.onClick();
      this.setState({
        error: null,
        isLoading: false,
      });

      if (this.props.onDone) {
        this.props.onDone(undefined);
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        error,
      });
      if (this.props.onDone) {
        this.props.onDone(error);
      }
    }
  };

  public render(): JSX.Element {
    return (
      <Tag
        onClick={this.state.isLoading ? undefined : this.onClick}
        className={this.props.cssClass}
        intent={this.props.intent}
        minimal={this.props.minimal}
        interactive={true}
      >
        {this.props.label}
      </Tag>
    );
  }
}
