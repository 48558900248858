import { Identity } from '@atlas-engine/atlas_engine_client';
import { useContext } from 'react';
import {
  NavigateFunction,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { AuthProviderContext, StartDialogProps } from '.';
import { DialogStrings } from '../DialogStrings';
import { RequireClaims } from '../RequireClaims';
import { TransactionErrorModal } from '../transactions/TransactionErrorModal';

function makeStartDialogPropsProxy(identity: Identity | null, navigate: NavigateFunction): StartDialogProps {
  return {
    closeStartDialog: () => navigate('/'),
    openStartDialog: (dialog: typeof DialogStrings[keyof typeof DialogStrings]['route']) => navigate(`/${dialog}`),
    getIdentity: () => identity as Identity,
    startDialogConfiguration: {
      body: '',
      id: '',
      startButtonTitle: '',
      title: '',
      url: '',
    },
    startProcess: (processModelId: string) => {
      throw new Error('startProcess not implemented');
    },
  };
}

export type StartDialogRoutesProps = {
  dialogs: { [startDialog in typeof DialogStrings[keyof typeof DialogStrings]['route']]: React.ComponentClass<StartDialogProps> | React.FunctionComponent<StartDialogProps>
}};

export function StartDialogRoutes(props: StartDialogRoutesProps): JSX.Element {
  const authServiceContext = useContext(AuthProviderContext);
  const navigate = useNavigate();

  const identity = authServiceContext.authProvider.getIdentity() ?? null;

  return (
    <Routes>
      {
        [
          ...Object.entries(props.dialogs).map(([startDialogId, StartDialog]) => {
            const url = `/${startDialogId}`;
            const dialogProps = makeStartDialogPropsProxy(identity, navigate);
            const requiredClaims = Object.values(DialogStrings).find((dialogString) => dialogString.route === startDialogId)?.readClaims ?? [];

            return (
              <Route
                key={startDialogId}
                path={url}
                element={(
                  <RequireClaims key={startDialogId} claims={requiredClaims}>
                    <StartDialog {...dialogProps} />
                  </RequireClaims>
                )}
              />
            );
          }),
          <Route key='defaultRoute' path='*' element={<TransactionErrorModal error={{ detail: 'Unknown address' }} abort={null} retry={null} />} />]
      }
    </Routes>
  );
}
