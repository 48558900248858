import { DateInput } from '@blueprintjs/datetime';
import { ICellEditorParams } from 'ag-grid-community';
import React from 'react';
import { DateEditor } from '../../../infrastructure';

type AgGridDateEditorState = {
  date: Date | null,
  editingFinished: (suppressNavigateAfterEdit?: boolean) => void;
}

export class AgGridDateEditor extends React.Component<ICellEditorParams, AgGridDateEditorState> {

  constructor(props: ICellEditorParams) {
    super(props);

    this.state = {
      date: props.value,
      editingFinished: props.stopEditing,
    };

    this.inputRef = React.createRef<DateInput>();
  }

  private inputRef: React.RefObject<DateInput>;

  componentDidMount(): void {
    setTimeout(() => this.inputRef.current?.inputElement?.focus(), 10);
  }

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  public getValue(): Date {
    return this.state.date as Date;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  public isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  public isCancelAfterEnd(): boolean {
    if (!this.state.date) {
      return true;
    }
    return false;
  }

  public render(): JSX.Element {
    return (
      <div
        className='ag-cell'
      >
        <DateEditor
          ref={this.inputRef}
          onChange={(data) => {
            this.setState({ date: data });
            setTimeout(() => this.state.editingFinished(), 10);
          }}
          value={this.state.date}
        />
      </div>
    );
  }
}
