import { Button, Dialog, H2 } from '@blueprintjs/core';
import { ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON } from 'wacoplast_wws__api';
import { CustomFormProps, DialogBody, DialogFooter } from '../../../../infrastructure';

export function RueckmeldungIstTeilrueckmeldungAbfragen(props: CustomFormProps): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Rückmeldung durchführen'
    >
      <DialogBody>
        <H2>Art der Rückmeldung?</H2>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => props.finishUserTask(ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON({ teilrueckmeldung: false }))}>Vollständige Rückmeldung</Button>
        <Button onClick={() => props.finishUserTask(ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON({ teilrueckmeldung: true }))}>Teilrückmeldung</Button>
      </DialogFooter>
    </Dialog>
  );
}
