"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadToJSON = exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSONTyped = exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSON(json) {
    return ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSON = ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSON;
function ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_database_id': json['produktionsauftrag_database_id'],
        'menge_gut': json['menge_gut'],
        'menge_einricht_ausschuss': json['menge_einricht_ausschuss'],
        'menge_produktion_ausschuss': json['menge_produktion_ausschuss'],
        'menge_gesamt': json['menge_gesamt'],
        'prozent_einricht_ausschuss': json['prozent_einricht_ausschuss'],
        'prozent_produktions_ausschuss': json['prozent_produktions_ausschuss'],
        'technische_unterbrechung': !runtime_1.exists(json, 'technische_unterbrechung') ? undefined : json['technische_unterbrechung'],
    };
}
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSONTyped = ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSONTyped;
function ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_database_id': value.produktionsauftrag_database_id,
        'menge_gut': value.menge_gut,
        'menge_einricht_ausschuss': value.menge_einricht_ausschuss,
        'menge_produktion_ausschuss': value.menge_produktion_ausschuss,
        'menge_gesamt': value.menge_gesamt,
        'prozent_einricht_ausschuss': value.prozent_einricht_ausschuss,
        'prozent_produktions_ausschuss': value.prozent_produktions_ausschuss,
        'technische_unterbrechung': value.technische_unterbrechung,
    };
}
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadToJSON = ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadToJSON;
