import { ValidationErrorWithContext } from './ValidationErrorWithContext';

export function validationErrorTypeToMessage(error: ValidationErrorWithContext): string | undefined {
  return {
    'type_error.integer': 'Der Wert muss eine Ganzzahl sein',
    'type_error.none.not_allowed': 'Dieses Feld darf nicht leer sein.',
    'value_error.any_str.max_length': `Der Wert darf maximal ${error.ctx?.limit_value ?? 0} Zeichen haben`,
    'value_error.date': 'Ungültiges Datum',
    'value_error.missing': 'Dieses Feld darf nicht leer sein.',
    'value_error.number.not_ge': `Der Wert muss größer oder gleich ${error.ctx?.limit_value ?? 0} sein`,
    'value_error.number.not_gt': `Der Wert muss größer als ${error.ctx?.limit_value ?? 0} sein`,
    'value_error.number.not_lt': `Der Wert muss kleiner als ${error.ctx?.limit_value ?? 9999} sein`,
    'value_error.number.not_le': `Der Wert muss kleiner oder gleich ${error.ctx?.limit_value ?? 9999} sein`,
  }[error.type];
}
