import { DataModels } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Callout,
  Collapse,
  H4,
  Spinner,
} from '@blueprintjs/core';
import { ValueFormatterParams } from 'ag-grid-community';
import React, { useState } from 'react';
import {
  EmitableEventNames,
  Kundenauftrag,
  KundenauftragArt,
  KundenauftragStatus,
} from 'wacoplast_wws__api';
import {
  KundenauftragAnlegenModal,
  KundenauftragArtKeyMapping,
  KundenauftragEditierenModal,
  KundenauftragStatusKeyMapping,
} from '.';
import {
  AuthProviderContext,
  CollapseDots,
  DATE_COLUMN_OPTIONS,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGridRenderer,
  StartDialogProps,
  formatDateString,
  formatLiefertermin,
  formatWerkstoffTypNummer,
  makeForeignKeyColumn,
  numberValueFormatterGenAGGrid,
  useUserTasks,
} from '../../infrastructure';
import { RequireClaims } from '../../infrastructure/RequireClaims';
import { ProcessConstants } from '../../processes';
import { defaultColumnState } from './defaultColumnConfiguration';
import styles from './KundenauftragVerwalten.module.scss';


const QUERY_WAITING_USER_TASKS_FOR_ERFASSEN = {
  state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
  processModelId: [
    ProcessConstants['Produktionsauftrag erfassen'].processId,
    ProcessConstants['Produktionsauftrag erfassen Blasen'].processId,
    ProcessConstants['Produktionsauftrag erfassen Lager'].processId,
    ProcessConstants['Produktionsauftrag erfassen Extern'].processId,
    ProcessConstants['Produktionsauftrag durchführen Blasen'].processId,
    ProcessConstants['Kundenauftrag abwickeln'].processId,
  ],
};

export function KundenauftragVerwalten(props: PropsWithTransaction<PropsWithServices<StartDialogProps>>): JSX.Element {
  const waitingUserTasksForErfassen = useUserTasks(props.atlasEngineClient, QUERY_WAITING_USER_TASKS_FOR_ERFASSEN).filter((userTask) => {

    const customForms = ['wacoplast-wws.RückmeldungVerpackungsVorschriftWählen'];

    return userTask.processModelId !== ProcessConstants['Produktionsauftrag durchführen Blasen'].processId || customForms.includes(userTask.userTaskConfig.customForm ?? '');
  });

  return <KundenauftragVerwaltenView {...props} waitingUserTasksForErfassen={waitingUserTasksForErfassen} />;
}

export type KundenauftragVerwaltenViewProps = PropsWithTransaction<PropsWithServices<StartDialogProps>> & {
  waitingUserTasksForErfassen: DataModels.FlowNodeInstances.UserTaskInstance[];
};

type ShownModal = null | {
  modal: 'create';
  correlationId: string;
} | {
  modal: 'continue';
  correlationId: string;
} | {
  modal: 'edit';
  kundenauftrag: Kundenauftrag;
};

type KundenauftragVerwaltenViewState = {
  stammdaten: Kundenauftrag[] | null;
  showModal: ShownModal;
};

class KundenauftragVerwaltenView extends React.Component<KundenauftragVerwaltenViewProps, KundenauftragVerwaltenViewState> {

  constructor(props: KundenauftragVerwaltenViewProps) {
    super(props);
    this.state = {
      stammdaten: null,
      showModal: null,
    };
  }

  private updateStammdaten(changes: Array<Kundenauftrag>): void {
    const databaseIdsToFilter = changes.map(change => change.database_id);
    const stammdaten = this.state.stammdaten?.filter(entity => !databaseIdsToFilter.includes(entity.database_id)) ?? [];
    const stammdatenChanges = changes
      .filter((changedEntity, index) => index === changes.findIndex(entity => entity.database_id === changedEntity.database_id));
    this.setState({ stammdaten: [...stammdaten, ...stammdatenChanges] });
  }

  public componentDidMount(): void {
    this.props.subscriptionEventService.subscribe(EmitableEventNames.Kundenauftrag, (data) => this.updateStammdaten(data.affected_entities as any));
    this.loadData();
  }

  public loadData(): Promise<void> {
    return this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const stammdaten = await this.props.services.kundenauftrag.getAllKundenauftragGet({});
        this.setState({
          stammdaten,
        });
      },
    }).catch(e => {
      console.error('Error loading KUAUs', e);
    });
  }

  private openKundenauftragAnlegenModal = (): void => {
    this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const result = await this.props.atlasEngineClient.processModels.startProcessInstance({
          processModelId: ProcessConstants['Kundenauftrag abwickeln'].processId,
        });

        this.setState({
          showModal: {
            modal: 'create',
            correlationId: result.correlationId,
          },
        });
      },
    }).catch(e => {
      console.error('Error writing KUAU', e);
    });
  };

  private openKundenauftragEditierenModal = (entity: Kundenauftrag): void => {
    if (this.isEntityEditable(entity)) {
      this.setState({
        showModal: {
          modal: 'edit',
          kundenauftrag: entity,
        },
      });
    }
  };

  private isEntityEditable = (entity: Kundenauftrag): boolean => {
    return ![KundenauftragStatus.NUMBER_7, KundenauftragStatus.NUMBER_8, KundenauftragStatus.NUMBER_9].includes(entity.status);
  };

  public render(): JSX.Element {
    if (this.state.stammdaten === null) {
      return <Spinner />;
    }

    return (
      <>
        {this.state.showModal && this.state.showModal.modal !== 'edit' && (
          <KundenauftragAnlegenModal
            onModalFinished={(result) => {
              this.setState({
                showModal: null,
              });
            }}
            transactionService={this.props.transactionService}
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlationId={this.state.showModal.correlationId}
          />
        )}
        {this.state.showModal?.modal === 'edit' &&
          <KundenauftragEditierenModal
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            config={this.props.config}
            services={this.props.services}
            stammdatenEntity={this.state.showModal.kundenauftrag}
            onModalFinished={(result) => {
              this.setState({
                showModal: null,
              });
            }}
          />
        }
        <AuthProviderContext.Consumer>
          {(authProvider) => (

            <StammdatenAgGridRenderer
              title='Kundenauftrag verwalten / Produktionsauftrag anlegen'
              onCloseStartDialogClicked={() => this.props.closeStartDialog()}
              stammdaten={this.state.stammdaten}
              isVersioned={false}
              performableActions={[{ requiredClaims: ['manipulate_kundenauftrag', 'manipulate_produktionsauftrag'], type: 'create', action: this.openKundenauftragAnlegenModal }]}
              getPerformableRowActions={(entity: Kundenauftrag) => {
                if (this.isEntityEditable(entity)) {
                  return [{ requiredClaims: ['manipulate_kundenauftrag'], type: 'edit', onPerform: () => this.openKundenauftragEditierenModal(entity) }];
                }
                return [];
              }}
              defaultColumnState={defaultColumnState}
              authProvider={authProvider.authProvider}
              columnDefs={[
                {
                  headerName: 'Nummer',
                  field: 'nummer',
                  colId: 'nummer',
                  filter: 'agTextColumnFilter',
                  ...NUMMER_SORT_OPTS,
                },
                {
                  headerName: 'Datum-Eingang',
                  field: 'datum_eingang',
                  colId: 'datum_eingang',
                  valueFormatter: (val: ValueFormatterParams) => formatDateString(val.value as Date),
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Datum-Erfassung',
                  field: 'datum_erfassung',
                  colId: 'datum_erfassung',
                  valueFormatter: (val: ValueFormatterParams) => formatDateString(val.value as Date),
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Status',
                  field: 'status',
                  colId: 'status',
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    valueFormatter: ({ value }: { value: any }) => `${KundenauftragStatusKeyMapping[KundenauftragStatus[value]]} (${value})`,
                  },
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  valueFormatter: ({ value }: { value: any }) => `${KundenauftragStatusKeyMapping[KundenauftragStatus[value]]} (${value})`,
                },
                {
                  headerName: 'Liefertermin-Soll',
                  field: 'liefertermin_soll',
                  colId: 'liefertermin_soll',
                  valueFormatter: (val: ValueFormatterParams) =>
                    formatLiefertermin(val.data.liefertermin_kennzeichen, val.data.liefertermin_soll),
                  ...DATE_COLUMN_OPTIONS,
                },
                // TODO: Hier fehlt der Abwicklungspfad der KUAUs. Bei uns haben nur PAUs einen Abwicklungspfad
                {
                  headerName: 'Kunde',
                  colId: 'kunde',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.kunde.name_kurz,
                      number: data.artikel.kunde.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Bestell-Nummer',
                  field: 'bestell_nummer',
                  colId: 'bestell_nummer',
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Artikel',
                  colId: 'artikel',
                  valueGetter: ({ data }) => data.artikel.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'KU-Art-Nr.',
                  colId: 'artikel_nummer_des_kunden',
                  valueGetter: ({ data }) => data.artikel.artikel_nummer_des_kunden,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Auftragsart',
                  field: 'auftragsart',
                  colId: 'auftragsart',
                  filter: 'agTextColumnFilter',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  valueFormatter: (val: ValueFormatterParams) => KundenauftragArtKeyMapping[KundenauftragArt[val.value]],
                },
                {
                  headerName: 'Rahmenauftrag',
                  colId: 'rahmenauftrag_nummer',
                  valueGetter: ({ data }) => data.rahmenauftrag?.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Menge',
                  field: 'menge',
                  colId: 'menge',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Flaschentyp',
                  colId: 'flaschen_typ',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.flaschen_form.flaschentyp.bezeichnung,
                      number: data.artikel.flaschen_form.flaschentyp.nummer,
                    };
                  }),
                },
                {
                  headerName: 'BLZ',
                  colId: 'blinden_zeichen',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.flaschen_form.flaschentyp.blindenzeichen.bezeichnung,
                      number: data.artikel.flaschen_form.flaschentyp.blindenzeichen.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Vol. [ml]',
                  colId: 'volumen_ml',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  valueGetter: ({ data }) => data.artikel.flaschen_form.flaschentyp.volumen,
                },
                {
                  headerName: 'Halsteil',
                  colId: 'hals_teil',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.flaschen_form.hals_teil.bezeichnung,
                      number: data.artikel.flaschen_form.hals_teil.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Gewicht [g]',
                  colId: 'gewicht_g',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  valueGetter: ({ data }) => data.artikel.gewicht,
                },
                {
                  headerName: 'Farbe',
                  colId: 'farbe',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.granulat_farbe.bezeichnung,
                      number: data.artikel.granulat_farbe.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Batch-Nr.',
                  colId: 'batch_nummer',
                  valueGetter: ({ data }) => data.artikel.granulat_farbe.batch_nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Farb-%',
                  colId: 'einfaerbungs_grad',
                  valueGetter: ({ data }) => data.artikel.einfaerbungs_grad,
                  valueFormatter: numberValueFormatterGenAGGrid(1),
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Werkstoff',
                  colId: 'werkstoff',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.werkstoff.bezeichnung,
                      number: data.artikel.werkstoff.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Werkstoff Typ',
                  colId: 'werkstoff_typ',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.werkstoff_typ?.bezeichnung ?? '',
                      number: formatWerkstoffTypNummer(data.artikel.werkstoff_typ?.nummer),
                    };
                  }),
                },
                {
                  headerName: 'Empfänger',
                  colId: 'empfaenger',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.empfaenger?.name_kurz ?? '',
                      number: data.artikel.empfaenger?.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Empfänger-Auftragsnummer',
                  field: 'auftragsnummer_des_empfaenger',
                  colId: 'auftragsnummer_des_empfaenger',
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Empfänger Artikel Nr',
                  colId: 'artikel_nummer_des_empfaengers',
                  valueGetter: ({ data }) => data.artikel.artikel_nummer_des_empfaengers,
                  filter: 'agTextColumnFilter',
                },
                // TODO: Hier fehlt BLM (SHB) und mögliche BLM (WACO) der KUAUs. Sind bei uns im PAU...
                // Änderungsnummer
                // Eingangsdatum (vorher)
                // Erfassungsdatum (vorher)
                // Menge (vorher)
                // LT (vorher)
                // Artikel (vorher)
                // Anlieferort Nr (vorher)
                // Datum letzte Status Änderung
                {
                  headerName: 'Gut-Menge',
                  field: 'versandte_menge',
                  colId: 'versandte_menge',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
              // Bereitgestellte Menge (roh-FL) {nicht bei R-AU}
              // Bereitgestellte Menge (bedruckt) {nicht bei R-AU}
              ]}
              transactionService={this.props.transactionService}
            >
              <RequireClaims hideOnError claims={['manipulate_kundenauftrag', 'manipulate_produktionsauftrag']}>
                <CalloutWaitingUserTasksForErfassen
                  kundenauftraege={this.state.stammdaten ?? []}
                  waitingUserTasksForErfassen={this.props.waitingUserTasksForErfassen}
                  onKundenauftragErfassenClicked={(process_correlation_id) => {
                    this.setState({
                      showModal: {
                        modal: 'continue',
                        correlationId: process_correlation_id,
                      },
                    });
                  }}
                />
              </RequireClaims>
            </StammdatenAgGridRenderer>
          )}
        </AuthProviderContext.Consumer>
      </>
    );
  }
}

type CalloutWaitingUserTasksForErfassenProps = {
  kundenauftraege: Kundenauftrag[];
  waitingUserTasksForErfassen: DataModels.FlowNodeInstances.UserTaskInstance[];
  onKundenauftragErfassenClicked: (process_correlation_id: string) => void;
};

function CalloutWaitingUserTasksForErfassen(props: CalloutWaitingUserTasksForErfassenProps): JSX.Element | null {
  const [showPending, setShowPending] = useState(false);

  const userTasks = props.kundenauftraege.map((kundenauftrag) => {
    const process_correlation_id = kundenauftrag.process_correlation_id;
    if (!process_correlation_id) {
      return null;
    }

    const userTasks = props.waitingUserTasksForErfassen.filter((x) => x.correlationId === process_correlation_id);
    if (userTasks.length === 0) {
      return null;
    }

    return (
      <li key={kundenauftrag.database_id}>
        Kundenauftrag Nummer <b>{kundenauftrag.nummer}</b> ist noch nicht fertig angelegt:
        <ul>
          {userTasks.map((userTask) => (
            <li key={userTask.flowNodeInstanceId}>
              {userTask.flowNodeName}
              {' '}
              <Button
                small
                onClick={() => props.onKundenauftragErfassenClicked(process_correlation_id)}
              >
                Bearbeitung fortsetzen
              </Button>
            </li>
          ))}
        </ul>
      </li>
    );
  }).filter((x) => x !== null);

  if (userTasks.length === 0) {
    return null;
  }

  return (
    <Callout intent='warning' className={styles.kundenauftraege_pending_callout}>
      <H4>
        Nicht vollständig angelegte Kundenaufträge <CollapseDots onClick={() => setShowPending(!showPending)} />
      </H4>
      <Collapse isOpen={showPending}>
        <p>
          Das Anlegen der folgenden Aufträge wurde unterbrochen. Um die Aufträge anzulegen, bitte die folgenden Dialoge abschließen:
          <ul>
            {userTasks}
          </ul>
        </p>
      </Collapse>
    </Callout>
  );
}
