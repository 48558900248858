import { CreateStellblattAbstaende, StellblattAbstaende } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatFlaschenFormNummer,
  makeImageViewerRenderer,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';

import styles from './AbstaendeVerwalten.module.scss';
import abstaende from './abstaende_image.png';
import { defaultColumnState } from './defaultColumnConfiguration';

export type StellblattAbstaendeVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function StellblattAbstaendeVerwalten(props: StellblattAbstaendeVerwaltenProps): JSX.Element {
  const flaschenformResult = useFetchLikeGetAllServiceFunction(props.services.flaschenForm.getAllFlaschenFormGet, props.services.flaschenForm);

  const editors: StammdatenFieldEditors<CreateStellblattAbstaende> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['flaschen_form_database_id', 'flaschen_form_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschenformResult, (data) => {
        return { id: data.database_id, primaryTitle: formatFlaschenFormNummer(data) };
      }, 'flaschen_form'),
      label: 'Flaschenform',
      notEditable: true,
    },
    {
      type: 'label',
      label: 'Abstände Kopf - Messer - Form',
      gridArea: 'kmf_label',
    },
    {
      type: 'simple',
      field: 'kopf_messer_form_abstand_a',
      renderer: makeNumberEditorRenderer(),
      label: 'K-M-F-Abstände-a [mm]',
    },
    {
      type: 'simple',
      field: 'kopf_messer_form_abstand_b',
      renderer: makeNumberEditorRenderer(),
      label: 'K-M-F-Abstände-b [mm]',
    },
    {
      type: 'complex',
      fields: [],
      renderer: makeImageViewerRenderer(abstaende),
      gridArea: 'abstaende_image',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'K-M-F-Abstände-a [mm]',
          field: 'kopf_messer_form_abstand_a',
          colId: 'kopf_messer_form_abstand_a',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'K-M-F-Abstände-b [mm]',
          field: 'kopf_messer_form_abstand_b',
          colId: 'kopf_messer_form_abstand_b',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Flaschenform',
          field: 'flaschen_form',
          colId: 'flaschen_form',
          valueGetter: (params) => formatFlaschenFormNummer(params.data.flaschen_form),
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateStellblattAbstaende) => props.services.stellblatt_abstaende.postStellblattAbstaendePost({ CreateStellblattAbstaende: data }),
        update: (data) => props.services.stellblatt_abstaende.putByDatabaseIdStellblattAbstaendeDatabaseIdPut({ database_id: data.database_id, UpdateStellblattAbstaende: data }),
        delete: (data, ignoreIntegrity) => props.services.stellblatt_abstaende.deleteByDatabaseIdStellblattAbstaendeDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.stellblatt_abstaende.restoreByDatabaseIdStellblattAbstaendeDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.stellblatt_abstaende.getAllStellblattAbstaendeGet({ include_deleted }),
        getHistory: (data: StellblattAbstaende) => props.services.stellblatt_abstaende.getHistoryStellblattAbstaendeDatabaseIdHistoryGet({ database_id: data.database_id }),
        lock: (data) => props.services.stellblatt_abstaende.lockByDatabaseIdStellblattAbstaendeDatabaseIdLockPatch({ database_id: data.database_id, LockRequest: { aenderungsgrund: data.aenderungsgrund as any } }),
        unlock: (data) => props.services.stellblatt_abstaende.unlockByDatabaseIdStellblattAbstaendeDatabaseIdUnlockPatch({ database_id: data.database_id, UnlockRequest: { aenderungsgrund: data.aenderungsgrund } }),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      stammdatenModalCssClass={styles.abstaende_verwalten_modal}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.StellblattAbstaende}
    />
  );
}
