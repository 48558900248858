"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFahrzeugToJSON = exports.CreateFahrzeugFromJSONTyped = exports.CreateFahrzeugFromJSON = void 0;
const runtime_1 = require("../runtime");
function CreateFahrzeugFromJSON(json) {
    return CreateFahrzeugFromJSONTyped(json, false);
}
exports.CreateFahrzeugFromJSON = CreateFahrzeugFromJSON;
function CreateFahrzeugFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'bezeichnung': json['bezeichnung'],
        'ladehoehe': json['ladehoehe'],
        'eu_pal_plaetze': json['eu_pal_plaetze'],
    };
}
exports.CreateFahrzeugFromJSONTyped = CreateFahrzeugFromJSONTyped;
function CreateFahrzeugToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'bezeichnung': value.bezeichnung,
        'ladehoehe': value.ladehoehe,
        'eu_pal_plaetze': value.eu_pal_plaetze,
    };
}
exports.CreateFahrzeugToJSON = CreateFahrzeugToJSON;
