import { useEffect, useState } from 'react';

import { AtlasEngineClient, BpmnType, DataModels } from '@atlas-engine/atlas_engine_client';
import { ProcessConstants } from '../../processes';

const processes = [
  ProcessConstants['Produktionsauftrag durchführen Blasen'].processId,
  ProcessConstants['Produktionsauftrag durchführen Extern'].processId,
  ProcessConstants['Produktionsauftrag durchführen Lager'].processId,
];

// TODO we need an overall subscription system in our repositories, which notifies all clients as soon as data needs to be reloaded. then this hook would be obsolete, because the status property would be enough information to determinate which paus can be storniert https://github.com/5minds/wacoplast_wws/issues/518
export function useSubProcesses(
  atlasEngineClient: AtlasEngineClient,
): DataModels.FlowNodeInstances.FlowNodeInstance[] {

  const [flowNodeInstances, setFlowNodeInstances] = useState<DataModels.FlowNodeInstances.FlowNodeInstance[]>([]);

  useEffect(() => {
    const subscriptions = [
      atlasEngineClient.notification.onActivityReached((e) => {
        if (e.flowNodeType === BpmnType.subProcess && processes.includes(e.processModelId)) {
          setFlowNodeInstances(flowNodeInstances => [...flowNodeInstances, e as any]);
        }
      }),
      atlasEngineClient.notification.onActivityFinished(e => {
        if (e.flowNodeType === BpmnType.subProcess && processes.includes(e.processModelId)) {
          setFlowNodeInstances(flowNodeInstances => flowNodeInstances.filter((flowNodeInstance) => flowNodeInstance.flowNodeInstanceId !== e.flowNodeInstanceId));
        }
      }),
      atlasEngineClient.notification.onProcessEnded(e => {
        if (processes.includes(e.processModelId)) {
          setFlowNodeInstances(flowNodeInstances => flowNodeInstances.filter(flowNodeInstance => e.processInstanceId !== flowNodeInstance.processInstanceId));
        }
      }),

    ];

    const flowNodeInstancesPromise = atlasEngineClient.flowNodeInstances.queryFlowNodeInstances({ flowNodeType: BpmnType.subProcess, processModelId: processes, state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended });
    flowNodeInstancesPromise.then((flowNodeInstances) => setFlowNodeInstances(flowNodeInstances.flowNodeInstances));

    return () => {
      subscriptions.forEach((subscriptionPromise) => {
        subscriptionPromise.then((subscription) => {
          atlasEngineClient.notification.removeSubscription(undefined as any, subscription);
        });
      });
    };
  }, [
    atlasEngineClient,
  ]);

  return flowNodeInstances;
}
