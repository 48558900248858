/* eslint-disable react/display-name */
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { StammdatenFieldEditorSimpleRendererProps } from '.';
import { NumberEditor } from '../..';

export type NumberEditorRendererOptions = {
  placeholder?: string,
  nullable: boolean,
  showButtons: boolean
  min?: number,
  max?: number,
  formatDecimalSeparator: boolean,
}

export type NumberEditorRendererOptionsPartial = Partial<NumberEditorRendererOptions>;
export type NumberWithDecimalsEditorRendererOptionsPartial = Partial<NumberWithDecimalsEditorRendererOptions>;

const defaultNumberEditorRendererOptions: NumberEditorRendererOptions = {
  placeholder: undefined,
  nullable: false,
  showButtons: false,
  min: undefined,
  max: undefined,
  formatDecimalSeparator: true,
};

export function makeNumberEditorRenderer<TEntityType>(optionsPartial: NumberEditorRendererOptionsPartial = {}): any {
  const options: NumberEditorRendererOptions = {
    ...defaultNumberEditorRendererOptions,
    ...optionsPartial,
  };

  const removeIcon = <FontAwesomeIcon size='sm' icon={faTrash} />;

  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, number | null>) => {

    const numberEditor = (
      <NumberEditor
        min={options.min}
        max={options.max}
        value={props.value ?? '' as any}
        disabled={props.disabled}
        placeholder={options.placeholder}
        onChange={(value) => props.onChange(value)}
        showButtons={options.showButtons}
        formatDecimalSeparator={options.formatDecimalSeparator}
      />
    );

    if (!options.nullable) {
      return numberEditor;
    }

    return (
      <ButtonGroup fill>
        {numberEditor}
        <Button
          icon={removeIcon}
          className={Classes.FIXED}
          disabled={props.disabled}
          onClick={(event) => {
            event.stopPropagation();
            props.onChange(undefined as any);
          }}
        />
      </ButtonGroup>
    );
  };
}

export type NumberWithDecimalsEditorRendererOptions = {
  placeholder?: string,
  nullable: boolean,
  showButtons: boolean
  min?: number,
  max?: number,
}

const defaultNumberWithDecimalsEditorRendererOptions: NumberWithDecimalsEditorRendererOptions = {
  placeholder: undefined,
  nullable: false,
  showButtons: false,
  min: undefined,
  max: undefined,
};

export function makeNumberWithDecimalsEditorRenderer<TEntityType>(optionsPartial: NumberWithDecimalsEditorRendererOptionsPartial = {}): any {
  const options: NumberWithDecimalsEditorRendererOptions = {
    ...defaultNumberWithDecimalsEditorRendererOptions,
    ...optionsPartial,
  };
  const removeIcon = <FontAwesomeIcon size='sm' icon={faTrash} />;

  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, number | null>) => {
    const precision = 3;
    const numberEditor = (
      <NumberEditor
        min={options.min}
        max={options.max}
        value={props.value ?? '' as any}
        disabled={props.disabled}
        placeholder={options.placeholder}
        onChange={(value) => props.onChange(value)}
        showButtons={options.showButtons}
        precision={precision}
        truncateDecimalPoints={false}
      />
    );

    if (!options.nullable) {
      return numberEditor;
    }

    return (
      <ButtonGroup fill>
        {numberEditor}
        <Button
          icon={removeIcon}
          className={Classes.FIXED}
          disabled={props.disabled}
          onClick={(event) => {
            event.stopPropagation();
            props.onChange(undefined as any);
          }}
        />
      </ButtonGroup>
    );
  };
}
