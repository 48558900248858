import { ControlGroup } from '@blueprintjs/core';
import React from 'react';
import { GranulatFarbe } from 'wacoplast_wws__api';
import {
  NumberEditor,
  StammdatenFieldEditorSimpleRendererProps,
  TextViewer,
  numberValueFormatterGenAGGrid,
} from '../../infrastructure';

export function makeGranulatFarbeBestandEditor(): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorSimpleRendererProps<GranulatFarbe, number | null>): JSX.Element => {

    const [bestand] = React.useState(props.value ?? 0);
    const [zugang, setZugang] = React.useState<number | null>(0);
    const [abgang, setAbgang] = React.useState<number | null>(0);

    React.useEffect(() => {
      const newBestand = bestand + (zugang ?? 0) - (abgang ?? 0);
      props.onChange(newBestand);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zugang, abgang]);

    return (
      <ControlGroup>
        <NumberEditor
          formatDecimalSeparator={true}
          disabled={props.disabled}
          onChange={setZugang}
          value={zugang}
          placeholder='Zugang'
          precision={0}
        />
        <NumberEditor
          formatDecimalSeparator={true}
          disabled={props.disabled}
          onChange={setAbgang}
          value={abgang}
          placeholder='Abgang'
          precision={0}
        />
        <TextViewer value={`Neuer Bestand: ${numberValueFormatterGenAGGrid(0)({ value: props.value } as any)} kg`} />
      </ControlGroup>
    );
  };
}
