import { Abwicklungspfad } from 'wacoplast_wws__api';

export const AbwicklungspfadKeyMapping = {
  1: 'LA',
  3: 'BL',
  4: 'LA_BL',
  5: 'EX',
  6: 'EX_LA',
  8: 'BL_EX',
};

export function hasAbwicklungspfad(abwicklungspfad: Abwicklungspfad, partial_abwicklungspfad: Abwicklungspfad.NUMBER_1 | Abwicklungspfad.NUMBER_3 | Abwicklungspfad.NUMBER_5): boolean {
  if (partial_abwicklungspfad === Abwicklungspfad.NUMBER_1) {
    return abwicklungspfad === Abwicklungspfad.NUMBER_1 || abwicklungspfad === Abwicklungspfad.NUMBER_4 || abwicklungspfad === Abwicklungspfad.NUMBER_6;
  } else if (partial_abwicklungspfad === Abwicklungspfad.NUMBER_3) {
    return abwicklungspfad === Abwicklungspfad.NUMBER_3 || abwicklungspfad === Abwicklungspfad.NUMBER_8 || abwicklungspfad === Abwicklungspfad.NUMBER_4;
  } else {
    return abwicklungspfad === Abwicklungspfad.NUMBER_5 || abwicklungspfad === Abwicklungspfad.NUMBER_6 || abwicklungspfad === Abwicklungspfad.NUMBER_8;
  }
}
