"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateFlaschenFormToJSON = exports.UpdateFlaschenFormFromJSONTyped = exports.UpdateFlaschenFormFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateFlaschenFormFromJSON(json) {
    return UpdateFlaschenFormFromJSONTyped(json, false);
}
exports.UpdateFlaschenFormFromJSON = UpdateFlaschenFormFromJSON;
function UpdateFlaschenFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'hoehe_inkl_hals_teil_mm': json['hoehe_inkl_hals_teil_mm'],
        'flaschentyp_database_id': json['flaschentyp_database_id'],
        'flaschentyp_version_id': json['flaschentyp_version_id'],
        'hals_teil_database_id': json['hals_teil_database_id'],
        'hals_teil_version_id': json['hals_teil_version_id'],
    };
}
exports.UpdateFlaschenFormFromJSONTyped = UpdateFlaschenFormFromJSONTyped;
function UpdateFlaschenFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'hoehe_inkl_hals_teil_mm': value.hoehe_inkl_hals_teil_mm,
        'flaschentyp_database_id': value.flaschentyp_database_id,
        'flaschentyp_version_id': value.flaschentyp_version_id,
        'hals_teil_database_id': value.hals_teil_database_id,
        'hals_teil_version_id': value.hals_teil_version_id,
    };
}
exports.UpdateFlaschenFormToJSON = UpdateFlaschenFormToJSON;
