import {
  Button,
  Dialog,
  HTMLTable,
  Tag,
} from '@blueprintjs/core';
import { LagerEntry } from 'wacoplast_wws__api';
import { DialogBody, DialogFooter, LOCALE_DE } from '../../../../infrastructure';

export type LagerpostenDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  data: Array<LagerEntry>;
}

function formatNumber(value: number): string {
  return Intl.NumberFormat(LOCALE_DE, {}).format(value);
}

export function LagerpostenDialog(props: LagerpostenDialogProps): JSX.Element {
  return (props.isOpen && (
    <Dialog title='Ähnliche Lagerposten'
      style={
        { maxWidth: '66vw', width: 'auto' }}
      isOpen={true}
      onClose={props.onClose}
    >
      <DialogBody scrollHorizontalOverflow>
        <HTMLTable striped bordered>
          <thead>
            <th>PAU</th>
            <th>KUAU</th>
            <th>Kunde</th>
            <th>Empfänger</th>
            <th>Bestell-Nummer</th>
            <th>Artikelnummer-Kunde</th>
            <th>Flaschenform</th>
            <th>Flaschentyp</th>
            <th>Blindenzeichen</th>
            <th>Farbe</th>
            <th>Einfärbungsgrad</th>
            <th>Gewicht [g]</th>
            <th>Werkstofftyp</th>
            <th>Hat-Bezeichnung</th>
            <th>Verpackungseinheit</th>
            <th>Flaschen je Einheit</th>
            <th>VPE je Stapel</th>
            <th>Flaschen je Stapel</th>
            <th>Höhe Stapel</th>
            <th>Anzahl Kartons</th>
            <th>Anzahl Stapel</th>
            <th>Bestand</th>
            <th>Lagerort</th>
            <th>Lagerplatz</th>
            <th>Liefertermin-Soll</th>
            <th>Lagerzugang-PR</th>
            <th>Auslieferung (Datum/Menge)</th>
            <th>Bestellmenge</th>
            <th>Menge Zugang PR (Stück)</th>
            <th>Buchung von</th>
            <th>Buchung am</th>
            <th>Letzer Vorgang</th>
            <th>Reserviert für</th>
            <th>Reserviert durch</th>
            <th>Reserviert am</th>
            <th>gesperrt</th>
            <th>Hinweis</th>
          </thead>
          <tbody>
            {
              props.data.map((lagerEntry, index) => {
                return (
                  <tr key={index}>
                    <td>{lagerEntry.pau_nummer}</td>
                    <td>{lagerEntry.kuau_nummer}</td>
                    <td>{lagerEntry.kunde_name_kurz} <Tag minimal>{lagerEntry.kunde_nummer}</Tag></td>
                    <td>{lagerEntry.empfaenger_name_kurz} <Tag minimal>{lagerEntry.empfaenger_nummer}</Tag></td>
                    <td>{lagerEntry.bestell_nummer}</td>
                    <td>{lagerEntry.artikel_nummer_des_kunde}</td>
                    <td>{lagerEntry.flaschen_form_nummer}</td>
                    <td>{lagerEntry.flaschen_typ_bezeichnung}</td>
                    <td>{lagerEntry.blindenzeichen}</td>
                    <td>{lagerEntry.granulat_farbe} <Tag minimal>{lagerEntry.granulat_farbe_nummer}</Tag></td>
                    <td>{lagerEntry.einfaerbungs_grad}</td>
                    <td>{lagerEntry.gewicht}</td>
                    <td>{lagerEntry.werkstofftyp}</td>
                    <td>{lagerEntry.hat_bezeichnung}</td>
                    <td>{lagerEntry.verpackungseinheit} <Tag minimal>{lagerEntry.verpackungseinheit_nummer}</Tag></td>
                    <td>{lagerEntry.flaschen_je_verpackungseinheit ? formatNumber(lagerEntry.flaschen_je_verpackungseinheit) : null}</td>
                    <td>{lagerEntry.anzahl_verpackungseinheiten_je_stapel ? formatNumber(lagerEntry.anzahl_verpackungseinheiten_je_stapel) : null}</td>
                    <td>{lagerEntry.flaschen_je_stapel ? formatNumber(lagerEntry.flaschen_je_stapel) : null}</td>
                    <td>{lagerEntry.hoehe_stapel ? formatNumber(lagerEntry.hoehe_stapel) : null}</td>
                    <td>{lagerEntry.anzahl_kartons ? formatNumber(lagerEntry.anzahl_kartons) : null}</td>
                    <td>{lagerEntry.anzahl_stapel}</td>
                    <td>{lagerEntry.bestand_stueckzahl ? formatNumber(lagerEntry.bestand_stueckzahl) : null }</td>
                    <td>{lagerEntry.lagerort}</td>
                    <td>{lagerEntry.lagerplatz}</td>
                    <td>{lagerEntry.liefertermin_soll}</td>
                    <td>{lagerEntry.datum_lagerzugang_pr}</td>
                    <td>{lagerEntry.auslieferung_datum_und_menge}</td>
                    <td>{lagerEntry.bestellmenge ? formatNumber(lagerEntry.bestellmenge) : null}</td>
                    <td>{lagerEntry.menge_zugang_pr_in_stueck ? formatNumber(lagerEntry.menge_zugang_pr_in_stueck) : null}</td>
                    <td>{lagerEntry.buchung_von}</td>
                    <td>{lagerEntry.buchung_am}</td>
                    <td>{lagerEntry.letzter_vorgang}</td>
                    <td>{lagerEntry.reserviert_fuer_kunde}</td>
                    <td>{lagerEntry.reserviert_durch}</td>
                    <td>{lagerEntry.reserviert_am}</td>
                    <td>{lagerEntry.gesperrt}</td>
                    <td>{lagerEntry.hinweis}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </HTMLTable>
      </DialogBody>
      <DialogFooter>
        <Button onClick={props.onClose}>Fertig</Button>
      </DialogFooter>
    </Dialog>
  )) || <></>;
}
