/* eslint-disable react/display-name */
import { Switch } from '@blueprintjs/core';
import { Blasform } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps } from '../../../infrastructure';

export function makeWechselbaresBlindenzeichenSwitchEditorRenderer(): any {
  return (props: StammdatenFieldEditorComplexRendererProps<Blasform>) => {
    return (
      <Switch
        checked={props.stammdatenEntity.wechselbares_blindenzeichen ?? false}
        disabled={props.disabled}
        onChange={(event) => props.onChange({ wechselbares_blindenzeichen: event.currentTarget.checked, blindenzeichen: undefined })}
      />
    );
  };
}
