import {
  Button,
  Dialog,
  H2,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import React from 'react';
import { ProduktionsplanBlasmaschinenWaehlenResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  EmptyObject,
  ModalProps,
  PropsWithServices,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';

export function ProduktionsplanBlasmaschinenWaehlen(props: PropsWithServices<CustomFormProps<EmptyObject>> & ModalProps<void>): JSX.Element {

  const abort = (): void => {
    props.finishUserTask({ response: 'abort' });
    props.onModalFinished();
  };

  const blasmaschinen = useFetchLikeGetAllServiceFunction(props.services.blasmaschine.getAllStellblattBlasmaschineGet, props.services.blasmaschine);

  const [blasmaschinenDatabaseIds, setBlasmaschinenDatabaseIds] = React.useState<Array<number>>([]);

  const finish = (): void => {
    props.finishUserTask(ProduktionsplanBlasmaschinenWaehlenResultToJSON({
      blasmaschinen: blasmaschinenDatabaseIds,
    }));
  };

  return (
    <Dialog isOpen onClose={abort} title='Produktionsplan drucken'>
      <DialogBody>
        <H2>Blasmaschine wählen</H2>
        <RadioGroup
          onChange={(event) => event.currentTarget.value === 'all' ? setBlasmaschinenDatabaseIds(blasmaschinen.data?.map(blasmaschine => blasmaschine.database_id) ?? []) : setBlasmaschinenDatabaseIds([])}
          selectedValue={blasmaschinenDatabaseIds.length > 1 ? 'all' : 'one'}
        >
          <Radio label='Alle Blasmaschinen' value='all' />
          <Radio label='Eine Blasmaschine' value='one' />
        </RadioGroup>
        <DefaultDropDownPicker
          disabled={blasmaschinenDatabaseIds.length > 1}
          dataProvider={blasmaschinen}
          onChange={(value) => setBlasmaschinenDatabaseIds(value ? [value.database_id] : [])}
          value={blasmaschinenDatabaseIds.length === 1 ? blasmaschinen.data?.find(blasmaschine => blasmaschine.database_id === blasmaschinenDatabaseIds[0]) : null}
          getDisplayData={(data) => {
            return { primaryTitle: data?.nummer.toString() ?? '' };
          }}
        />
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Fertig</Button>
        <Button onClick={abort}>Abbruch</Button>
      </DialogFooter>
    </Dialog>
  );
}
