"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlaschenFormApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FlaschenFormApi extends runtime.BaseAPI {
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdFlaschenFormDatabaseIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling deleteByDatabaseIdFlaschenFormDatabaseIdDelete.');
            }
            if (requestParameters.DeleteRequest === null || requestParameters.DeleteRequest === undefined) {
                throw new runtime.RequiredError('DeleteRequest', 'Required parameter requestParameters.DeleteRequest was null or undefined when calling deleteByDatabaseIdFlaschenFormDatabaseIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DeleteRequestToJSON(requestParameters.DeleteRequest),
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdFlaschenFormDatabaseIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteByDatabaseIdFlaschenFormDatabaseIdDeleteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get All
     */
    getAllFlaschenFormGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.include_deleted !== undefined) {
                queryParameters['include_deleted'] = requestParameters.include_deleted;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FlaschenFormFromJSON));
        });
    }
    /**
     * Get All
     */
    getAllFlaschenFormGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllFlaschenFormGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdFlaschenFormDatabaseIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getByDatabaseIdFlaschenFormDatabaseIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenFormFromJSON(jsonValue));
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdFlaschenFormDatabaseIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getByDatabaseIdFlaschenFormDatabaseIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get History
     */
    getHistoryFlaschenFormDatabaseIdHistoryGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getHistoryFlaschenFormDatabaseIdHistoryGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/{database_id}/history`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FlaschenFormFromJSON));
        });
    }
    /**
     * Get History
     */
    getHistoryFlaschenFormDatabaseIdHistoryGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHistoryFlaschenFormDatabaseIdHistoryGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdFlaschenFormDatabaseIdLockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling lockByDatabaseIdFlaschenFormDatabaseIdLockPatch.');
            }
            if (requestParameters.LockRequest === null || requestParameters.LockRequest === undefined) {
                throw new runtime.RequiredError('LockRequest', 'Required parameter requestParameters.LockRequest was null or undefined when calling lockByDatabaseIdFlaschenFormDatabaseIdLockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/{database_id}/lock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.LockRequestToJSON(requestParameters.LockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenFormFromJSON(jsonValue));
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdFlaschenFormDatabaseIdLockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.lockByDatabaseIdFlaschenFormDatabaseIdLockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Post
     */
    postFlaschenFormPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.CreateFlaschenForm === null || requestParameters.CreateFlaschenForm === undefined) {
                throw new runtime.RequiredError('CreateFlaschenForm', 'Required parameter requestParameters.CreateFlaschenForm was null or undefined when calling postFlaschenFormPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CreateFlaschenFormToJSON(requestParameters.CreateFlaschenForm),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenFormFromJSON(jsonValue));
        });
    }
    /**
     * Post
     */
    postFlaschenFormPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postFlaschenFormPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdFlaschenFormDatabaseIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling putByDatabaseIdFlaschenFormDatabaseIdPut.');
            }
            if (requestParameters.UpdateFlaschenForm === null || requestParameters.UpdateFlaschenForm === undefined) {
                throw new runtime.RequiredError('UpdateFlaschenForm', 'Required parameter requestParameters.UpdateFlaschenForm was null or undefined when calling putByDatabaseIdFlaschenFormDatabaseIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UpdateFlaschenFormToJSON(requestParameters.UpdateFlaschenForm),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenFormFromJSON(jsonValue));
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdFlaschenFormDatabaseIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.putByDatabaseIdFlaschenFormDatabaseIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdFlaschenFormDatabaseIdRestorePatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling restoreByDatabaseIdFlaschenFormDatabaseIdRestorePatch.');
            }
            if (requestParameters.RestoreRequest === null || requestParameters.RestoreRequest === undefined) {
                throw new runtime.RequiredError('RestoreRequest', 'Required parameter requestParameters.RestoreRequest was null or undefined when calling restoreByDatabaseIdFlaschenFormDatabaseIdRestorePatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/{database_id}/restore`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RestoreRequestToJSON(requestParameters.RestoreRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenFormFromJSON(jsonValue));
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdFlaschenFormDatabaseIdRestorePatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.restoreByDatabaseIdFlaschenFormDatabaseIdRestorePatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdFlaschenFormDatabaseIdUnlockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling unlockByDatabaseIdFlaschenFormDatabaseIdUnlockPatch.');
            }
            if (requestParameters.UnlockRequest === null || requestParameters.UnlockRequest === undefined) {
                throw new runtime.RequiredError('UnlockRequest', 'Required parameter requestParameters.UnlockRequest was null or undefined when calling unlockByDatabaseIdFlaschenFormDatabaseIdUnlockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_form/{database_id}/unlock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UnlockRequestToJSON(requestParameters.UnlockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenFormFromJSON(jsonValue));
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdFlaschenFormDatabaseIdUnlockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.unlockByDatabaseIdFlaschenFormDatabaseIdUnlockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.FlaschenFormApi = FlaschenFormApi;
