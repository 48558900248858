import { format, parse } from 'date-fns';
import { LieferterminKennzeichen } from 'wacoplast_wws__api';

export function formatDateTimeString(date: Date): string {
  return format(date, 'dd.MM.yyyy, HH:mm');
}

export function parseDateTimeString(date: string): Date {
  return parse(date, 'd.L.yyyy, HH:mm', new Date());
}

export function formatDateString(date: Date): string {
  return format(date, 'dd.MM.yyyy');
}

export function parseDateString(date: string): Date {
  return parse(date, 'd.L.yyyy', new Date());
}

export function isValidDate(date: any): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export function formatLiefertermin(liefertermin_kennzeichen: LieferterminKennzeichen, liefertermin_soll: Date | null): string {
  if (liefertermin_kennzeichen === LieferterminKennzeichen.KW && liefertermin_soll) {
    return `KW ${format(liefertermin_soll, 'I R')}`;
  } else if (liefertermin_kennzeichen === LieferterminKennzeichen.T && liefertermin_soll) {
    return formatDateString(liefertermin_soll);
  } else if (liefertermin_kennzeichen === LieferterminKennzeichen.W) {
    return 'wahlfrei';
  }
  return 'Ungültiger Liefertermin';
}

export function dateComparator(valueA: Date, valueB: Date): number {
  const a = valueA?.setHours(0, 0, 0, 0);
  const b = valueB?.setHours(0, 0, 0, 0);

  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined && b !== undefined) {
    return 1;
  } else if (a !== undefined && b === undefined) {
    return -1;
  }

  if (a > b) {
    return -1;
  } else if (a < b) {
    return 1;
  }

  return 0;
}

export const DATE_COLUMN_OPTIONS = {
  filter: 'agDateColumnFilter',
  filterParams: {
    comparator: dateComparator,
  },
  comparator: dateComparator,
};
