"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FahrzeugZuKundeToJSON = exports.FahrzeugZuKundeFromJSONTyped = exports.FahrzeugZuKundeFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FahrzeugZuKundeFromJSON(json) {
    return FahrzeugZuKundeFromJSONTyped(json, false);
}
exports.FahrzeugZuKundeFromJSON = FahrzeugZuKundeFromJSON;
function FahrzeugZuKundeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'kunde_database_id': json['kunde_database_id'],
        'kunde_version_id': json['kunde_version_id'],
        'empfaenger_database_id': json['empfaenger_database_id'],
        'empfaenger_version_id': json['empfaenger_version_id'],
        'fahrzeug_database_id': json['fahrzeug_database_id'],
        'fahrzeug_version_id': json['fahrzeug_version_id'],
        'ladehoehe': json['ladehoehe'],
        'prioritaet': json['prioritaet'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'kunde': _1.FirmaFromJSON(json['kunde']),
        'empfaenger': _1.FirmaFromJSON(json['empfaenger']),
        'fahrzeug': _1.FahrzeugFromJSON(json['fahrzeug']),
    };
}
exports.FahrzeugZuKundeFromJSONTyped = FahrzeugZuKundeFromJSONTyped;
function FahrzeugZuKundeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'empfaenger_database_id': value.empfaenger_database_id,
        'empfaenger_version_id': value.empfaenger_version_id,
        'fahrzeug_database_id': value.fahrzeug_database_id,
        'fahrzeug_version_id': value.fahrzeug_version_id,
        'ladehoehe': value.ladehoehe,
        'prioritaet': value.prioritaet,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'kunde': _1.FirmaToJSON(value.kunde),
        'empfaenger': _1.FirmaToJSON(value.empfaenger),
        'fahrzeug': _1.FahrzeugToJSON(value.fahrzeug),
    };
}
exports.FahrzeugZuKundeToJSON = FahrzeugZuKundeToJSON;
