/* eslint-disable no-constant-condition */
import React, { createContext } from 'react';
import { TransactionErrorModal } from './TransactionErrorModal';
import { TransactionLoadingModal } from './TransactionLoadingModal';
import { ITransaction, ITransactionService, ModalComponent } from './';
import { mapErrorMessageToGerman } from '../validation/mapErrorMessageToGerman';


export type PropsWithTransaction<T> = T & {
  transactionService: ITransactionService;
};

type ErrorDialogResult = 'retry' | 'abort';

export type TodoStackEntryModal<TResult> = {
  type: 'modal';
  modal: ModalComponent<TResult>;

  handleModalClosed: (result: TResult) => void;
}

export type TodoStackEntryTransaction = {
  type: 'transaction';
  transaction: ITransaction;

  isLoading: boolean;
  error: any | null;

  handleErrorDialogClosed: (result: ErrorDialogResult) => void;
}

export type TodoStackEntry = TodoStackEntryTransaction | TodoStackEntryModal<any>;

export type TransactionProviderProps = React.PropsWithChildren<unknown>;

export type TransactionProviderState = {
  todoStack: Array<TodoStackEntry>;
};

const TransactionContext = createContext<ITransactionService>({
  runTransaction: () => {
    throw new Error('No context');
  },
  openModal: () => {
    throw new Error('No context');
  },
  isProcessing: () => false,
});

export function withTransactionService<TProps>(Component: React.ComponentClass<PropsWithTransaction<TProps>> | React.FunctionComponent<PropsWithTransaction<TProps>>) {
  // eslint-disable-next-line react/display-name
  return (props: TProps) => {
    return (
      <TransactionContext.Consumer>
        {(transactionService) => (
          <Component {...props} transactionService={transactionService} />
        )}
      </TransactionContext.Consumer>
    );
  };
}

export class TransactionProvider extends React.Component<TransactionProviderProps, TransactionProviderState> implements ITransactionService {

  constructor(props: TransactionProviderProps) {
    super(props);

    this.state = {
      todoStack: [],
    };
  }

  private removeTransactionTodoStackEntry(targetTransaction: ITransaction): void {
    this.setState(state => {
      const entries = [...state.todoStack];
      const indexToRemove = entries.findIndex(entry => entry.type === 'transaction' && entry.transaction === targetTransaction);

      entries.splice(indexToRemove, 1);

      return {
        todoStack: entries,
      };
    });
  }

  private pushNewTodoStackEntry(newTodoEntry: TodoStackEntry): void {
    this.setState(state => {
      const entries = [
        newTodoEntry,
        ...state.todoStack,
      ];

      return {
        todoStack: entries,
      };
    });
  }

  private updateTransactionTopTodoStackEntry(targetTransaction: ITransaction, updated: Partial<TodoStackEntryTransaction>): void {
    this.setState(state => {
      const entries = [...state.todoStack];
      const indexToUpdate = entries.findIndex(entry => entry.type === 'transaction' && entry.transaction === targetTransaction);

      entries[indexToUpdate] = {
        ...entries[indexToUpdate],
        ...updated,
      } as any;

      return {
        todoStack: entries,
      };
    });
  }

  public isProcessing(): boolean {
    return false;
  }

  public async openModal<TResult>(modal: ModalComponent<TResult>): Promise<TResult> {
    return new Promise<TResult>((resolve, reject) => {
      const handleModalClosed = (result: TResult): void => {
        this.setState(state => ({
          todoStack: state.todoStack.slice(1),
        }), () => {
          resolve(result);
        });
      };

      this.pushNewTodoStackEntry({
        type: 'modal',
        modal,
        handleModalClosed,
      });
    });
  }

  public async runTransaction(
    transaction: ITransaction,
  ): Promise<void> {
    this.pushNewTodoStackEntry({
      type: 'transaction',

      error: null,
      isLoading: true,
      transaction: transaction,
      handleErrorDialogClosed: (result: ErrorDialogResult) => {},
    });

    do {
      try {
        await transaction.execute();
        break;
      } catch (e: any) {
        const errorDialogResult = await new Promise<ErrorDialogResult>((resolve) => {
          const onErrorDialogFinished = (result: ErrorDialogResult): void => {
            resolve(result);
          };
          if (e.json) {
            e.json().then((error: any) => {
              error = mapErrorMessageToGerman(error);
              this.updateTransactionTopTodoStackEntry(transaction, {
                handleErrorDialogClosed: onErrorDialogFinished,
                isLoading: false,
                error: error,
              });
            });
          } else {
            this.updateTransactionTopTodoStackEntry(transaction, {
              handleErrorDialogClosed: onErrorDialogFinished,
              isLoading: false,
              error: e,
            });
          }
        });

        if (errorDialogResult === 'abort') {
          this.removeTransactionTodoStackEntry(transaction);

          if (transaction.onAborted) {
            transaction.onAborted();
          }

          throw e;
        }

        this.updateTransactionTopTodoStackEntry(transaction, {
          isLoading: true,
          error: null,
        });
      }
    } while (true);


    this.removeTransactionTodoStackEntry(transaction);

    if (transaction.postExecute) {
      await transaction.postExecute();
    }
  }

  private renderModals(): JSX.Element | null {
    if (this.state.todoStack.length === 0) {
      return null;
    }

    const topTodoStackEntry = this.state.todoStack[0];

    if (topTodoStackEntry.type !== 'transaction') {
      const resolveFunction = topTodoStackEntry.handleModalClosed;
      return <topTodoStackEntry.modal onModalFinished={resolveFunction} />;
    }

    if (topTodoStackEntry.isLoading) {
      return <TransactionLoadingModal loadingMessage={topTodoStackEntry.transaction.loadingMessage} />;
    }

    if (topTodoStackEntry.error !== null) {
      const resolveFunction = topTodoStackEntry.handleErrorDialogClosed;
      const abort = topTodoStackEntry.transaction.isAbortable
        ? () => resolveFunction('abort')
        : null;

      const retry = topTodoStackEntry.transaction.isRetryable
        ? () => resolveFunction('retry')
        : null;

      return (
        <TransactionErrorModal
          error={topTodoStackEntry.error}
          abort={abort}
          retry={retry}
        />
      );
    }

    return null;
  }

  public render(): JSX.Element {
    return (
      <TransactionContext.Provider value={this}>
        {this.renderModals()}
        {this.props.children}
      </TransactionContext.Provider>
    );
  }
}
