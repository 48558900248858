import {
  Button,
  Dialog,
  H2,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import React from 'react';
import { UmbauplanSortierkriteriumWaehlenResult, UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum, UmbauplanSortierkriteriumWaehlenResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
} from '../../../infrastructure';

export function UmbauplanSortierkriteriumWaehlen(props: CustomFormProps<UmbauplanSortierkriteriumWaehlenResult> & ModalProps<void>): JSX.Element {

  const abort = (): void => {
    props.finishUserTask({ response: 'abort' });
    props.onModalFinished();
  };

  const [sortierkriterium, setSortierkriterium] = React.useState<UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum>(UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum.Datum);

  const finish = (): void => {
    props.finishUserTask(UmbauplanSortierkriteriumWaehlenResultToJSON({
      sortierkriterium,
    }));
  };

  return (
    <Dialog isOpen onClose={abort} title='Umbauplan drucken'>
      <DialogBody>
        <H2>Sortierkriterium auswählen</H2>
        <RadioGroup
          onChange={(event) => setSortierkriterium(UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum[event.currentTarget.value as keyof typeof UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum])}
          selectedValue={sortierkriterium ?? undefined}
        >
          <Radio label='Datum' value={UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum.Datum} />
          <Radio label='Blasmaschine' value={UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum.Blasmaschine} />
        </RadioGroup>
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Fertig</Button>
        <Button onClick={abort}>Abbruch</Button>
      </DialogFooter>
    </Dialog>
  );
}
