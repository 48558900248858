import { CreateFlaschenForm, FlaschenForm } from 'wacoplast_wws__api';
import {
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatFlaschenFormNummer,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type FlaschenFormVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function FlaschenFormVerwalten(props: FlaschenFormVerwaltenProps): JSX.Element {

  const flaschentypResult = useFetchLikeGetAllServiceFunction(props.services.flaschenTyp.getAllFlaschenTypGet, props.services.flaschenTyp);
  const halsteilResult = useFetchLikeGetAllServiceFunction(props.services.halsTeil.getAllHalsTeilGet, props.services.halsTeil);

  const editors: StammdatenFieldEditors<CreateFlaschenForm> = [
    {
      type: 'complex',
      fields: ['flaschentyp_database_id', 'flaschentyp_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschentypResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'flaschentyp'),
      label: 'Flaschentyp',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['hals_teil_database_id', 'hals_teil_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(halsteilResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'hals_teil'),
      label: 'Halsteil',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'hoehe_inkl_hals_teil_mm',
      renderer: makeNumberEditorRenderer({
        min: 0,
        max: 500,
      }),
      label: 'Höhe inkl. Halsteil [mm]',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          colId: 'nummer',
          valueGetter: (data) => formatFlaschenFormNummer(data.data),
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Höhe inkl. Halsteil [mm]',
          field: 'hoehe_inkl_hals_teil_mm',
          colId: 'hoehe_inkl_hals_teil_mm',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Flaschentyp',
          colId: 'flaschen_typ',
          ...makeForeignKeyColumn('flaschentyp', (data: FlaschenForm) => {
            return {
              description: data.flaschentyp.bezeichnung,
              number: data.flaschentyp.nummer,
            };
          }),
        },
        {
          headerName: 'Halsteil',
          colId: 'hals_teil',
          ...makeForeignKeyColumn('hals_teil', (data: FlaschenForm) => {
            return {
              description: data.hals_teil.bezeichnung,
              number: data.hals_teil.nummer,
            };
          }),
        },
      ]}
      stammdatenService={{
        create: (data: CreateFlaschenForm) => props.services.flaschenForm.postFlaschenFormPost({ CreateFlaschenForm: data }),
        update: (data) => props.services.flaschenForm.putByDatabaseIdFlaschenFormDatabaseIdPut(
          {
            database_id: data.database_id,
            UpdateFlaschenForm: data,
          },
        ),
        delete: (data, ignoreIntegrity) => props.services.flaschenForm.deleteByDatabaseIdFlaschenFormDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.flaschenForm.restoreByDatabaseIdFlaschenFormDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.flaschenForm.getAllFlaschenFormGet({ include_deleted }),
        getHistory: (data) => props.services.flaschenForm.getHistoryFlaschenFormDatabaseIdHistoryGet({ database_id: data.database_id }),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.Flaschenform}
    />
  );
}
