/* eslint-disable @typescript-eslint/naming-convention */
export const KundenauftragStatusKeyMapping = {
  NUMBER_1: 'erfasst',
  NUMBER_3: 'Abwicklung_geplant',
  NUMBER_4: 'In_arbeit',
  NUMBER_5: 'Fertig_produziert_Teilmenge',
  NUMBER_6: 'Fertig_produziert',
  NUMBER_7: 'Teillieferung_erfolgt',
  NUMBER_8: 'Ausgeliefert',
  NUMBER_9: 'Storniert',
};
