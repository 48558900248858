"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateGewichtstoleranzToJSON = exports.CreateGewichtstoleranzFromJSONTyped = exports.CreateGewichtstoleranzFromJSON = void 0;
const runtime_1 = require("../runtime");
function CreateGewichtstoleranzFromJSON(json) {
    return CreateGewichtstoleranzFromJSONTyped(json, false);
}
exports.CreateGewichtstoleranzFromJSON = CreateGewichtstoleranzFromJSON;
function CreateGewichtstoleranzFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ausnahme': json['ausnahme'],
        'gewicht_von': json['gewicht_von'],
        'gewicht_bis': !runtime_1.exists(json, 'gewicht_bis') ? undefined : json['gewicht_bis'],
        'toleranz': json['toleranz'],
        'flaschentyp_database_id': !runtime_1.exists(json, 'flaschentyp_database_id') ? undefined : json['flaschentyp_database_id'],
        'flaschentyp_version_id': !runtime_1.exists(json, 'flaschentyp_version_id') ? undefined : json['flaschentyp_version_id'],
        'kunde_database_id': !runtime_1.exists(json, 'kunde_database_id') ? undefined : json['kunde_database_id'],
        'kunde_version_id': !runtime_1.exists(json, 'kunde_version_id') ? undefined : json['kunde_version_id'],
    };
}
exports.CreateGewichtstoleranzFromJSONTyped = CreateGewichtstoleranzFromJSONTyped;
function CreateGewichtstoleranzToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ausnahme': value.ausnahme,
        'gewicht_von': value.gewicht_von,
        'gewicht_bis': value.gewicht_bis,
        'toleranz': value.toleranz,
        'flaschentyp_database_id': value.flaschentyp_database_id,
        'flaschentyp_version_id': value.flaschentyp_version_id,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
    };
}
exports.CreateGewichtstoleranzToJSON = CreateGewichtstoleranzToJSON;
