"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateVerpackungsEinheitToJSON = exports.CreateVerpackungsEinheitFromJSONTyped = exports.CreateVerpackungsEinheitFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateVerpackungsEinheitFromJSON(json) {
    return CreateVerpackungsEinheitFromJSONTyped(json, false);
}
exports.CreateVerpackungsEinheitFromJSON = CreateVerpackungsEinheitFromJSON;
function CreateVerpackungsEinheitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': json['nummer'],
        'material_gruppe': _1.MaterialGruppeFromJSON(json['material_gruppe']),
        'bezeichnung_lang': json['bezeichnung_lang'],
        'bezeichnung_kurz': json['bezeichnung_kurz'],
        'durchmesser_mm': !runtime_1.exists(json, 'durchmesser_mm') ? undefined : json['durchmesser_mm'],
        'gewicht_kg': !runtime_1.exists(json, 'gewicht_kg') ? undefined : json['gewicht_kg'],
        'laenge_mm': !runtime_1.exists(json, 'laenge_mm') ? undefined : json['laenge_mm'],
        'breite_mm': !runtime_1.exists(json, 'breite_mm') ? undefined : json['breite_mm'],
        'pro_lage_bei_euro_palette': !runtime_1.exists(json, 'pro_lage_bei_euro_palette') ? undefined : json['pro_lage_bei_euro_palette'],
    };
}
exports.CreateVerpackungsEinheitFromJSONTyped = CreateVerpackungsEinheitFromJSONTyped;
function CreateVerpackungsEinheitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'material_gruppe': _1.MaterialGruppeToJSON(value.material_gruppe),
        'bezeichnung_lang': value.bezeichnung_lang,
        'bezeichnung_kurz': value.bezeichnung_kurz,
        'durchmesser_mm': value.durchmesser_mm,
        'gewicht_kg': value.gewicht_kg,
        'laenge_mm': value.laenge_mm,
        'breite_mm': value.breite_mm,
        'pro_lage_bei_euro_palette': value.pro_lage_bei_euro_palette,
    };
}
exports.CreateVerpackungsEinheitToJSON = CreateVerpackungsEinheitToJSON;
