import { DataModels } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  H2,
  Spinner,
} from '@blueprintjs/core';
import React from 'react';
import { KundenauftragStornierungAbfragen, KundenauftragdetailsAngeben } from '.';
import {
  ProduktionsauftragBlasenErfassen,
  ProduktionsauftragBlasenVerpackungsVorschriftWaehlen,
  ProduktionsauftragErfassen,
  ProduktionsauftragExternErfassen,
  ProduktionsauftragLagerErfassen,
} from '..';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  IdentityProvider,
  ModalProps,
  PropsWithTransaction,
  RenderSequentialUserTasks,
  withBoundProps,
} from '../../infrastructure';
import { PropsWithServices, withServices } from '../../infrastructure/withServices';


export type KundenauftragAnlegenModalResult = {
  kundenauftrag_erstellt: boolean
};


export type KundenauftragAnlegenModalProps = PropsWithTransaction<PropsWithServices<ModalProps<KundenauftragAnlegenModalResult>>> &
{
  getIdentity: IdentityProvider;
  correlationId: string;
};

export class KundenauftragAnlegenModal extends React.Component<KundenauftragAnlegenModalProps> {

  private abort = (): void => {
    this.props.onModalFinished({ kundenauftrag_erstellt: false });
  };

  private finish = (): void => {
    this.props.onModalFinished({ kundenauftrag_erstellt: true });
  };

  public getComponentForCustomForm = (userTask: DataModels.FlowNodeInstances.UserTaskInstance): React.ComponentType<CustomFormProps> | null => {
    const customForm = userTask.userTaskConfig.customForm;

    const props = {
      subscriptionEventService: this.props.subscriptionEventService,
      atlasEngineClient: this.props.atlasEngineClient,
      config: this.props.config,
      getIdentity: this.props.getIdentity,
      services: this.props.services,
      transactionService: this.props.transactionService,
    };

    if (customForm === 'wacoplast-wws.KundenauftragdetailsAngeben') {
      return withBoundProps(KundenauftragdetailsAngeben, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragErfassen') {
      return withBoundProps(ProduktionsauftragErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragLagerMengeErfassen') {
      return withBoundProps(ProduktionsauftragLagerErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragBlasenErfassen') {
      return withBoundProps(ProduktionsauftragBlasenErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragExternMengeErfassen') {
      return withBoundProps(ProduktionsauftragExternErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.RückmeldungVerpackungsVorschriftWählen') {
      return withServices(withBoundProps(ProduktionsauftragBlasenVerpackungsVorschriftWaehlen as any, { onFinish: this.finish, onAbort: this.abort }));
    }

    if (customForm === 'wacoplast-wws.KundenauftragStornierungAbfragen') {
      return withBoundProps(KundenauftragStornierungAbfragen as any, { ...props, finish: this.finish });
    }

    if (customForm === 'wacoplast-wws.KundenauftragErfolgreichAngelegt') {
      return (props: CustomFormProps) => {
        return (
          <Dialog
            isOpen={true}
            onClose={() => props.abortUserTask()}
            title='Kundenauftrag anlegen'
          >
            <DialogBody>
              <H2>Der Kundenauftrag wurde erfolgreich angelegt</H2>
            </DialogBody>
            <DialogFooter>
              <Button onClick={() => {
                return props.finishUserTask({});
              }}
              >
                Okay
              </Button>
            </DialogFooter>
          </Dialog>
        );
      };
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragErfasstHinweis') {
      return (props: CustomFormProps) => {
        return (
          <Dialog
            isOpen={true}
            onClose={() => props.abortUserTask()}
            title='Kundenauftrag anlegen'
          >
            <DialogBody>
              <H2>Die Produktionsaufträge wurden erfolgreich angelegt</H2>
            </DialogBody>
            <DialogFooter>
              <Button onClick={() => {
                this.finish();
                return props.finishUserTask({});
              }}
              >
                Okay
              </Button>
            </DialogFooter>
          </Dialog>
        );
      };
    }

    return null;
  };


  public render(): JSX.Element | null {
    const loadingModal = (
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          {this.props.correlationId && <Button onClick={this.abort} intent='warning'>Ausblenden</Button>}
        </DialogFooter>
      </Dialog>
    );

    if (!this.props.correlationId) {
      return loadingModal;
    }

    return (
      <RenderSequentialUserTasks
        atlasEngineClient={this.props.atlasEngineClient}
        correlationId={this.props.correlationId}
        getIdentity={this.props.getIdentity}
        getComponent={this.getComponentForCustomForm}
        handleUserTaskAborted={(api, userTask, result) => {
          if (userTask.userTaskConfig.customForm === 'wacoplast-wws.KundenauftragdetailsAngeben') {
            this.abort();
          } else {
            this.finish();
          }
          return api.finishUserTask(result ?? { response: 'abort' });
        }}
      >
        {loadingModal}
      </RenderSequentialUserTasks>
    );
  }
}
