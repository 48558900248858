"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePackordnungToJSON = exports.CreatePackordnungFromJSONTyped = exports.CreatePackordnungFromJSON = void 0;
const runtime_1 = require("../runtime");
function CreatePackordnungFromJSON(json) {
    return CreatePackordnungFromJSONTyped(json, false);
}
exports.CreatePackordnungFromJSON = CreatePackordnungFromJSON;
function CreatePackordnungFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'flaschen_form_database_id': json['flaschen_form_database_id'],
        'flaschen_form_version_id': json['flaschen_form_version_id'],
        'verpackungs_einheit_database_id': json['verpackungs_einheit_database_id'],
        'verpackungs_einheit_version_id': json['verpackungs_einheit_version_id'],
        'prioritaet': !runtime_1.exists(json, 'prioritaet') ? undefined : json['prioritaet'],
        'anzahl_flaschen': json['anzahl_flaschen'],
        'fuellweise': !runtime_1.exists(json, 'fuellweise') ? undefined : json['fuellweise'],
        'packvorschrift_file_hash': !runtime_1.exists(json, 'packvorschrift_file_hash') ? undefined : json['packvorschrift_file_hash'],
    };
}
exports.CreatePackordnungFromJSONTyped = CreatePackordnungFromJSONTyped;
function CreatePackordnungToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'flaschen_form_database_id': value.flaschen_form_database_id,
        'flaschen_form_version_id': value.flaschen_form_version_id,
        'verpackungs_einheit_database_id': value.verpackungs_einheit_database_id,
        'verpackungs_einheit_version_id': value.verpackungs_einheit_version_id,
        'prioritaet': value.prioritaet,
        'anzahl_flaschen': value.anzahl_flaschen,
        'fuellweise': value.fuellweise,
        'packvorschrift_file_hash': value.packvorschrift_file_hash,
    };
}
exports.CreatePackordnungToJSON = CreatePackordnungToJSON;
