import { BpmnValidationError, HTTPValidationError } from 'wacoplast_wws__api';
import { ValidationErrorWithContext } from './ValidationErrorWithContext';

export function findMatchingValidationErrors(httpValidationError: HTTPValidationError | undefined | null, fieldLocation: string | Array<string> | Array<Array<string>>): ValidationErrorWithContext[] {
  const matchingErrors: ValidationErrorWithContext[] = [];

  if (httpValidationError?.detail) {
    for (const validationError of httpValidationError.detail) {
      if (matchesFieldLocation(fieldLocation, validationError)) {
        matchingErrors.push(validationError);
      }
    }
  }

  return matchingErrors;
}

export function removeDuplicateValidationErrors(validationErrors: ValidationErrorWithContext[]): ValidationErrorWithContext[] {
  const reducedErrors: ValidationErrorWithContext[] = [];

  for (const validationError of validationErrors) {
    if (!reducedErrors.some((reducedError) => reducedError.type === validationError.type && reducedError.msg === validationError.msg)) {
      reducedErrors.push(validationError);
    }
  }

  return reducedErrors;
}

export function matchesFieldLocation(fieldLocation: string | Array<string> | Array<Array<string>>, validationError: ValidationErrorWithContext): boolean {
  if (Array.isArray(fieldLocation)) {

    if (fieldLocation.length && Array.isArray(fieldLocation[0])) {
      return fieldLocation.some((fieldLoc) => validationError.loc.length === 2 && validationError.loc[0] === 'body' && validationError.loc[1] === fieldLoc[1]);
    }

    if (validationError.loc.length === fieldLocation.length) {
      let found = true;
      for (let i = 0; i < fieldLocation.length; i++) {
        if (validationError.loc[i] !== fieldLocation[i]) {
          found = false;
          break;
        }
      }
      return found;
    }

    return false;
  }

  return validationError.loc.length === 2 && validationError.loc[0] === 'body' && validationError.loc[1] === fieldLocation;
}

export function updateValidationError(obj: any, lastValidationError: BpmnValidationError | null): BpmnValidationError {
  const details = Object.keys(obj).reduce(
    (validationError: HTTPValidationError | null | undefined, updatedKey) => removeFieldFromValidationError(validationError, [updatedKey]),
    lastValidationError,
  ) as any;

  return {
    ...details,
    previous_payload: lastValidationError?.previous_payload ?? {},
  };
}


export function removeFieldFromValidationError(validationError: HTTPValidationError | null | undefined, fieldLocation: string | Array<string>): HTTPValidationError | null | undefined {
  if (validationError === undefined || validationError === null || validationError.detail === undefined || validationError.detail === null) {
    return validationError;
  }

  return {
    detail: validationError.detail.filter(error => !matchesFieldLocation(fieldLocation, error)),
  };
}

export function getNamespacedValidationErrors(validationError: HTTPValidationError | null | undefined, prefix: string): HTTPValidationError | null | undefined {
  if (validationError === undefined || validationError === null || validationError.detail === undefined || validationError.detail === null) {
    return validationError;
  }

  const namespacedErrors: ValidationErrorWithContext[] = [];

  for (const error of validationError.detail) {
    if (error.loc.length >= 1 && error.loc[0] === prefix) {
      namespacedErrors.push({
        ...error,
        loc: error.loc.slice(1),
      });
    }
  }

  return {
    detail: namespacedErrors,
  };
}
