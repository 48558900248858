import { Spinner } from '@blueprintjs/core';
import React, { PropsWithChildren, useEffect } from 'react';
import { AuthProviderContext } from './processcube';
import { TransactionErrorModal } from './transactions/TransactionErrorModal';

enum AuthorizationState {
  Pending,
  Authorized,
  Unauthorized
}

export function RequireClaims(props: PropsWithChildren<{ claims: Array<string>, hideOnError?: boolean }>): JSX.Element {

  const [authorizationState, setAuthorizationState] = React.useState<AuthorizationState>(AuthorizationState.Pending);
  const authProvider = React.useContext(AuthProviderContext).authProvider;

  useEffect(() => {
    setAuthorizationState(AuthorizationState.Pending);
    authProvider.hasClaims(props.claims).then(hasClaims => {
      if (hasClaims) {
        setAuthorizationState(AuthorizationState.Authorized);
      } else {
        setAuthorizationState(AuthorizationState.Unauthorized);
      }
    });
  }, [authProvider, props.claims]);

  if (authorizationState === AuthorizationState.Pending) {
    return <Spinner />;
  } else if (authorizationState === AuthorizationState.Authorized) {
    return <>{props.children}</>;
  } else {
    if (props.hideOnError) {
      return (<></>);
    }
    return (
      <TransactionErrorModal
        error={{ detail: 'Missing Authorization: ' }}
        retry={null}
        abort={null}
      />
    );
  }
}
