import { Tag } from '@blueprintjs/core';
import { DropDownPicker } from '.';
import { CommonEditorProps } from '..';
import { UseFetchLikeServiceFunctionResult, makeDefaultfDropDownItemRenderer } from '../..';

export type getDefaultDisplayData<TEntryType> = (data: TEntryType) => { primaryTitle: string, secondaryTitle?: string };

export type DefaultDropDownPickerProps<TEntryType> = CommonEditorProps<TEntryType | null> & {
  dataProvider: UseFetchLikeServiceFunctionResult<Array<TEntryType>>,
  nullable?: boolean,
  getDisplayData: getDefaultDisplayData<TEntryType>,
  filter?: (data: TEntryType) => boolean,
};

export function DefaultDropDownPicker<TEntryType>(props: DefaultDropDownPickerProps<TEntryType>): JSX.Element {
  return (
    <DropDownPicker
      {...props}
      itemRenderer={makeDefaultfDropDownItemRenderer(props.getDisplayData)}
      valueRenderer={(item) => {
        const displayData = props.getDisplayData(item);
        return (
          <>
            {displayData.primaryTitle}
            {' '}
            {displayData?.secondaryTitle !== undefined &&
              <Tag minimal={true}>
                {displayData?.secondaryTitle}
              </Tag>
            }
          </>
        );
      }}
      filter={(query, data) => {
        const displayData = props.getDisplayData(data);
        const matchesDescription = displayData.primaryTitle.toLowerCase().includes(query.toLowerCase());
        const matchesNumber = displayData.secondaryTitle?.includes(query) === true;
        const matchesCustomFilter = props.filter ? props.filter(data) : true;
        return (matchesDescription || matchesNumber) && matchesCustomFilter;
      }}
    />
  );
}
