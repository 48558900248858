import {
  Button,
  Dialog,
  H2,
  Spinner,
} from '@blueprintjs/core';
import { MaschinenlaufplanErstellenResult, PapiereBestaetigenModalResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
} from '../../../infrastructure';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export function PapiereBestaetigenDialog(props: PropsWithServices<CustomFormProps<MaschinenlaufplanErstellenResult> & ModalProps<void>>): JSX.Element {
  const finish = (papiere_korrekt: boolean): void => {
    props.finishUserTask(PapiereBestaetigenModalResultToJSON({
      papiere_korrekt,
    }));
  };

  const abort = (): void => {
    props.finishUserTask({ response: 'abort' });
    props.onModalFinished();
  };

  const generated_pdf = props.tokenPayload.generated_pdfs.find(pdf => pdf.file_name === 'Eingabemaske.pdf');

  return (
    <Dialog isOpen onClose={abort} title='Papiere drucken'>
      <DialogBody>
        <H2>Sind diese Daten korrekt?</H2>
        <Document
          error={'PDF konnte leider nicht geladen werden'}
          loading={<><Spinner />Eingabemaske wird geladen</>}
          file={`${props.config.restServiceUrl}/file_storage/${generated_pdf?.file_group}/${generated_pdf?.file_name}/${generated_pdf?.hash}`}
        >
          <Page pageNumber={1} />
        </Document>
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={() => finish(true)}>Ja</Button>
        <Button intent='danger' onClick={() => finish(false)}>Nein</Button>
      </DialogFooter>
    </Dialog>
  );
}
