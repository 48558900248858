"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StellblattAbstaendeToJSON = exports.StellblattAbstaendeFromJSONTyped = exports.StellblattAbstaendeFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StellblattAbstaendeFromJSON(json) {
    return StellblattAbstaendeFromJSONTyped(json, false);
}
exports.StellblattAbstaendeFromJSON = StellblattAbstaendeFromJSON;
function StellblattAbstaendeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'kopf_messer_form_abstand_a': !runtime_1.exists(json, 'kopf_messer_form_abstand_a') ? undefined : json['kopf_messer_form_abstand_a'],
        'kopf_messer_form_abstand_b': !runtime_1.exists(json, 'kopf_messer_form_abstand_b') ? undefined : json['kopf_messer_form_abstand_b'],
        'flaschen_form_database_id': json['flaschen_form_database_id'],
        'flaschen_form_version_id': json['flaschen_form_version_id'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'flaschen_form': _1.FlaschenFormFromJSON(json['flaschen_form']),
    };
}
exports.StellblattAbstaendeFromJSONTyped = StellblattAbstaendeFromJSONTyped;
function StellblattAbstaendeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'kopf_messer_form_abstand_a': value.kopf_messer_form_abstand_a,
        'kopf_messer_form_abstand_b': value.kopf_messer_form_abstand_b,
        'flaschen_form_database_id': value.flaschen_form_database_id,
        'flaschen_form_version_id': value.flaschen_form_version_id,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'flaschen_form': _1.FlaschenFormToJSON(value.flaschen_form),
    };
}
exports.StellblattAbstaendeToJSON = StellblattAbstaendeToJSON;
