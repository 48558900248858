import { CreateHalsTeil } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeTextEditorRenderer,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';
import { FileDownloadCellRenderer } from './FileDownloadCellRenderer';
import { makeFilePickerRenderer } from './HalsTeilFilePicker';

export type HalsTeilVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function HalsTeilVerwalten(props: HalsTeilVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateHalsTeil> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
    {
      type: 'simple',
      field: 'verwendung',
      renderer: makeTextEditorRenderer(),
      label: 'Verwendung',
    },
    {
      type: 'simple',
      field: 'bemerkung',
      renderer: makeTextEditorRenderer(),
      label: 'Bemerkung',
    },
    {
      type: 'simple',
      field: 'specification_files',
      renderer: makeFilePickerRenderer(props.services.fileStorage),
      label: 'Spezifikationsdateien',
    },
  ];

  return (
    <StammdatenAgGrid<any, CreateHalsTeil>
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Bezeichnung',
          field: 'bezeichnung',
          colId: 'bezeichnung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Verwendung',
          field: 'verwendung',
          colId: 'verwendung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Bemerkung',
          field: 'bemerkung',
          colId: 'bemerkung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Spezifikationsdateien',
          field: 'specification_files',
          colId: 'specification_files',
          cellRenderer: FileDownloadCellRenderer,
          cellRendererParams: {
            restServiceUrl: props.config.restServiceUrl,
          },
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: async (data) => props.services.halsTeil.postHalsTeilPost({ CreateHalsTeil: data }),
        update: async (data) => props.services.halsTeil.putByDatabaseIdHalsTeilDatabaseIdPut({ database_id: data.database_id, UpdateHalsTeil: data }),
        delete: (data, ignoreIntegrity) => props.services.halsTeil.deleteByDatabaseIdHalsTeilDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.halsTeil.restoreByDatabaseIdHalsTeilDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.halsTeil.getAllHalsTeilGet({ include_deleted }),
        getHistory: (data) => props.services.halsTeil.getHistoryHalsTeilDatabaseIdHistoryGet({ database_id: data.database_id }),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      defaultEntityForCreation={{ specification_files: [] }}
      dialogStrings={DialogStrings.Halsteil}
    />
  );
}
