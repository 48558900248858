"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultToJSON = exports.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSONTyped = exports.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSON = void 0;
const _1 = require("./");
function RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSON(json) {
    return RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSONTyped(json, false);
}
exports.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSON = RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSON;
function RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsphase': _1.ValidatedProduktionsphaseFromJSON(json['produktionsphase']),
    };
}
exports.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSONTyped = RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSONTyped;
function RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsphase': _1.ValidatedProduktionsphaseToJSON(value.produktionsphase),
    };
}
exports.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultToJSON = RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultToJSON;
