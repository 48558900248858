"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON = exports.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSONTyped = exports.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSON = void 0;
function ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSON(json) {
    return ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSON = ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSON;
function ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'teilrueckmeldung': json['teilrueckmeldung'],
    };
}
exports.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSONTyped = ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSONTyped;
function ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'teilrueckmeldung': value.teilrueckmeldung,
    };
}
exports.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON = ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON;
