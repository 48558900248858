"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragStatusToJSON = exports.ProduktionsauftragStatusFromJSONTyped = exports.ProduktionsauftragStatusFromJSON = exports.ProduktionsauftragStatus = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var ProduktionsauftragStatus;
(function (ProduktionsauftragStatus) {
    ProduktionsauftragStatus[ProduktionsauftragStatus["NUMBER_1"] = 1] = "NUMBER_1";
    ProduktionsauftragStatus[ProduktionsauftragStatus["NUMBER_3"] = 3] = "NUMBER_3";
    ProduktionsauftragStatus[ProduktionsauftragStatus["NUMBER_4"] = 4] = "NUMBER_4";
    ProduktionsauftragStatus[ProduktionsauftragStatus["NUMBER_5"] = 5] = "NUMBER_5";
    ProduktionsauftragStatus[ProduktionsauftragStatus["NUMBER_6"] = 6] = "NUMBER_6";
    ProduktionsauftragStatus[ProduktionsauftragStatus["NUMBER_9"] = 9] = "NUMBER_9";
})(ProduktionsauftragStatus = exports.ProduktionsauftragStatus || (exports.ProduktionsauftragStatus = {}));
function ProduktionsauftragStatusFromJSON(json) {
    return ProduktionsauftragStatusFromJSONTyped(json, false);
}
exports.ProduktionsauftragStatusFromJSON = ProduktionsauftragStatusFromJSON;
function ProduktionsauftragStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ProduktionsauftragStatusFromJSONTyped = ProduktionsauftragStatusFromJSONTyped;
function ProduktionsauftragStatusToJSON(value) {
    return value;
}
exports.ProduktionsauftragStatusToJSON = ProduktionsauftragStatusToJSON;
