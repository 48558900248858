export function comparator(a: any, b: any): number {
  if (a === undefined && b === undefined) {
    return 0;
  } else if ((isNaN(a) && !isNaN(b) && (a)) || (a !== undefined && b === undefined) || (a !== null && b === null)) {
    return 1;
  } else if ((!isNaN(a) && isNaN(b) && b) || (a === undefined && b !== undefined) || (a === null && b !== null)) {
    return -1;
  } else if (a && b && isNaN(a) && isNaN(b)) {
    const _a = String(a);
    const _b = String(b);
    return _a.toLowerCase().trim().localeCompare(_b.toLowerCase().trim());
  }

  return Number(a) - Number(b);
}
