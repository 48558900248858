import { MenuItem } from '@blueprintjs/core';
import { DropDownPicker } from '.';
import { CommonEditorProps } from '..';

export type getEnumDisplayData<TEntryType> = (data: TEntryType) => string;

export type EnumDropDownPickerProps<TEntryType> = CommonEditorProps<TEntryType | null> & {
  data: Array<TEntryType>;
  getDisplayData: getEnumDisplayData<TEntryType>;
  nullable?: boolean;
};

export function EnumDropDownPicker<TEntryType>(props: EnumDropDownPickerProps<TEntryType>): JSX.Element {
  return (
    <DropDownPicker
      {...props}
      nullable={props.nullable}
      dataProvider={{ data: props.data, error: null, isLoading: false, reload: () => { } }}
      itemRenderer={(item, itemProps): JSX.Element => (
        <MenuItem
          text={(item && props.getDisplayData(item)) ?? '---'}
          onClick={itemProps?.handleClick}
          key={itemProps?.index}
          selected={itemProps?.modifiers.active}
        />
      )}
      valueRenderer={(item): JSX.Element => {
        const displayData = props.getDisplayData(item);
        return <>{displayData}</>;
      }}
      filter={(query, data) => data && props.getDisplayData(data)?.toLowerCase()?.includes(query.toLowerCase())}
    />
  );
}
