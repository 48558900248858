"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateStellblattAbstaendeToJSON = exports.UpdateStellblattAbstaendeFromJSONTyped = exports.UpdateStellblattAbstaendeFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateStellblattAbstaendeFromJSON(json) {
    return UpdateStellblattAbstaendeFromJSONTyped(json, false);
}
exports.UpdateStellblattAbstaendeFromJSON = UpdateStellblattAbstaendeFromJSON;
function UpdateStellblattAbstaendeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'kopf_messer_form_abstand_a': !runtime_1.exists(json, 'kopf_messer_form_abstand_a') ? undefined : json['kopf_messer_form_abstand_a'],
        'kopf_messer_form_abstand_b': !runtime_1.exists(json, 'kopf_messer_form_abstand_b') ? undefined : json['kopf_messer_form_abstand_b'],
        'flaschen_form_database_id': json['flaschen_form_database_id'],
        'flaschen_form_version_id': json['flaschen_form_version_id'],
    };
}
exports.UpdateStellblattAbstaendeFromJSONTyped = UpdateStellblattAbstaendeFromJSONTyped;
function UpdateStellblattAbstaendeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'kopf_messer_form_abstand_a': value.kopf_messer_form_abstand_a,
        'kopf_messer_form_abstand_b': value.kopf_messer_form_abstand_b,
        'flaschen_form_database_id': value.flaschen_form_database_id,
        'flaschen_form_version_id': value.flaschen_form_version_id,
    };
}
exports.UpdateStellblattAbstaendeToJSON = UpdateStellblattAbstaendeToJSON;
