"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RahmenauftragStatusToJSON = exports.RahmenauftragStatusFromJSONTyped = exports.RahmenauftragStatusFromJSON = exports.RahmenauftragStatus = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var RahmenauftragStatus;
(function (RahmenauftragStatus) {
    RahmenauftragStatus[RahmenauftragStatus["NUMBER_1"] = 1] = "NUMBER_1";
    RahmenauftragStatus[RahmenauftragStatus["NUMBER_2"] = 2] = "NUMBER_2";
    RahmenauftragStatus[RahmenauftragStatus["NUMBER_3"] = 3] = "NUMBER_3";
    RahmenauftragStatus[RahmenauftragStatus["NUMBER_4"] = 4] = "NUMBER_4";
    RahmenauftragStatus[RahmenauftragStatus["NUMBER_5"] = 5] = "NUMBER_5";
    RahmenauftragStatus[RahmenauftragStatus["NUMBER_9"] = 9] = "NUMBER_9";
})(RahmenauftragStatus = exports.RahmenauftragStatus || (exports.RahmenauftragStatus = {}));
function RahmenauftragStatusFromJSON(json) {
    return RahmenauftragStatusFromJSONTyped(json, false);
}
exports.RahmenauftragStatusFromJSON = RahmenauftragStatusFromJSON;
function RahmenauftragStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RahmenauftragStatusFromJSONTyped = RahmenauftragStatusFromJSONTyped;
function RahmenauftragStatusToJSON(value) {
    return value;
}
exports.RahmenauftragStatusToJSON = RahmenauftragStatusToJSON;
