/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  EditableCallback,
  GetMainMenuItemsParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  MenuItemDef,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { addDays, differenceInCalendarDays, isBefore } from 'date-fns';

import {
  AbwicklungspfadProduktionsauftrag,
  Blasmaschine,
  EmitableEventNames,
  Kundenauftrag,
  PlantafelEntry,
  Produktionsauftrag,
  ProduktionsauftragBlasen,
  ProduktionsauftragStatus,
  QHinweisProdukt,
  WerkstoffTyp,
} from 'wacoplast_wws__api';

import { DataModels } from '@atlas-engine/atlas_engine_client';
import { Button, H2, Tag } from '@blueprintjs/core';
import { faArrowLeft, faInfoCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgGridBLMEditor,
  AgGridDateEditor,
  AgGridLieferterminEditor,
  AgGridWerkstoffTypEditor,
} from '.';
import { AgGridThemeContext } from '../../App';
import {
  AsyncTag,
  DATE_COLUMN_OPTIONS,
  PropsWithServices,
  PropsWithTransaction,
  StartDialogProps,
  UseFetchLikeServiceFunctionResult,
  agGridLocaleDE,
  applyColumnStateOnColumnDefs,
  comparator,
  dateValueFormatter,
  formatLiefertermin,
  makeForeignKeyColumn,
  numberValueFormatterGenAGGrid,
  processCellForClipboard,
  resetColumnState,
  storeColumnStateOnChanges,
  useFetchLikeGetAllServiceFunction,
  useRunningProcesses,
  useSubProcesses,
  useUserTasks,
} from '../../infrastructure';
import { ProcessConstants } from '../../processes';
import { StornierenModals, renderStornierenModal, stornieren } from '../auftragStornieren';
import { KundenauftragStatusKeyMapping } from '../kundenauftrag';
import { defaultColumnState } from './defaultColumnConfiguration';
import {
  GranulatFarbeBedarfVorschauModal,
  MaschinenlaufplanModal,
  PlantafelRueckmeldungModal,
  PlantafelRueckmeldungModalResult,
  ProduktionsplanModal,
  QHinweisProduktModal,
  RueckstellMessprotokollModal,
  UmbauplanModal,
} from './modals';
import styles from './Plantafel.module.scss';
import { PlantafelProduktionsauftragEntry } from './PlantafelProduktionsauftragEntry';
import './plantafel_aggrid.scss';
import { RequireClaims } from '../../infrastructure/RequireClaims';


type PlantafelValueSetterParams = { data: PlantafelProduktionsauftragEntry } & Omit<ValueSetterParams, 'data'>;

type PlantafelValueGetter = (params: { data: PlantafelProduktionsauftragEntry } & Omit<ValueGetterParams, 'data'>) => string | number | null | undefined | Date | WerkstoffTyp;
type PlantafelValueSetter = (params: PlantafelValueSetterParams) => boolean;


type PlantafelColDef = Omit<ColDef, 'field' | 'valueGetter' | 'valueSetter' | 'colId'> & {
  valueGetter: PlantafelValueGetter;
  valueSetter?: PlantafelValueSetter;
  colId: string;
};

type PlantafelColGroupDef = Omit<ColGroupDef, 'children'> & {
  children: PlantafelColDef[];
}

type PlantafelColDefs = (PlantafelColGroupDef | PlantafelColDef)[];

type PlantafelProps = PropsWithTransaction<PropsWithServices<StartDialogProps>> & {
  runningSubprocesses: DataModels.FlowNodeInstances.FlowNodeInstance[];
  runningProcesses: DataModels.ProcessInstances.ProcessInstance[];
  runningHinweisUserTasks: DataModels.FlowNodeInstances.UserTaskInstance[];
  blasmaschinen: UseFetchLikeServiceFunctionResult<Array<Blasmaschine>>;
  qhinweise: UseFetchLikeServiceFunctionResult<Array<QHinweisProdukt>>;
  werkstofftypen: UseFetchLikeServiceFunctionResult<Array<WerkstoffTyp>>;
  agGridTheme: string;
};

type PlantafelState = {
  showModal: {
    type: 'mlp';
    correlation_id: string;
  } | {
    type: 'umbauplan';
    correlation_id: string;
  } | {
    type: 'produktionsplan';
    correlation_id: string;
  } | {
    type: 'granulat_farbe_bedarf_vorschau';
    correlation_id: string;
  } | {
    type: 'rueckstell_messprotokoll';
    correlation_id: string;
  } | null;
  data: Array<PlantafelProduktionsauftragEntry>;
  stornierenModal: StornierenModals;
  displayedDataIsFiltered: boolean;
};

const groupSafeValueGetter = (fn: PlantafelValueGetter): PlantafelValueGetter => {
  return (params) => {
    if (params.node?.group === true) {
      return null;
    }
    return fn(params);
  };
};

const RUNNING_PROCESSES_QUERY = [ProcessConstants['Papiere drucken'].processId, ProcessConstants['Umbauplan drucken'].processId, ProcessConstants['Produktionsplan drucken'].processId];

const QUERY_HINWEIS_FOR_FINISHED_RUECKMELDUNG_USER_TASK: DataModels.FlowNodeInstances.UserTaskQuery = {
  state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
  processModelId: [
    ProcessConstants['Produktionsauftrag durchführen Blasen'].processId,
  ],
  flowNodeId: ['Activity_0cd77s1'],
};

export function Plantafel(props: PropsWithTransaction<PropsWithServices<StartDialogProps>>): JSX.Element {
  const runningSubprocesses = useSubProcesses(props.atlasEngineClient);
  const runningProcesses = useRunningProcesses(props.atlasEngineClient, RUNNING_PROCESSES_QUERY);
  const runningHinweisUserTasks = useUserTasks(props.atlasEngineClient, QUERY_HINWEIS_FOR_FINISHED_RUECKMELDUNG_USER_TASK);

  const qhinweise = useFetchLikeGetAllServiceFunction(props.services.qHinweis.getAllQHinweisProduktGet, props.services.qHinweis);
  const blasmaschinen = useFetchLikeGetAllServiceFunction(props.services.blasmaschine.getAllStellblattBlasmaschineGet, props.services.blasmaschine);
  const werkstofftypen = useFetchLikeGetAllServiceFunction(props.services.werkstoffTyp.getAllWerkstoffTypGet, props.services.werkstoffTyp);

  return (
    <AgGridThemeContext.Consumer>
      {({ agGridTheme }) => (
        <PlantafelView
          {...props}
          qhinweise={qhinweise}
          blasmaschinen={blasmaschinen}
          werkstofftypen={werkstofftypen}
          runningSubprocesses={runningSubprocesses}
          runningProcesses={runningProcesses}
          runningHinweisUserTasks={runningHinweisUserTasks}
          agGridTheme={agGridTheme}
        />
      )}
    </AgGridThemeContext.Consumer>
  );

}

class PlantafelView extends React.Component<PlantafelProps, PlantafelState> {

  private gridApi: GridApi | null = null;
  private gridColumnApi: ColumnApi | null = null;

  constructor(props: PlantafelProps) {
    super(props);
    this.state = {
      data: [],
      stornierenModal: null,
      showModal: null,
      displayedDataIsFiltered: false,
    };
  }

  private updateData(updatedData: Array<PlantafelEntry>, oldData: Array<PlantafelProduktionsauftragEntry>): void {
    const databaseIdsToFilter = updatedData.map(entry => entry.produktionsauftrag_blasen.database_id);
    const filteredOldData = oldData.filter(entry => !databaseIdsToFilter.includes(entry.produktionsauftrag_blasen.database_id));
    const filteredNewData = updatedData
      .filter((entry, index) => index === updatedData.findIndex(find_entry => find_entry.produktionsauftrag_blasen.database_id === entry.produktionsauftrag_blasen.database_id));
    if (filteredOldData.length === this.state.data.length && filteredNewData.length === 0) {
      return;
    }
    this.setState({ data: [...filteredOldData, ...filteredNewData] });
  }

  public async componentDidMount(): Promise<void> {
    this.props.subscriptionEventService.subscribe(EmitableEventNames.Kundenauftrag, (data) => {
      const kundenauftraege: Array<Kundenauftrag> = data.affected_entities as any;
      kundenauftraege.forEach(kuau => {
        this.props.services.plantafelEntryApi.getAllPlantafelEntryGet({ kundenauftrag_database_id: kuau.database_id }).then(updated_entries => {
          this.updateData(updated_entries as any, this.state.data);
        });
      });
    });
    this.props.subscriptionEventService.subscribe(EmitableEventNames.Produktionsauftrag, (data) => {
      const produktionsauftraege: Array<Produktionsauftrag> = data.affected_entities as any;
      const blasen_produktionsauftraege: Array<ProduktionsauftragBlasen> = produktionsauftraege
        .filter(pau => pau.abwicklungspfad === AbwicklungspfadProduktionsauftrag.blasen) as any;
      const paus_with_wrong_status_database_ids = blasen_produktionsauftraege
        .filter(pau => ![ProduktionsauftragStatus.NUMBER_3, ProduktionsauftragStatus.NUMBER_4, ProduktionsauftragStatus.NUMBER_5].includes(pau.status))
        .map(pau => pau.database_id);
      const filtered_data = this.state.data.filter(entry => !paus_with_wrong_status_database_ids.includes(entry.produktionsauftrag_blasen.database_id));
      blasen_produktionsauftraege
        .map(pau => pau.blasmaschine_database_id)
        .filter((blasmaschine_database_id, index, array) => array.findIndex(entry => entry === blasmaschine_database_id) === index)
        .forEach(blasmaschine_database_id => {
          this.props.services.plantafelEntryApi.getAllPlantafelEntryGet({ blasmaschine_database_id: blasmaschine_database_id }).then(updated_entries => {
            this.updateData(updated_entries as any, filtered_data);
          });
        });
    });
    this.props.subscriptionEventService.subscribe(EmitableEventNames.Blasmaschine, (data) => {
      this.props.blasmaschinen.reload();
    });
    this.props.subscriptionEventService.subscribe(EmitableEventNames.WerkstoffTyp, (data) => {
      this.props.werkstofftypen.reload();
    });
    this.props.subscriptionEventService.subscribe(EmitableEventNames.QHinweisProdukt, (data) => {
      this.props.qhinweise.reload();
    });
    await this.loadData();
    this.gridColumnApi?.autoSizeAllColumns();
  }

  private async loadData(): Promise<void> {
    return this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const plantafelEntries = await this.props.services.plantafelEntryApi.getAllPlantafelEntryGet({});

        this.setState({
          data: plantafelEntries,
        });
        this.gridApi?.setRowData(plantafelEntries);
      },
    }).catch(e => {
      console.error('Error while loading Plantafel', e);
    });
  }

  private async openRueckmeldungModal(plantafelEntry: PlantafelProduktionsauftragEntry): Promise<void> {
    await this.props.transactionService.openModal<PlantafelRueckmeldungModalResult>((props) => (
      <PlantafelRueckmeldungModal
        {...props}
        subscriptionEventService={this.props.subscriptionEventService}
        transactionService={this.props.transactionService}
        atlasEngineClient={this.props.atlasEngineClient}
        services={this.props.services}
        getIdentity={this.props.getIdentity}
        config={this.props.config}
        plantafelEntry={plantafelEntry}
      />
    ));
  }

  private openPapiereDruckenModal(correlation_id: string): void {
    this.setState({
      showModal: { type: 'mlp', correlation_id },
    });
  }

  private async startProcessAndOpenModal(processId: string, modalType: 'granulat_farbe_bedarf_vorschau' | 'umbauplan' | 'produktionsplan' | 'rueckstell_messprotokoll'): Promise<void> {
    let runningProcessCorrelationId = this.props.runningProcesses.find((process) => process.processModelId === processId)?.correlationId;
    if (runningProcessCorrelationId === undefined) {
      runningProcessCorrelationId = (await this.props.atlasEngineClient.processModels.startProcessInstance({ processModelId: processId })).correlationId;
    }
    this.setState({
      showModal: { type: modalType, correlation_id: runningProcessCorrelationId },
    });
  }

  private transactionRestValueSetter = (fn: (params: PlantafelValueSetterParams) => Promise<Array<PlantafelProduktionsauftragEntry> | ProduktionsauftragBlasen | null>): PlantafelValueSetter => {
    return (params) => {
      this.props.transactionService.runTransaction({
        isRetryable: false,
        isAbortable: true,
        loadingMessage: 'Daten werden gespeichert...',
        execute: async () => {
          const value = await fn(params);
          if (value === null) {
            return;
          }

          if (Array.isArray(value)) {
            const database_ids = value.map((entry) => entry.produktionsauftrag_blasen.database_id);
            this.setState({
              data: [...value, ...this.state.data.filter((entry) => !database_ids.includes(entry.produktionsauftrag_blasen.database_id))],
            }, () => params.api.refreshCells());
          } else {
            this.setState({
              data: this.state.data.map((entry) => {
                if (entry.produktionsauftrag_blasen.database_id === value.database_id) {
                  return {
                    ...entry,
                    produktionsauftrag_blasen: value,
                  };
                }
                return entry;
              }),
            }, () => {
              params.api.refreshCells({
                rowNodes: [params.node as any],
                columns: [params.column],
              });
            });
          }
        },
      })
        .catch(e => {
          console.error('Error writing plantafel value', e);
        });

      return false;
    };
  };

  onGridReady = (params: GridReadyEvent): void => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    storeColumnStateOnChanges('plantafel', this.gridApi, this.gridColumnApi);
  };

  public render(): JSX.Element {
    const groupCellsNotEditable: EditableCallback = (parms) => {
      return parms.node.group !== true && !this.props.transactionService.isProcessing() && this.props.blasmaschinen.error === null;
    };

    const onlyGroupCellsEditable: EditableCallback = (parms) => {
      return parms.node.group === true && !this.props.transactionService.isProcessing() && this.props.blasmaschinen.error === null;
    };


    const colDefs: PlantafelColDefs = [
      {
        headerName: 'PAU',
        colId: 'produktionsauftrag_blasen',
        pinned: 'left',
        valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.nummer),
        filter: 'agTextColumnFilter',
        cellClassRules: {
          highlight_pau_nr_if_pau_is_new: (params) => {
            if (params.node.group === true) {
              return false;
            }

            const data = params.data as PlantafelProduktionsauftragEntry;
            const datum_anlage: Date = data.produktionsauftrag_blasen.datum_anlage; // datum_anlage ?

            // if datum einsteuerung is 0 - 3 days old, highlight it
            const diff = differenceInCalendarDays(new Date(), datum_anlage);
            return diff >= 0 && diff <= 3;
          },
        },
      },
      {
        headerName: 'Dat. E',
        groupId: 'Dat. E',
        children: [
          {
            headerName: 'KUAU-Nr.',
            colId: 'kundenauftrag_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'AU-ART',
            colId: 'auftrag_art',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.auftragsart),
            columnGroupShow: 'open',
          },
          {
            headerName: 'RAU-NR',
            colId: 'rahmenauftrag_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.rahmenauftrag?.nummer),
            columnGroupShow: 'open',
            filter: 'agNumberColumnFilter',
          },
          {
            headerName: 'Dat. E (KUAU)',
            colId: 'datum_e_kuau',
            columnGroupShow: 'open',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.datum_eingang),
            valueFormatter: dateValueFormatter,
            ...DATE_COLUMN_OPTIONS,
          },
          {
            headerName: 'Dat. Anlage (PAU)',
            colId: 'datum_anlage_pau',
            columnGroupShow: undefined,
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.datum_anlage),
            valueFormatter: dateValueFormatter,
            ...DATE_COLUMN_OPTIONS,
          },
        ],
      },
      {
        headerName: 'Kunde',
        groupId: 'Kunde',
        children: [
          {
            headerName: 'KU-Nr.',
            colId: 'kunden_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.kunde.nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Kunde',
            colId: 'kunden_name_kurz',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.kunde.name_kurz),
            columnGroupShow: undefined,
          },
        ],
      },
      {
        headerName: 'Bestellung',
        groupId: 'Bestellung',
        children: [
          {
            headerName: 'Bestell-Nr.',
            colId: 'bestell_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.bestell_nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'EMPF-AU-NR.',
            colId: 'auftragsnummer_des_empfaenger',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.auftragsnummer_des_empfaenger),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'KU-ART-NR.',
            colId: 'artikel_nummer_des_kunden',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.artikel_nummer_des_kunden),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'FL-TYP-NR.',
            colId: 'flaschen_typ_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.flaschentyp.nummer),
            columnGroupShow: undefined,
            filter: 'agTextColumnFilter',
            cellRenderer: this.qHinweisProduktCellRenderer,
          },
        ],
      },
      {
        headerName: 'FL-TYP-BEZ.',
        colId: 'flaschen_typ_bezeichnung',
        valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.flaschentyp.bezeichnung),
      },
      {
        headerName: 'BLZ',
        groupId: 'BLZ',
        children: [
          {
            headerName: 'BLZ-Nr',
            colId: 'blinden_zeichen_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.flaschentyp.blindenzeichen.nummer),
            columnGroupShow: 'open',
          },
          {
            headerName: 'BLZ',
            colId: 'blinden_zeichen',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.flaschentyp.blindenzeichen.bezeichnung),
            columnGroupShow: undefined,
          },
        ],
      },
      {
        headerName: 'VGG',
        groupId: 'VGG',
        children: [
          {
            headerName: 'Volumen [ml]',
            colId: 'volumen_ml',
            columnGroupShow: 'open',
            filter: 'agNumberColumnFilter',
            valueFormatter: numberValueFormatterGenAGGrid(0),
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.flaschentyp.volumen),
          },
          {
            headerName: 'Gewinde - Nr.',
            colId: 'gewinde_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.hals_teil.nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Gewinde',
            colId: 'gewinde',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.hals_teil.bezeichnung),
            columnGroupShow: undefined,
          },
        ],
      },
      {
        headerName: 'G [g]',
        colId: 'gewicht_g',
        filter: 'agNumberColumnFilter',
        valueFormatter: numberValueFormatterGenAGGrid(0),
        valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.gewicht),
      },
      {
        headerName: 'FARBE',
        colId: 'farbe',
        valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.granulat_farbe.bezeichnung),
      },
      {
        headerName: 'Einfärbung',
        groupId: 'Einfärbung',
        children: [
          {
            headerName: 'FARB-NR (WACO)',
            colId: 'granulat_farbe_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.granulat_farbe.nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'BATCH-NR',
            colId: 'granulat_farbe_batch_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.granulat_farbe.batch_nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'F %',
            colId: 'Faerbungs_Grad',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.einfaerbungs_grad),
            columnGroupShow: undefined,
            filter: 'agNumberColumnFilter',
          },
        ],
      },
      {
        headerName: 'Rückmeldung',
        groupId: 'Rückmeldung',
        children: [
          {
            colId: 'rueckmeldung',
            headerName: '',
            valueGetter: () => undefined,
            columnGroupShow: undefined,
            cellRenderer: this.actionButtonsRenderer,
            filter: false,
          },
          {
            headerName: 'Menge Gesamt',
            colId: 'Menge_Gesamt',
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
            valueFormatter: numberValueFormatterGenAGGrid(0),
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.menge_gesamt),
          },
          {
            headerName: 'Menge Gut',
            colId: 'Menge_Gut',
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
            valueFormatter: numberValueFormatterGenAGGrid(0),
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.menge_gut),
          },
          {
            headerName: 'Zähler-Stand (Umbau-Start)',
            colId: 'Zähler_Stand_Umbau',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.zaehlerstand_umbau_start),
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
          },
          {
            headerName: 'Zähler-Stand (Prod.-Start)',
            colId: 'Zähler_Stand_Prod_Start',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.zaehlerstand_produktion_ende),
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
          },
          {
            headerName: 'Zähler-Stand (Prod. -Ende)',
            colId: 'Zähler_Stand_Prod_Ende',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.zaehlerstand_produktion_ende),
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
          },
          {
            headerName: 'Menge Einricht Ausschuss',
            colId: 'Menge_Einricht_Schrott',
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
            valueFormatter: numberValueFormatterGenAGGrid(0),
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.menge_einricht_ausschuss),
          },
          {
            headerName: 'Menge Produktions Ausschuss',
            colId: 'Menge_Ausschuss',
            filter: 'agNumberColumnFilter',
            columnGroupShow: 'open',
            valueFormatter: numberValueFormatterGenAGGrid(0),
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.menge_produktion_ausschuss),
          },
        ],
      },
      {
        headerName: 'WS',
        groupId: 'WS',
        children: [
          {
            headerName: 'WS-NR',
            colId: 'werkstoff_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.werkstoff.nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Werkstoff Typ',
            colId: 'werkstoff_typ',
            valueSetter: this.transactionRestValueSetter(({ data, newValue }) => {
              if (newValue === null || newValue === undefined) {
                return new Promise(((res, rej) => rej(null)));
              }
              return this.props.services.plantafelEntryApi.updateWerkstoffTypPlantafelEntryProduktionsauftragDatabaseIdUpdateWerkstoffTypPost({
                produktionsauftrag_database_id: data.produktionsauftrag_blasen.database_id,
                WerkstoffTyp: newValue,
              });
            }),
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.werkstoff_typ),
            columnGroupShow: 'open',
            cellEditor: AgGridWerkstoffTypEditor,
            cellEditorParams: {
              werkstoffTypen: this.props.werkstofftypen.data,
            },
            editable: this.props.werkstofftypen.error === null,
            ...makeForeignKeyColumn('produktionsauftrag_blasen', (data: PlantafelProduktionsauftragEntry) => {
              return {
                description: data.produktionsauftrag_blasen.werkstoff_typ.bezeichnung,
                number: data.produktionsauftrag_blasen.werkstoff_typ.nummer as any,
              };
            }),
          },
          {
            headerName: 'WS',
            colId: 'werkstoff_bezeichnung',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.werkstoff.bezeichnung),
            columnGroupShow: undefined,
          },
        ],
      },
      {
        headerName: 'Ziel',
        groupId: 'Ziel',
        children: [
          {
            headerName: 'Empfänger-Nr.',
            colId: 'empfaenger_nummer',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.empfaenger?.nummer),
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Empfänger- Firma',
            colId: 'empfaenger_firma_kurz',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.artikel.empfaenger?.name_kurz),
            columnGroupShow: undefined,
          },
        ],
      },
      {
        headerName: 'Bestell-Menge',
        colId: 'Bestell_Menge',
        filter: 'agNumberColumnFilter',
        valueFormatter: numberValueFormatterGenAGGrid(0),
        valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.kundenauftrag.menge),
      },
      {
        headerName: 'PAU-Menge',
        colId: 'PAU_Menge',
        valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.menge),
        valueSetter: this.transactionRestValueSetter(({ data, newValue }) => {
          return this.props.services.plantafelEntryApi.updatePauMengePlantafelEntryProduktionsauftragBlasenDatabaseIdUpdatePauMengePost({
            pau_menge: newValue,
            produktionsauftrag_blasen_database_id: data.produktionsauftrag_blasen.database_id,
          });
        }),
        valueFormatter: numberValueFormatterGenAGGrid(0),
        editable: groupCellsNotEditable,
        filter: 'agNumberColumnFilter',
        cellClassRules: {
          highlight_pau_menge_if_different: (params) => {
            if (params.node.group === true) {
              return false;
            }

            const data = params.data as PlantafelProduktionsauftragEntry;
            return data.produktionsauftrag_blasen.menge !== data.produktionsauftrag_blasen.kundenauftrag.menge;
          },
        },
      },
      {
        headerName: 'Mengen/lT',
        groupId: 'Mengen/lT',
        children: [
          {
            headerName: 'LT (KU)',
            colId: 'LT',
            valueGetter: groupSafeValueGetter(({ data }) => {
              const liefertermin_soll = data.produktionsauftrag_blasen.kundenauftrag.liefertermin_soll;
              const liefertermin_kennzeichen = data.produktionsauftrag_blasen.kundenauftrag.liefertermin_kennzeichen;
              return formatLiefertermin(liefertermin_kennzeichen, liefertermin_soll ?? null);
            }),
            valueSetter: this.transactionRestValueSetter(({ data, newValue }) => {
              return this.props.services.plantafelEntryApi.updateLieferterminPlantafelEntryProduktionsauftragBlasenDatabaseIdUpdateLieferterminPost({
                kundenauftrag_database_id: data.produktionsauftrag_blasen.kundenauftrag_database_id,
                produktionsauftrag_blasen_database_id: data.produktionsauftrag_blasen.database_id,
                liefertermin_kennzeichen: newValue.liefertermin_kennzeichen,
                liefertermin_soll: newValue.liefertermin_soll ?? undefined,
              });
            }),
            columnGroupShow: undefined,
            editable: groupCellsNotEditable,
            cellEditorFramework: AgGridLieferterminEditor,
            cellClassRules: {
              highlight_lt_ku_if_expected_finished_date_is_to_late: (params) => {
                if (params.node.group === true) {
                  return false;
                }

                const data = params.data as PlantafelProduktionsauftragEntry;

                const liefertermin_soll = data.produktionsauftrag_blasen.kundenauftrag.liefertermin_soll;
                if (liefertermin_soll === undefined) {
                  return false;
                }

                const fertig_vor_liefertermin = data.produktionsauftrag_blasen.fertig_vor_liefertermin; // puffer in tagen
                const erwarteter_liefertermin = addDays(data.datum_ende ?? 0, fertig_vor_liefertermin);

                return isBefore(liefertermin_soll, erwarteter_liefertermin);
              },
              // TODO check how this should be highlighted

              // highlight_lt_ku_if_db_nr_set_and_14_days_buffer: (params) => {
              //   if (!params.data) {
              //     return false;
              //   }

              //   const data = params.data as PlantafelEntry;
              //   const dbNrAnzDr = data.DB_Nr_slash_Anz_Dr;

              //   const pauEnde = data.PAU_Ende;
              //   const paueEndePlus14 = addDays(pauEnde, 14);

              //   const lt_ku = data.LT_KU;
              //   return dbNrAnzDr !== '' && isAfter(paueEndePlus14, lt_ku);
              // },
            },
          },
        ],
      },
      {
        headerName: 'Status',
        groupId: 'Status',
        children: [
          {
            headerName: 'Status',
            colId: 'kundenauftrag_status',
            valueGetter: groupSafeValueGetter(({ data }) => KundenauftragStatusKeyMapping[`NUMBER_${data.produktionsauftrag_blasen.kundenauftrag.status}`]),
            columnGroupShow: 'open',
          },
          {
            headerName: 'BD [d]',
            colId: 'BD_d',
            valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.blasdauer?.toFixed(0)),
            valueSetter: this.transactionRestValueSetter(({ data, newValue }) => {
              return this.props.services.plantafelEntryApi.updateBlasdauerPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasdauerPost({
                produktionsauftrag_database_id: data.produktionsauftrag_blasen.database_id,
                blasdauer: newValue,
              });
            }),
            columnGroupShow: undefined,
            editable: groupCellsNotEditable,
            filter: 'agNumberColumnFilter',
          }, // BD [d]    (Blasdauer in Tagen)
        ],
      },
      {
        headerName: 'P [d]', // P [d]    (Puffer in Tagen)
        colId: 'P_d',
        valueGetter: groupSafeValueGetter(({ data }) => data.produktionsauftrag_blasen.fertig_vor_liefertermin?.toFixed(0)),
        valueSetter: this.transactionRestValueSetter(({ data, newValue }) => {
          return this.props.services.plantafelEntryApi.updateFertigVorLieferterminPlantafelEntryProduktionsauftragDatabaseIdUpdateFertigVorLieferterminPost({
            produktionsauftrag_database_id: data.produktionsauftrag_blasen.database_id,
            fertig_vor_liefertermin: newValue,
          });
        }),
        editable: groupCellsNotEditable,
        filter: 'agNumberColumnFilter',
        cellClassRules: {
          // TODO check how this should be highlighted

          // highlight_p_d_if_db_nr_set: (params) => {
          //   if (!params.data) {
          //     return false;
          //   }

          //   const data = params.data as PlantafelEntry;
          //   return data.DB_Nr_slash_Anz_Dr !== '';
          // },
        },
      },
      {
        headerName: 'Maschine',
        groupId: 'Maschine',
        children: [
          {
            headerName: 'BLM,nnn',
            colId: 'BLM',
            columnGroupShow: undefined,

            editable: groupCellsNotEditable,
            cellEditorFramework: AgGridBLMEditor,
            filter: 'agTextColumnFilter',
            valueSetter: this.transactionRestValueSetter(async ({ data, newValue, node }) => {
              const blasmaschine = this.props.blasmaschinen.data?.find((blasmaschine) => blasmaschine.nummer === newValue.blm);
              const blasmaschineIsValid = blasmaschine !== undefined && blasmaschine !== null;

              const isValid = blasmaschineIsValid && newValue.nnn > 0 && newValue.nnn <= 999;

              if (node && isValid) {
                const updatedData = await this.props.services.plantafelEntryApi.updateBlasmachineNummerAndNnnPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasmachineNummerAndNnnPost({
                  produktionsauftrag_database_id: data.produktionsauftrag_blasen.database_id,
                  blasmachine_nummer: newValue.blm,
                  nnn: newValue.nnn,
                });

                return updatedData;
              }

              return null;
            }),
            valueGetter: groupSafeValueGetter(({ data }) => {
              const nnn = `00${data.produktionsauftrag_blasen.nnn}`;
              return `${data.produktionsauftrag_blasen.blasmaschine.nummer},${nnn.slice(-3)}`;
            }),

            showRowGroup: true,
            rowGroup: true,
            cellRenderer: 'agGroupCellRenderer',

            cellRendererParams: {
              suppressCount: true,
              innerRenderer: (params: any) => {
                return `${params.value}`;
              },
            },
            keyCreator: ({ data }): string => data.produktionsauftrag_blasen.blasmaschine.nummer,
            sort: 'desc',
            sortingOrder: ['desc'],
            comparator: (valueA: any, valueB: any, nodeA, nodeB, isInverted) => {
              const rowA = nodeA.data as PlantafelProduktionsauftragEntry;
              const rowB = nodeB.data as PlantafelProduktionsauftragEntry;

              if (nodeA.group === true || nodeB.group === true) {
                return valueB - valueA;
              }

              const blmSort = rowA.produktionsauftrag_blasen.blasmaschine.nummer - rowB.produktionsauftrag_blasen.blasmaschine.nummer;
              if (blmSort !== 0) {
                return blmSort;
              }

              return rowB.produktionsauftrag_blasen.nnn - rowA.produktionsauftrag_blasen.nnn;
            },
          },
        ],
      },
      {
        headerName: 'PAU- Start',
        colId: 'PAU_Start',
        editable: onlyGroupCellsEditable,
        cellEditorFramework: AgGridDateEditor,
        ...DATE_COLUMN_OPTIONS,
        valueFormatter: dateValueFormatter,
        valueGetter: (params) => {
          if (!params.node?.group) {
            return params.data.datum_start;
          }

          const blmForThisGroup = Number(params.node?.groupData?.BLM);
          if (!blmForThisGroup) {
            return '';
          }

          const blasmaschine = this.props.blasmaschinen.data?.find((x => x.nummer === blmForThisGroup));
          if (!blasmaschine) {
            return '';
          }

          return blasmaschine.start_datum;
        },
        valueSetter: this.transactionRestValueSetter(async ({ node, newValue }) => {
          if (!node?.group) {
            throw new Error('Invalid edit on node');
          }

          const blasmaschine_nummer = node?.groupData?.BLM;
          const blasmaschine = this.props.blasmaschinen.data?.find((x) => x.nummer === parseInt(blasmaschine_nummer));

          if (!blasmaschine) {
            throw new Error('Blasmaschine nicht gefunden');
          }

          const updated = await this.props.services.plantafelEntryApi.updateBlasmachineDatumAktuelleProduktionStartPlantafelEntryUpdateBlasmachineDatumAktuelleProduktionStartBlasmaschineDatabaseIdPost({
            blasmaschine_database_id: blasmaschine.database_id,
            datum_aktuelle_produktion_start: newValue,
          });

          // this will update the plantafel entries
          return updated.updated_plantafel_entries;
        }),
      },
      {
        headerName: 'PAU- Ende',
        colId: 'PAU_Ende',
        valueGetter: groupSafeValueGetter(({ data }) => data.datum_ende),
        valueFormatter: dateValueFormatter,
        ...DATE_COLUMN_OPTIONS,
      },
    ];

    const closeStartDialogIcon = <FontAwesomeIcon icon={faArrowLeft} />;
    const refreshPlantafelIcon = <FontAwesomeIcon icon={faSync} />;

    return (
      <>
        {this.state.showModal?.type === 'mlp' && (
          <MaschinenlaufplanModal
            onModalFinished={() => {
              this.setState({
                showModal: null,
              });
            }}
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlation_id={this.state.showModal.correlation_id}
          />
        )}
        {this.state.showModal?.type === 'umbauplan' && (
          <UmbauplanModal
            onModalFinished={() => {
              this.setState({
                showModal: null,
              });
            }}
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlation_id={this.state.showModal.correlation_id}
          />
        )}
        {this.state.showModal?.type === 'granulat_farbe_bedarf_vorschau' && (
          <GranulatFarbeBedarfVorschauModal
            onModalFinished={() => {
              this.setState({
                showModal: null,
              });
            }}
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlation_id={this.state.showModal.correlation_id}
          />
        )}
        {this.state.showModal?.type === 'rueckstell_messprotokoll' && (
          <RueckstellMessprotokollModal
            onModalFinished={() => {
              this.setState({
                showModal: null,
              });
            }}
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlation_id={this.state.showModal.correlation_id}
          />
        )}
        {this.state.showModal?.type === 'produktionsplan' && (
          <ProduktionsplanModal
            onModalFinished={() => {
              this.setState({
                showModal: null,
              });
            }}
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlation_id={this.state.showModal.correlation_id}
          />
        )}
        {renderStornierenModal(this.state.stornierenModal, (modal) => this.setState({ stornierenModal: modal }))}
        <div className={`${styles.grid_container} plantafel`}>
          <div className={styles.grid_container__header}>
            <H2>
              Plantafel
              {' '}
              <Button icon={closeStartDialogIcon} small onClick={() => this.props.closeStartDialog()}>Zurück zur Startseite</Button>
              {' '}
              <Button icon={refreshPlantafelIcon} small onClick={() => this.loadData()}>Aktualisieren</Button>
              {' '}
              <Button small onClick={() => this.startProcessAndOpenModal(ProcessConstants['Umbauplan drucken'].processId, 'umbauplan')}>Umbauplan drucken</Button>
              {' '}
              <Button small onClick={() => this.startProcessAndOpenModal(ProcessConstants['GranulatFarbe Bedarfvorschau drucken'].processId, 'granulat_farbe_bedarf_vorschau')}>GranulatFarbe Bedarfvorschau drucken</Button>
              {' '}
              <Button small onClick={() => this.startProcessAndOpenModal(ProcessConstants['Produktionsplan drucken'].processId, 'produktionsplan')}>Produktionsplan drucken</Button>
              {' '}
              <Button small onClick={() => this.startProcessAndOpenModal(ProcessConstants['Rückstell-Messprotokoll drucken'].processId, 'rueckstell_messprotokoll')}>Rückstell-Messprotokoll drucken</Button>
              {' '}
              {this.state.displayedDataIsFiltered &&
                <Tag intent='warning' minimal>Gefilterte Ansicht</Tag>
              }
            </H2>
          </div>
          <div className={this.props.agGridTheme}>
            <AgGridReact
              suppressCellFocus={this.props.transactionService.isProcessing()}
              suppressMultiSort
              rowData={this.state.data}
              suppressFieldDotNotation
              groupDisplayType='custom'
              groupDefaultExpanded={1}
              getContextMenuItems={() => ['copy', 'copyWithHeaders', 'separator', 'export']}
              processCellForClipboard={processCellForClipboard}
              getRowId={({ data }: { data: PlantafelProduktionsauftragEntry }) => String(data.produktionsauftrag_blasen.database_id)}
              defaultColDef={{
                resizable: true,
                sortable: true,
                comparator: comparator,
                filter: true,
                suppressMovable: true,
                floatingFilter: true,
                filterParams: { applyMiniFilterWhileTyping: true, buttons: ['reset'] },
              }}
              enableGroupEdit
              onGridReady={this.onGridReady}
              columnDefs={applyColumnStateOnColumnDefs('plantafel', colDefs, defaultColumnState)}
              onFilterChanged={() => {
                this.setState({
                  displayedDataIsFiltered: this.gridApi?.isAnyFilterPresent() ?? false,
                });
              }}
              localeText={agGridLocaleDE}
              suppressColumnVirtualisation={true}
              getMainMenuItems={(params: GetMainMenuItemsParams): Array<string | MenuItemDef> => ([
                'pinSubMenu',
                'separator',
                {
                  name: 'Spalten zurücksetzen',
                  action: () => {
                    resetColumnState('plantafel', this.gridColumnApi);
                    this.gridApi?.setColumnDefs(applyColumnStateOnColumnDefs('plantafel', colDefs, defaultColumnState));
                    this.gridApi?.resetQuickFilter();
                  },
                },
                {
                  name: 'Spalten ausbreiten',
                  action: () => {
                    this.gridColumnApi?.autoSizeAllColumns(false);
                    this.gridApi?.expandAll();
                  },
                },
                {
                  name: 'Spalten an Sicht anpassen',
                  action: () => this.gridApi?.sizeColumnsToFit(),
                },
              ])
              }
            />
          </div>
        </div>
      </>
    );
  }

  private qHinweisProduktCellRenderer = (props: ICellRendererParams): JSX.Element => {
    if (props.node?.group === true) {
      return <></>;
    }

    const entry = props.node.data as PlantafelProduktionsauftragEntry;
    const flaschen_typ = entry.produktionsauftrag_blasen.kundenauftrag.artikel.flaschen_form.flaschentyp;
    const hinweiseFuerFlaschenTyp = this.props.qhinweise.data?.filter((x) => x.flaschen_typ.nummer === flaschen_typ.nummer && x.flaschen_typ.version_id === flaschen_typ.version_id);

    if (hinweiseFuerFlaschenTyp === undefined || hinweiseFuerFlaschenTyp.length === 0 || !props.value) {
      return <>{props.value}</>;
    }

    const onClick = async (): Promise<void> => {
      await this.props.transactionService.openModal<void>((props) => (
        <QHinweisProduktModal
          flaschen_typ_bezeichung={flaschen_typ.bezeichnung?.toString() ?? ''}
          flaschen_typ_nummer={flaschen_typ.nummer?.toString() ?? ''}
          qHinweiseProdukt={hinweiseFuerFlaschenTyp}
          {...props}
        />
      ));
    };

    return (
      <div className={styles['hinweis-cell']}>
        <span className={styles['hinweis-cell__text']}>{props.value}</span>
        <Button small={true} intent='primary' className={styles['hinweis-cell__button']} onClick={onClick}>
          <FontAwesomeIcon icon={faInfoCircle} /> Produkt
        </Button>
      </div>
    );
  };

  private actionButtonsRenderer = (props: Omit<ICellRendererParams, 'data'> & { data: PlantafelProduktionsauftragEntry }): JSX.Element => {
    if (props.node?.group === true) {
      return <></>;
    }

    const correlation_id = props.data.produktionsauftrag_blasen.kundenauftrag.process_correlation_id;
    if (!correlation_id) {
      // No process found for this row. Disable actions
      return <></>;
    }

    const is_fertig_blasen_pau = [ProduktionsauftragStatus.NUMBER_5, ProduktionsauftragStatus.NUMBER_6, ProduktionsauftragStatus.NUMBER_9].includes(props.data.produktionsauftrag_blasen.status);
    const has_vpv = props.data.produktionsauftrag_blasen.verpackungs_vorschrift ? true : false;

    const matchingSubProcessForStornieren = this.props.runningSubprocesses.find(subProcess =>
      subProcess.correlationId === correlation_id
      && subProcess.processModelId === ProcessConstants['Produktionsauftrag durchführen Blasen'].processId,
    );

    const matchingRunningProcessForPapiereDrucken = this.props.runningProcesses.find(process => process.correlationId === correlation_id && process.processModelId === ProcessConstants['Papiere drucken'].processId);
    const matchingHinweisUserTask = this.props.runningHinweisUserTasks.find(userTask => userTask.correlationId === correlation_id);

    return (
      <div className={styles.row_actions_box}>
        {props.node.firstChild && !matchingRunningProcessForPapiereDrucken && has_vpv && (matchingSubProcessForStornieren || matchingHinweisUserTask) &&
          <RequireClaims claims={['manipulate_produktionsauftrag']} hideOnError>
            <Tag
              interactive
              minimal
              intent='primary'
              onClick={() => this.openRueckmeldungModal(props.data)}
            >
              Rückmelden
            </Tag>
          </RequireClaims>
        }
        {matchingSubProcessForStornieren && !is_fertig_blasen_pau &&
          <RequireClaims claims={['manipulate_produktionsauftrag']} hideOnError>
            <Tag
              interactive
              minimal
              intent='danger'
              onClick={() => stornieren(matchingSubProcessForStornieren, ProcessConstants['Produktionsauftrag durchführen Blasen'].signals.stornieren, this.props.atlasEngineClient, (modal) => this.setState({ stornierenModal: modal }))}
            >
              Stornieren
            </Tag>
          </RequireClaims>
        }
        {matchingRunningProcessForPapiereDrucken && has_vpv && matchingSubProcessForStornieren &&
          <RequireClaims claims={['manipulate_produktionsauftrag']} hideOnError>
            <AsyncTag
              label={'Papiere drucken'}
              minimal={true}
              intent={'primary'}
              onClick={() => this.openPapiereDruckenModal(correlation_id)}
            />
          </RequireClaims>}
        {!matchingRunningProcessForPapiereDrucken && has_vpv && matchingSubProcessForStornieren &&
          <AsyncTag
            label={'Papiere neu drucken'}
            minimal={true}
            cssClass={props.data.produktionsauftrag_blasen.aktuelle_papiere_gedruckt ? undefined : styles.epic_highlighted_drucken_button}
            onClick={async () => {
              await this.props.atlasEngineClient.processModels.startProcessInstance({ processModelId: ProcessConstants.throw_signal_event.processId, correlationId: correlation_id, initialToken: { signal: ProcessConstants['Produktionsauftrag durchführen Blasen'].signals.papiereDrucken } });
              this.openPapiereDruckenModal(correlation_id);
            }}
          />}
      </div>
    );
  };
}
