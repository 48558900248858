import { ValueFormatterParams } from 'ag-grid-community';
import { Blasmaschine, CreateBlasmaschine, EinzelDoppel } from 'wacoplast_wws__api';
import {
  DATE_COLUMN_OPTIONS,
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatDateString,
  makeEnumRadioButtons,
  makeNumberEditorRenderer,
  makeSwitchEditorRenderer,
  numberValueFormatterGenAGGrid,
} from '../../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';
import { EinzelDoppelKeyMapping } from './EinzelDoppelKeyMapping';
import { formatBooleanStringToJaNein, formatBooleanToJaNein, formatEnumValueToDescription } from '../../../infrastructure/filterFormatters';

export type BlasmaschineVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function BlasmaschineVerwalten(props: BlasmaschineVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateBlasmaschine> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeNumberEditorRenderer(),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'stich',
      renderer: makeNumberEditorRenderer(),
      label: 'Stich',
    },
    {
      type: 'simple',
      field: 'anzahl_kavitaeten',
      renderer: makeNumberEditorRenderer({
        min: 1,
        max: 10,
      }),
      label: 'Anzahl Kavitäten',
    },
    {
      type: 'simple',
      field: 'einzel_doppel',
      renderer: makeEnumRadioButtons(Object.values(EinzelDoppel), (value) => (EinzelDoppelKeyMapping as any)[value]),
      label: 'Einzel-Doppel',
    },
    {
      type: 'simple',
      field: 'zaehlt_schuss',
      renderer: makeSwitchEditorRenderer(),
      label: 'Zählt Schuss',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Stich',
          field: 'stich',
          colId: 'stich',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Anzahl Kavitäten',
          field: 'anzahl_kavitaeten',
          colId: 'anzahl_kavitaeten',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Einzel-Doppel',
          field: 'einzel_doppel',
          colId: 'einzel_doppel',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: any) => formatEnumValueToDescription(value, EinzelDoppelKeyMapping),
          },
          valueFormatter: ({ value }) => formatEnumValueToDescription(value, EinzelDoppelKeyMapping),
        },
        {
          headerName: 'Start-Datum-aktuelle-Produktion',
          field: 'start_datum',
          colId: 'start_datum',
          valueFormatter: (val: ValueFormatterParams) => val.value ? formatDateString(val.value as Date) : '',
          ...DATE_COLUMN_OPTIONS,
        },
        {
          headerName: 'Zählt Schuss',
          field: 'zaehlt_schuss',
          colId: 'zaehlt_schuss',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: any) => formatBooleanStringToJaNein(value),
          },
          valueFormatter: ({ value }) => formatBooleanToJaNein(value),
        },
      ]}
      stammdatenService={{
        create: (data: CreateBlasmaschine) => props.services.blasmaschine.postStellblattBlasmaschinePost({ CreateBlasmaschine: data }),
        update: (data) => props.services.blasmaschine.putByDatabaseIdStellblattBlasmaschineDatabaseIdPut({ database_id: data.database_id, UpdateBlasmaschine: data }),
        delete: (data, ignoreIntegrity) => props.services.blasmaschine.deleteByDatabaseIdStellblattBlasmaschineDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.blasmaschine.restoreByDatabaseIdStellblattBlasmaschineDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.blasmaschine.getAllStellblattBlasmaschineGet({ include_deleted }),
        getHistory: (data: Blasmaschine) => props.services.blasmaschine.getHistoryStellblattBlasmaschineDatabaseIdHistoryGet({ database_id: data.database_id }),
        lock: (data) => props.services.blasmaschine.lockByDatabaseIdStellblattBlasmaschineDatabaseIdLockPatch({ database_id: data.database_id, LockRequest: { aenderungsgrund: data.aenderungsgrund as any } }),
        unlock: (data) => props.services.blasmaschine.unlockByDatabaseIdStellblattBlasmaschineDatabaseIdUnlockPatch({ database_id: data.database_id, UnlockRequest: { aenderungsgrund: data.aenderungsgrund } }),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      defaultEntityForCreation={{ zaehlt_schuss: false }}
      dialogStrings={DialogStrings.Blasmaschine}
    />
  );
}
