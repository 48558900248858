"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateFahrzeugToJSON = exports.UpdateFahrzeugFromJSONTyped = exports.UpdateFahrzeugFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateFahrzeugFromJSON(json) {
    return UpdateFahrzeugFromJSONTyped(json, false);
}
exports.UpdateFahrzeugFromJSON = UpdateFahrzeugFromJSON;
function UpdateFahrzeugFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'bezeichnung': json['bezeichnung'],
        'ladehoehe': json['ladehoehe'],
        'eu_pal_plaetze': json['eu_pal_plaetze'],
    };
}
exports.UpdateFahrzeugFromJSONTyped = UpdateFahrzeugFromJSONTyped;
function UpdateFahrzeugToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'bezeichnung': value.bezeichnung,
        'ladehoehe': value.ladehoehe,
        'eu_pal_plaetze': value.eu_pal_plaetze,
    };
}
exports.UpdateFahrzeugToJSON = UpdateFahrzeugToJSON;
