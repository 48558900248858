/* eslint-disable react/display-name */
import { Switch } from '@blueprintjs/core';
import { StammdatenFieldEditorSimpleRendererProps } from './editors';

export function makeSwitchEditorRenderer<TEntityType>(): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, boolean | null>) => {
    return (
      <Switch
        checked={props.value ?? false as any}
        disabled={props.disabled}
        onChange={(event) => props.onChange(event.currentTarget.checked)}
      />
    );
  };
}
