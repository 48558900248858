"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtruderTemperaturToJSON = exports.ExtruderTemperaturFromJSONTyped = exports.ExtruderTemperaturFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ExtruderTemperaturFromJSON(json) {
    return ExtruderTemperaturFromJSONTyped(json, false);
}
exports.ExtruderTemperaturFromJSON = ExtruderTemperaturFromJSON;
function ExtruderTemperaturFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'extruder_temperatur': !runtime_1.exists(json, 'extruder_temperatur') ? undefined : json['extruder_temperatur'],
        'werkstoff_database_id': json['werkstoff_database_id'],
        'werkstoff_version_id': json['werkstoff_version_id'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'werkstoff': _1.WerkstoffFromJSON(json['werkstoff']),
    };
}
exports.ExtruderTemperaturFromJSONTyped = ExtruderTemperaturFromJSONTyped;
function ExtruderTemperaturToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'extruder_temperatur': value.extruder_temperatur,
        'werkstoff_database_id': value.werkstoff_database_id,
        'werkstoff_version_id': value.werkstoff_version_id,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'werkstoff': _1.WerkstoffToJSON(value.werkstoff),
    };
}
exports.ExtruderTemperaturToJSON = ExtruderTemperaturToJSON;
