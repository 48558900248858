"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBlasmaschineToJSON = exports.UpdateBlasmaschineFromJSONTyped = exports.UpdateBlasmaschineFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UpdateBlasmaschineFromJSON(json) {
    return UpdateBlasmaschineFromJSONTyped(json, false);
}
exports.UpdateBlasmaschineFromJSON = UpdateBlasmaschineFromJSON;
function UpdateBlasmaschineFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'stich': json['stich'],
        'anzahl_kavitaeten': json['anzahl_kavitaeten'],
        'zaehlt_schuss': json['zaehlt_schuss'],
        'einzel_doppel': _1.EinzelDoppelFromJSON(json['einzel_doppel']),
    };
}
exports.UpdateBlasmaschineFromJSONTyped = UpdateBlasmaschineFromJSONTyped;
function UpdateBlasmaschineToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'stich': value.stich,
        'anzahl_kavitaeten': value.anzahl_kavitaeten,
        'zaehlt_schuss': value.zaehlt_schuss,
        'einzel_doppel': _1.EinzelDoppelToJSON(value.einzel_doppel),
    };
}
exports.UpdateBlasmaschineToJSON = UpdateBlasmaschineToJSON;
