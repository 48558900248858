"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateExtruderTemperaturToJSON = exports.UpdateExtruderTemperaturFromJSONTyped = exports.UpdateExtruderTemperaturFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateExtruderTemperaturFromJSON(json) {
    return UpdateExtruderTemperaturFromJSONTyped(json, false);
}
exports.UpdateExtruderTemperaturFromJSON = UpdateExtruderTemperaturFromJSON;
function UpdateExtruderTemperaturFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'extruder_temperatur': !runtime_1.exists(json, 'extruder_temperatur') ? undefined : json['extruder_temperatur'],
        'werkstoff_database_id': json['werkstoff_database_id'],
        'werkstoff_version_id': json['werkstoff_version_id'],
    };
}
exports.UpdateExtruderTemperaturFromJSONTyped = UpdateExtruderTemperaturFromJSONTyped;
function UpdateExtruderTemperaturToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'extruder_temperatur': value.extruder_temperatur,
        'werkstoff_database_id': value.werkstoff_database_id,
        'werkstoff_version_id': value.werkstoff_version_id,
    };
}
exports.UpdateExtruderTemperaturToJSON = UpdateExtruderTemperaturToJSON;
