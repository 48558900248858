"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateQHinweisProduktToJSON = exports.UpdateQHinweisProduktFromJSONTyped = exports.UpdateQHinweisProduktFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateQHinweisProduktFromJSON(json) {
    return UpdateQHinweisProduktFromJSONTyped(json, false);
}
exports.UpdateQHinweisProduktFromJSON = UpdateQHinweisProduktFromJSON;
function UpdateQHinweisProduktFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'datum': (new Date(json['datum'])),
        'hinweis_aus_reklamation': json['hinweis_aus_reklamation'],
        'erfolgte_korrekturmassnahmen': !runtime_1.exists(json, 'erfolgte_korrekturmassnahmen') ? undefined : json['erfolgte_korrekturmassnahmen'],
        'flaschen_typ_database_id': json['flaschen_typ_database_id'],
        'flaschen_typ_version_id': json['flaschen_typ_version_id'],
    };
}
exports.UpdateQHinweisProduktFromJSONTyped = UpdateQHinweisProduktFromJSONTyped;
function UpdateQHinweisProduktToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'datum': (value.datum.toISOString()),
        'hinweis_aus_reklamation': value.hinweis_aus_reklamation,
        'erfolgte_korrekturmassnahmen': value.erfolgte_korrekturmassnahmen,
        'flaschen_typ_database_id': value.flaschen_typ_database_id,
        'flaschen_typ_version_id': value.flaschen_typ_version_id,
    };
}
exports.UpdateQHinweisProduktToJSON = UpdateQHinweisProduktToJSON;
