"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateHalsteilZuBlasformToJSON = exports.UpdateHalsteilZuBlasformFromJSONTyped = exports.UpdateHalsteilZuBlasformFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateHalsteilZuBlasformFromJSON(json) {
    return UpdateHalsteilZuBlasformFromJSONTyped(json, false);
}
exports.UpdateHalsteilZuBlasformFromJSON = UpdateHalsteilZuBlasformFromJSON;
function UpdateHalsteilZuBlasformFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'blasform_database_id': json['blasform_database_id'],
        'blasform_version_id': json['blasform_version_id'],
        'hals_teil_database_id': json['hals_teil_database_id'],
        'hals_teil_version_id': json['hals_teil_version_id'],
        'flaschentyp_database_id': json['flaschentyp_database_id'],
        'flaschentyp_version_id': json['flaschentyp_version_id'],
        'kunde_database_id': !runtime_1.exists(json, 'kunde_database_id') ? undefined : json['kunde_database_id'],
        'kunde_version_id': !runtime_1.exists(json, 'kunde_version_id') ? undefined : json['kunde_version_id'],
        'reservefeld_1': !runtime_1.exists(json, 'reservefeld_1') ? undefined : json['reservefeld_1'],
        'reservefeld_2': !runtime_1.exists(json, 'reservefeld_2') ? undefined : json['reservefeld_2'],
        'reservefeld_3': !runtime_1.exists(json, 'reservefeld_3') ? undefined : json['reservefeld_3'],
        'reservefeld_4': !runtime_1.exists(json, 'reservefeld_4') ? undefined : json['reservefeld_4'],
        'prioritaet': json['prioritaet'],
        'bemerkung': !runtime_1.exists(json, 'bemerkung') ? undefined : json['bemerkung'],
    };
}
exports.UpdateHalsteilZuBlasformFromJSONTyped = UpdateHalsteilZuBlasformFromJSONTyped;
function UpdateHalsteilZuBlasformToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'blasform_database_id': value.blasform_database_id,
        'blasform_version_id': value.blasform_version_id,
        'hals_teil_database_id': value.hals_teil_database_id,
        'hals_teil_version_id': value.hals_teil_version_id,
        'flaschentyp_database_id': value.flaschentyp_database_id,
        'flaschentyp_version_id': value.flaschentyp_version_id,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'reservefeld_1': value.reservefeld_1,
        'reservefeld_2': value.reservefeld_2,
        'reservefeld_3': value.reservefeld_3,
        'reservefeld_4': value.reservefeld_4,
        'prioritaet': value.prioritaet,
        'bemerkung': value.bemerkung,
    };
}
exports.UpdateHalsteilZuBlasformToJSON = UpdateHalsteilZuBlasformToJSON;
