"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtikelGruppenToJSON = exports.ArtikelGruppenFromJSONTyped = exports.ArtikelGruppenFromJSON = exports.ArtikelGruppen = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var ArtikelGruppen;
(function (ArtikelGruppen) {
    ArtikelGruppen["Granulat_Farben"] = "Granulat-Farben";
    ArtikelGruppen["Basis_Granulat"] = "Basis-Granulat";
    ArtikelGruppen["BLWZ"] = "BLWZ";
    ArtikelGruppen["DR_Farben"] = "DR-Farben";
})(ArtikelGruppen = exports.ArtikelGruppen || (exports.ArtikelGruppen = {}));
function ArtikelGruppenFromJSON(json) {
    return ArtikelGruppenFromJSONTyped(json, false);
}
exports.ArtikelGruppenFromJSON = ArtikelGruppenFromJSON;
function ArtikelGruppenFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ArtikelGruppenFromJSONTyped = ArtikelGruppenFromJSONTyped;
function ArtikelGruppenToJSON(value) {
    return value;
}
exports.ArtikelGruppenToJSON = ArtikelGruppenToJSON;
