import { DialogStrings } from '../infrastructure';

export const stammdatenDialogs: Array<typeof DialogStrings[keyof typeof DialogStrings]> = [
  DialogStrings.Artikel,
  DialogStrings.Firma,
  DialogStrings.Werkstoff,
  DialogStrings.Werkstofftyp,
  DialogStrings.Granulatfarbe,
  DialogStrings.Lieferant,
  DialogStrings.Flaschentyp,
  DialogStrings.Halsteil,
  DialogStrings.Flaschenform,
  DialogStrings.Blindenzeichen,
  DialogStrings.Qhinweisprodukt,
  DialogStrings.Fahrzeug,
  DialogStrings.FahrzeugZuKunde,
  DialogStrings.Gewichtstoleranz,
];

export const verpackungDialogs: Array<typeof DialogStrings[keyof typeof DialogStrings]> = [
  DialogStrings.Packordnung,
  DialogStrings.Verpackungseinheit,
  DialogStrings.Verpackungsmaterial,
  DialogStrings.Verpackungsvorschrift,
];

export const stellblattDialogs: Array<typeof DialogStrings[keyof typeof DialogStrings]> = [
  DialogStrings.StellblattAbstaende,
  DialogStrings.EinstellparameterBlasform,
  DialogStrings.BlasmaschineZeiten,
  DialogStrings.Blasform,
  DialogStrings.HalsteilZuBlasform,
  DialogStrings.Blasmaschine,
  DialogStrings.ExtruderTemperatur,
];
