import { Popover2 } from '@blueprintjs/popover2';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { LieferterminKennzeichen, PlantafelEntry } from 'wacoplast_wws__api';
import { LieferterminEditor, formatLiefertermin } from '../../../infrastructure';

export type AgGridLieferterminEditorProps = Omit<ICellRendererParams, 'data'> & {
  liefertermin_soll?: Date | null,
  liefertermin_kennzeichen: LieferterminKennzeichen,
  data: PlantafelEntry,
}

type AgGridLieferterminEditorState = {
  isPopoverOpen: boolean,
  liefertermin_soll: Date | null,
  liefertermin_kennzeichen: LieferterminKennzeichen,
};

export class AgGridLieferterminEditor extends React.Component<AgGridLieferterminEditorProps, AgGridLieferterminEditorState> {

  constructor(props: AgGridLieferterminEditorProps) {
    super(props);

    this.state = {
      isPopoverOpen: false,
      liefertermin_soll: props.data.produktionsauftrag_blasen.kundenauftrag.liefertermin_soll ?? null,
      liefertermin_kennzeichen: props.data.produktionsauftrag_blasen.kundenauftrag.liefertermin_kennzeichen,
    };
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.setState({ isPopoverOpen: true });
    }, 10);
  }

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  public getValue(): { liefertermin_soll: Date | null, liefertermin_kennzeichen: LieferterminKennzeichen } {
    return {
      liefertermin_soll: this.state.liefertermin_soll ?? null,
      liefertermin_kennzeichen: this.state.liefertermin_kennzeichen,
    };
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  public isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  public isCancelAfterEnd(): boolean {
    return false;
  }

  public render(): JSX.Element {
    return (
      <div
        className='ag-cell'
      >
        <Popover2
          isOpen={this.state.isPopoverOpen}
          content={
            <div style={{ padding: '10px' }}>
              <LieferterminEditor
                onChange={(data) => this.setState({ liefertermin_kennzeichen: data.liefertermin_kennzeichen ?? LieferterminKennzeichen.W, liefertermin_soll: data.liefertermin_soll })}
                value={this.state}
              />
            </div>
          }
        >
          <div>
            {formatLiefertermin(this.state.liefertermin_kennzeichen, this.state.liefertermin_soll ?? null)}
          </div>
        </Popover2>
      </div>
    );
  }
}
