"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.KundenauftragStatusToJSON = exports.KundenauftragStatusFromJSONTyped = exports.KundenauftragStatusFromJSON = exports.KundenauftragStatus = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var KundenauftragStatus;
(function (KundenauftragStatus) {
    KundenauftragStatus[KundenauftragStatus["NUMBER_1"] = 1] = "NUMBER_1";
    KundenauftragStatus[KundenauftragStatus["NUMBER_3"] = 3] = "NUMBER_3";
    KundenauftragStatus[KundenauftragStatus["NUMBER_4"] = 4] = "NUMBER_4";
    KundenauftragStatus[KundenauftragStatus["NUMBER_5"] = 5] = "NUMBER_5";
    KundenauftragStatus[KundenauftragStatus["NUMBER_6"] = 6] = "NUMBER_6";
    KundenauftragStatus[KundenauftragStatus["NUMBER_7"] = 7] = "NUMBER_7";
    KundenauftragStatus[KundenauftragStatus["NUMBER_8"] = 8] = "NUMBER_8";
    KundenauftragStatus[KundenauftragStatus["NUMBER_9"] = 9] = "NUMBER_9";
})(KundenauftragStatus = exports.KundenauftragStatus || (exports.KundenauftragStatus = {}));
function KundenauftragStatusFromJSON(json) {
    return KundenauftragStatusFromJSONTyped(json, false);
}
exports.KundenauftragStatusFromJSON = KundenauftragStatusFromJSON;
function KundenauftragStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.KundenauftragStatusFromJSONTyped = KundenauftragStatusFromJSONTyped;
function KundenauftragStatusToJSON(value) {
    return value;
}
exports.KundenauftragStatusToJSON = KundenauftragStatusToJSON;
