import {
  H3,
  HTMLTable,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { Kundenauftrag } from 'wacoplast_wws__api';
import {
  AlertErrorRenderer,
  AsyncButton,
  PropsWithServices,
  UseFetchLikeServiceFunctionResult,
  formatDateTimeString,
  formatLiefertermin,
  formatWerkstoffTypNummer,
  numberValueFormatterGen,
} from '../../../../infrastructure';
import { KundenauftragArtKeyMapping } from '../..';

import styles from './KundenauftragInformationTable.module.scss';


export type KundenauftragInformationTableProps = PropsWithServices<{ kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag> }>

export function KundenauftragInformationTable(props: KundenauftragInformationTableProps): JSX.Element {

  const numberFormatter = numberValueFormatterGen(0);

  if (props.kundenauftrag.isLoading) {
    return (
      <>
        <Spinner />
        Wird geladen
      </>
    );
  } else if (props.kundenauftrag.error) {
    return (
      <>
        <AlertErrorRenderer error={props.kundenauftrag.error} />
        <AsyncButton onClick={() => props.kundenauftrag.reload()} label='Erneut versuchen' />
      </>
    );
  }

  return (
    <>
      <H3>Kundenauftrag</H3>
      <HTMLTable striped className={styles.kundenauftrag_information_table}>
        <tbody>
          <tr>
            <td>Nummer</td>
            <td>{props.kundenauftrag.data.nummer}</td>
          </tr>
          <tr>
            <td>Artikelnummer</td>
            <td>{props.kundenauftrag.data.artikel.nummer}</td>
          </tr>
          <tr>
            <td>Kunde</td>
            <td>{props.kundenauftrag.data.artikel.kunde.name_kurz}</td>
          </tr>
          <tr>
            <td>Empfänger</td>
            <td>{props.kundenauftrag.data.artikel.empfaenger?.name_kurz}</td>
          </tr>
          <tr>
            <td>Auftragsart</td>
            <td>{KundenauftragArtKeyMapping[props.kundenauftrag.data.auftragsart]}</td>
          </tr>
          <tr>
            <td>Datum Eingang</td>
            <td>{formatDateTimeString(props.kundenauftrag.data.datum_eingang)}</td>
          </tr>
          <tr>
            <td>Liefertermin</td>
            <td>{formatLiefertermin(props.kundenauftrag.data.liefertermin_kennzeichen, props.kundenauftrag.data.liefertermin_soll ?? null)}</td>
          </tr>
          <tr>
            <td>Menge</td>
            <td>{numberFormatter(props.kundenauftrag.data.menge)}</td>
          </tr>
          <tr>
            <td>Bestellnummer</td>
            <td>{props.kundenauftrag.data.bestell_nummer}</td>
          </tr>
          <tr>
            <td>Kunde-Artikelnummer</td>
            <td>{props.kundenauftrag.data.artikel.artikel_nummer_des_kunden}</td>
          </tr>
          <tr>
            <td>Empfänger-Auftragsnummer</td>
            <td>{props.kundenauftrag.data.auftragsnummer_des_empfaenger}</td>
          </tr>
          <tr>
            <td>Empfänger-Artikelnummer</td>
            <td>{props.kundenauftrag.data.artikel.artikel_nummer_des_empfaengers}</td>
          </tr>
          <tr>
            <td>Flaschentyp</td>
            <td>{props.kundenauftrag.data.artikel.flaschen_form.flaschentyp.bezeichnung} <Tag minimal>{props.kundenauftrag.data.artikel.flaschen_form.flaschentyp.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Volumen [ml]</td>
            <td>{props.kundenauftrag.data.artikel.flaschen_form.flaschentyp.volumen}</td>
          </tr>
          <tr>
            <td>Blindenzeichen</td>
            <td>{props.kundenauftrag.data.artikel.flaschen_form.flaschentyp.blindenzeichen.bezeichnung}</td>
          </tr>
          <tr>
            <td>Halsteil</td>
            <td>{props.kundenauftrag.data.artikel.flaschen_form.hals_teil.bezeichnung} <Tag minimal>{props.kundenauftrag.data.artikel.flaschen_form.hals_teil.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Farbe</td>
            <td>{props.kundenauftrag.data.artikel.granulat_farbe.bezeichnung} <Tag minimal>{props.kundenauftrag.data.artikel.granulat_farbe.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Batch-Nummer</td>
            <td>{props.kundenauftrag.data.artikel.granulat_farbe.batch_nummer}</td>
          </tr>
          <tr>
            <td>Farb-Bestand</td>
            <td>{props.kundenauftrag.data.artikel.granulat_farbe.bestand} kg</td>
          </tr>
          <tr>
            <td>Einfärbungsgrad</td>
            <td>{props.kundenauftrag.data.artikel.einfaerbungs_grad}</td>
          </tr>
          <tr>
            <td>Gewicht [g]</td>
            <td>{numberFormatter(props.kundenauftrag.data.artikel.gewicht)}</td>
          </tr>
          <tr>
            <td>Werkstoff</td>
            <td>{props.kundenauftrag.data.artikel.werkstoff.bezeichnung} <Tag minimal>{props.kundenauftrag.data.artikel.werkstoff.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Werkstoff-Typ</td>
            {
              props.kundenauftrag.data.artikel.werkstoff_typ
                ? <td>{props.kundenauftrag.data.artikel.werkstoff_typ.bezeichnung} <Tag minimal>{formatWerkstoffTypNummer(props.kundenauftrag.data.artikel.werkstoff_typ.nummer)}</Tag></td>
                : <td>-</td>
            }
          </tr>
        </tbody>
      </HTMLTable>
    </>
  );
}
