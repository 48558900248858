import {
  Button,
  Dialog,
  Divider,
  H2,
} from '@blueprintjs/core';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
} from '../../../infrastructure';

import { ProduktionsplanDruckenResult } from 'wacoplast_wws__api';

export function ProduktionsplanErfolgreichGedrucktHinweis(props: PropsWithServices<CustomFormProps<ProduktionsplanDruckenResult>> & ModalProps<void>): JSX.Element {
  const finish = (): void => {
    props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog isOpen onClose={finish} title='Produktionsplan drucken'>
      <DialogBody>
        <H2>Der Produktionsplan wurden erfolgreich gedruckt.</H2>
        {
          props.tokenPayload.generated_pdfs.map((pdf, index) =>
            (
              <>
                <a
                  href={`${props.config.restServiceUrl}/file_storage/${pdf.file_group}/${pdf.file_name}/${pdf.hash}`}
                  key={index}
                  rel='noreferrer'
                  target='_blank'
                >
                  {pdf.file_name}
                </a>
                <Divider />
              </>
            ))
        }
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Okay</Button>
      </DialogFooter>
    </Dialog>
  );
}
