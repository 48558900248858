export const ProcessConstants = {
  'Kundenauftrag abwickeln': {
    processId: 'Process_2fabd7d2-6280-4d33-84fc-cdc3414ff031',
  },

  'Produktionsauftrag erfassen': {
    processId: 'Process_5421ee84-ab19-4cc5-a0e5-083f64452b70',
  },

  'Produktionsauftrag erfassen Blasen': {
    processId: 'Process_f0734411-3800-4644-a1d5-50a9d96f7060',
  },

  'Produktionsauftrag erfassen Extern': {
    processId: 'Process_a5c4c896-c37a-42e3-9889-b4b32a2cd9f5',
  },

  'Produktionsauftrag erfassen Lager': {
    processId: 'Process_79f0b1d2-16ba-4e1c-a99c-6f8b0a4f11c6',
  },

  'Produktionsauftrag durchführen Lager': {
    processId: 'Process_b5148d18-70e2-40a7-94cd-27f40152794d',
    signals: {
      stornieren: 'produktionsauftrag_lager_stornieren',
    },
  },

  'Produktionsauftrag durchführen Extern': {
    processId: 'Process_d3ee3547-9c8d-4496-89db-f42942973cad',
    signals: {
      stornieren: 'produktionsauftrag_extern_stornieren',
    },
    flowNodes: {
      'Bestellung extern aufgeben': 'Activity_0d66o42',
    },
  },

  'Produktionsauftrag durchführen Blasen': {
    processId: 'Process_b7fee3aa-e0ed-4690-b69d-8626a3c2840c',
    signals: {
      papiereDrucken: 'produktionsauftrag_blasen_papiere_drucken',
      stornieren: 'produktionsauftrag_blasen_stornieren',
    },
  },

  'Produktionsauftrag durchführen': {
    processId: 'Process_2fabd7d2-6280-4d33-84fc-cdc3414ff031',
  },

  'throw_signal_event': {
    processId: 'Process_12wx6fr',
  },

  'Packvorschrift Datei generieren': {
    processId: 'Packvorschrift_Datei_generieren_Process',
  },

  'Papiere drucken': {
    processId: 'Papiere_drucken_Process',
  },

  'Umbauplan drucken': {
    processId: 'Umbauplan_drucken_Process',
  },

  'GranulatFarbe Bedarfvorschau drucken': {
    processId: 'GranulatFarbe_Bedarfvorschau_drucken_Process',
  },

  'Produktionsplan drucken': {
    processId: 'Produktionsplan_drucken_Process',
  },

  'Rückstell-Messprotokoll drucken': {
    processId: 'Rueckstell_Messprotokoll_drucken_Process',
  },
};

export const PauDurchfuehrenModels = {
  blasen: ProcessConstants['Produktionsauftrag durchführen Blasen'],
  extern: ProcessConstants['Produktionsauftrag durchführen Extern'],
  lager: ProcessConstants['Produktionsauftrag durchführen Lager'],
};
