import { Blasform, CreateBlasform } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeTextAreaEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';
import { makeAnzahlKavitaetenNumberEditorRenderer } from './AnzahlKavitaetenNumberEditor';
import { makeBlindenzeichenSelector } from './BlindenzeichenSelector';
import { makeNestNummernEditorRenderer } from './NestNummernEditor';
import { makeWechselbaresBlindenzeichenSwitchEditorRenderer } from './WechselbaresBlindenzeichenSwitch';

import styles from './BlasformVerwalten.module.scss';
import { defaultColumnState } from './defaultColumnConfiguration';
import { formatBooleanToJaNein } from '../../../infrastructure/filterFormatters';

export type BlasformVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function BlasformVerwalten(props: BlasformVerwaltenProps): JSX.Element {
  const flaschentypResult = useFetchLikeGetAllServiceFunction(props.services.flaschenTyp.getAllFlaschenTypGet, props.services.flaschenTyp);
  const blindenzeichenResult = useFetchLikeGetAllServiceFunction(props.services.blindenzeichen.getAllBlindenzeichenGet, props.services.blindenzeichen);

  const editors: StammdatenFieldEditors<CreateBlasform> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'prioritaet',
      renderer: makeNumberEditorRenderer(),
      label: 'Priorität',
    },
    {
      type: 'simple',
      field: 'nummer_intern',
      renderer: makeTextEditorRenderer(),
      label: 'Nummer intern',
    },
    {
      type: 'complex',
      fields: ['wechselbares_blindenzeichen'],
      renderer: makeWechselbaresBlindenzeichenSwitchEditorRenderer(),
      label: 'Wechselbares Blindenzeichen',
    },
    {
      type: 'complex',
      fields: ['flaschen_typ_database_id', 'flaschen_typ_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschentypResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'flaschen_typ'),
      label: 'Flaschentyp',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'stich',
      renderer: makeNumberEditorRenderer(),
      label: 'Stich',
    },
    {
      type: 'complex',
      fields: ['anzahl_kavitaeten'],
      renderer: makeAnzahlKavitaetenNumberEditorRenderer(),
      label: 'Anzahl Kavitaeten',
    },
    {
      type: 'simple',
      field: 'lagerplatz_nummer',
      renderer: makeTextEditorRenderer(),
      label: 'Lagerplatz Nummer',
    },
    {
      type: 'simple',
      field: 'blindenzeichen',
      renderer: makeBlindenzeichenSelector(blindenzeichenResult, (data) => ({ primaryTitle: data.bezeichnung, secondaryTitle: data.nummer })),
      label: 'Mögliche Blindenzeichen',
    },
    {
      type: 'label',
      label: 'Nestnummern',
      gridArea: 'nest_nummern_label',
    },
    {
      type: 'complex',
      fields: ['nest_nummer_1', 'nest_nummer_2', 'nest_nummer_3', 'nest_nummer_4', 'nest_nummer_5', 'nest_nummer_6', 'nest_nummer_7', 'nest_nummer_8', 'nest_nummer_9', 'nest_nummer_10'],
      renderer: makeNestNummernEditorRenderer(),
      gridArea: 'nest_nummern',
    },
    {
      type: 'simple',
      field: 'hinweis',
      renderer: makeTextAreaEditorRenderer(),
      label: 'Hinweis',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultEntityForCreation={{ wechselbares_blindenzeichen: false }}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Nummer intern',
          field: 'nummer_intern',
          colId: 'nummer_intern',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Flaschentyp',
          colId: 'flaschen_typ',
          ...makeForeignKeyColumn('flaschen_typ', (data: Blasform) => {
            return {
              description: data.flaschen_typ.bezeichnung,
            };
          }),
        },
        {
          headerName: 'Stich',
          field: 'stich',
          colId: 'stich',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Anzahl Kavitaeten',
          field: 'anzahl_kavitaeten',
          colId: 'anzahl_kavitaeten',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 1',
          field: 'nest_nummer_1',
          colId: 'nest_nummer_1',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 2',
          field: 'nest_nummer_2',
          colId: 'nest_nummer_2',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 3',
          field: 'nest_nummer_3',
          colId: 'nest_nummer_3',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 4',
          field: 'nest_nummer_4',
          colId: 'nest_nummer_4',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 5',
          field: 'nest_nummer_5',
          colId: 'nest_nummer_5',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 6',
          field: 'nest_nummer_6',
          colId: 'nest_nummer_6',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 7',
          field: 'nest_nummer_7',
          colId: 'nest_nummer_7',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 8',
          field: 'nest_nummer_8',
          colId: 'nest_nummer_8',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 9',
          field: 'nest_nummer_9',
          colId: 'nest_nummer_9',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Nest 10',
          field: 'nest_nummer_10',
          colId: 'nest_nummer_10',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Wechselbares Blindenzeichen',
          field: 'wechselbares_blindenzeichen',
          colId: 'wechselbares_blindenzeichen',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: any) => formatBooleanToJaNein(value),
          },
          valueFormatter: ({ value }) => value ? 'Ja' : 'Nein',
        },
        {
          headerName: 'Blindenzeichen',
          field: 'blindenzeichen',
          colId: 'blindenzeichen',
          filter: 'agTextColumnFilter',
          valueGetter: (params) => params.data.blindenzeichen.map((blz) => blindenzeichenResult.data?.find((b) => b.database_id === blz.blindenzeichen_database_id && b.version_id === blz.blindenzeichen_version_id)?.bezeichnung),
        },
        {
          headerName: 'Priorität',
          field: 'prioritaet',
          colId: 'prioritaet',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Lagerplatz Nummer',
          field: 'lagerplatz_nummer',
          colId: 'lagerplatz_nummer',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Hinweis',
          field: 'hinweis',
          colId: 'hinweis',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateBlasform) => props.services.blasform.postStellblattBlasformPost({ CreateBlasform: { ...data, blindenzeichen: data.blindenzeichen ?? [] } }),
        update: (data) => props.services.blasform.putByDatabaseIdStellblattBlasformDatabaseIdPut({ database_id: data.database_id, UpdateBlasform: { ...data, blindenzeichen: data.blindenzeichen ?? [] } }),
        delete: (data, ignoreIntegrity) => props.services.blasform.deleteByDatabaseIdStellblattBlasformDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.blasform.restoreByDatabaseIdStellblattBlasformDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.blasform.getAllStellblattBlasformGet({ include_deleted }),
        getHistory: (data: Blasform) => props.services.blasform.getHistoryStellblattBlasformDatabaseIdHistoryGet({ database_id: data.database_id }),
        lock: (data) => props.services.blasform.lockByDatabaseIdStellblattBlasformDatabaseIdLockPatch({ database_id: data.database_id, LockRequest: { aenderungsgrund: data.aenderungsgrund as any } }),
        unlock: (data) => props.services.blasform.unlockByDatabaseIdStellblattBlasformDatabaseIdUnlockPatch({ database_id: data.database_id, UnlockRequest: { aenderungsgrund: data.aenderungsgrund } }),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      stammdatenModalCssClass={styles.blasform_verwalten_modal}
      dialogStrings={DialogStrings.Blasform}
    />
  );
}
