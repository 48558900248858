"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMetadataToJSON = exports.FileMetadataFromJSONTyped = exports.FileMetadataFromJSON = void 0;
function FileMetadataFromJSON(json) {
    return FileMetadataFromJSONTyped(json, false);
}
exports.FileMetadataFromJSON = FileMetadataFromJSON;
function FileMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hash': json['hash'],
        'file_name': json['file_name'],
        'content_type': json['content_type'],
        'created_at': (new Date(json['created_at'])),
        'created_by_user_name': json['created_by_user_name'],
        'created_by_user_id': json['created_by_user_id'],
    };
}
exports.FileMetadataFromJSONTyped = FileMetadataFromJSONTyped;
function FileMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hash': value.hash,
        'file_name': value.file_name,
        'content_type': value.content_type,
        'created_at': (value.created_at.toISOString()),
        'created_by_user_name': value.created_by_user_name,
        'created_by_user_id': value.created_by_user_id,
    };
}
exports.FileMetadataToJSON = FileMetadataToJSON;
