/* eslint-disable react/display-name */
import React from 'react';
import {
  Artikel,
  BpmnValidationError,
  Firma,
  KundenauftragArt,
  KundenauftragDetailsAngebenResult,
  KundenauftragDetailsAngebenResultFromJSON,
  KundenauftragDetailsAngebenResultToJSON,
  LieferterminKennzeichen,
  Rahmenauftrag,
} from 'wacoplast_wws__api';
import { Dialog } from '@blueprintjs/core';
import {
  AsyncButton,
  CustomFormProps,
  DateEditor,
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  DialogFormField,
  EnumDropDownPicker,
  LieferterminEditor,
  NumberEditor,
  PropsWithServices,
  PropsWithTransaction,
  TextEditor,
  TextViewer,
  UseFetchLikeServiceFunctionResult,
  getPayloadOrBpmnValidationErrorFromToken,
  getValidatedDateTimePickerComponentValue,
  isValidDate,
  removeFieldFromValidationError,
  updateValidationError,
  useFetchLikeGetAllServiceFunction,
} from '../../../../infrastructure';
import { ArtikelDropDownPicker } from '../../..';
import { KundenauftragArtKeyMapping, MAX_DAYS_FOR_AUFTRAGSEINGANG, calculateMinDateWithLimit } from '../..';
import { min } from 'date-fns';

export type KundenauftragDetailsAngebenProps = PropsWithTransaction<PropsWithServices<CustomFormProps<KundenauftragDetailsAngebenResult>>>;

export type KundenauftragDetailsAngebenCardProps = KundenauftragDetailsAngebenProps & {
  kunden: UseFetchLikeServiceFunctionResult<Array<Firma>>,
  artikel: UseFetchLikeServiceFunctionResult<Array<Artikel>>,
  rahmenauftraege: UseFetchLikeServiceFunctionResult<Array<Rahmenauftrag>>
};

type KundenauftragDetailsAngebenState = {
  kunde: Firma | null,
  artikel: Artikel | null,
  auftragsart: KundenauftragArt | null,
  rahmenauftrag: Rahmenauftrag | null,
  menge: number | null,
  auftragsnummer_des_empfaenger: string | null,
  bestell_nummer: string | null,
  liefertermin_soll: Date | null,
  liefertermin_kennzeichen: LieferterminKennzeichen,
  datum_eingang: Date | null,
  validationError: BpmnValidationError | null,
};

class KundenauftragdetailsAngebenCard extends React.PureComponent<KundenauftragDetailsAngebenCardProps, KundenauftragDetailsAngebenState> {

  constructor(props: KundenauftragDetailsAngebenCardProps) {
    super(props);

    const [
      ,
      previousValues,
      err,
    ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, KundenauftragDetailsAngebenResultFromJSON);

    this.state = {
      kunde: previousValues.artikel?.kunde ?? null,
      artikel: previousValues.artikel ?? null,
      auftragsart: previousValues.auftragsart ?? null,
      rahmenauftrag: previousValues.rahmenauftrag ?? null,
      menge: previousValues.menge ?? null,
      auftragsnummer_des_empfaenger: previousValues.auftragsnummer_des_empfaenger ?? null,
      bestell_nummer: previousValues.bestell_nummer ?? null,
      liefertermin_soll: isValidDate(previousValues.liefertermin_soll) ? previousValues.liefertermin_soll ?? null : null,
      liefertermin_kennzeichen: previousValues.liefertermin_kennzeichen ?? LieferterminKennzeichen.W,
      datum_eingang: isValidDate(previousValues.datum_eingang) ? previousValues.datum_eingang ?? null : null,
      validationError: err,
    };
  }

  private submit = async (): Promise<void> => {

    const payload: KundenauftragDetailsAngebenResult = {
      artikel: this.state.artikel,
      auftragsart: this.state.auftragsart,
      rahmenauftrag: this.state.rahmenauftrag ?? undefined,
      menge: this.state.menge,
      auftragsnummer_des_empfaenger: this.state.auftragsnummer_des_empfaenger,
      bestell_nummer: this.state.bestell_nummer,
      liefertermin_soll: (this.state.liefertermin_soll ?? undefined) as Date,
      liefertermin_kennzeichen: this.state.liefertermin_kennzeichen,
      datum_eingang: getValidatedDateTimePickerComponentValue(this.state.datum_eingang ?? undefined),
    } as any;

    return this.props.finishUserTask(KundenauftragDetailsAngebenResultToJSON(payload));
  };


  public render(): JSX.Element {
    const filteredArtikelData = !this.props.artikel.data
      ? this.props.artikel
      : {
        ...this.props.artikel,
        data: this.props.artikel.data.filter((artikel) => artikel.kunde_database_id === this.state.kunde?.database_id),
      };

    return (
      <Dialog
        isOpen={true}
        onClose={() => this.props.abortUserTask()}
        title='Kundenauftragsdetails angeben'
      >
        <DialogBody>
          <DialogFormField
            fieldLocation=''
            fieldLabel='Kunde'
          >
            <DefaultDropDownPicker
              dataProvider={this.props.kunden}
              onChange={(value) => {
                this.setState({
                  kunde: value,
                  artikel: null,
                });
              }}
              value={this.state.kunde}
              getDisplayData={(data) => {
                return { primaryTitle: data?.name_kurz ?? '---', secondaryTitle: data?.nummer };
              }}
            />
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['artikel']}
            fieldLabel='Artikel'
          >
            <ArtikelDropDownPicker
              disabled={!this.state.kunde}
              dataProvider={filteredArtikelData}
              onChange={(value) => {
                this.setState({
                  validationError: updateValidationError({ artikel: null }, this.state.validationError),
                  artikel: value,
                });
              }}
              value={this.state.artikel}
            />
          </DialogFormField>

          <DialogFormField
            fieldLabel='Empfänger'
            fieldLocation=''
          >
            <TextViewer value={this.state.artikel?.empfaenger?.name_kurz ?? this.state.artikel?.kunde.name_kurz ?? null} /> {/** TODO remove kunde from here as soon as #194 is fixed (https://github.com/5minds/wacoplast_wws/issues/194) */}
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['auftragsart']}
            fieldLabel='Auftragsart'
          >
            <EnumDropDownPicker
              data={Object.values(KundenauftragArt)}
              getDisplayData={(data) => (data && KundenauftragArtKeyMapping[data]) ?? '---'}
              onChange={(value) => this.setState({
                validationError: updateValidationError({ auftragsart: null, rahmenauftrag: null }, this.state.validationError),
                auftragsart: value,
                rahmenauftrag: value === KundenauftragArt.A ? this.state.rahmenauftrag : null,
              })}
              value={this.state.auftragsart}
            />
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['rahmenauftrag']}
            fieldLabel='Rahmenauftrag'
          >
            <DefaultDropDownPicker
              disabled={!this.state.artikel || this.state.auftragsart !== KundenauftragArt.A}
              dataProvider={this.props.rahmenauftraege}
              filter={(data) => data.artikel_database_id === this.state.artikel?.database_id}
              onChange={(value) => this.setState({
                validationError: { ...removeFieldFromValidationError(this.state.validationError, ['rahmenauftrag']) as BpmnValidationError, previous_payload: this.state.validationError?.previous_payload ?? {} },
                rahmenauftrag: value,
              })}
              value={this.state.rahmenauftrag}
              getDisplayData={(data) => {
                return { primaryTitle: data.nummer };
              }}
            />
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['menge']}
            fieldLabel='Menge'
          >
            <NumberEditor
              min={0}
              onChange={(value) => this.setState({
                validationError: { ...removeFieldFromValidationError(this.state.validationError, ['menge']) as BpmnValidationError, previous_payload: this.state.validationError?.previous_payload ?? {} },
                menge: value,
              })}
              value={this.state.menge}
              formatDecimalSeparator
            />
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['auftragsnummer_des_empfaenger']}
            fieldLabel='Auftragsnummer des Empfängers'
          >
            <TextEditor
              onChange={(value) => this.setState({
                validationError: { ...removeFieldFromValidationError(this.state.validationError, ['auftragsnummer_des_empfaenger']) as BpmnValidationError, previous_payload: this.state.validationError?.previous_payload ?? {} },
                auftragsnummer_des_empfaenger: value,
              })}
              value={this.state.auftragsnummer_des_empfaenger}
            />
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['bestell_nummer']}
            fieldLabel='Bestellnummer'
          >
            <TextEditor
              onChange={(value) => this.setState({
                validationError: { ...removeFieldFromValidationError(this.state.validationError, ['bestell_nummer']) as BpmnValidationError, previous_payload: this.state.validationError?.previous_payload ?? {} },
                bestell_nummer: value,
              })}
              value={this.state.bestell_nummer}
            />
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['liefertermin_kennzeichen']}
            fieldLabel='Liefertermin soll'
          >
            <LieferterminEditor
              onChange={(value) => this.setState({
                validationError: { ...removeFieldFromValidationError(this.state.validationError, ['liefertermin_kennzeichen']) as BpmnValidationError, previous_payload: this.state.validationError?.previous_payload ?? {} },
                liefertermin_soll: value.liefertermin_soll,
                liefertermin_kennzeichen: value.liefertermin_kennzeichen ?? LieferterminKennzeichen.W,
              })}
              value={{ liefertermin_soll: this.state.liefertermin_soll, liefertermin_kennzeichen: this.state.liefertermin_kennzeichen }}
            />
          </DialogFormField>

          <DialogFormField
            lastValidationResponse={this.state.validationError}
            fieldLocation={['datum_eingang']}
            fieldLabel='Datum Eingang'
          >
            <DateEditor
              minDate={calculateMinDateWithLimit(MAX_DAYS_FOR_AUFTRAGSEINGANG)}
              maxDate={min([this.state.rahmenauftrag?.liefertermin_soll ?? new Date(), new Date()])}
              onChange={(value) => this.setState({
                validationError: { ...removeFieldFromValidationError(this.state.validationError, ['datum_eingang']) as BpmnValidationError, previous_payload: this.state.validationError?.previous_payload ?? {} },
                datum_eingang: value,
              })}
              value={this.state.datum_eingang}
            />
          </DialogFormField>
        </DialogBody>
        <DialogFooter>
          <AsyncButton intent='primary' label='Speichern' onClick={this.submit} />
        </DialogFooter>
      </Dialog>
    );
  }

}

export function KundenauftragdetailsAngeben(props: KundenauftragDetailsAngebenProps): JSX.Element {
  const kunden = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const artikel = useFetchLikeGetAllServiceFunction(props.services.artikel.getAllArtikelGet, props.services.artikel);
  const rahmenauftraege = useFetchLikeGetAllServiceFunction(props.services.rahmenauftrag.getAllRahmenauftragGet, props.services.rahmenauftrag);

  return <KundenauftragdetailsAngebenCard {...props} kunden={kunden} artikel={artikel} rahmenauftraege={rahmenauftraege} />;
}
