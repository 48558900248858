"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateHalsTeilToJSON = exports.CreateHalsTeilFromJSONTyped = exports.CreateHalsTeilFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateHalsTeilFromJSON(json) {
    return CreateHalsTeilFromJSONTyped(json, false);
}
exports.CreateHalsTeilFromJSON = CreateHalsTeilFromJSON;
function CreateHalsTeilFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'bezeichnung': json['bezeichnung'],
        'verwendung': !runtime_1.exists(json, 'verwendung') ? undefined : json['verwendung'],
        'bemerkung': !runtime_1.exists(json, 'bemerkung') ? undefined : json['bemerkung'],
        'specification_files': (json['specification_files'].map(_1.HalsTeilFileStorageAssociationFromJSON)),
    };
}
exports.CreateHalsTeilFromJSONTyped = CreateHalsTeilFromJSONTyped;
function CreateHalsTeilToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'bezeichnung': value.bezeichnung,
        'verwendung': value.verwendung,
        'bemerkung': value.bemerkung,
        'specification_files': (value.specification_files.map(_1.HalsTeilFileStorageAssociationToJSON)),
    };
}
exports.CreateHalsTeilToJSON = CreateHalsTeilToJSON;
