import { Classes } from '@blueprintjs/core';

export type CollapseDotsProps = {
  onClick: () => void;
}

export function CollapseDots(props: CollapseDotsProps): JSX.Element {

  return (
    <span onClick={() => props.onClick()} className={Classes.POPOVER_TARGET}>
      <span className={Classes.BREADCRUMBS_COLLAPSED} />
    </span>
  );
}
