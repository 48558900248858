/* eslint-disable react/display-name */
import styles from './flaschentypVerwalten.module.scss';
import { Blindenzeichen } from 'wacoplast_wws__api';
import { StammdatenFieldEditorSimpleRendererProps, TextEditor, UseFetchLikeServiceFunctionResult } from '../../infrastructure';


export function makeTextEditorWithSublabelForBLZRenderer<TEntityType extends { blindenzeichen_database_id: number | null }, TForeignKeyType extends Blindenzeichen>(dataProvider: UseFetchLikeServiceFunctionResult<Array<TForeignKeyType>>, placeholder?: string, disabled?: boolean): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, string | null>) => {
    const blzNummer = parseInt(dataProvider.data?.find((entry) => (entry.database_id === props.stammdatenEntity['blindenzeichen_database_id']))?.nummer as string);
    const geradeUngeradeString = blzNummer % 2 === 0 ? 'geraden' : 'ungeraden';
    const blzHinweisString = props.stammdatenEntity.blindenzeichen_database_id ? `Nummer sollte auf einer ${geradeUngeradeString} Ziffer enden.` : '';

    return (
      <>
        <span className={styles.sublabel}>
          {blzHinweisString}
        </span>
        <TextEditor
          value={props.value ? String(props.value) : null}
          disabled={props.disabled || disabled}
          placeholder={placeholder}
          onChange={(value) => props.onChange((value?.length ? value : null))}
        />
      </>
    ); 
  };
}

