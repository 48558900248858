import { Button, Dialog, H2 } from '@blueprintjs/core';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
} from '../../../infrastructure';

export function FehlendePapiereDruckenDatenHinweis(props: CustomFormProps<{ message: string }> & ModalProps<void>): JSX.Element {

  const finish = (): void => {
    props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog isOpen onClose={finish} title='Papiere drucken'>
      <DialogBody>
        <H2>Fehlende Druckdaten</H2>
        Während des Druckens wurde kein passender Eintrag zu { props.tokenPayload.message } gefunden. Bitte legen Sie zuerst eine an.
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Okay</Button>
      </DialogFooter>
    </Dialog>
  );
}
