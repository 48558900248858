/* eslint-disable react/display-name */
import { Callout } from '@blueprintjs/core';
import { VersionedSchema } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps } from '.';
import { DefaultDropDownPicker, UseFetchLikeServiceFunctionResult, getDefaultDisplayData } from '../..';

export function makeVersionedDropDownPickerRenderer<TEntityType, TForeignKeyType extends VersionedSchema & {database_id: number}>(
  dataProvider: UseFetchLikeServiceFunctionResult<Array<TForeignKeyType>>,
  getItemData: getDefaultDisplayData<TForeignKeyType>,
  prefix: string,
  nullable = false,
  onChangeValueFormatter?: (data: TForeignKeyType | null) => Partial<TEntityType>,
): any {
  const database_id_field = `${prefix}_database_id` as keyof TEntityType;
  const version_id_field = `${prefix}_version_id` as keyof TEntityType;

  return (props: StammdatenFieldEditorComplexRendererProps<TEntityType>) => {
    const valueInLatestVersion = dataProvider.data?.find((entry) => (
      entry.database_id === props.stammdatenEntity[database_id_field] as any
    ));
    const valueReferenced = props.stammdatenEntity[prefix as keyof TEntityType] as any as TForeignKeyType;

    const isReferencingOldVersion = valueInLatestVersion && valueInLatestVersion.version_id !== props.stammdatenEntity[version_id_field] as any && dataProvider.data;
    const isReferencingDeletedEntity = valueReferenced && !valueInLatestVersion && dataProvider.data;

    const isReferencingNonSelectable = isReferencingOldVersion || isReferencingDeletedEntity;

    const value = isReferencingNonSelectable ? valueReferenced : valueInLatestVersion;

    return (
      <>
        {
          isReferencingOldVersion && !props.disabled && (
            <Callout intent='warning' style={{ marginBottom: '5px' }}>
              Hier wird eine alte Version ({value?.version_id}) des Datensatzes referenziert.<br />
              Um auf die Version ({valueInLatestVersion.version_id}) zu aktualisieren, muss in der Auswahl der neue Datensatz ausgewählt werden.
            </Callout>
          )
        }
        {
          isReferencingDeletedEntity && !props.disabled && (
            <Callout intent='danger' style={{ marginBottom: '5px' }}>
              Hier wird eine alte Version ({value?.version_id}) eines inzwischen gelöschten Datensatzes referenziert.
            </Callout>
          )
        }
        <DefaultDropDownPicker
          dataProvider={dataProvider}
          getDisplayData={getItemData}
          nullable={nullable}
          disabled={props.disabled}
          value={value ?? null}
          onChange={(value) => props.onChange(onChangeValueFormatter
            ? onChangeValueFormatter(value)
            : {
              [database_id_field]: value?.database_id ?? null,
              [version_id_field]: value?.version_id ?? null,
              [prefix as keyof TEntityType]: value === null ? null : valueReferenced ?? null,
            } as any)}
        />
      </>
    );
  };
}
