import { HTMLTable, Tag } from '@blueprintjs/core';
import { Artikel } from 'wacoplast_wws__api';
import { formatWerkstoffTypNummer } from '../../../infrastructure';

import styles from './ArtikelInformationTable.module.scss';


export type ArtikelInformationTableProps = { artikel: Artikel };

export function ArtikelInformationTable(props: ArtikelInformationTableProps): JSX.Element {

  // const numberFormatter = numberValueFormatterGen(0);

  return (
    <>
      <HTMLTable striped className={styles.artikel_information_table}>
        <tbody>
          <tr>
            <td>Nummer</td>
            <td>{props.artikel.nummer}</td>
          </tr>
          <tr>
            <td>Kunde</td>
            <td>{props.artikel.kunde.name_kurz} <Tag minimal>{props.artikel.kunde.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Flaschentyp</td>
            <td>{props.artikel.flaschen_form.flaschentyp.bezeichnung} <Tag minimal>{props.artikel.flaschen_form.flaschentyp.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Blindenzeichen</td>
            <td>{props.artikel.flaschen_form.flaschentyp.blindenzeichen.bezeichnung} <Tag minimal>{props.artikel.flaschen_form.flaschentyp.blindenzeichen.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Volumen [ml]</td>
            <td>{props.artikel.flaschen_form.flaschentyp.volumen}</td>
          </tr>
          <tr>
            <td>Halsteil</td>
            <td>{props.artikel.flaschen_form.hals_teil.bezeichnung} <Tag minimal>{props.artikel.flaschen_form.hals_teil.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Gewicht [g]</td>
            <td>{props.artikel.gewicht}</td>
          </tr>
          <tr>
            <td>Farbe</td>
            <td>{props.artikel.granulat_farbe.bezeichnung} <Tag minimal>{props.artikel.granulat_farbe.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Batch-Nr.</td>
            <td>{props.artikel.granulat_farbe.batch_nummer}</td>
          </tr>
          <tr>
            <td>Farb-%</td>
            <td>{props.artikel.einfaerbungs_grad}</td>
          </tr>
          <tr>
            <td>Werkstoff</td>
            <td>{props.artikel.werkstoff.bezeichnung} <Tag minimal>{props.artikel.werkstoff.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Werkstoff-Typ</td>
            {
              props.artikel.werkstoff_typ
                ? <td>{props.artikel.werkstoff_typ.bezeichnung} <Tag minimal>{formatWerkstoffTypNummer(props.artikel.werkstoff_typ.nummer)}</Tag></td>
                : <td>-</td>
            }
          </tr>
          <tr>
            <td>Empfänger</td>
            {
              props.artikel.empfaenger
                ? <td>{props.artikel.empfaenger.name_kurz} <Tag minimal>{props.artikel.empfaenger.nummer}</Tag></td>
                : <td>-</td>
            }
          </tr>
        </tbody>
      </HTMLTable>
    </>
  );
}
