import { Button, Dialog, H2 } from '@blueprintjs/core';
import { KundenauftragStornierungAbfragenResultToJSON } from 'wacoplast_wws__api';
import { CustomFormProps, DialogBody, DialogFooter } from '../../../../infrastructure';

export function KundenauftragStornierungAbfragen(props: CustomFormProps & { finish: () => void }): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Kundenauftrag stornieren'
    >
      <DialogBody>
        <H2>
          Soll der Kundenauftrag storniert werden?
        </H2>
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={() => {
            props.finish();
            props.finishUserTask(KundenauftragStornierungAbfragenResultToJSON({ soll_kuau_storniert_werden: true }));
          }}
        >
          Ja, KUAU stornieren
        </Button>
        <Button
          intent='primary'
          onClick={() => props.abortUserTask(KundenauftragStornierungAbfragenResultToJSON({ soll_kuau_storniert_werden: false }))}
        >
          Nein, neuen PAU anlegen
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
