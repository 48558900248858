"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirmaTypToJSON = exports.FirmaTypFromJSONTyped = exports.FirmaTypFromJSON = exports.FirmaTyp = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var FirmaTyp;
(function (FirmaTyp) {
    FirmaTyp["Kunde"] = "Kunde";
    FirmaTyp["Empfaenger"] = "Empfaenger";
    FirmaTyp["Empfaenger_SHB"] = "Empfaenger_SHB";
})(FirmaTyp = exports.FirmaTyp || (exports.FirmaTyp = {}));
function FirmaTypFromJSON(json) {
    return FirmaTypFromJSONTyped(json, false);
}
exports.FirmaTypFromJSON = FirmaTypFromJSON;
function FirmaTypFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.FirmaTypFromJSONTyped = FirmaTypFromJSONTyped;
function FirmaTypToJSON(value) {
    return value;
}
exports.FirmaTypToJSON = FirmaTypToJSON;
