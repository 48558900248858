/* eslint-disable react/display-name */
import React from 'react';
import { MaterialGruppe, VerpackungsEinheit } from 'wacoplast_wws__api';
import { StammdatenFieldEditorSimpleRendererProps, UseFetchLikeServiceFunctionResult, makeStammdatenEntityRelatedDisabledEditor } from '../../infrastructure';

export function makeKartonTraegerOnlyEditorRenderer<TEntityType>(dataProvider: UseFetchLikeServiceFunctionResult<Array<VerpackungsEinheit>>, component: React.FunctionComponent<StammdatenFieldEditorSimpleRendererProps<TEntityType, any>>): any {

  return makeStammdatenEntityRelatedDisabledEditor(component, (stammdatenEntity) => {
    const selectedVerpackungsEinheit = dataProvider.data?.find((data) => data.database_id === (stammdatenEntity as any).verpackungs_einheit_database_id
    && data.version_id === (stammdatenEntity as any).verpackungs_einheit_version_id);

    const isKartonTraegerSelected = selectedVerpackungsEinheit !== undefined && [MaterialGruppe.NUMBER_10, MaterialGruppe.NUMBER_30].includes(selectedVerpackungsEinheit?.material_gruppe);

    return !isKartonTraegerSelected;
  });
}
