import { Button, Dialog, HTMLTable } from '@blueprintjs/core';
import React from 'react';

import { QHinweisProdukt } from 'wacoplast_wws__api';
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  ModalProps,
  formatDateString,
} from '../../../infrastructure';
import styles from './HinweisModal.module.scss';

export type QHinweisProduktModalProps = ModalProps<void> & {
  flaschen_typ_nummer: string;
  flaschen_typ_bezeichung: string;
  qHinweiseProdukt: QHinweisProdukt[];
}

export function QHinweisProduktModal(props: QHinweisProduktModalProps): JSX.Element {
  return (
    <HinweisModalContainer {...props}>
      <HTMLTable className={styles['content-container__table']}>
        <tr>
          <th>Datum</th>
          <th>Hinweis aus Reklamation</th>
          <th>Erfolgte Korrekturmaßnahmen</th>
        </tr>
        {props.qHinweiseProdukt.map((qHinweisProdukt, index): JSX.Element => {
          return (
            <tr key={index}>
              <td>
                {formatDateString(qHinweisProdukt.datum)}
              </td>
              <td>
                {qHinweisProdukt.hinweis_aus_reklamation}
              </td>
              <td>
                {qHinweisProdukt.erfolgte_korrekturmassnahmen}
              </td>
            </tr>
          );
        })}
      </HTMLTable>
    </HinweisModalContainer>
  );
}




type HinweisModalProps = React.PropsWithChildren<ModalProps<void> & {
  flaschen_typ_nummer: string;
  flaschen_typ_bezeichung: string;
}>;

function HinweisModalContainer(props: HinweisModalProps): JSX.Element {
  return (
    <Dialog isOpen={true} className={styles.hinweisModalContainer} onClose={() => props.onModalFinished()}>
      <DialogHeader
        onClick={() => props.onModalFinished()}
      >
        Hinweise zu {props.flaschen_typ_bezeichung}
        {' '}
        <small className='text-muted'>
          (Nr.: {props.flaschen_typ_nummer})
        </small>
      </DialogHeader>
      <DialogBody>
        <div className={styles['content-container']}>
          {props.children}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => props.onModalFinished()}>Schließen</Button>
      </DialogFooter>
    </Dialog>
  );
}
