"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GewichtstoleranzToJSON = exports.GewichtstoleranzFromJSONTyped = exports.GewichtstoleranzFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GewichtstoleranzFromJSON(json) {
    return GewichtstoleranzFromJSONTyped(json, false);
}
exports.GewichtstoleranzFromJSON = GewichtstoleranzFromJSON;
function GewichtstoleranzFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'ausnahme': json['ausnahme'],
        'gewicht_von': json['gewicht_von'],
        'gewicht_bis': !runtime_1.exists(json, 'gewicht_bis') ? undefined : json['gewicht_bis'],
        'toleranz': json['toleranz'],
        'flaschentyp_database_id': !runtime_1.exists(json, 'flaschentyp_database_id') ? undefined : json['flaschentyp_database_id'],
        'flaschentyp_version_id': !runtime_1.exists(json, 'flaschentyp_version_id') ? undefined : json['flaschentyp_version_id'],
        'kunde_database_id': !runtime_1.exists(json, 'kunde_database_id') ? undefined : json['kunde_database_id'],
        'kunde_version_id': !runtime_1.exists(json, 'kunde_version_id') ? undefined : json['kunde_version_id'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'flaschentyp': !runtime_1.exists(json, 'flaschentyp') ? undefined : _1.FlaschenTypFromJSON(json['flaschentyp']),
        'kunde': !runtime_1.exists(json, 'kunde') ? undefined : _1.FirmaFromJSON(json['kunde']),
    };
}
exports.GewichtstoleranzFromJSONTyped = GewichtstoleranzFromJSONTyped;
function GewichtstoleranzToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'ausnahme': value.ausnahme,
        'gewicht_von': value.gewicht_von,
        'gewicht_bis': value.gewicht_bis,
        'toleranz': value.toleranz,
        'flaschentyp_database_id': value.flaschentyp_database_id,
        'flaschentyp_version_id': value.flaschentyp_version_id,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'flaschentyp': _1.FlaschenTypToJSON(value.flaschentyp),
        'kunde': _1.FirmaToJSON(value.kunde),
    };
}
exports.GewichtstoleranzToJSON = GewichtstoleranzToJSON;
