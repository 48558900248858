import io from 'socket.io-client';
import { IdentityProvider } from './processcube';
import {
  AbwicklungspfadProduktionsauftrag,
  ArtikelFromJSON,
  BlasformFromJSON,
  BlasmaschineFromJSON,
  BlasmaschineZeitenFromJSON,
  BlindenzeichenFromJSON,
  EinstellparameterBlasformFromJSON,
  EmitableEventNames,
  ExtruderTemperaturFromJSON,
  FahrzeugFromJSON,
  FahrzeugZuKundeFromJSON,
  FirmaFromJSON,
  FlaschenFormFromJSON,
  FlaschenTypFromJSON,
  GewichtstoleranzFromJSON,
  GranulatFarbeFromJSON,
  HalsTeilFromJSON,
  HalsteilZuBlasformFromJSON,
  KundenauftragFromJSON,
  LieferantFromJSON,
  PackordnungFromJSON,
  ProduktionsauftragBlasenFromJSON,
  ProduktionsauftragExternFromJSON,
  ProduktionsauftragFromJSON,
  ProduktionsauftragLagerFromJSON,
  QHinweisProduktFromJSON,
  RahmenauftragFromJSON,
  StellblattAbstaendeFromJSON,
  SubscriptionEvent,
  SubscriptionEventFromJSON,
  VerpackungsEinheitFromJSON,
  VerpackungsMaterialFromJSON,
  VerpackungsVorschriftFromJSON,
  WerkstoffFromJSON,
  WerkstoffTypFromJSON,
} from 'wacoplast_wws__api';

export class SubscriptionEventService{

  private connection: SocketIOClient.Socket;

  constructor(restServiceUrl: string, getIdentity: IdentityProvider) {
    this.connection = io(restServiceUrl, { path: '/subscribe/socket.io', transports: ['websocket', 'polling'], auth: { token: `Bearer ${getIdentity().token}` } });
  }

  public subscribe(event: string, callback: (data: SubscriptionEvent) => void): void {
    this.connection.on(event, async (data: string) => {
      const subscribtionEvent = SubscriptionEventFromJSON(JSON.parse(data));
      const mappingFunction = fromJSONFunctionMapping[subscribtionEvent.event_name];
      const affectedEntities = subscribtionEvent.affected_entities.map((value) => mappingFunction(value));
      subscribtionEvent.affected_entities = affectedEntities;
      callback(subscribtionEvent);
    });
  }
}

const fromJSONFunctionMapping: Record<EmitableEventNames, (json: any) => any> = {
  [EmitableEventNames.Abstaende]: StellblattAbstaendeFromJSON,
  [EmitableEventNames.Artikel]: ArtikelFromJSON,
  [EmitableEventNames.Blasform]: BlasformFromJSON,
  [EmitableEventNames.Blasmaschine]: BlasmaschineFromJSON,
  [EmitableEventNames.BlasmaschineZeiten]: BlasmaschineZeitenFromJSON,
  [EmitableEventNames.Blindenzeichen]: BlindenzeichenFromJSON,
  [EmitableEventNames.EinstellparameterBlasform]: EinstellparameterBlasformFromJSON,
  [EmitableEventNames.ExtruderTemperatur]: ExtruderTemperaturFromJSON,
  [EmitableEventNames.Fahrzeug]: FahrzeugFromJSON,
  [EmitableEventNames.FahrzeugZuKunde]: FahrzeugZuKundeFromJSON,
  [EmitableEventNames.Firma]: FirmaFromJSON,
  [EmitableEventNames.FlaschenForm]: FlaschenFormFromJSON,
  [EmitableEventNames.FlaschenTyp]: FlaschenTypFromJSON,
  [EmitableEventNames.Gewichtstoleranz]: GewichtstoleranzFromJSON,
  [EmitableEventNames.GranulatFarbe]: GranulatFarbeFromJSON,
  [EmitableEventNames.HalsTeil]: HalsTeilFromJSON,
  [EmitableEventNames.HalsteilZuBlasform]: HalsteilZuBlasformFromJSON,
  [EmitableEventNames.Kundenauftrag]: KundenauftragFromJSON,
  [EmitableEventNames.Lieferant]: LieferantFromJSON,
  [EmitableEventNames.Packordnung]: PackordnungFromJSON,
  [EmitableEventNames.Produktionsauftrag]: (json: any) => {
    const parsed_pau = ProduktionsauftragFromJSON(json);
    if (parsed_pau.abwicklungspfad === AbwicklungspfadProduktionsauftrag.blasen) {
      return ProduktionsauftragBlasenFromJSON(json);
    } else if (parsed_pau.abwicklungspfad === AbwicklungspfadProduktionsauftrag.extern) {
      return ProduktionsauftragExternFromJSON(json);
    } else if (parsed_pau.abwicklungspfad === AbwicklungspfadProduktionsauftrag.lager){
      return ProduktionsauftragLagerFromJSON(json);
    }
    return parsed_pau;
  },
  [EmitableEventNames.QHinweisProdukt]: QHinweisProduktFromJSON,
  [EmitableEventNames.Rahmenauftrag]: RahmenauftragFromJSON,
  [EmitableEventNames.VerpackungsEinheit]: VerpackungsEinheitFromJSON,
  [EmitableEventNames.VerpackungsMaterial]: VerpackungsMaterialFromJSON,
  [EmitableEventNames.VerpackungsVorschrift]: VerpackungsVorschriftFromJSON,
  [EmitableEventNames.Werkstoff]: WerkstoffFromJSON,
  [EmitableEventNames.WerkstoffTyp]: WerkstoffTypFromJSON,
};
