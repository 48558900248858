import { Identity } from '@atlas-engine/atlas_engine_client';
import { Button, Dialog, Spinner } from '@blueprintjs/core';

import {
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  RenderSequentialUserTasks,
  withBoundProps,
  withServices,
} from '../../../infrastructure';
import {
  FehlendePapiereDruckenDatenHinweis,
  MLPDatenErfassenDialog,
  PapiereBestaetigenDialog,
  PapiereErfolgreichGedrucktHinweis,
} from '../../generate_pdfs';
import { ProduktionsauftragBlasenVerpackungsVorschriftWaehlen } from '../../produktionsauftrag';

export type MaschinenlaufplanModalProps = PropsWithServices<ModalProps<void>> & { correlation_id: string, getIdentity: () => Identity };

export function MaschinenlaufplanModal(props: MaschinenlaufplanModalProps): JSX.Element {
  return (
    <RenderSequentialUserTasks
      {...props}
      correlationId={props.correlation_id}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.PapiereDruckenDatenErfassen') {
          return withBoundProps(MLPDatenErfassenDialog, { onModalFinished: props.onModalFinished });
        } else if (userTask.userTaskConfig.customForm === 'wacoplast-wws.AufFehlendePapiereDruckenDatenHinweisen') {
          return withBoundProps(FehlendePapiereDruckenDatenHinweis, { onModalFinished: props.onModalFinished });
        } else if (userTask.userTaskConfig.customForm === 'wacoplast-wws.PapiereErfolgreichGedruckt') {
          return withServices(withBoundProps(PapiereErfolgreichGedrucktHinweis, { onModalFinished: props.onModalFinished }));
        } else if (userTask.userTaskConfig.customForm === 'wacoplast-wws.PapiereBestaetigen') {
          return withServices(withBoundProps(PapiereBestaetigenDialog, { onModalFinished: props.onModalFinished, tokenPayload: userTask.tokens[0].payload })) as any;
        } else if (userTask.userTaskConfig.customForm === 'wacoplast-wws.RückmeldungVerpackungsVorschriftWählen') {
          return withServices(withBoundProps(ProduktionsauftragBlasenVerpackungsVorschriftWaehlen as any, { onFinish: () => {}, onAbort: props.onModalFinished }));
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          {<Button onClick={() => props.onModalFinished()} intent='warning'>Ausblenden</Button>}
        </DialogFooter>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}
