/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/display-name */
import { AtlasEngineClient } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  InputGroup,
  Spinner,
} from '@blueprintjs/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  CreatePackordnung,
  FileStorageApi,
  Packordnung,
  PackordnungDateiGenerierenModalResultToJSON,
} from 'wacoplast_wws__api';
import { DialogBody, FileUploadDialog, StammdatenFieldEditorSimpleRendererProps } from '../../../infrastructure';
import { ProcessConstants } from '../../../processes';
import styles from './PackordnungFilePicker.module.scss';

function PackordnungFileDownloadDialog(): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      canOutsideClickClose={false}
      isCloseButtonShown={false}
      canEscapeKeyClose={false}
      title='Einen Moment bitte'
    >
      <DialogBody>
        <Spinner />
      </DialogBody>
    </Dialog>
  );
}

type PackordnungFileUploadDialogProps = {
  fileStorageApi: FileStorageApi,
  fileName?: string,
  onClose: (
    hash?: string | undefined,
    fileName?: string | undefined
  ) => void,
};

function PackordnungFileUploadDialog(props: PackordnungFileUploadDialogProps): JSX.Element {
  return (
    <FileUploadDialog
      onClose={props.onClose}
      fileStorageApi={props.fileStorageApi}
      fileName={props.fileName ?? undefined}
    />
  );
}

export function makePackordnungFilePickerRenderer(fileStorageApi: FileStorageApi, atlasEngineClient: AtlasEngineClient) {
  return (props: StammdatenFieldEditorSimpleRendererProps<CreatePackordnung, string | null>) => {

    const [fileName, setFileName] = React.useState<string | null>((props.stammdatenEntity as Packordnung).packvorschrift_file?.file_name ?? null);
    const [showModal, setShowModal] = React.useState<null | 'upload' | 'download'>(null);
    const anchorRef = React.useRef<HTMLAnchorElement>(null);

    const generatePackordnungFile = async (): Promise<void> => {
      setShowModal('download');
      const payload = PackordnungDateiGenerierenModalResultToJSON({
        flaschen_form_database_id: props.stammdatenEntity.flaschen_form_database_id,
        flaschen_form_version_id: props.stammdatenEntity.flaschen_form_version_id,
        verpackungs_einheit_database_id: props.stammdatenEntity.verpackungs_einheit_database_id,
        verpackungs_einheit_version_id: props.stammdatenEntity.verpackungs_einheit_version_id,
        stueckzahl_pro_vpe: props.stammdatenEntity.anzahl_flaschen,
      });
      const result = await atlasEngineClient.processModels.startProcessInstanceAndAwaitEndEvent({ processModelId: ProcessConstants['Packvorschrift Datei generieren'].processId, initialToken: payload });
      if (anchorRef.current) {
        anchorRef.current.href = `data:application/vnd.ms-excel.sheet.macroenabled.12;base64,${result.tokenPayload.file_base64_encoded}`;
        anchorRef.current.download = result.tokenPayload.filename;
        anchorRef.current?.click();
      }
      setShowModal(null);
    };

    const isPackordnungFileGenerateable = (props.stammdatenEntity.flaschen_form_database_id ?? null) === null || (props.stammdatenEntity.verpackungs_einheit_database_id ?? null) === null || (props.stammdatenEntity.anzahl_flaschen ?? null) === null;

    return (
      <>
        <a ref={anchorRef} style={{ display: 'none' }} />
        <ButtonGroup fill>
          <InputGroup disabled value={fileName ?? 'Keine Datei ausgewählt'} fill />
          <Button
            disabled={props.disabled || isPackordnungFileGenerateable}
            onClick={generatePackordnungFile}
          >
            <span className={styles.DropDownPicker_Button_Text}>
              Erstellen
            </span>
          </Button>
          <Button
            disabled={props.disabled}
            onClick={() => setShowModal('upload')}
          >
            <span className={styles.DropDownPicker_Button_Text}>
              Hochladen
            </span>
          </Button>
          <Button
            icon={<FontAwesomeIcon size='sm' icon={faTrash} />}
            className={Classes.FIXED}
            disabled={props.disabled}
            onClick={() => {
              props.onChange(null);
              setFileName(null);
            }}
          />
        </ButtonGroup>
        {
          showModal === 'download' && (
            <PackordnungFileDownloadDialog />
          )
        }
        {
          showModal === 'upload' && !props.disabled &&
            <PackordnungFileUploadDialog
              fileStorageApi={fileStorageApi}
              onClose={(hash, fileName) => {
                if (hash && fileName) {
                  props.onChange(hash);
                  setFileName(fileName);
                }
                setShowModal(null);
              }}
              fileName={fileName ?? undefined}
            />
        }
      </>
    );
  };
}
