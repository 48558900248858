import { InputGroup, NumericInput, Tag } from '@blueprintjs/core';
import { DateInput, DateInputProps } from '@blueprintjs/datetime';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addYears,
  format,
  lastDayOfYear,
  parse,
} from 'date-fns';
import { de } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import {
  formatDateString,
  formatDateTimeString,
  parseDateString,
  parseDateTimeString,
} from '..';

import styles from './inputs.module.scss';

export const LOCALE_DE = 'de-DE';

export type CommonEditorProps<TValueType> = {
  placeholder?: string;
  onChange: (data: TValueType) => void;
  value: TValueType;
  disabled?: boolean;
};

export type TextViewerProps = {
  value: string | null,
  placeholder?: string
};

export type TextEditorProps = CommonEditorProps<string | null>;

export type NumberEditorProps = CommonEditorProps<number | null> & {
  min?: number,
  max?: number,
  showButtons?: boolean,
  formatDecimalSeparator?: boolean,
};

export type DateEditorProps = CommonEditorProps<Date | null> & { minDate?: Date, maxDate?: Date };

export function TextViewer(props: TextViewerProps): JSX.Element {
  return (
    <InputGroup
      style={{ backgroundColor: '#e4e4e4' }}
      tabIndex={-1}
      readOnly
      value={props.value ?? undefined}
      placeholder={props.placeholder}
      type='text'
      fill={true}
    />
  );
}

export function TextEditor(props: TextEditorProps): JSX.Element {
  return (
    <InputGroup
      disabled={props.disabled}
      defaultValue={props.value ?? undefined}
      fill={true}
      placeholder={props.placeholder}
      type='text'
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
}

function validateNumberValue(value: number | null, min = 0, max = 999999999999999): number | null {
  if (value && value < min) {
    return min;
  } else if (value && value > max) {
    return max;
  }
  return (value !== null && isNaN(value)) ? (min ?? null) : value;
}

export function NumberEditorOLD(props: NumberEditorProps): JSX.Element {
  const [value, setValue] = useState('');
  const propsValue = props.value?.toString(10) ?? '';
  // Set InitialValue through useEffect, because the NumericInput sets the
  // InitialValue not formatted correctly. This avoids the bug in the
  // NumericInput, and is only executed on ComponentDidMount.
  useEffect(() => setValue(initialValue), []);
  const initialValue = decimalPointFormatter(propsValue, 0);

  function onFocusActive(event: React.FocusEvent<HTMLElement>): void {
    const eventValue: string = (event.target as HTMLInputElement).value;
    setValue(eventValue.replaceAll('.', ''));
  }

  function onFocusLost(event: React.FocusEvent<HTMLElement>): void {
    const eventValue: string = (event.target as HTMLInputElement).value;

    if (props.formatDecimalSeparator) {
      setValue(decimalPointFormatter(eventValue, 0));
    } else {
      setValue(eventValue);
    }
  }

  function onChange(valueAsNumber: number, valueAsString: string): void {
    // Only set null, if the string representation is empty and the
    // blueprintjs int value is 0.
    if (valueAsNumber === 0 && valueAsString === '') {
      props.onChange(null);
    } else {
      props.onChange(Math.trunc(valueAsNumber));
    }
    setValue(valueAsString);
  }

  return (
    <NumericInput
      minorStepSize={1}
      min={props.min ?? 0}
      max={props.max}
      placeholder={props.placeholder}
      fill={true}
      disabled={props.disabled}
      buttonPosition={props.showButtons === true ? 'right' : 'none'}
      locale={LOCALE_DE}
      allowNumericCharactersOnly={true}
      onValueChange={onChange}
      onFocus={onFocusActive}
      onBlur={onFocusLost}
      value={value}
    />
  );
}

export const decimalPointFormatter = (value: string, minimumFractionDigits: number): string => {

  if (value === '') {
    return '';
  }

  const intValue: number | undefined = parseInt(value.replaceAll('.', ''));

  if (intValue === undefined) {
    return '';
  }
  const format = new Intl.NumberFormat(LOCALE_DE, {
    minimumFractionDigits: minimumFractionDigits,
  });

  return format.format(intValue);
};


export function NumberWithDecimalsEditor(props: NumberEditorProps): JSX.Element {
  const [displayedValue, setDisplayedValue] = React.useState(String(props.value ?? ''));
  return (
    <NumericInput
      minorStepSize={0.01}
      min={props.min ?? 0}
      max={props.max}
      placeholder={props.placeholder}
      onValueChange={(value, textValue) => {
        if (isNaN(value)) {
          setDisplayedValue(String(props.value ?? ''));
        } else {
          const validatedNumber = validateNumberValue(value, props.min, props.max);
          setDisplayedValue(validatedNumber === value ? textValue : String(validatedNumber));
          props.onChange(validatedNumber);
        }
      }
      }
      fill={true}
      value={displayedValue}
      disabled={props.disabled}
      buttonPosition={props.showButtons === true ? 'right' : 'none'}
    />
  );
}

// eslint-disable-next-line react/display-name
export const DateTimeEditor = React.forwardRef((props: DateEditorProps, ref?: React.ForwardedRef<DateInput>): JSX.Element => {

  const [value, setValue] = useState<Date | null>(props.value ?? null);

  return (
    // eslint-disable-next-line @blueprintjs/no-deprecated-components
    <DateInput
      {...commonDateInputProps}
      defaultValue={value ?? undefined}
      disabled={props.disabled}
      formatDate={date => formatDateTimeString(date)}
      maxDate={props.maxDate}
      minDate={props.minDate}
      onChange={setValue}
      parseDate={(str) => parseDateTimeString(str)}
      placeholder={props.placeholder}
      popoverProps={{ onClose: () => props.onChange(value) }}
      ref={ref}
      timePickerProps={{ showArrowButtons: true, useAmPm: false }}
      timePrecision='minute'
    />
  );
});

// eslint-disable-next-line react/display-name
export const DateEditor = React.forwardRef((props: DateEditorProps, ref?: React.ForwardedRef<DateInput>): JSX.Element => {

  const [value, setValue] = useState<Date | null>(props.value ?? null);

  return (
    // eslint-disable-next-line @blueprintjs/no-deprecated-components
    <DateInput
      {...commonDateInputProps}
      dayPickerProps={{
        onDayClick: props.onChange,
        onTodayButtonClick: props.onChange,
      }}
      defaultValue={value ?? undefined}
      disabled={props.disabled}
      formatDate={date => formatDateString(date)}
      maxDate={props.maxDate ?? commonDateInputProps.maxDate}
      minDate={props.minDate ?? commonDateInputProps.minDate}
      onChange={setValue}
      parseDate={(str) => parseDateString(str)}
      placeholder={props.placeholder}
      popoverProps={{ onClose: () => props.onChange(value) }}
      ref={ref}
    />
  );
});

export const commonDateInputProps: Partial<DateInputProps> = {
  dayPickerProps: { firstDayOfWeek: 1 },
  showActionsBar: true,
  todayButtonText: 'Heute',
  clearButtonText: 'Leeren',
  invalidDateMessage: 'Ungültiges Datum',
  outOfRangeMessage: 'Außerhalb der validen Reichweite',
  minDate: parse('01.01.1900', 'dd.MM.yyyy', new Date(0)),
  maxDate: lastDayOfYear(addYears(new Date(), 10)),
  fill: true,
  rightElement: <Tag minimal={true}><FontAwesomeIcon icon={faCalendar} /></Tag>,
  className: styles.date_input_editor,
  localeUtils: {
    getFirstDayOfWeek: () => 1,
    formatMonthTitle: (month: Date) => format(month, 'LLLL', { locale: de }),
    formatDay: (date: Date) => format(date, 'd', { locale: de }),
    formatWeekdayShort: (index: number) => format(new Date(2021, 0, index + 3), 'EEEEEE', { locale: de }),
    formatWeekdayLong: (index: number) => format(new Date(2021, 0, index + 3), 'EEEE', { locale: de }),
    getMonths: () => ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    formatDate: (date: Date) => format(date, 'dd.MM.yyyy', { locale: de }),
    parseDate: (str: string) => parse(str, 'dd.MM.yyyy', new Date(0)),
  },
};
