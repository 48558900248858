import { Button, Dialog, H2 } from '@blueprintjs/core';
import { CustomFormProps, DialogBody, DialogFooter } from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungIstNichtLetzteTeilrueckmeldungHinweis(props: CustomFormProps & {
  finish: () => void,
  anzahl_teilrueckmeldungen?: number;
}): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen - 1)}
    >
      <DialogBody>
        <H2>{`Teilrückmeldung Nr. ${props.tokenPayload.anzahl_teilrueckmeldungen - 1} ist abgeschlossen`}</H2>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => {
          props.finish();
          return props.finishUserTask({});
        }}
        >
          Okay
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
