"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFlaschenFormToJSON = exports.CreateFlaschenFormFromJSONTyped = exports.CreateFlaschenFormFromJSON = void 0;
function CreateFlaschenFormFromJSON(json) {
    return CreateFlaschenFormFromJSONTyped(json, false);
}
exports.CreateFlaschenFormFromJSON = CreateFlaschenFormFromJSON;
function CreateFlaschenFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hoehe_inkl_hals_teil_mm': json['hoehe_inkl_hals_teil_mm'],
        'flaschentyp_database_id': json['flaschentyp_database_id'],
        'flaschentyp_version_id': json['flaschentyp_version_id'],
        'hals_teil_database_id': json['hals_teil_database_id'],
        'hals_teil_version_id': json['hals_teil_version_id'],
    };
}
exports.CreateFlaschenFormFromJSONTyped = CreateFlaschenFormFromJSONTyped;
function CreateFlaschenFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hoehe_inkl_hals_teil_mm': value.hoehe_inkl_hals_teil_mm,
        'flaschentyp_database_id': value.flaschentyp_database_id,
        'flaschentyp_version_id': value.flaschentyp_version_id,
        'hals_teil_database_id': value.hals_teil_database_id,
        'hals_teil_version_id': value.hals_teil_version_id,
    };
}
exports.CreateFlaschenFormToJSON = CreateFlaschenFormToJSON;
