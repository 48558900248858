/**
 *  Validating each Date from a DatePickerComponent is necessary,
 *  because our automatically generated client will call the toISOString method on
 *  each value of the Date type, before adding it to a request body. The DatePickerComponent has
 *  a null or undefined value in case that no date was picked.
 *  Then a call to toISOString would, without using this function, result in an error.
 */
export function getValidatedDateTimePickerComponentValue(date: Date | undefined): Date {
  return date ?? { toISOString: () => undefined } as unknown as Date;
}

/**
 *  Validating each Date from a DatePickerComponent is necessary,
 *  because our automatically generated client will call the toISOString method on
 *  each value of the Date type, before adding it to a request body. The DatePickerComponent has
 *  a null or undefined value in case that no date was picked.
 *  Then a call to toISOString would, without using this function, result in an error.
 */
export function getValidatedDatePickerComponentValue(date: Date | undefined): Date {
  return date ?? { toISOString: () => '' } as unknown as Date;
}
