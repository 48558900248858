"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFahrzeugZuKundeToJSON = exports.CreateFahrzeugZuKundeFromJSONTyped = exports.CreateFahrzeugZuKundeFromJSON = void 0;
function CreateFahrzeugZuKundeFromJSON(json) {
    return CreateFahrzeugZuKundeFromJSONTyped(json, false);
}
exports.CreateFahrzeugZuKundeFromJSON = CreateFahrzeugZuKundeFromJSON;
function CreateFahrzeugZuKundeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'kunde_database_id': json['kunde_database_id'],
        'kunde_version_id': json['kunde_version_id'],
        'empfaenger_database_id': json['empfaenger_database_id'],
        'empfaenger_version_id': json['empfaenger_version_id'],
        'fahrzeug_database_id': json['fahrzeug_database_id'],
        'fahrzeug_version_id': json['fahrzeug_version_id'],
        'ladehoehe': json['ladehoehe'],
        'prioritaet': json['prioritaet'],
    };
}
exports.CreateFahrzeugZuKundeFromJSONTyped = CreateFahrzeugZuKundeFromJSONTyped;
function CreateFahrzeugZuKundeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'empfaenger_database_id': value.empfaenger_database_id,
        'empfaenger_version_id': value.empfaenger_version_id,
        'fahrzeug_database_id': value.fahrzeug_database_id,
        'fahrzeug_version_id': value.fahrzeug_version_id,
        'ladehoehe': value.ladehoehe,
        'prioritaet': value.prioritaet,
    };
}
exports.CreateFahrzeugZuKundeToJSON = CreateFahrzeugZuKundeToJSON;
