import { useCallback, useState } from 'react';
import { CreateGranulatFarbe, GranulatFarbe } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeNumberWithDecimalsEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { GranulatFarbeBestandHistoryModal, makeGranulatFarbeBestandEditor } from '.';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { defaultColumnState } from './defaultColumnConfiguration';

export type GranulatFarbeVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function GranulatFarbeVerwalten(props: GranulatFarbeVerwaltenProps): JSX.Element {
  const lieferantService = props.services.lieferant;
  const serviceFunction = useCallback((params: Record<string, any>, initOverrides?: RequestInit) => lieferantService.getAllLieferantGet({ ...params, artikel_gruppe: 'Granulat-Farben' }, initOverrides), [lieferantService]);
  const lieferantResult = useFetchLikeGetAllServiceFunction(serviceFunction, null);

  const [granulatFarbeForHistoryDialog, setGranulatFarbeForHistoryDialog] = useState<GranulatFarbe | null>(null);

  const editors: StammdatenFieldEditors<CreateGranulatFarbe> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
    {
      type: 'simple',
      field: 'batch_nummer',
      renderer: makeTextEditorRenderer(),
      label: 'Batch-Nummer',
    },
    {
      type: 'simple',
      field: 'bestand',
      renderer: makeGranulatFarbeBestandEditor(),
      label: 'Bestand',
    },
    {
      type: 'simple',
      field: 'preis_pro_kg',
      renderer: makeNumberWithDecimalsEditorRenderer(),
      label: 'Preis pro kg',
    },
    {
      type: 'complex',
      fields: ['lieferant_database_id', 'lieferant_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(lieferantResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_lang ?? '', secondaryTitle: data.nummer };
      }, 'lieferant'),
      label: 'Lieferant',
    },
  ];

  return (
    <>
      {granulatFarbeForHistoryDialog &&
        <GranulatFarbeBestandHistoryModal {...props} entity={granulatFarbeForHistoryDialog} onModalFinished={() => setGranulatFarbeForHistoryDialog(null)} />}
      <StammdatenAgGrid<GranulatFarbe, any>
        editEditors={editors}
        createEditors={editors}
        defaultColumnState={defaultColumnState}
        columnDefs={[
          {
            headerName: 'Nummer',
            field: 'nummer',
            colId: 'nummer',
            filter: 'agTextColumnFilter',
            ...NUMMER_SORT_OPTS,
          },
          {
            headerName: 'Bezeichnung',
            field: 'bezeichnung',
            colId: 'bezeichnung',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Batch-Nummer',
            field: 'batch_nummer',
            colId: 'batch_nummer',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Prei pro kg [€]',
            field: 'preis_pro_kg',
            colId: 'preis_pro_kg',
            valueFormatter: numberValueFormatterGenAGGrid(2),
            filter: 'agNumberColumnFilter',
          },
          {
            headerName: 'Bestand [kg]',
            field: 'bestand',
            colId: 'bestand',
            filter: 'agNumberColumnFilter',
            valueFormatter: numberValueFormatterGenAGGrid(0),
          },
          {
            headerName: 'Lieferant',
            colId: 'lieferant',
            ...makeForeignKeyColumn('lieferant', (data: GranulatFarbe) => {
              return {
                description: data.lieferant.name_lang as any,
                number: data.lieferant.nummer,
              };
            }),
          },
        ]}
        stammdatenService={{
          create: (data: CreateGranulatFarbe) => props.services.granulatFarben.postGranulatFarbePost({ CreateGranulatFarbe: data }),
          update: (data) => props.services.granulatFarben.putByDatabaseIdGranulatFarbeDatabaseIdPut({ database_id: data.database_id, UpdateGranulatFarbe: { ...data, bestand: data.bestand ?? -1 } }),
          delete: (data, ignoreIntegrity) => props.services.granulatFarben.deleteByDatabaseIdGranulatFarbeDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
          restore: (data) => props.services.granulatFarben.restoreByDatabaseIdGranulatFarbeDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
          getAll: (include_deleted) => props.services.granulatFarben.getAllGranulatFarbeGet({ include_deleted }),
          getHistory: (data) => props.services.granulatFarben.getHistoryGranulatFarbeDatabaseIdHistoryGet({ database_id: data.database_id }),
        }}
        transactionService={props.transactionService}
        onCloseStartDialogClicked={() => props.closeStartDialog()}
        subscriptionEventService={props.subscriptionEventService}
        getCustomPerformableRowActions={() => {
          return [{
            label: 'Bestandshistorie ansehen',
            icon: faWarehouse,
            intent: 'none',
            onPerform: (entity) => setGranulatFarbeForHistoryDialog(entity),
          }];
        }}
        filterHistoryColDefs={(colDefs) => {
          return colDefs.filter((colDef: any) => colDef.colId !== 'bestand');
        }}
        dialogStrings={DialogStrings.Granulatfarbe}
      />
    </>
  );
}
