import { Button, Callout, Dialog } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { getErrorDescription } from '../../infrastructure';
import { DialogBody, DialogFooter } from '../components/Dialog';


export type TransactionErrorModalProps = {
  error: any;
  retry: null | (() => void | Promise<void>);
  abort: null | (() => void | Promise<void>);
};

export function TransactionErrorModal(props: TransactionErrorModalProps): JSX.Element {

  const {
    summary,
    details,
  } = getErrorDescription(props.error);

  const navigate = useNavigate();

  return (
    <Dialog title={summary} isOpen={true} onClose={props.abort ?? undefined} isCloseButtonShown={props.abort !== null}>
      <DialogBody>
        <Callout intent='danger'>
          {details}
        </Callout>
      </DialogBody>
      <DialogFooter>
        {
          <Button
            onClick={(() => {
              navigate('/'); props.abort?.();
            })}
          >Zurück zur Startseite
          </Button>
        }
        {props.abort &&
          <Button onClick={props.abort}>Abbrechen</Button>
        }
        {props.retry &&
          <Button intent='primary' onClick={props.retry ?? undefined}>Erneut versuchen</Button>
        }
      </DialogFooter>
    </Dialog>
  );
}
