import {
  AufdruckArt,
  CreateVerpackungsVorschrift,
  Firma,
  FlaschenForm,
  MaterialGruppe,
  Transportweg,
  VerpackungZustand,
  VerpackungsEinheit,
  VerpackungsMaterial,
  VerpackungsVorschrift,
} from 'wacoplast_wws__api';
import { AufdruckArtKeyMapping, TransportwegKeyMapping, VerpackungZustandKeyMapping } from '..';
import {
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  UseFetchLikeServiceFunctionResult,
  filterFetchLikeServiceFunctionResult,
  formatFlaschenFormNummer,
  makeEnumDropDownPickerRenderer,
  makeEnumRadioButtons,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeStammdatenEntityRelatedDisabledEditor,
  makeSwitchEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';
import { makeKartonTraegerOnlyEditorRenderer } from '../KartonTraegerOnlyEditor';
import { defaultColumnState } from './defaultColumnConfiguration';
import { makeSpezialfallKartonReduktionEditor } from './SpezialfallKartonReduktionEditor';
import { formatBooleanStringToJaNein, formatBooleanToJaNein, formatEnumValueToDescription } from '../../../infrastructure/filterFormatters';


export type VerpackungsVorschriftVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function createVerpackungsvorschriftFieldEditors(
  verpackungsEinheitResult: UseFetchLikeServiceFunctionResult<Array<VerpackungsEinheit>>,
  lagenResult: UseFetchLikeServiceFunctionResult<Array<VerpackungsEinheit>>,
  transportEinheitTraegerResult: UseFetchLikeServiceFunctionResult<Array<VerpackungsMaterial>>,
  kantenschutzResult: UseFetchLikeServiceFunctionResult<Array<VerpackungsMaterial>>,
  folieResult: UseFetchLikeServiceFunctionResult<Array<VerpackungsMaterial>>,
  kundenResult: UseFetchLikeServiceFunctionResult<Array<Firma>>,
  empfaengerResult: UseFetchLikeServiceFunctionResult<Array<Firma>>,
  flaschenFormResult: UseFetchLikeServiceFunctionResult<Array<FlaschenForm>>,
): StammdatenFieldEditors<CreateVerpackungsVorschrift> {
  return [
    {
      type: 'complex',
      fields: ['flaschen_form_database_id', 'flaschen_form_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschenFormResult, (data) => {
        return { primaryTitle: formatFlaschenFormNummer(data) };
      }, 'flaschen_form'),
      label: 'Flaschenform',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['kunde_database_id', 'kunde_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(kundenResult, (data) => {
        return { primaryTitle: data.name_kurz, secondaryTitle: String(data.nummer) };
      }, 'kunde', false, (data) => {
        return { kunde_database_id: data?.database_id, kunde_version_id: data?.version_id, empfaenger_database_id: data?.database_id, empfaenger_version_id: data?.version_id };
      }),
      label: 'Kunde',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['empfaenger_database_id', 'empfaenger_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(empfaengerResult, (data) => {
        return { primaryTitle: data.name_kurz, secondaryTitle: String(data.nummer) };
      }, 'empfaenger'),
      label: 'Empfänger',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['verpackungs_einheit_database_id', 'verpackungs_einheit_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(verpackungsEinheitResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'verpackungs_einheit', false, (data) => {
        const isKartonEinheit = data?.material_gruppe !== undefined && [MaterialGruppe.NUMBER_10, MaterialGruppe.NUMBER_30].includes(data?.material_gruppe);
        return { verpackungs_einheit_database_id: data?.database_id, verpackungs_einheit_version_id: data?.version_id, geschlossen: isKartonEinheit ? false : null, polybeutel: isKartonEinheit ? false : null, aufdruck: null };
      }),
      label: 'Verpackungseinheit',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['transport_einheit_traeger_database_id', 'transport_einheit_traeger_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(transportEinheitTraegerResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'transport_einheit_traeger'),
      label: 'Transport-Einheit-Träger',
    },
    {
      type: 'simple',
      field: 'zustand',
      renderer: makeEnumRadioButtons(Object.values(VerpackungZustand), (value) => (VerpackungZustandKeyMapping as any)[value]),
      label: 'Zustand',
    },
    {
      type: 'complex',
      fields: ['unterlage_database_id', 'unterlage_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(lagenResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'unterlage', true, (data) => {
        return { unterlage_database_id: data?.database_id, unterlage_version_id: data?.version_id, unterlagen_anzahl: data?.database_id ? 1 : null };
      }),
      label: 'Unterlage',
    },
    {
      type: 'simple',
      field: 'unterlagen_anzahl',
      renderer: makeStammdatenEntityRelatedDisabledEditor<CreateVerpackungsVorschrift, unknown>(makeNumberEditorRenderer({ min: 1 }), (data) => data.unterlage_database_id ? false : true),
      label: 'Unterlagen-Anzahl',
    },
    {
      type: 'complex',
      fields: ['zwischenlage_database_id', 'zwischenlage_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(lagenResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'zwischenlage', true, (data) => {
        return { zwischenlage_database_id: data?.database_id, zwischenlage_version_id: data?.version_id, zwischenlagen_anzahl: data?.database_id ? 1 : null };
      }),
      label: 'Zwischenlage',
    },
    {
      type: 'simple',
      field: 'zwischenlagen_anzahl',
      renderer: makeStammdatenEntityRelatedDisabledEditor<CreateVerpackungsVorschrift, unknown>(makeNumberEditorRenderer({ min: 1 }), (data) => data.zwischenlage_database_id ? false : true),
      label: 'Zwischenlage-Anzahl',
    },
    {
      type: 'complex',
      fields: ['abdeckung_database_id', 'abdeckung_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(lagenResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'abdeckung', true, (data) => {
        return { abdeckung_database_id: data?.database_id, abdeckung_version_id: data?.version_id, abdeckung_anzahl: data?.database_id ? 1 : null };
      }),
      label: 'Abdeckung',
    },
    {
      type: 'simple',
      field: 'abdeckung_anzahl',
      renderer: makeStammdatenEntityRelatedDisabledEditor<CreateVerpackungsVorschrift, unknown>(makeNumberEditorRenderer({ min: 1 }), (data) => data.abdeckung_database_id ? false : true),
      label: 'Abdeckung-Anzahl',
    },
    {
      type: 'complex',
      fields: ['kantenschutz_database_id', 'kantenschutz_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(kantenschutzResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'kantenschutz'),
      label: 'Kantenschutz',
    },
    {
      type: 'complex',
      fields: ['folie_database_id', 'folie_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(folieResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'folie'),
      label: 'Folie',
    },
    {
      type: 'simple',
      field: 'aufdruck',
      renderer: makeKartonTraegerOnlyEditorRenderer(verpackungsEinheitResult, makeEnumRadioButtons(Object.values(AufdruckArt), (value) => (AufdruckArtKeyMapping as any)[value])),
      label: 'Aufdruck',
    },
    {
      type: 'simple',
      field: 'geschlossen',
      renderer: makeKartonTraegerOnlyEditorRenderer(verpackungsEinheitResult, makeSwitchEditorRenderer()),
      label: 'Geschlossen',
    },
    {
      type: 'simple',
      field: 'polybeutel',
      renderer: makeKartonTraegerOnlyEditorRenderer(verpackungsEinheitResult, makeSwitchEditorRenderer()),
      label: 'Polybeutel',
    },
    {
      type: 'simple',
      field: 'stapel_hoehe_1',
      renderer: makeNumberEditorRenderer(),
      label: 'Stapelhöhe 1',
    },
    {
      type: 'simple',
      field: 'stapel_hoehe_2',
      renderer: makeStammdatenEntityRelatedDisabledEditor<CreateVerpackungsVorschrift, unknown>(makeNumberEditorRenderer(), (data) => data.stapel_hoehe_1 ? false : true),
      label: 'Stapelhöhe 2',
    },
    {
      type: 'simple',
      field: 'spezialfall_karton_reduktion',
      renderer: makeSpezialfallKartonReduktionEditor(verpackungsEinheitResult),
      label: 'Spezialfall (Karton-Reduktion um 1 in oberster Lage)',
    },
    {
      type: 'simple',
      field: 'transportweg',
      renderer: makeEnumDropDownPickerRenderer(Object.keys(Transportweg), (data) => (TransportwegKeyMapping as any)[data]),
      label: 'Transportweg-Variante',
    },
    {
      type: 'simple',
      field: 'prioritaet',
      renderer: makeNumberEditorRenderer(),
      label: 'Priorität',
    },
    {
      type: 'simple',
      field: 'bemerkung',
      renderer: makeTextEditorRenderer(),
      label: 'Bemerkung',
    },
  ];
}

export function getVerpackungsVorschriftNummer(verpackungsVorschrift: VerpackungsVorschrift): string {
  return `${verpackungsVorschrift.kunde.nummer}.${verpackungsVorschrift.empfaenger.nummer}.${formatFlaschenFormNummer(verpackungsVorschrift.flaschen_form)}.${verpackungsVorschrift.verpackungs_einheit.nummer}_${Transportweg[verpackungsVorschrift.transportweg]}-${verpackungsVorschrift.variante}`;
}

export function VerpackungsVorschriftVerwalten(props: VerpackungsVorschriftVerwaltenProps): JSX.Element {
  const verpackungsMaterialResult = useFetchLikeGetAllServiceFunction(props.services.verpackungs_material.getAllVerpackungsMaterialGet, props.services.verpackungs_material);
  const verpackungsEinheitResult = useFetchLikeGetAllServiceFunction(props.services.verpackungs_einheit.getAllVerpackungsEinheitGet, props.services.verpackungs_einheit);

  const lagenResult = filterFetchLikeServiceFunctionResult(verpackungsEinheitResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_50);
  const transportEinheitTraegerResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_80);
  const kantenschutzResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_70);
  const folieResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_90);

  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const empfaengerResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllFirmaGet, props.services.firma);
  const flaschenFormResult = useFetchLikeGetAllServiceFunction(props.services.flaschenForm.getAllFlaschenFormGet, props.services.flaschenForm);

  const editors = createVerpackungsvorschriftFieldEditors(verpackungsEinheitResult, lagenResult, transportEinheitTraegerResult, kantenschutzResult, folieResult, kundenResult, empfaengerResult, flaschenFormResult);

  return (
    <StammdatenAgGrid
      defaultEntityForCreation={{
        transport_einheit_traeger_database_id: verpackungsMaterialResult.data?.find((data) => data.nummer === 81)?.database_id,
        transport_einheit_traeger_version_id: verpackungsMaterialResult.data?.find((data) => data.nummer === 81)?.version_id,
      }}
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          valueGetter: (params) => getVerpackungsVorschriftNummer(params.data),
        },
        {
          headerName: 'Flaschenform',
          colId: 'flaschen_form',
          valueGetter: (params) => formatFlaschenFormNummer(params.data.flaschen_form),
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Flaschentyp-Bezeichnung',
          colId: 'flaschen_typ_bezeichnung',
          valueGetter: (params) => params.data.flaschen_form?.flaschentyp.bezeichnung,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Kunde',
          colId: 'kunde',
          ...makeForeignKeyColumn('kunde', (data: VerpackungsVorschrift) => {
            return {
              description: data.kunde.name_kurz,
              number: String(data.kunde.nummer),
            };
          }),
        },
        {
          headerName: 'Empfänger',
          colId: 'empfänger',
          ...makeForeignKeyColumn('empfaenger', (data: VerpackungsVorschrift) => {
            return {
              description: data.empfaenger.name_kurz,
              number: String(data.empfaenger.nummer),
            };
          }),
        },
        {
          headerName: 'Verpackungseinheit',
          colId: 'verpackungs_einheit',
          ...makeForeignKeyColumn('verpackungs_einheit', (data: VerpackungsVorschrift) => {
            return {
              description: data.verpackungs_einheit.bezeichnung_kurz,
              number: String(data.verpackungs_einheit.nummer),
            };
          }),
        },
        {
          headerName: 'Variante',
          field: 'variante',
          colId: 'variante',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Transport-Einheit-Träger',
          colId: 'transport_einheit_traeger',
          ...makeForeignKeyColumn('transport_einheit_traeger', (data: VerpackungsVorschrift) => {
            return {
              description: data.transport_einheit_traeger?.bezeichnung_kurz ?? '',
              number: String(data.transport_einheit_traeger?.nummer ?? ''),
            };
          }),
        },
        {
          headerName: 'Zustand',
          field: 'zustand',
          colId: 'zustand',
          filter: 'agSetColumnFilter',
          valueFormatter: (value) => (VerpackungZustandKeyMapping as any)[value.value],
        },
        {
          headerName: 'Unterlage',
          colId: 'unterlage',
          ...makeForeignKeyColumn('unterlage', (data: VerpackungsVorschrift) => {
            return {
              description: data.unterlage?.bezeichnung_kurz ?? '',
              number: String(data.unterlage?.nummer ?? ''),
            };
          }),
        },
        {
          headerName: 'Unterlagen-Anzahl',
          field: 'unterlagen_anzahl',
          colId: 'unterlagen_anzahl',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Zwischenlage',
          colId: 'zwischenlage',
          ...makeForeignKeyColumn('zwischenlage', (data: VerpackungsVorschrift) => {
            return {
              description: data.zwischenlage?.bezeichnung_kurz ?? '',
              number: String(data.zwischenlage?.nummer ?? ''),
            };
          }),
        },
        {
          headerName: 'Zwischenlage-Anzahl',
          field: 'zwischenlagen_anzahl',
          colId: 'zwischenlagen_anzahl',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Abdeckung',
          colId: 'abdeckung',
          ...makeForeignKeyColumn('abdeckung', (data: VerpackungsVorschrift) => {
            return {
              description: data.abdeckung?.bezeichnung_kurz ?? '',
              number: String(data.abdeckung?.nummer ?? ''),
            };
          }),
        },
        {
          headerName: 'Abdeckung-Anzahl',
          field: 'abdeckung_anzahl',
          colId: 'abdeckung_anzahl',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Kantenschutz',
          colId: 'kantenschutz',
          ...makeForeignKeyColumn('kantenschutz', (data: VerpackungsVorschrift) => {
            return {
              description: data.kantenschutz?.bezeichnung_kurz ?? '',
              number: String(data.kantenschutz?.nummer ?? ''),
            };
          }),
        },
        {
          headerName: 'Folie',
          colId: 'folie',
          ...makeForeignKeyColumn('folie', (data: VerpackungsVorschrift) => {
            return {
              description: data.folie?.bezeichnung_kurz ?? '',
              number: String(data.folie?.nummer ?? ''),
            };
          }),
        },
        {
          headerName: 'Aufdruck',
          field: 'aufdruck',
          colId: 'aufdruck',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: any) => formatEnumValueToDescription(value, AufdruckArtKeyMapping),
          },
          valueFormatter: (value) => (AufdruckArtKeyMapping as any)[value.value],
        },
        {
          headerName: 'Geschlossen',
          field: 'geschlossen',
          colId: 'geschlossen',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: { value: string | null }) => formatBooleanStringToJaNein(value),
          },
          valueGetter: ({ data }) => data.geschlossen ?? false,
          valueFormatter: ({ value }) => formatBooleanToJaNein(value, false),
        },
        {
          headerName: 'Polybeutel',
          field: 'polybeutel',
          colId: 'polybeutel',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: { value: string | null }) => formatBooleanStringToJaNein(value),
          },
          valueGetter: ({ data }) => data.polybeutel ?? false,
          valueFormatter: ({ value }) => formatBooleanToJaNein(value, false),
        },
        {
          headerName: 'Transportweg-Variante',
          field: 'transportweg',
          colId: 'transportweg',
          filter: 'agSetColumnFilter',
          valueFormatter: (value) => (TransportwegKeyMapping as any)[value.value],
        },
        {
          headerName: 'Stapelhöhe 1',
          field: 'stapel_hoehe_1',
          colId: 'stapel_hoehe_1',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Stapelhöhe 2',
          field: 'stapel_hoehe_2',
          colId: 'stapel_hoehe_2',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Spezialfall',
          field: 'spezialfall_karton_reduktion',
          colId: 'spezialfall_karton_reduktion',
          filter: 'agTextColumnFilter',
          valueFormatter: value => value.value ? `oberste Lage: Seitenlage; ${value.value} Karton weniger` : '',
        },
        {
          headerName: 'Priorität',
          field: 'prioritaet',
          colId: 'prioritaet',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Bemerkung',
          field: 'bemerkung',
          colId: 'bemerkung',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateVerpackungsVorschrift) => props.services.verpackungs_vorschrift.postVerpackungsVorschriftPost({ CreateVerpackungsVorschrift: data }),
        update: (data) => props.services.verpackungs_vorschrift.putByDatabaseIdVerpackungsVorschriftDatabaseIdPut({ database_id: data.database_id, UpdateVerpackungsVorschrift: data }),
        delete: (data, ignoreIntegrity) => props.services.verpackungs_vorschrift.deleteByDatabaseIdVerpackungsVorschriftDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.verpackungs_vorschrift.restoreByDatabaseIdVerpackungsVorschriftDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.verpackungs_vorschrift.getAllVerpackungsVorschriftGet({ include_deleted }),
        getHistory: (data: VerpackungsVorschrift) => props.services.verpackungs_vorschrift.getHistoryVerpackungsVorschriftDatabaseIdHistoryGet({ database_id: data.database_id }),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Verpackungsvorschrift}
    />
  );
}
