"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragExternErfassenResultToJSON = exports.ProduktionsauftragExternErfassenResultFromJSONTyped = exports.ProduktionsauftragExternErfassenResultFromJSON = void 0;
const _1 = require("./");
function ProduktionsauftragExternErfassenResultFromJSON(json) {
    return ProduktionsauftragExternErfassenResultFromJSONTyped(json, false);
}
exports.ProduktionsauftragExternErfassenResultFromJSON = ProduktionsauftragExternErfassenResultFromJSON;
function ProduktionsauftragExternErfassenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'menge': json['menge'],
        'werkstoff_typ': _1.WerkstoffTypFromJSON(json['werkstoff_typ']),
    };
}
exports.ProduktionsauftragExternErfassenResultFromJSONTyped = ProduktionsauftragExternErfassenResultFromJSONTyped;
function ProduktionsauftragExternErfassenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'menge': value.menge,
        'werkstoff_typ': _1.WerkstoffTypToJSON(value.werkstoff_typ),
    };
}
exports.ProduktionsauftragExternErfassenResultToJSON = ProduktionsauftragExternErfassenResultToJSON;
