import { useCallback } from 'react';
import { Kundenauftrag, WerkstoffTyp } from 'wacoplast_wws__api';
import { FetchLikeFunction, UseFetchLikeServiceFunctionResult } from '../../infrastructure';

export function useWerkstoffTypenServiceFunction(
  kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag>,
  props: any): FetchLikeFunction<Array<WerkstoffTyp>> {
  return useCallback(
    async (initOverrides?: RequestInit) => {
      if (!kundenauftrag.data) {
        return [];
      }

      const allWerkstoffTypes =
        await props.services.werkstoffTyp.getAllWerkstoffTypGet(
          { include_deleted: false },
          initOverrides,
        );
      const werkstoffTypen = allWerkstoffTypes.filter(
        (werkstoffTyp: WerkstoffTyp) =>
          werkstoffTyp.werkstoff_database_id ===
          kundenauftrag.data.artikel.werkstoff_database_id || werkstoffTyp.werkstoff_database_id === undefined,
      );

      return werkstoffTypen;
    },
    [kundenauftrag.data, props.services.werkstoffTyp],
  );
}
