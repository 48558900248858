"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragErfassenResultToJSON = exports.ProduktionsauftragErfassenResultFromJSONTyped = exports.ProduktionsauftragErfassenResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProduktionsauftragErfassenResultFromJSON(json) {
    return ProduktionsauftragErfassenResultFromJSONTyped(json, false);
}
exports.ProduktionsauftragErfassenResultFromJSON = ProduktionsauftragErfassenResultFromJSON;
function ProduktionsauftragErfassenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'werkstoff_typ': _1.WerkstoffTypFromJSON(json['werkstoff_typ']),
        'abwicklungspfad': _1.AbwicklungspfadFromJSON(json['abwicklungspfad']),
        'produktionsauftrag_lager': !runtime_1.exists(json, 'produktionsauftrag_lager') ? undefined : _1.ProduktionsauftragLagerErfassenResultFromJSON(json['produktionsauftrag_lager']),
        'produktionsauftrag_extern': !runtime_1.exists(json, 'produktionsauftrag_extern') ? undefined : _1.ProduktionsauftragExternErfassenResultFromJSON(json['produktionsauftrag_extern']),
        'produktionsauftrag_blasen': !runtime_1.exists(json, 'produktionsauftrag_blasen') ? undefined : _1.ProduktionsauftragBlasenErfassenResultFromJSON(json['produktionsauftrag_blasen']),
    };
}
exports.ProduktionsauftragErfassenResultFromJSONTyped = ProduktionsauftragErfassenResultFromJSONTyped;
function ProduktionsauftragErfassenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'werkstoff_typ': _1.WerkstoffTypToJSON(value.werkstoff_typ),
        'abwicklungspfad': _1.AbwicklungspfadToJSON(value.abwicklungspfad),
        'produktionsauftrag_lager': _1.ProduktionsauftragLagerErfassenResultToJSON(value.produktionsauftrag_lager),
        'produktionsauftrag_extern': _1.ProduktionsauftragExternErfassenResultToJSON(value.produktionsauftrag_extern),
        'produktionsauftrag_blasen': _1.ProduktionsauftragBlasenErfassenResultToJSON(value.produktionsauftrag_blasen),
    };
}
exports.ProduktionsauftragErfassenResultToJSON = ProduktionsauftragErfassenResultToJSON;
