"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HalsteilZuBlasformToJSON = exports.HalsteilZuBlasformFromJSONTyped = exports.HalsteilZuBlasformFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function HalsteilZuBlasformFromJSON(json) {
    return HalsteilZuBlasformFromJSONTyped(json, false);
}
exports.HalsteilZuBlasformFromJSON = HalsteilZuBlasformFromJSON;
function HalsteilZuBlasformFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'blasform_database_id': json['blasform_database_id'],
        'blasform_version_id': json['blasform_version_id'],
        'hals_teil_database_id': json['hals_teil_database_id'],
        'hals_teil_version_id': json['hals_teil_version_id'],
        'flaschentyp_database_id': json['flaschentyp_database_id'],
        'flaschentyp_version_id': json['flaschentyp_version_id'],
        'kunde_database_id': !runtime_1.exists(json, 'kunde_database_id') ? undefined : json['kunde_database_id'],
        'kunde_version_id': !runtime_1.exists(json, 'kunde_version_id') ? undefined : json['kunde_version_id'],
        'reservefeld_1': !runtime_1.exists(json, 'reservefeld_1') ? undefined : json['reservefeld_1'],
        'reservefeld_2': !runtime_1.exists(json, 'reservefeld_2') ? undefined : json['reservefeld_2'],
        'reservefeld_3': !runtime_1.exists(json, 'reservefeld_3') ? undefined : json['reservefeld_3'],
        'reservefeld_4': !runtime_1.exists(json, 'reservefeld_4') ? undefined : json['reservefeld_4'],
        'prioritaet': json['prioritaet'],
        'bemerkung': !runtime_1.exists(json, 'bemerkung') ? undefined : json['bemerkung'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'blasform': _1.BlasformFromJSON(json['blasform']),
        'hals_teil': _1.HalsTeilFromJSON(json['hals_teil']),
        'flaschentyp': _1.FlaschenTypFromJSON(json['flaschentyp']),
        'kunde': !runtime_1.exists(json, 'kunde') ? undefined : _1.FirmaFromJSON(json['kunde']),
    };
}
exports.HalsteilZuBlasformFromJSONTyped = HalsteilZuBlasformFromJSONTyped;
function HalsteilZuBlasformToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'blasform_database_id': value.blasform_database_id,
        'blasform_version_id': value.blasform_version_id,
        'hals_teil_database_id': value.hals_teil_database_id,
        'hals_teil_version_id': value.hals_teil_version_id,
        'flaschentyp_database_id': value.flaschentyp_database_id,
        'flaschentyp_version_id': value.flaschentyp_version_id,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'reservefeld_1': value.reservefeld_1,
        'reservefeld_2': value.reservefeld_2,
        'reservefeld_3': value.reservefeld_3,
        'reservefeld_4': value.reservefeld_4,
        'prioritaet': value.prioritaet,
        'bemerkung': value.bemerkung,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'blasform': _1.BlasformToJSON(value.blasform),
        'hals_teil': _1.HalsTeilToJSON(value.hals_teil),
        'flaschentyp': _1.FlaschenTypToJSON(value.flaschentyp),
        'kunde': _1.FirmaToJSON(value.kunde),
    };
}
exports.HalsteilZuBlasformToJSON = HalsteilZuBlasformToJSON;
