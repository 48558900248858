"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FahrzeugApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FahrzeugApi extends runtime.BaseAPI {
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdFahrzeugDatabaseIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling deleteByDatabaseIdFahrzeugDatabaseIdDelete.');
            }
            if (requestParameters.DeleteRequest === null || requestParameters.DeleteRequest === undefined) {
                throw new runtime.RequiredError('DeleteRequest', 'Required parameter requestParameters.DeleteRequest was null or undefined when calling deleteByDatabaseIdFahrzeugDatabaseIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DeleteRequestToJSON(requestParameters.DeleteRequest),
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdFahrzeugDatabaseIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteByDatabaseIdFahrzeugDatabaseIdDeleteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get All
     */
    getAllFahrzeugGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.include_deleted !== undefined) {
                queryParameters['include_deleted'] = requestParameters.include_deleted;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FahrzeugFromJSON));
        });
    }
    /**
     * Get All
     */
    getAllFahrzeugGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllFahrzeugGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdFahrzeugDatabaseIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getByDatabaseIdFahrzeugDatabaseIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FahrzeugFromJSON(jsonValue));
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdFahrzeugDatabaseIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getByDatabaseIdFahrzeugDatabaseIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get History
     */
    getHistoryFahrzeugDatabaseIdHistoryGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getHistoryFahrzeugDatabaseIdHistoryGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/{database_id}/history`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FahrzeugFromJSON));
        });
    }
    /**
     * Get History
     */
    getHistoryFahrzeugDatabaseIdHistoryGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHistoryFahrzeugDatabaseIdHistoryGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdFahrzeugDatabaseIdLockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling lockByDatabaseIdFahrzeugDatabaseIdLockPatch.');
            }
            if (requestParameters.LockRequest === null || requestParameters.LockRequest === undefined) {
                throw new runtime.RequiredError('LockRequest', 'Required parameter requestParameters.LockRequest was null or undefined when calling lockByDatabaseIdFahrzeugDatabaseIdLockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/{database_id}/lock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.LockRequestToJSON(requestParameters.LockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FahrzeugFromJSON(jsonValue));
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdFahrzeugDatabaseIdLockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.lockByDatabaseIdFahrzeugDatabaseIdLockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Post
     */
    postFahrzeugPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.CreateFahrzeug === null || requestParameters.CreateFahrzeug === undefined) {
                throw new runtime.RequiredError('CreateFahrzeug', 'Required parameter requestParameters.CreateFahrzeug was null or undefined when calling postFahrzeugPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CreateFahrzeugToJSON(requestParameters.CreateFahrzeug),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FahrzeugFromJSON(jsonValue));
        });
    }
    /**
     * Post
     */
    postFahrzeugPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postFahrzeugPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdFahrzeugDatabaseIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling putByDatabaseIdFahrzeugDatabaseIdPut.');
            }
            if (requestParameters.UpdateFahrzeug === null || requestParameters.UpdateFahrzeug === undefined) {
                throw new runtime.RequiredError('UpdateFahrzeug', 'Required parameter requestParameters.UpdateFahrzeug was null or undefined when calling putByDatabaseIdFahrzeugDatabaseIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UpdateFahrzeugToJSON(requestParameters.UpdateFahrzeug),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FahrzeugFromJSON(jsonValue));
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdFahrzeugDatabaseIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.putByDatabaseIdFahrzeugDatabaseIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdFahrzeugDatabaseIdRestorePatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling restoreByDatabaseIdFahrzeugDatabaseIdRestorePatch.');
            }
            if (requestParameters.RestoreRequest === null || requestParameters.RestoreRequest === undefined) {
                throw new runtime.RequiredError('RestoreRequest', 'Required parameter requestParameters.RestoreRequest was null or undefined when calling restoreByDatabaseIdFahrzeugDatabaseIdRestorePatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/{database_id}/restore`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RestoreRequestToJSON(requestParameters.RestoreRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FahrzeugFromJSON(jsonValue));
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdFahrzeugDatabaseIdRestorePatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.restoreByDatabaseIdFahrzeugDatabaseIdRestorePatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdFahrzeugDatabaseIdUnlockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling unlockByDatabaseIdFahrzeugDatabaseIdUnlockPatch.');
            }
            if (requestParameters.UnlockRequest === null || requestParameters.UnlockRequest === undefined) {
                throw new runtime.RequiredError('UnlockRequest', 'Required parameter requestParameters.UnlockRequest was null or undefined when calling unlockByDatabaseIdFahrzeugDatabaseIdUnlockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/fahrzeug/{database_id}/unlock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UnlockRequestToJSON(requestParameters.UnlockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FahrzeugFromJSON(jsonValue));
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdFahrzeugDatabaseIdUnlockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.unlockByDatabaseIdFahrzeugDatabaseIdUnlockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.FahrzeugApi = FahrzeugApi;
