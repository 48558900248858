import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import jwtDecode from 'jwt-decode';

import { AuthorityConfig } from '@atlas-engine-contrib/atlas-ui_contracts';
import { Identity } from '@atlas-engine/atlas_engine_client';
import React, { PropsWithChildren } from 'react';
import {
  Location,
  NavigateFunction,
  Route,
  Routes,
} from 'react-router-dom';
import {
  Button,
  Callout,
  Dialog,
  Spinner,
} from '@blueprintjs/core';
import { DialogBody, DialogFooter } from '../components';

export type IdentityWithEmail = Identity & {
  email?: string;
}

export type ProcessSigninResponseResult = {
  identity: Identity;
  targetRoute: string | Location;
};

export const AuthProviderContext = React.createContext<{authProvider: AuthProvider}>(null as any);

export type AuthProviderState = {
  identity: IdentityWithEmail | null | undefined;
  userManager: UserManager | null;
  error: any;
};

export type AuthProviderProps = PropsWithChildren<{
  authorityConfig: AuthorityConfig;
  navigate: NavigateFunction;
  location: Location;
}>;

export class AuthProvider extends React.Component<AuthProviderProps, AuthProviderState> {
  constructor(props: AuthProviderProps) {
    super(props);
    this.state = {
      identity: undefined,
      userManager: null,
      error: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.initialize();
  }

  private initialize = async (): Promise<void> => {
    try {
      this.setState({
        userManager: null,
        error: null,
        identity: undefined,
      });
      const stateStorage = new WebStorageStateStore({ store: window.sessionStorage });
      const allKeys = await stateStorage.getAllKeys();
      const activeSession = allKeys.find((key) => key.endsWith(`:${this.props.authorityConfig.clientId}`));

      const authorityUrlFromActiveSession = activeSession?.replace('user:', '').replace(`:${this.props.authorityConfig.clientId}`, '');
      const authority = this.props.authorityConfig.authority ?? authorityUrlFromActiveSession;
      /* eslint-disable @typescript-eslint/naming-convention */
      const userManager = new UserManager({
        authority: authority,
        client_id: this.props.authorityConfig.clientId,
        redirect_uri: `${this.props.authorityConfig.redirectBasePath}/signin-oidc`,
        silent_redirect_uri: `${this.props.authorityConfig.redirectBasePath}/signin-oidc`,
        post_logout_redirect_uri: `${this.props.authorityConfig.redirectBasePath}/signout-oidc`,
        loadUserInfo: false,
        response_type: this.props.authorityConfig.responseType,
        scope: this.props.authorityConfig.scopes,
      });
      userManager.events.addUserLoaded(this.handleUserLoadedEvent.bind(this));
      userManager.events.addUserUnloaded(this.handleUserUnloadedEvent.bind(this));
      userManager.startSilentRenew();

      if (this.props.location.pathname === '/signin-oidc') {
        const response = await this.processSigninResponse(userManager);
        this.setState({
          identity: response.identity,
          userManager: userManager,
          error: null,
        });
        this.props.navigate(response.targetRoute);
        return;
      }

      this.setState({
        userManager,
        identity: null,
        error: null,
      });
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  };

  public render(): JSX.Element {
    const identity = this.getIdentity();

    if (this.state.error) {
      return (
        <Dialog title='Fehler während der Anmeldung' isOpen={true} onClose={() => {}} isCloseButtonShown={false}>
          <DialogBody>
            <Callout intent='danger'>
              {this.state.error.message ?? String(this.state.error)}
            </Callout>
          </DialogBody>
          <DialogFooter>
            <Button onClick={() => this.initialize()} intent='primary'>Erneut versuchen</Button>
          </DialogFooter>
        </Dialog>
      );
    }

    const oidc_routes = (
      <Routes>
        <Route
          path='/signin-oidc'
          element={(
            <Dialog title='Sie werden eingeloggt' isOpen={true} onClose={() => {}} isCloseButtonShown={false}>
              <DialogBody><Spinner /></DialogBody>
            </Dialog>
          )}
        />
      </Routes>
    );

    if (identity === undefined || this.state.userManager === null) {
      return oidc_routes;
    }

    if (identity === null) {
      this.login();
      return oidc_routes;
    }

    if (this.props.location.pathname === '/signout-oidc') {
      this.props.navigate('/');
    }

    return (
      <AuthProviderContext.Provider value={{ authProvider: this }}>
        {this.props.children}
      </AuthProviderContext.Provider>
    );
  }

  public login = async (): Promise<void> => {
    if (this.state.userManager == null) {
      throw new Error('UserManager is not initialized');
    }
    try {
      await this.state.userManager.signinRedirect({
        state: this.props.location,
      });
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  };

  public logout(): void {
    if (this.state.userManager == null) {
      throw new Error('UserManager is not initialized');
    }
    try {
      this.state.userManager.signoutRedirect();
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public async processSigninResponse(userManager: UserManager): Promise<ProcessSigninResponseResult> {
    try {
      const user = await userManager.signinCallback();
      userManager.events.load(user);

      const identity = this.mapUserToIdentity(user);

      return {
        identity: identity,
        targetRoute: user.state ?? '/',
      };
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public async processSignoutResponse(): Promise<void> {
    if (this.state.userManager == null) {
      throw new Error('UserManager is not initialized');
    }
    try {
      await this.state.userManager.signoutCallback();
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public getIdentity(): IdentityWithEmail | null | undefined {
    return this.state.identity;
  }

  public async hasClaims(claims: Array<string>): Promise<boolean> {
    if (this.state.userManager == null) {
      throw new Error('UserManager is not initialized');
    }
    try {
      const user = await this.state.userManager.getUser();

      if (!user || !user.access_token || user.access_token === '') {
        return false;
      }

      const decodedAccessToken = jwtDecode<Record<string, unknown>>(user.access_token);

      if (!decodedAccessToken) {
        return false;
      }

      return claims.every(claim => decodedAccessToken[claim] !== undefined);
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  public async getAccessToken(): Promise<Record<string, any>> {
    if (this.state.userManager == null) {
      throw new Error('UserManager is not initialized');
    }
    try {
      const user = await this.state.userManager.getUser();

      if (!user || !user.access_token || user.access_token === '') {
        return {};
      }

      const decodedAccessToken = jwtDecode<Record<string, unknown>>(user.access_token);

      if (!decodedAccessToken) {
        return {};
      }

      return decodedAccessToken;
    } catch (error) {
      throw this.handleAtlasAuthorityRequestError(error);
    }
  }

  private handleUserLoadedEvent(user: User): void {
    const identity = this.mapUserToIdentity(user);
    this.setState({
      identity,
    });
  }

  private handleUserUnloadedEvent(): void {
    this.setState({
      identity: undefined,
    });
  }

  private handleAtlasAuthorityRequestError(error: unknown): void {
    this.setState({
      error: error,
    });
  }

  private mapUserToIdentity(user: User): IdentityWithEmail {
    return {
      token: user.access_token,
      userId: user.profile.sub,
      email: user.profile.email,
    };
  }
}
