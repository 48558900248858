import { Button, Dialog, H2 } from '@blueprintjs/core';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
} from '../../../infrastructure';

import { RueckstellMessprotokollDruckenResult } from 'wacoplast_wws__api';

export function RueckstellMessprotokollErfolgreichGedrucktHinweis(props: PropsWithServices<CustomFormProps<RueckstellMessprotokollDruckenResult>> & ModalProps<void>): JSX.Element {
  const finish = (): void => {
    props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog isOpen onClose={finish} title='Rückstell-Messprotokoll drucken'>
      <DialogBody>
        <H2>Das Rückstell-Messprotokoll wurde erfolgreich gedruckt.</H2>
        {props.tokenPayload.generated_pdf &&
          <a
            href={`${props.config.restServiceUrl}/file_storage/${props.tokenPayload.generated_pdf.file_group}/${props.tokenPayload.generated_pdf.file_name}/${props.tokenPayload.generated_pdf.hash}`}
            rel='noreferrer'
            target='_blank'
          >
            Ansehen
          </a>
        }
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Okay</Button>
      </DialogFooter>
    </Dialog>
  );
}
