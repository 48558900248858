import { NumericInput } from '@blueprintjs/core';
import React from 'react';

export type AgGridBLMEditorProps = {
  value: string,
}

type AgGridBLMEditorState = {
  blm: string,
  nnn: string,
}

export class AgGridBLMEditor extends React.Component<AgGridBLMEditorProps, AgGridBLMEditorState> {

  constructor(props: AgGridBLMEditorProps) {
    super(props);

    this.state = {
      blm: '',
      nnn: '',
    };

    this.inputRef = React.createRef<NumericInput>();
  }

  private inputRef: React.RefObject<NumericInput>;

  componentDidMount(): void {
    setTimeout(() => this.inputRef.current?.inputElement?.focus(), 10);
  }

  public onValueChange = (valNum: number, valStr: string): void => {
    const [blm, nnn] = valStr.split(',') as Array<string>;
    this.setState({
      blm: blm,
      nnn: nnn ?? '',
    });
  };

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  public getValue(): {blm: number, nnn: number} {
    return { blm: Number(this.state.blm), nnn: Number(this.state.nnn) };
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  public isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  public isCancelAfterEnd(): boolean {
    return false;
  }

  public render(): JSX.Element {
    return (
      <div
        className='ag-cell'
      >
        <NumericInput
          min={1}
          minorStepSize={0.001}
          ref={this.inputRef}
          defaultValue={this.props.value}
          rightElement={<></>}
          onValueChange={this.onValueChange}
          locale='de'
        />
      </div>
    );
  }
}
