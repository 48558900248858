/* eslint-disable react/display-name */
import { Radio, RadioGroup } from '@blueprintjs/core';
import { StammdatenFieldEditorSimpleRendererProps } from '../..';

export function makeEnumRadioButtons<TEntityType>(options: Array<string>, getFormattedValue: (value: string) => string): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, string>) => {
    return (
      <RadioGroup inline selectedValue={props.value} disabled={props.disabled} onChange={(event) => props.onChange(event.currentTarget.value)}>
        {
          options.map((option, index) => <Radio value={option} label={getFormattedValue(option)} key={index} />)
        }
      </RadioGroup>
    );
  };
}
