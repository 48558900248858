"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateVerpackungsVorschriftToJSON = exports.UpdateVerpackungsVorschriftFromJSONTyped = exports.UpdateVerpackungsVorschriftFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UpdateVerpackungsVorschriftFromJSON(json) {
    return UpdateVerpackungsVorschriftFromJSONTyped(json, false);
}
exports.UpdateVerpackungsVorschriftFromJSON = UpdateVerpackungsVorschriftFromJSON;
function UpdateVerpackungsVorschriftFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'transport_einheit_traeger_database_id': json['transport_einheit_traeger_database_id'],
        'transport_einheit_traeger_version_id': json['transport_einheit_traeger_version_id'],
        'unterlage_database_id': !runtime_1.exists(json, 'unterlage_database_id') ? undefined : json['unterlage_database_id'],
        'unterlage_version_id': !runtime_1.exists(json, 'unterlage_version_id') ? undefined : json['unterlage_version_id'],
        'unterlagen_anzahl': !runtime_1.exists(json, 'unterlagen_anzahl') ? undefined : json['unterlagen_anzahl'],
        'zwischenlage_database_id': !runtime_1.exists(json, 'zwischenlage_database_id') ? undefined : json['zwischenlage_database_id'],
        'zwischenlage_version_id': !runtime_1.exists(json, 'zwischenlage_version_id') ? undefined : json['zwischenlage_version_id'],
        'zwischenlagen_anzahl': !runtime_1.exists(json, 'zwischenlagen_anzahl') ? undefined : json['zwischenlagen_anzahl'],
        'abdeckung_database_id': !runtime_1.exists(json, 'abdeckung_database_id') ? undefined : json['abdeckung_database_id'],
        'abdeckung_version_id': !runtime_1.exists(json, 'abdeckung_version_id') ? undefined : json['abdeckung_version_id'],
        'abdeckung_anzahl': !runtime_1.exists(json, 'abdeckung_anzahl') ? undefined : json['abdeckung_anzahl'],
        'kantenschutz_database_id': !runtime_1.exists(json, 'kantenschutz_database_id') ? undefined : json['kantenschutz_database_id'],
        'kantenschutz_version_id': !runtime_1.exists(json, 'kantenschutz_version_id') ? undefined : json['kantenschutz_version_id'],
        'folie_database_id': !runtime_1.exists(json, 'folie_database_id') ? undefined : json['folie_database_id'],
        'folie_version_id': !runtime_1.exists(json, 'folie_version_id') ? undefined : json['folie_version_id'],
        'zustand': _1.VerpackungZustandFromJSON(json['zustand']),
        'aufdruck': !runtime_1.exists(json, 'aufdruck') ? undefined : _1.AufdruckArtFromJSON(json['aufdruck']),
        'geschlossen': !runtime_1.exists(json, 'geschlossen') ? undefined : json['geschlossen'],
        'polybeutel': !runtime_1.exists(json, 'polybeutel') ? undefined : json['polybeutel'],
        'transportweg': _1.TransportwegFromJSON(json['transportweg']),
        'stapel_hoehe_1': json['stapel_hoehe_1'],
        'stapel_hoehe_2': !runtime_1.exists(json, 'stapel_hoehe_2') ? undefined : json['stapel_hoehe_2'],
        'variante': !runtime_1.exists(json, 'variante') ? undefined : json['variante'],
        'prioritaet': json['prioritaet'],
        'bemerkung': !runtime_1.exists(json, 'bemerkung') ? undefined : json['bemerkung'],
        'spezialfall_karton_reduktion': !runtime_1.exists(json, 'spezialfall_karton_reduktion') ? undefined : json['spezialfall_karton_reduktion'],
    };
}
exports.UpdateVerpackungsVorschriftFromJSONTyped = UpdateVerpackungsVorschriftFromJSONTyped;
function UpdateVerpackungsVorschriftToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'transport_einheit_traeger_database_id': value.transport_einheit_traeger_database_id,
        'transport_einheit_traeger_version_id': value.transport_einheit_traeger_version_id,
        'unterlage_database_id': value.unterlage_database_id,
        'unterlage_version_id': value.unterlage_version_id,
        'unterlagen_anzahl': value.unterlagen_anzahl,
        'zwischenlage_database_id': value.zwischenlage_database_id,
        'zwischenlage_version_id': value.zwischenlage_version_id,
        'zwischenlagen_anzahl': value.zwischenlagen_anzahl,
        'abdeckung_database_id': value.abdeckung_database_id,
        'abdeckung_version_id': value.abdeckung_version_id,
        'abdeckung_anzahl': value.abdeckung_anzahl,
        'kantenschutz_database_id': value.kantenschutz_database_id,
        'kantenschutz_version_id': value.kantenschutz_version_id,
        'folie_database_id': value.folie_database_id,
        'folie_version_id': value.folie_version_id,
        'zustand': _1.VerpackungZustandToJSON(value.zustand),
        'aufdruck': _1.AufdruckArtToJSON(value.aufdruck),
        'geschlossen': value.geschlossen,
        'polybeutel': value.polybeutel,
        'transportweg': _1.TransportwegToJSON(value.transportweg),
        'stapel_hoehe_1': value.stapel_hoehe_1,
        'stapel_hoehe_2': value.stapel_hoehe_2,
        'variante': value.variante,
        'prioritaet': value.prioritaet,
        'bemerkung': value.bemerkung,
        'spezialfall_karton_reduktion': value.spezialfall_karton_reduktion,
    };
}
exports.UpdateVerpackungsVorschriftToJSON = UpdateVerpackungsVorschriftToJSON;
