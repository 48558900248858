"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateWerkstoffTypToJSON = exports.UpdateWerkstoffTypFromJSONTyped = exports.UpdateWerkstoffTypFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateWerkstoffTypFromJSON(json) {
    return UpdateWerkstoffTypFromJSONTyped(json, false);
}
exports.UpdateWerkstoffTypFromJSON = UpdateWerkstoffTypFromJSON;
function UpdateWerkstoffTypFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'werkstoff_database_id': !runtime_1.exists(json, 'werkstoff_database_id') ? undefined : json['werkstoff_database_id'],
        'werkstoff_version_id': !runtime_1.exists(json, 'werkstoff_version_id') ? undefined : json['werkstoff_version_id'],
        'bezeichnung': json['bezeichnung'],
        'verwendung': !runtime_1.exists(json, 'verwendung') ? undefined : json['verwendung'],
    };
}
exports.UpdateWerkstoffTypFromJSONTyped = UpdateWerkstoffTypFromJSONTyped;
function UpdateWerkstoffTypToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'werkstoff_database_id': value.werkstoff_database_id,
        'werkstoff_version_id': value.werkstoff_version_id,
        'bezeichnung': value.bezeichnung,
        'verwendung': value.verwendung,
    };
}
exports.UpdateWerkstoffTypToJSON = UpdateWerkstoffTypToJSON;
