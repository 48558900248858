"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateBlasmaschineZeitenToJSON = exports.CreateBlasmaschineZeitenFromJSONTyped = exports.CreateBlasmaschineZeitenFromJSON = void 0;
const runtime_1 = require("../runtime");
function CreateBlasmaschineZeitenFromJSON(json) {
    return CreateBlasmaschineZeitenFromJSONTyped(json, false);
}
exports.CreateBlasmaschineZeitenFromJSON = CreateBlasmaschineZeitenFromJSON;
function CreateBlasmaschineZeitenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'blasmaschine_database_id': json['blasmaschine_database_id'],
        'blasmaschine_version_id': json['blasmaschine_version_id'],
        'blasform_database_id': json['blasform_database_id'],
        'blasform_version_id': json['blasform_version_id'],
        'hals_teil_database_id': json['hals_teil_database_id'],
        'hals_teil_version_id': json['hals_teil_version_id'],
        'werkstoff_database_id': json['werkstoff_database_id'],
        'werkstoff_version_id': json['werkstoff_version_id'],
        'gewicht': json['gewicht'],
        'programm_zeit': !runtime_1.exists(json, 'programm_zeit') ? undefined : json['programm_zeit'],
        'programm_hub': !runtime_1.exists(json, 'programm_hub') ? undefined : json['programm_hub'],
        'maschinen_zyklus': !runtime_1.exists(json, 'maschinen_zyklus') ? undefined : json['maschinen_zyklus'],
        'zone_1': !runtime_1.exists(json, 'zone_1') ? undefined : json['zone_1'],
        'zone_2': !runtime_1.exists(json, 'zone_2') ? undefined : json['zone_2'],
        'zone_3': !runtime_1.exists(json, 'zone_3') ? undefined : json['zone_3'],
        'zone_4': !runtime_1.exists(json, 'zone_4') ? undefined : json['zone_4'],
        'zone_5': !runtime_1.exists(json, 'zone_5') ? undefined : json['zone_5'],
        'zone_6': !runtime_1.exists(json, 'zone_6') ? undefined : json['zone_6'],
        'zone_7': !runtime_1.exists(json, 'zone_7') ? undefined : json['zone_7'],
        'zone_8': !runtime_1.exists(json, 'zone_8') ? undefined : json['zone_8'],
        'zone_9': !runtime_1.exists(json, 'zone_9') ? undefined : json['zone_9'],
        'zone_10': !runtime_1.exists(json, 'zone_10') ? undefined : json['zone_10'],
        'zone_1_temp': !runtime_1.exists(json, 'zone_1_temp') ? undefined : json['zone_1_temp'],
        'zone_2_temp': !runtime_1.exists(json, 'zone_2_temp') ? undefined : json['zone_2_temp'],
        'zone_3_temp': !runtime_1.exists(json, 'zone_3_temp') ? undefined : json['zone_3_temp'],
        'zone_4_temp': !runtime_1.exists(json, 'zone_4_temp') ? undefined : json['zone_4_temp'],
        'zone_5_temp': !runtime_1.exists(json, 'zone_5_temp') ? undefined : json['zone_5_temp'],
        'zone_6_temp': !runtime_1.exists(json, 'zone_6_temp') ? undefined : json['zone_6_temp'],
        'zone_7_temp': !runtime_1.exists(json, 'zone_7_temp') ? undefined : json['zone_7_temp'],
        'zone_8_temp': !runtime_1.exists(json, 'zone_8_temp') ? undefined : json['zone_8_temp'],
        'zone_9_temp': !runtime_1.exists(json, 'zone_9_temp') ? undefined : json['zone_9_temp'],
        'zone_10_temp': !runtime_1.exists(json, 'zone_10_temp') ? undefined : json['zone_10_temp'],
        'stueck_pro_stunde': !runtime_1.exists(json, 'stueck_pro_stunde') ? undefined : json['stueck_pro_stunde'],
        'maschinen_taktgeber': !runtime_1.exists(json, 'maschinen_taktgeber') ? undefined : json['maschinen_taktgeber'],
        'maschinen_laufkontrolle': !runtime_1.exists(json, 'maschinen_laufkontrolle') ? undefined : json['maschinen_laufkontrolle'],
        'verz_trennen_1_vor': !runtime_1.exists(json, 'verz_trennen_1_vor') ? undefined : json['verz_trennen_1_vor'],
        'verz_trennen_1_zur': !runtime_1.exists(json, 'verz_trennen_1_zur') ? undefined : json['verz_trennen_1_zur'],
        'zeitglied_trennen_1': !runtime_1.exists(json, 'zeitglied_trennen_1') ? undefined : json['zeitglied_trennen_1'],
        'verz_blas_blad': !runtime_1.exists(json, 'verz_blas_blad') ? undefined : json['verz_blas_blad'],
        'vorblaszeit': !runtime_1.exists(json, 'vorblaszeit') ? undefined : json['vorblaszeit'],
        'blasdruck': !runtime_1.exists(json, 'blasdruck') ? undefined : json['blasdruck'],
        'standzeit': !runtime_1.exists(json, 'standzeit') ? undefined : json['standzeit'],
        'verz_form_zu': !runtime_1.exists(json, 'verz_form_zu') ? undefined : json['verz_form_zu'],
        'lueftzeit_dorn': !runtime_1.exists(json, 'lueftzeit_dorn') ? undefined : json['lueftzeit_dorn'],
        'lueftzeit_form': !runtime_1.exists(json, 'lueftzeit_form') ? undefined : json['lueftzeit_form'],
        'verz_wagen_ab': !runtime_1.exists(json, 'verz_wagen_ab') ? undefined : json['verz_wagen_ab'],
        'verz_wagen_hoch': !runtime_1.exists(json, 'verz_wagen_hoch') ? undefined : json['verz_wagen_hoch'],
        'verz_stanze_vor': !runtime_1.exists(json, 'verz_stanze_vor') ? undefined : json['verz_stanze_vor'],
        'verz_stanze_zur': !runtime_1.exists(json, 'verz_stanze_zur') ? undefined : json['verz_stanze_zur'],
        'verz_greifer_zu': !runtime_1.exists(json, 'verz_greifer_zu') ? undefined : json['verz_greifer_zu'],
        'verz_greifer_auf_se': !runtime_1.exists(json, 'verz_greifer_auf_se') ? undefined : json['verz_greifer_auf_se'],
        'verz_greifer_auf_le': !runtime_1.exists(json, 'verz_greifer_auf_le') ? undefined : json['verz_greifer_auf_le'],
        'verz_ent_senk': !runtime_1.exists(json, 'verz_ent_senk') ? undefined : json['verz_ent_senk'],
        'verz_dorn_hoch': !runtime_1.exists(json, 'verz_dorn_hoch') ? undefined : json['verz_dorn_hoch'],
        'zeit_bandstop': !runtime_1.exists(json, 'zeit_bandstop') ? undefined : json['zeit_bandstop'],
        'verz_sturtzluft': !runtime_1.exists(json, 'verz_sturtzluft') ? undefined : json['verz_sturtzluft'],
        'dauer_stuetzluft': !runtime_1.exists(json, 'dauer_stuetzluft') ? undefined : json['dauer_stuetzluft'],
        'verz_dekomp': !runtime_1.exists(json, 'verz_dekomp') ? undefined : json['verz_dekomp'],
        'dauer_dekomp': !runtime_1.exists(json, 'dauer_dekomp') ? undefined : json['dauer_dekomp'],
        'verz_pneum_1': !runtime_1.exists(json, 'verz_pneum_1') ? undefined : json['verz_pneum_1'],
        'dauer_pneum_1': !runtime_1.exists(json, 'dauer_pneum_1') ? undefined : json['dauer_pneum_1'],
        'blaszeit_ist': !runtime_1.exists(json, 'blaszeit_ist') ? undefined : json['blaszeit_ist'],
        'wbk_0': !runtime_1.exists(json, 'wbk_0') ? undefined : json['wbk_0'],
        'wbk_1': !runtime_1.exists(json, 'wbk_1') ? undefined : json['wbk_1'],
        'wbk_2': !runtime_1.exists(json, 'wbk_2') ? undefined : json['wbk_2'],
        'wbk_3': !runtime_1.exists(json, 'wbk_3') ? undefined : json['wbk_3'],
        'wbk_4': !runtime_1.exists(json, 'wbk_4') ? undefined : json['wbk_4'],
        'wbk_5': !runtime_1.exists(json, 'wbk_5') ? undefined : json['wbk_5'],
        'wbk_6': !runtime_1.exists(json, 'wbk_6') ? undefined : json['wbk_6'],
        'wbk_7': !runtime_1.exists(json, 'wbk_7') ? undefined : json['wbk_7'],
        'wbk_8': !runtime_1.exists(json, 'wbk_8') ? undefined : json['wbk_8'],
        'wbk_9': !runtime_1.exists(json, 'wbk_9') ? undefined : json['wbk_9'],
        'wbk_10': !runtime_1.exists(json, 'wbk_10') ? undefined : json['wbk_10'],
        'wbk_11': !runtime_1.exists(json, 'wbk_11') ? undefined : json['wbk_11'],
        'wbk_12': !runtime_1.exists(json, 'wbk_12') ? undefined : json['wbk_12'],
        'wbk_13': !runtime_1.exists(json, 'wbk_13') ? undefined : json['wbk_13'],
        'wbk_14': !runtime_1.exists(json, 'wbk_14') ? undefined : json['wbk_14'],
        'wbk_15': !runtime_1.exists(json, 'wbk_15') ? undefined : json['wbk_15'],
        'wbk_16': !runtime_1.exists(json, 'wbk_16') ? undefined : json['wbk_16'],
        'wbk_17': !runtime_1.exists(json, 'wbk_17') ? undefined : json['wbk_17'],
        'wbk_18': !runtime_1.exists(json, 'wbk_18') ? undefined : json['wbk_18'],
        'wbk_19': !runtime_1.exists(json, 'wbk_19') ? undefined : json['wbk_19'],
        'wbk_20': !runtime_1.exists(json, 'wbk_20') ? undefined : json['wbk_20'],
        'wbk_21': !runtime_1.exists(json, 'wbk_21') ? undefined : json['wbk_21'],
        'wbk_22': !runtime_1.exists(json, 'wbk_22') ? undefined : json['wbk_22'],
        'wbk_23': !runtime_1.exists(json, 'wbk_23') ? undefined : json['wbk_23'],
        'wbk_24': !runtime_1.exists(json, 'wbk_24') ? undefined : json['wbk_24'],
        'wbk_25': !runtime_1.exists(json, 'wbk_25') ? undefined : json['wbk_25'],
        'wbk_26': !runtime_1.exists(json, 'wbk_26') ? undefined : json['wbk_26'],
        'wbk_27': !runtime_1.exists(json, 'wbk_27') ? undefined : json['wbk_27'],
        'wbk_28': !runtime_1.exists(json, 'wbk_28') ? undefined : json['wbk_28'],
        'wbk_29': !runtime_1.exists(json, 'wbk_29') ? undefined : json['wbk_29'],
        'bemerkung': !runtime_1.exists(json, 'bemerkung') ? undefined : json['bemerkung'],
    };
}
exports.CreateBlasmaschineZeitenFromJSONTyped = CreateBlasmaschineZeitenFromJSONTyped;
function CreateBlasmaschineZeitenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'blasmaschine_database_id': value.blasmaschine_database_id,
        'blasmaschine_version_id': value.blasmaschine_version_id,
        'blasform_database_id': value.blasform_database_id,
        'blasform_version_id': value.blasform_version_id,
        'hals_teil_database_id': value.hals_teil_database_id,
        'hals_teil_version_id': value.hals_teil_version_id,
        'werkstoff_database_id': value.werkstoff_database_id,
        'werkstoff_version_id': value.werkstoff_version_id,
        'gewicht': value.gewicht,
        'programm_zeit': value.programm_zeit,
        'programm_hub': value.programm_hub,
        'maschinen_zyklus': value.maschinen_zyklus,
        'zone_1': value.zone_1,
        'zone_2': value.zone_2,
        'zone_3': value.zone_3,
        'zone_4': value.zone_4,
        'zone_5': value.zone_5,
        'zone_6': value.zone_6,
        'zone_7': value.zone_7,
        'zone_8': value.zone_8,
        'zone_9': value.zone_9,
        'zone_10': value.zone_10,
        'zone_1_temp': value.zone_1_temp,
        'zone_2_temp': value.zone_2_temp,
        'zone_3_temp': value.zone_3_temp,
        'zone_4_temp': value.zone_4_temp,
        'zone_5_temp': value.zone_5_temp,
        'zone_6_temp': value.zone_6_temp,
        'zone_7_temp': value.zone_7_temp,
        'zone_8_temp': value.zone_8_temp,
        'zone_9_temp': value.zone_9_temp,
        'zone_10_temp': value.zone_10_temp,
        'stueck_pro_stunde': value.stueck_pro_stunde,
        'maschinen_taktgeber': value.maschinen_taktgeber,
        'maschinen_laufkontrolle': value.maschinen_laufkontrolle,
        'verz_trennen_1_vor': value.verz_trennen_1_vor,
        'verz_trennen_1_zur': value.verz_trennen_1_zur,
        'zeitglied_trennen_1': value.zeitglied_trennen_1,
        'verz_blas_blad': value.verz_blas_blad,
        'vorblaszeit': value.vorblaszeit,
        'blasdruck': value.blasdruck,
        'standzeit': value.standzeit,
        'verz_form_zu': value.verz_form_zu,
        'lueftzeit_dorn': value.lueftzeit_dorn,
        'lueftzeit_form': value.lueftzeit_form,
        'verz_wagen_ab': value.verz_wagen_ab,
        'verz_wagen_hoch': value.verz_wagen_hoch,
        'verz_stanze_vor': value.verz_stanze_vor,
        'verz_stanze_zur': value.verz_stanze_zur,
        'verz_greifer_zu': value.verz_greifer_zu,
        'verz_greifer_auf_se': value.verz_greifer_auf_se,
        'verz_greifer_auf_le': value.verz_greifer_auf_le,
        'verz_ent_senk': value.verz_ent_senk,
        'verz_dorn_hoch': value.verz_dorn_hoch,
        'zeit_bandstop': value.zeit_bandstop,
        'verz_sturtzluft': value.verz_sturtzluft,
        'dauer_stuetzluft': value.dauer_stuetzluft,
        'verz_dekomp': value.verz_dekomp,
        'dauer_dekomp': value.dauer_dekomp,
        'verz_pneum_1': value.verz_pneum_1,
        'dauer_pneum_1': value.dauer_pneum_1,
        'blaszeit_ist': value.blaszeit_ist,
        'wbk_0': value.wbk_0,
        'wbk_1': value.wbk_1,
        'wbk_2': value.wbk_2,
        'wbk_3': value.wbk_3,
        'wbk_4': value.wbk_4,
        'wbk_5': value.wbk_5,
        'wbk_6': value.wbk_6,
        'wbk_7': value.wbk_7,
        'wbk_8': value.wbk_8,
        'wbk_9': value.wbk_9,
        'wbk_10': value.wbk_10,
        'wbk_11': value.wbk_11,
        'wbk_12': value.wbk_12,
        'wbk_13': value.wbk_13,
        'wbk_14': value.wbk_14,
        'wbk_15': value.wbk_15,
        'wbk_16': value.wbk_16,
        'wbk_17': value.wbk_17,
        'wbk_18': value.wbk_18,
        'wbk_19': value.wbk_19,
        'wbk_20': value.wbk_20,
        'wbk_21': value.wbk_21,
        'wbk_22': value.wbk_22,
        'wbk_23': value.wbk_23,
        'wbk_24': value.wbk_24,
        'wbk_25': value.wbk_25,
        'wbk_26': value.wbk_26,
        'wbk_27': value.wbk_27,
        'wbk_28': value.wbk_28,
        'wbk_29': value.wbk_29,
        'bemerkung': value.bemerkung,
    };
}
exports.CreateBlasmaschineZeitenToJSON = CreateBlasmaschineZeitenToJSON;
