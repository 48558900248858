"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlantafelEntryToJSON = exports.PlantafelEntryFromJSONTyped = exports.PlantafelEntryFromJSON = void 0;
const _1 = require("./");
function PlantafelEntryFromJSON(json) {
    return PlantafelEntryFromJSONTyped(json, false);
}
exports.PlantafelEntryFromJSON = PlantafelEntryFromJSON;
function PlantafelEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_blasen': _1.ProduktionsauftragBlasenFromJSON(json['produktionsauftrag_blasen']),
        'datum_start': (new Date(json['datum_start'])),
        'datum_ende': (new Date(json['datum_ende'])),
    };
}
exports.PlantafelEntryFromJSONTyped = PlantafelEntryFromJSONTyped;
function PlantafelEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_blasen': _1.ProduktionsauftragBlasenToJSON(value.produktionsauftrag_blasen),
        'datum_start': (value.datum_start.toISOString().substr(0, 10)),
        'datum_ende': (value.datum_ende.toISOString().substr(0, 10)),
    };
}
exports.PlantafelEntryToJSON = PlantafelEntryToJSON;
