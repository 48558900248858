import { FormGroup } from '@blueprintjs/core';
import { PropsWithChildren } from 'react';
import { HTTPValidationError } from 'wacoplast_wws__api';
import { findMatchingValidationErrors, removeDuplicateValidationErrors } from '..';
import { validationErrorTypeToMessage } from '../validation/validationErrorTypeToMessage';

export type FormFieldProps = PropsWithChildren<{
  lastValidationResponse?: HTTPValidationError | undefined | null;
  fieldLocation: string | string[] | string[][];
  fieldLabel?: string | JSX.Element;
  style?: React.CSSProperties | undefined;
}>;

export function DialogFormField(props: FormFieldProps): JSX.Element {
  const { lastValidationResponse, fieldLocation } = props;
  const validationErrors = removeDuplicateValidationErrors(findMatchingValidationErrors(lastValidationResponse, fieldLocation));
  const errorDescriptions = validationErrors.map((validationError) => validationErrorTypeToMessage(validationError) ?? validationError.msg);

  return (
    <FormGroup
      style={props.style}
      label={props.fieldLabel}
      helperText={errorDescriptions.length <= 1 ? errorDescriptions[0] : renderErrorDescriptions(errorDescriptions)}
      intent='danger'
    >
      {props.children}
    </FormGroup>
  );
}

function renderErrorDescriptions(errorDescriptions: Array<string>): JSX.Element {
  return (
    <ul>
      {errorDescriptions.map((error, index) => <li key={index}>{error}</li>)}
    </ul>
  );
}
