import { ICellRendererParams } from 'ag-grid-community';
import { Tag } from '@blueprintjs/core';

export type ForeignKeyCellValues = { description: string, number?: string };
export type GetValueFunction<TType, TForeignKeyType> = (data: TType, value: TForeignKeyType) => ForeignKeyCellValues;

export type ForeignKeyCellValuesProvider<TType, TForeignKeyType> = ForeignKeyCellValuesProviderGetValue<TType, TForeignKeyType> | ForeignKeyCellValuesProviderFields<TForeignKeyType>;

export type ForeignKeyCellValuesProviderFields<TForeignKeyType> = {
  description?: keyof TForeignKeyType;
  number?: keyof TForeignKeyType;
};

export type ForeignKeyCellValuesProviderGetValue<TType, TForeignKeyType> = {
  getValue: GetValueFunction<TType, TForeignKeyType>;
};

export type ForeignKeyCellRendererProps<TType, TForeignKeyType> = ForeignKeyCellValuesProvider<TType, TForeignKeyType> & ICellRendererParams;

function isForeignKeyCellValuesProviderGetValue<TType, TForeignKeyType>(props: ForeignKeyCellValuesProvider<TType, TForeignKeyType>): props is ForeignKeyCellValuesProviderGetValue<TType, TForeignKeyType> {
  return !!(props as any).getValue;
}

function isForeignKeyCellValuesProviderFields<TType, TForeignKeyType>(props: ForeignKeyCellValuesProvider<TType, TForeignKeyType>): props is ForeignKeyCellValuesProviderFields<TForeignKeyType> {
  return !isForeignKeyCellValuesProviderGetValue(props);
}


function getGetValueFunction<TType, TForeignKeyType>(props: ForeignKeyCellValuesProvider<TType, TForeignKeyType>): GetValueFunction<TType, TForeignKeyType> {
  if (isForeignKeyCellValuesProviderGetValue(props)) {
    return props.getValue;
  }

  if (isForeignKeyCellValuesProviderFields(props)) {
    return (data, value: any) => {
      return {
        description: (props.description ? value[props.description] : 'Unbekanntes Objekt') ?? 'Unbekanntes Objekt',
        number: props.number ? value[props.number] : undefined,
      };
    };
  }

  throw new Error('Invalid props');
}

export function resolveForeignKeyCellValues<TType, TForeignKeyType>(provider: ForeignKeyCellValuesProvider<TType, TForeignKeyType>, data: TType, value: TForeignKeyType): ForeignKeyCellValues {
  return getGetValueFunction(provider)(data, value);
}

export function ForeignKeyCellRenderer<TType, TForeignKeyType>(props: ForeignKeyCellRendererProps<TType, TForeignKeyType>): JSX.Element | string {

  if (!props.value) {
    return '';
  }

  const { description, number } = resolveForeignKeyCellValues(props, props.data, props.value);

  return (
    <>
      {
        description
      }
      {
        number
          ? <> <Tag minimal={true}>{number}</Tag></>
          : <></>
      }
    </>
  );
}
