import { Code } from '@blueprintjs/core';
import { ForbiddenError, isAtlasEngineError } from '@atlas-engine/atlas_engine_sdk';


export function getErrorDescription(error: any): {summary: string, details: JSX.Element | string} {
  if (isAtlasEngineError(error)) {
    if (error instanceof ForbiddenError && error?.additionalInformation?.claim) {

      const missingClaim = error?.additionalInformation?.claim;

      return {
        summary: 'Berechtigungsfehler',
        details: <>Für diese Aktion fehlt folgende Berechtigung: <Code>{missingClaim}</Code>.</>,
      };
    }

    return {
      summary: `${error.name}: ${error.message}`,
      details: <>{JSON.stringify(error, null, '  ')}</>,
    };
  }

  if (Array.isArray(error.detail)) {
    const validationError = error.detail?.[0];
    if (validationError.msg && validationError.type) {
      return {
        summary: validationError.type,
        details: validationError.msg,
      };
    }
  }

  const errorToString: string = error.detail ?? error.message ?? error.statusText ?? '';
  if (/network/i.test(errorToString)){
    return {
      summary: 'Netzwerkfehler',
      details: 'Der Server konnte nicht erreicht werden. Bitte erneut versuchen.',
    };
  }

  // Gleiche Nachricht für gleichen Fehler bei Chrome und Edge
  if (errorToString === 'Failed to fetch') {
    return {
      summary: 'Netzwerkfehler',
      details: 'Der Server konnte nicht erreicht werden. Bitte erneut versuchen.',
    };
  }

  if (errorToString?.startsWith('Missing Authorization: ')) {
    return {
      summary: 'Berechtigungsfehler',
      details: <>Für diese Aktion fehlt eine Berechtigung. {errorToString.replace('Missing Authorization: ', '')}</>,
    };
  }

  if (errorToString === 'Unknown address') {
    return {
      summary: 'Unbekannte Adresse',
      details: 'Die angeforderte Adresse ist unbekannt.',
    };
  }

  console.dir(error);

  return {
    summary: 'Unbekannter Fehler',
    details: <>{errorToString}</>,
  };
}
