import { Button, Dialog, H2 } from '@blueprintjs/core';
import { addDays, format } from 'date-fns';
import { RueckmeldungIstEnddatumKorrektAbfragenResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  PropsWithServices,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungIstEnddatumKorrektAbfragen(props: PropsWithServices<CustomFormProps<{ database_id: number; anzahl_teilrueckmeldungen?: number; }>>,
): JSX.Element {
  const produktionsauftraege = useFetchLikeServiceFunction(props.services.produktionsauftraegeZuKundenauftrag.getAllProduktionsauftragZuKundenauftragGet, props.services.produktionsauftraegeZuKundenauftrag);
  const produktionsauftragBlasen = produktionsauftraege.data?.map(pau => pau.produktionsauftrag_blasen.map(b => b.produktionsauftrag_blasen)).flat().find(b => b.database_id === props.tokenPayload.database_id);
  const blasmaschine = produktionsauftragBlasen?.blasmaschine;
  const blasdauer = produktionsauftragBlasen?.blasdauer;
  const endeDatum = format(addDays(new Date(blasmaschine?.start_datum ?? new Date()), blasdauer ?? 0), 'd.L.yyyy');

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
    >
      <DialogBody>
        <H2>Ist das Enddatum {endeDatum} korrekt?</H2>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => props.finishUserTask(RueckmeldungIstEnddatumKorrektAbfragenResultToJSON({ ende_datum_korrekt: true }))}>Ja</Button>
        <Button onClick={() => props.abortUserTask(RueckmeldungIstEnddatumKorrektAbfragenResultToJSON({ ende_datum_korrekt: false }))}>Nein</Button>
      </DialogFooter>
    </Dialog>
  );
}
