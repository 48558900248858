"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateArtikelToJSON = exports.CreateArtikelFromJSONTyped = exports.CreateArtikelFromJSON = void 0;
const runtime_1 = require("../runtime");
function CreateArtikelFromJSON(json) {
    return CreateArtikelFromJSONTyped(json, false);
}
exports.CreateArtikelFromJSON = CreateArtikelFromJSON;
function CreateArtikelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gewicht': json['gewicht'],
        'werkstoff_database_id': json['werkstoff_database_id'],
        'werkstoff_version_id': json['werkstoff_version_id'],
        'granulat_farbe_database_id': json['granulat_farbe_database_id'],
        'granulat_farbe_version_id': json['granulat_farbe_version_id'],
        'werkstoff_typ_database_id': !runtime_1.exists(json, 'werkstoff_typ_database_id') ? undefined : json['werkstoff_typ_database_id'],
        'werkstoff_typ_version_id': !runtime_1.exists(json, 'werkstoff_typ_version_id') ? undefined : json['werkstoff_typ_version_id'],
        'einfaerbungs_grad': !runtime_1.exists(json, 'einfaerbungs_grad') ? undefined : json['einfaerbungs_grad'],
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'artikel_nummer_des_kunden': !runtime_1.exists(json, 'artikel_nummer_des_kunden') ? undefined : json['artikel_nummer_des_kunden'],
        'artikel_nummer_des_empfaengers': !runtime_1.exists(json, 'artikel_nummer_des_empfaengers') ? undefined : json['artikel_nummer_des_empfaengers'],
        'kunde_database_id': json['kunde_database_id'],
        'kunde_version_id': json['kunde_version_id'],
        'empfaenger_database_id': !runtime_1.exists(json, 'empfaenger_database_id') ? undefined : json['empfaenger_database_id'],
        'empfaenger_version_id': !runtime_1.exists(json, 'empfaenger_version_id') ? undefined : json['empfaenger_version_id'],
        'flaschen_form_database_id': json['flaschen_form_database_id'],
        'flaschen_form_version_id': json['flaschen_form_version_id'],
    };
}
exports.CreateArtikelFromJSONTyped = CreateArtikelFromJSONTyped;
function CreateArtikelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gewicht': value.gewicht,
        'werkstoff_database_id': value.werkstoff_database_id,
        'werkstoff_version_id': value.werkstoff_version_id,
        'granulat_farbe_database_id': value.granulat_farbe_database_id,
        'granulat_farbe_version_id': value.granulat_farbe_version_id,
        'werkstoff_typ_database_id': value.werkstoff_typ_database_id,
        'werkstoff_typ_version_id': value.werkstoff_typ_version_id,
        'einfaerbungs_grad': value.einfaerbungs_grad,
        'nummer': value.nummer,
        'artikel_nummer_des_kunden': value.artikel_nummer_des_kunden,
        'artikel_nummer_des_empfaengers': value.artikel_nummer_des_empfaengers,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'empfaenger_database_id': value.empfaenger_database_id,
        'empfaenger_version_id': value.empfaenger_version_id,
        'flaschen_form_database_id': value.flaschen_form_database_id,
        'flaschen_form_version_id': value.flaschen_form_version_id,
    };
}
exports.CreateArtikelToJSON = CreateArtikelToJSON;
