"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadToJSON = exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSONTyped = exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSON = void 0;
function ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSON(json) {
    return ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSON = ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSON;
function ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_database_id': json['produktionsauftrag_database_id'],
    };
}
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSONTyped = ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSONTyped;
function ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_database_id': value.produktionsauftrag_database_id,
    };
}
exports.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadToJSON = ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadToJSON;
