"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeletionStateToJSON = exports.DeletionStateFromJSONTyped = exports.DeletionStateFromJSON = exports.DeletionState = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var DeletionState;
(function (DeletionState) {
    DeletionState[DeletionState["NUMBER_0"] = 0] = "NUMBER_0";
    DeletionState[DeletionState["NUMBER_1"] = 1] = "NUMBER_1";
    DeletionState[DeletionState["NUMBER_2"] = 2] = "NUMBER_2";
    DeletionState[DeletionState["NUMBER_5"] = 5] = "NUMBER_5";
})(DeletionState = exports.DeletionState || (exports.DeletionState = {}));
function DeletionStateFromJSON(json) {
    return DeletionStateFromJSONTyped(json, false);
}
exports.DeletionStateFromJSON = DeletionStateFromJSON;
function DeletionStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DeletionStateFromJSONTyped = DeletionStateFromJSONTyped;
function DeletionStateToJSON(value) {
    return value;
}
exports.DeletionStateToJSON = DeletionStateToJSON;
