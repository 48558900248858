"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BpmnValidationErrorToJSON = exports.BpmnValidationErrorFromJSONTyped = exports.BpmnValidationErrorFromJSON = void 0;
const _1 = require("./");
function BpmnValidationErrorFromJSON(json) {
    return BpmnValidationErrorFromJSONTyped(json, false);
}
exports.BpmnValidationErrorFromJSON = BpmnValidationErrorFromJSON;
function BpmnValidationErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'detail': (json['detail'].map(_1.ErrorDict2FromJSON)),
        'previous_payload': json['previous_payload'],
    };
}
exports.BpmnValidationErrorFromJSONTyped = BpmnValidationErrorFromJSONTyped;
function BpmnValidationErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'detail': (value.detail.map(_1.ErrorDict2ToJSON)),
        'previous_payload': value.previous_payload,
    };
}
exports.BpmnValidationErrorToJSON = BpmnValidationErrorToJSON;
