"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerpackungZustandToJSON = exports.VerpackungZustandFromJSONTyped = exports.VerpackungZustandFromJSON = exports.VerpackungZustand = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var VerpackungZustand;
(function (VerpackungZustand) {
    VerpackungZustand["neu"] = "neu";
    VerpackungZustand["alt"] = "alt";
    VerpackungZustand["egal"] = "egal";
})(VerpackungZustand = exports.VerpackungZustand || (exports.VerpackungZustand = {}));
function VerpackungZustandFromJSON(json) {
    return VerpackungZustandFromJSONTyped(json, false);
}
exports.VerpackungZustandFromJSON = VerpackungZustandFromJSON;
function VerpackungZustandFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.VerpackungZustandFromJSONTyped = VerpackungZustandFromJSONTyped;
function VerpackungZustandToJSON(value) {
    return value;
}
exports.VerpackungZustandToJSON = VerpackungZustandToJSON;
