"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsphaseToJSON = exports.ProduktionsphaseFromJSONTyped = exports.ProduktionsphaseFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProduktionsphaseFromJSON(json) {
    return ProduktionsphaseFromJSONTyped(json, false);
}
exports.ProduktionsphaseFromJSON = ProduktionsphaseFromJSON;
function ProduktionsphaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'zaehlerstand_umbau_start': !runtime_1.exists(json, 'zaehlerstand_umbau_start') ? undefined : json['zaehlerstand_umbau_start'],
        'zaehlerstand_produktion_start': !runtime_1.exists(json, 'zaehlerstand_produktion_start') ? undefined : json['zaehlerstand_produktion_start'],
        'zaehlerstand_produktion_ende': !runtime_1.exists(json, 'zaehlerstand_produktion_ende') ? undefined : json['zaehlerstand_produktion_ende'],
        'menge_gut': !runtime_1.exists(json, 'menge_gut') ? undefined : json['menge_gut'],
    };
}
exports.ProduktionsphaseFromJSONTyped = ProduktionsphaseFromJSONTyped;
function ProduktionsphaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'zaehlerstand_umbau_start': value.zaehlerstand_umbau_start,
        'zaehlerstand_produktion_start': value.zaehlerstand_produktion_start,
        'zaehlerstand_produktion_ende': value.zaehlerstand_produktion_ende,
        'menge_gut': value.menge_gut,
    };
}
exports.ProduktionsphaseToJSON = ProduktionsphaseToJSON;
