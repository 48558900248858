import { Artikel, CreateArtikel } from 'wacoplast_wws__api';
import { makeWerkstoffTypFilteredDropDown } from '.';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatFlaschenFormNummer,
  formatWerkstoffTypNummer,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeNumberWithDecimalsEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';


export type ArtikelVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function ArtikelVerwalten(props: ArtikelVerwaltenProps): JSX.Element {

  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const empfaengerResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllFirmaGet, props.services.firma);
  const flaschenFormResult = useFetchLikeGetAllServiceFunction(props.services.flaschenForm.getAllFlaschenFormGet, props.services.flaschenForm);
  const werkstoffResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffe.getAllWerkstoffGet, props.services.werkstoffe);
  const werkstoffTypResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffTyp.getAllWerkstoffTypGet, props.services.werkstoffTyp);
  const granulatFarbeResult = useFetchLikeGetAllServiceFunction(props.services.granulatFarben.getAllGranulatFarbeGet, props.services.granulatFarben);

  const editors: StammdatenFieldEditors<CreateArtikel> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['kunde_database_id', 'kunde_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(kundenResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
      }, 'kunde', false, (data) => {
        return {
          kunde_database_id: data?.database_id,
          kunde_version_id: data?.version_id,
          empfaenger_database_id: data?.database_id,
          empfaenger_version_id: data?.version_id,
        };
      }),
      label: 'Kunde',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'artikel_nummer_des_kunden',
      renderer: makeTextEditorRenderer(),
      label: 'Kunde-Artikelnummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['empfaenger_database_id', 'empfaenger_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(empfaengerResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
      }, 'empfaenger', true),
      label: 'Empfänger',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'artikel_nummer_des_empfaengers',
      renderer: makeTextEditorRenderer(),
      label: 'Empfänger-Artikelnummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['flaschen_form_database_id', 'flaschen_form_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschenFormResult, (data) => {
        return { id: data.database_id, primaryTitle: data.flaschentyp.bezeichnung, secondaryTitle: formatFlaschenFormNummer(data) };
      }, 'flaschen_form'),
      label: 'Flaschenform',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'gewicht',
      renderer: makeNumberWithDecimalsEditorRenderer(),
      label: 'Gewicht [g]',
    },
    {
      type: 'complex',
      fields: ['werkstoff_database_id', 'werkstoff_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(werkstoffResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'werkstoff', false, (data) => {
        return {
          werkstoff_database_id: data?.database_id,
          werkstoff_version_id: data?.version_id,
          werkstoff_typ_database_id: undefined,
          werkstoff_typ_version_id: undefined,
        };
      }),
      label: 'Werkstoff',
    },
    {
      type: 'complex',
      fields: ['werkstoff_typ_database_id', 'werkstoff_typ_version_id'],
      renderer: makeWerkstoffTypFilteredDropDown(werkstoffTypResult, (werkstoffTyp) => {
        return { id: werkstoffTyp.database_id, primaryTitle: werkstoffTyp.bezeichnung, secondaryTitle: formatWerkstoffTypNummer(werkstoffTyp.nummer) };
      }, 'werkstoff_typ', true),
      label: 'Werkstofftyp',
    },
    {
      type: 'complex',
      fields: ['granulat_farbe_database_id', 'granulat_farbe_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(granulatFarbeResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'granulat_farbe'),
      label: 'Granulatfarbe',
    },
    {
      type: 'simple',
      field: 'einfaerbungs_grad',
      renderer: makeNumberEditorRenderer({
        nullable: true,
      }),
      label: 'Einfärbungsgrad',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
          colId: 'nummer',
        },
        {
          headerName: 'Kunde',
          ...makeForeignKeyColumn('kunde', (data: Artikel) => {
            return {
              description: data.kunde.name_kurz,
              number: data.kunde.nummer,
            };
          }),
          colId: 'kunde',
        },
        {
          headerName: 'Kunden-Artikelnummer',
          field: 'artikel_nummer_des_kunden',
          filter: 'agTextColumnFilter',
          colId: 'artikel_nummer_des_kunden',
        },
        {
          headerName: 'Fl-Typ-Bez',
          colId: 'flaschen_typ_bezeichnung',
          ...makeForeignKeyColumn('flaschen_form', (data: Artikel) => {
            return {
              description: data.flaschen_form.flaschentyp.bezeichnung,
              number: data.flaschen_form.flaschentyp.nummer,
            };
          }),
        },
        {
          headerName: 'Volumen [ml]',
          colId: 'volumen',
          valueGetter: ({ data }) => data.flaschen_form.flaschentyp.volumen,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Halsteil',
          colId: 'hals_teil',
          ...makeForeignKeyColumn('flaschen_form', (data: Artikel) => {
            return {
              description: data.flaschen_form.hals_teil.bezeichnung,
              number: data.flaschen_form.hals_teil.nummer,
            };
          }),
        },
        {
          headerName: 'Gewicht [g]',
          field: 'gewicht',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          colId: 'gewicht',
        },
        {
          headerName: 'BLZ',
          colId: 'blinden_zeichen',
          ...makeForeignKeyColumn('flaschen_form', (data: Artikel) => {
            return {
              description: data.flaschen_form.flaschentyp.blindenzeichen.bezeichnung,
              number: data.flaschen_form.flaschentyp.blindenzeichen.nummer,
            };
          }),
        },
        {
          headerName: 'Farbe',
          ...makeForeignKeyColumn('granulat_farbe', (data: Artikel) => {
            return {
              description: data.granulat_farbe.bezeichnung,
              number: data.granulat_farbe.nummer,
            };
          }),
          colId: 'granulat_farbe',
        },
        {
          headerName: 'Farb-Batch-Nr',
          colId: 'farb_batch_nummer',
          valueGetter: ({ data }) => data.granulat_farbe.batch_nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Einfärbungsgrad in %',
          field: 'einfaerbungs_grad',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          colId: 'einfaerbungs_grad',
        },
        {
          headerName: 'Werkstoff',
          ...makeForeignKeyColumn('werkstoff', (data: Artikel) => {
            return {
              description: data.werkstoff.bezeichnung,
              number: data.werkstoff.nummer,
            };
          }),
          colId: 'werkstoff',
        },
        {
          headerName: 'WerkstoffTyp',
          ...makeForeignKeyColumn('werkstoff_typ', (data: Artikel) => {
            return {
              description: data.werkstoff_typ?.bezeichnung ?? '',
              number: formatWerkstoffTypNummer(data.werkstoff_typ?.nummer),
            };
          }),
          colId: 'werkstoff_typ',
        },
        {
          headerName: 'Empfänger-Firma',
          ...makeForeignKeyColumn('empfaenger', (data: Artikel) => {
            return {
              description: data.empfaenger?.name_kurz ?? '',
              number: data.empfaenger?.nummer,
            };
          }),
          colId: 'empfaenger',
        },
        {
          headerName: 'EmArtNr',
          field: 'artikel_nummer_des_empfaengers',
          filter: 'agTextColumnFilter',
          colId: 'artikel_nummer_des_empfaengers',
        },
      ]}
      stammdatenService={{
        create: (data: CreateArtikel) => props.services.artikel.postArtikelPost({ CreateArtikel: data }),
        update: (data) => props.services.artikel.putByDatabaseIdArtikelDatabaseIdPut(
          {
            database_id: data.database_id,
            UpdateArtikel: data,
          },
        ),
        delete: (data, ignoreIntegrity) => props.services.artikel.deleteByDatabaseIdArtikelDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.artikel.restoreByDatabaseIdArtikelDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.artikel.getAllArtikelGet({ include_deleted }),
        getHistory: (artikel) => props.services.artikel.getHistoryArtikelDatabaseIdHistoryGet({ database_id: artikel.database_id }),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Artikel}
    />
  );
}
