"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragEntryToJSON = exports.ProduktionsauftragEntryFromJSONTyped = exports.ProduktionsauftragEntryFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProduktionsauftragEntryFromJSON(json) {
    return ProduktionsauftragEntryFromJSONTyped(json, false);
}
exports.ProduktionsauftragEntryFromJSON = ProduktionsauftragEntryFromJSON;
function ProduktionsauftragEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_blasen': _1.ProduktionsauftragBlasenFromJSON(json['produktionsauftrag_blasen']),
        'datum_start': !runtime_1.exists(json, 'datum_start') ? undefined : (new Date(json['datum_start'])),
        'datum_ende': !runtime_1.exists(json, 'datum_ende') ? undefined : (new Date(json['datum_ende'])),
    };
}
exports.ProduktionsauftragEntryFromJSONTyped = ProduktionsauftragEntryFromJSONTyped;
function ProduktionsauftragEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_blasen': _1.ProduktionsauftragBlasenToJSON(value.produktionsauftrag_blasen),
        'datum_start': value.datum_start === undefined ? undefined : (value.datum_start.toISOString().substr(0, 10)),
        'datum_ende': value.datum_ende === undefined ? undefined : (value.datum_ende.toISOString().substr(0, 10)),
    };
}
exports.ProduktionsauftragEntryToJSON = ProduktionsauftragEntryToJSON;
