/* eslint-disable react/display-name */
import { LieferterminKennzeichen } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps } from '.';
import { LieferterminEditor } from '../..';

export function makeLieferterminEditorRenderer<TEntityType extends {liefertermin_kennzeichen: LieferterminKennzeichen, liefertermin_soll: Date | null}>(): any {
  return (props: StammdatenFieldEditorComplexRendererProps<TEntityType>) => {
    return (
      <LieferterminEditor
        disabled={props.disabled}
        onChange={ (data) => {
          props.onChange(data as any);
        }}
        value={{
          liefertermin_soll: props.stammdatenEntity.liefertermin_soll,
          liefertermin_kennzeichen: props.stammdatenEntity.liefertermin_kennzeichen,
        }}
      />
    );
  };
}
