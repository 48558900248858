import { MenuItem } from '@blueprintjs/core';
import { IItemRendererProps } from '@blueprintjs/select';
import {
  DefaultDropDownPicker,
  StammdatenFieldEditorComplexRendererProps,
  UseFetchLikeServiceFunctionResult,
  getDefaultDisplayData,
} from '../..';

export function makeDefaultfDropDownItemRenderer<TEntryType>(getDisplayData: getDefaultDisplayData<TEntryType>) {
  // eslint-disable-next-line react/display-name
  return (item: TEntryType, itemProps?: IItemRendererProps): JSX.Element => {
    const displayData = getDisplayData(item);
    return (
      <MenuItem
        label={displayData.secondaryTitle}
        text={displayData.primaryTitle.length ? displayData.primaryTitle : '---'}
        onClick={itemProps?.handleClick}
        key={itemProps?.index}
        selected={itemProps?.modifiers.active}
      />
    );
  };
}

export function makeDefaultDropDownPickerRenderer<TEntityType, TForeignKeyType extends { database_id: number }>(
  dataProvider: UseFetchLikeServiceFunctionResult<Array<TForeignKeyType>>,
  getItemData: getDefaultDisplayData<TForeignKeyType>,
  valueGetter: (item: TEntityType) => TForeignKeyType,
  onChangeValueFormatter: (data: TForeignKeyType | null) => Partial<TEntityType>,
): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorComplexRendererProps<TEntityType>) => {
    return (
      <DefaultDropDownPicker
        dataProvider={dataProvider}
        getDisplayData={getItemData}
        nullable={false}
        disabled={props.disabled}
        value={valueGetter(props.stammdatenEntity)}
        onChange={(value) => props.onChange(onChangeValueFormatter(value))}
      />
    );
  };
}
