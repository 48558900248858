"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WerkstoffApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class WerkstoffApi extends runtime.BaseAPI {
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdWerkstoffDatabaseIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling deleteByDatabaseIdWerkstoffDatabaseIdDelete.');
            }
            if (requestParameters.DeleteRequest === null || requestParameters.DeleteRequest === undefined) {
                throw new runtime.RequiredError('DeleteRequest', 'Required parameter requestParameters.DeleteRequest was null or undefined when calling deleteByDatabaseIdWerkstoffDatabaseIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DeleteRequestToJSON(requestParameters.DeleteRequest),
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdWerkstoffDatabaseIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteByDatabaseIdWerkstoffDatabaseIdDeleteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get All
     */
    getAllWerkstoffGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.include_deleted !== undefined) {
                queryParameters['include_deleted'] = requestParameters.include_deleted;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WerkstoffFromJSON));
        });
    }
    /**
     * Get All
     */
    getAllWerkstoffGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllWerkstoffGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdWerkstoffDatabaseIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getByDatabaseIdWerkstoffDatabaseIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffFromJSON(jsonValue));
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdWerkstoffDatabaseIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getByDatabaseIdWerkstoffDatabaseIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get History
     */
    getHistoryWerkstoffDatabaseIdHistoryGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getHistoryWerkstoffDatabaseIdHistoryGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/{database_id}/history`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WerkstoffFromJSON));
        });
    }
    /**
     * Get History
     */
    getHistoryWerkstoffDatabaseIdHistoryGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHistoryWerkstoffDatabaseIdHistoryGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdWerkstoffDatabaseIdLockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling lockByDatabaseIdWerkstoffDatabaseIdLockPatch.');
            }
            if (requestParameters.LockRequest === null || requestParameters.LockRequest === undefined) {
                throw new runtime.RequiredError('LockRequest', 'Required parameter requestParameters.LockRequest was null or undefined when calling lockByDatabaseIdWerkstoffDatabaseIdLockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/{database_id}/lock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.LockRequestToJSON(requestParameters.LockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffFromJSON(jsonValue));
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdWerkstoffDatabaseIdLockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.lockByDatabaseIdWerkstoffDatabaseIdLockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Post
     */
    postWerkstoffPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.CreateWerkstoff === null || requestParameters.CreateWerkstoff === undefined) {
                throw new runtime.RequiredError('CreateWerkstoff', 'Required parameter requestParameters.CreateWerkstoff was null or undefined when calling postWerkstoffPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CreateWerkstoffToJSON(requestParameters.CreateWerkstoff),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffFromJSON(jsonValue));
        });
    }
    /**
     * Post
     */
    postWerkstoffPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postWerkstoffPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdWerkstoffDatabaseIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling putByDatabaseIdWerkstoffDatabaseIdPut.');
            }
            if (requestParameters.UpdateWerkstoff === null || requestParameters.UpdateWerkstoff === undefined) {
                throw new runtime.RequiredError('UpdateWerkstoff', 'Required parameter requestParameters.UpdateWerkstoff was null or undefined when calling putByDatabaseIdWerkstoffDatabaseIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UpdateWerkstoffToJSON(requestParameters.UpdateWerkstoff),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffFromJSON(jsonValue));
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdWerkstoffDatabaseIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.putByDatabaseIdWerkstoffDatabaseIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdWerkstoffDatabaseIdRestorePatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling restoreByDatabaseIdWerkstoffDatabaseIdRestorePatch.');
            }
            if (requestParameters.RestoreRequest === null || requestParameters.RestoreRequest === undefined) {
                throw new runtime.RequiredError('RestoreRequest', 'Required parameter requestParameters.RestoreRequest was null or undefined when calling restoreByDatabaseIdWerkstoffDatabaseIdRestorePatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/{database_id}/restore`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RestoreRequestToJSON(requestParameters.RestoreRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffFromJSON(jsonValue));
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdWerkstoffDatabaseIdRestorePatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.restoreByDatabaseIdWerkstoffDatabaseIdRestorePatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdWerkstoffDatabaseIdUnlockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling unlockByDatabaseIdWerkstoffDatabaseIdUnlockPatch.');
            }
            if (requestParameters.UnlockRequest === null || requestParameters.UnlockRequest === undefined) {
                throw new runtime.RequiredError('UnlockRequest', 'Required parameter requestParameters.UnlockRequest was null or undefined when calling unlockByDatabaseIdWerkstoffDatabaseIdUnlockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff/{database_id}/unlock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UnlockRequestToJSON(requestParameters.UnlockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffFromJSON(jsonValue));
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdWerkstoffDatabaseIdUnlockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.unlockByDatabaseIdWerkstoffDatabaseIdUnlockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.WerkstoffApi = WerkstoffApi;
