/* eslint-disable react/display-name */
import { MenuItem, Tag } from '@blueprintjs/core';
import { IItemRendererProps } from '@blueprintjs/select';
import { useState } from 'react';
import { Artikel, Firma, Rahmenauftrag } from 'wacoplast_wws__api';
import {
  CommonEditorProps,
  DefaultDropDownPicker,
  DialogFormField,
  DropDownPicker,
  StammdatenFieldEditorComplexRendererProps,
  UseFetchLikeServiceFunctionResult,
} from '../../infrastructure';

export type ArtikelDropDownPickerProps = CommonEditorProps<Artikel | null> & {
  dataProvider: UseFetchLikeServiceFunctionResult<Array<Artikel>>,
  nullable?: boolean,
  filter?: (data: Artikel) => boolean,
};

export function ArtikelDropDownPicker(props: ArtikelDropDownPickerProps): JSX.Element {
  return (
    <DropDownPicker
      {...props}
      itemRenderer={(item: Artikel, itemProps?: IItemRendererProps) => {
        const text = (
          <>
            <Tag minimal={true}>{item.flaschen_form.flaschentyp.nummer}</Tag>{' '}
            <Tag minimal={true}>{item.flaschen_form.flaschentyp.bezeichnung}</Tag>{' '}
            <Tag minimal={true}>{item.granulat_farbe.bezeichnung}</Tag>
            {
              item.empfaenger?.name_kurz && (
                <>
                  {' '}
                  <Tag minimal={true}>{item.empfaenger?.name_kurz}</Tag>
                </>
              )
            }
          </>
        );
        return (
          <MenuItem
            text={item.nummer}
            labelElement={text}
            onClick={itemProps?.handleClick}
            key={itemProps?.index}
            selected={itemProps?.modifiers.active}
          />
        );
      }}
      valueRenderer={(item) => {
        return (
          <>
            {item.nummer}{' '}
            <Tag minimal={true}>{item.flaschen_form.flaschentyp.nummer}</Tag>{' '}
            <Tag minimal={true}>{item.flaschen_form.flaschentyp.bezeichnung}</Tag>{' '}
            <Tag minimal={true}>{item.granulat_farbe.bezeichnung}</Tag>
            {
              item.empfaenger?.name_kurz && (
                <>
                  {' '}
                  <Tag minimal={true}>{item.empfaenger?.name_kurz}</Tag>
                </>
              )
            }
          </>
        );
      }}
      filter={(query, data) => {
        const matchesNumber = data.nummer.toLowerCase().includes(query.toLowerCase());
        const matchesTypeNumber = data.flaschen_form.flaschentyp.nummer.toLowerCase().includes(query.toLowerCase());
        const matchesTypeName = data.flaschen_form.flaschentyp.bezeichnung.toLowerCase().includes(query.toLowerCase());
        const matchesColor = data.granulat_farbe.bezeichnung.toLowerCase().includes(query.toLowerCase());
        const matchesEmpfaenger = data.empfaenger ? data.empfaenger.name_kurz.toLowerCase().includes(query.toLowerCase()) : false;
        const matchesCustomFilter = props.filter ? props.filter(data) : true;
        return (matchesNumber || matchesTypeNumber || matchesTypeName || matchesColor || matchesEmpfaenger) && matchesCustomFilter;
      }}
    />
  );
}

export function makeArtikelDropDownPickerRenderer(
  artikelDataProvider: UseFetchLikeServiceFunctionResult<Array<Artikel>>,
  kundenDataProvider?: UseFetchLikeServiceFunctionResult<Array<Firma>>,
  nullable = false,
): any {
  return (props: StammdatenFieldEditorComplexRendererProps<Rahmenauftrag>) => {
    const preloadArtikel = props.stammdatenEntity.artikel;
    const artikel = preloadArtikel ? preloadArtikel : artikelDataProvider.data?.find((entry) => entry.database_id === props.stammdatenEntity.artikel_database_id);
    const [kunde, setKunde] = useState<Firma | null>(artikel?.kunde ?? null);
    return (
      <>
        {kundenDataProvider &&
          <DialogFormField
            fieldLocation=''
            fieldLabel='Kunde'
          >
            <DefaultDropDownPicker
              dataProvider={kundenDataProvider}
              getDisplayData={(data) => {
                return { primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
              }}
              disabled={props.disabled}
              value={kunde}
              onChange={(value) => {
                setKunde(value);
                props.onChange({
                  artikel_database_id: null,
                  artikel_version_id: null,
                } as any);
              }}
            />
          </DialogFormField>
        }
        <DialogFormField
          fieldLocation=''
          fieldLabel='Artikel'
          style={{ margin: 0 }}
        >
          <ArtikelDropDownPicker
            filter={(data) => data.kunde.database_id === kunde?.database_id}
            dataProvider={artikelDataProvider}
            nullable={nullable}
            disabled={props.disabled || kunde === null}
            value={artikel ?? null}
            onChange={(value) => props.onChange({
              artikel_database_id: value?.database_id ?? null,
              artikel_version_id: value?.version_id ?? null,
            } as any)}
          />
        </DialogFormField>
      </>
    );
  };
}
