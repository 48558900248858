import { Button, Dialog, H2 } from '@blueprintjs/core';
import { RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultToJSON } from 'wacoplast_wws__api';
import { CustomFormProps, DialogBody, DialogFooter } from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungDurchTechnischesProblemUnterbrochenErfragen(
  props: CustomFormProps<{ anzahl_teilrueckmeldungen?: number }>,
): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
    >
      <DialogBody>
        <H2>Wurde die Produktion durch ein technisches Problem unterbrochen?</H2>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => props.finishUserTask(RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultToJSON({ technische_unterbrechung: true }))}>Ja</Button>
        <Button onClick={() => props.finishUserTask(RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultToJSON({ technische_unterbrechung: false }))}>Nein</Button>
      </DialogFooter>
    </Dialog>
  );
}
