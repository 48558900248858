"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateKundenauftragToJSON = exports.UpdateKundenauftragFromJSONTyped = exports.UpdateKundenauftragFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UpdateKundenauftragFromJSON(json) {
    return UpdateKundenauftragFromJSONTyped(json, false);
}
exports.UpdateKundenauftragFromJSON = UpdateKundenauftragFromJSON;
function UpdateKundenauftragFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': json['nummer'],
        'bestell_nummer': json['bestell_nummer'],
        'auftragsnummer_des_empfaenger': !runtime_1.exists(json, 'auftragsnummer_des_empfaenger') ? undefined : json['auftragsnummer_des_empfaenger'],
        'datum_erfassung': (new Date(json['datum_erfassung'])),
        'datum_eingang': (new Date(json['datum_eingang'])),
        'status': _1.KundenauftragStatusFromJSON(json['status']),
        'rahmenauftrag_database_id': !runtime_1.exists(json, 'rahmenauftrag_database_id') ? undefined : json['rahmenauftrag_database_id'],
        'rahmenauftrag_version_id': !runtime_1.exists(json, 'rahmenauftrag_version_id') ? undefined : json['rahmenauftrag_version_id'],
        'auftragsart': _1.KundenauftragArtFromJSON(json['auftragsart']),
        'menge': json['menge'],
        'liefertermin_soll': !runtime_1.exists(json, 'liefertermin_soll') ? undefined : (new Date(json['liefertermin_soll'])),
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenFromJSON(json['liefertermin_kennzeichen']),
        'artikel_database_id': json['artikel_database_id'],
        'artikel_version_id': json['artikel_version_id'],
        'process_correlation_id': !runtime_1.exists(json, 'process_correlation_id') ? undefined : json['process_correlation_id'],
    };
}
exports.UpdateKundenauftragFromJSONTyped = UpdateKundenauftragFromJSONTyped;
function UpdateKundenauftragToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'bestell_nummer': value.bestell_nummer,
        'auftragsnummer_des_empfaenger': value.auftragsnummer_des_empfaenger,
        'datum_erfassung': (value.datum_erfassung.toISOString()),
        'datum_eingang': (value.datum_eingang.toISOString()),
        'status': _1.KundenauftragStatusToJSON(value.status),
        'rahmenauftrag_database_id': value.rahmenauftrag_database_id,
        'rahmenauftrag_version_id': value.rahmenauftrag_version_id,
        'auftragsart': _1.KundenauftragArtToJSON(value.auftragsart),
        'menge': value.menge,
        'liefertermin_soll': value.liefertermin_soll === undefined ? undefined : (value.liefertermin_soll.toISOString()),
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenToJSON(value.liefertermin_kennzeichen),
        'artikel_database_id': value.artikel_database_id,
        'artikel_version_id': value.artikel_version_id,
        'process_correlation_id': value.process_correlation_id,
    };
}
exports.UpdateKundenauftragToJSON = UpdateKundenauftragToJSON;
