import { H3 } from '@blueprintjs/core';
import { FormCardProps } from './FormCard';
import styles from './FormCard.module.scss';

export type TwoPanelFormCardProps = FormCardProps & {
  sidepanel: JSX.Element
};

export const SidepanelFormCard = (props: TwoPanelFormCardProps): JSX.Element => {
  return (
    <div className={styles.form_card}>
      <div className={styles.two_panel_form_card_content}>
        <div>
          <H3>{props.title}</H3>
          <div className={styles.form_card__body}>
            {props.children}
          </div>
          <div className={styles.form_card__footer}>
            {props.footer}
          </div>
        </div>
        <div className={styles.two_panel_form_card__seperator} />
        <div>
          {props.sidepanel}
        </div>
      </div>
    </div>
  );
};
