import { CreateFlaschenTyp, FlaschenTyp } from 'wacoplast_wws__api';
import {
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { makeTextEditorWithSublabelForBLZRenderer } from './';
import { defaultColumnState } from './defaultColumnConfiguration';

export type FlaschenTypVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function FlaschenTypVerwalten(props: FlaschenTypVerwaltenProps): JSX.Element {

  const blindenzeichenResult = useFetchLikeGetAllServiceFunction(props.services.blindenzeichen.getAllBlindenzeichenGet, props.services.blindenzeichen);

  const editors: StammdatenFieldEditors<CreateFlaschenTyp> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorWithSublabelForBLZRenderer(blindenzeichenResult),
      label: 'Nummer',
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
    {
      type: 'simple',
      field: 'volumen',
      renderer: makeNumberEditorRenderer({ formatDecimalSeparator: true }),
      label: 'Volumen [ml]',
    },
    {
      type: 'complex',
      fields: ['blindenzeichen_database_id', 'blindenzeichen_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(blindenzeichenResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'blindenzeichen'),
      label: 'Blindenzeichen',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Bezeichnung',
          field: 'bezeichnung',
          colId: 'bezeichnung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Volumen [ml]',
          field: 'volumen',
          colId: 'volumen',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Blindenzeichen',
          colId: 'blinden_zeichen',
          ...makeForeignKeyColumn('blindenzeichen', (data: FlaschenTyp) => {
            return {
              description: data.blindenzeichen.bezeichnung,
              number: data.blindenzeichen.nummer,
            };
          }),
        },
      ]}
      stammdatenService={{
        create: (data: CreateFlaschenTyp) => props.services.flaschenTyp.postFlaschenTypPost({ CreateFlaschenTyp: data }),
        update: (data) => props.services.flaschenTyp.putByDatabaseIdFlaschenTypDatabaseIdPut({ database_id: data.database_id, UpdateFlaschenTyp: data }),
        delete: (data, ignoreIntegrity) => props.services.flaschenTyp.deleteByDatabaseIdFlaschenTypDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.flaschenTyp.restoreByDatabaseIdFlaschenTypDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.flaschenTyp.getAllFlaschenTypGet({ include_deleted }),
        getHistory: (data) => props.services.flaschenTyp.getHistoryFlaschenTypDatabaseIdHistoryGet({ database_id: data.database_id }),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.Flaschentyp}
    />
  );
}
