"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.KundenauftragUpdateStatusPayloadToJSON = exports.KundenauftragUpdateStatusPayloadFromJSONTyped = exports.KundenauftragUpdateStatusPayloadFromJSON = void 0;
const _1 = require("./");
function KundenauftragUpdateStatusPayloadFromJSON(json) {
    return KundenauftragUpdateStatusPayloadFromJSONTyped(json, false);
}
exports.KundenauftragUpdateStatusPayloadFromJSON = KundenauftragUpdateStatusPayloadFromJSON;
function KundenauftragUpdateStatusPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'kundenauftrag_database_id': json['kundenauftrag_database_id'],
        'status': _1.KundenauftragStatusFromJSON(json['status']),
    };
}
exports.KundenauftragUpdateStatusPayloadFromJSONTyped = KundenauftragUpdateStatusPayloadFromJSONTyped;
function KundenauftragUpdateStatusPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'kundenauftrag_database_id': value.kundenauftrag_database_id,
        'status': _1.KundenauftragStatusToJSON(value.status),
    };
}
exports.KundenauftragUpdateStatusPayloadToJSON = KundenauftragUpdateStatusPayloadToJSON;
