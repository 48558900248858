import { HTMLTable } from '@blueprintjs/core';
import { Blasform } from 'wacoplast_wws__api';
import { NumberEditor, StammdatenFieldEditorComplexRendererProps } from '../../../infrastructure';

export function makeNestNummernEditorRenderer(): any {
  const nester = [[1, 2, 3, 4, 5], [6, 7, 8, 9, 10]];
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorComplexRendererProps<Blasform>) => {
    return (
      nester.map((nester_row, index) => {
        return (
          <HTMLTable bordered condensed key={index}>
            <thead>
              <tr>
                {
                  nester_row.map((nest) => (
                    <th key={nest}>Nest {nest}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                {
                  nester_row.map((nest) => {
                    const fieldName = `nest_nummer_${nest}` as keyof Blasform;
                    return (
                      <td key={nest}>
                        <NumberEditor
                          value={props.stammdatenEntity[fieldName] as number ?? 0}
                          disabled={props.disabled || (props.stammdatenEntity.anzahl_kavitaeten ?? 0) < nest}
                          onChange={(value) => props.onChange({ [fieldName]: value ?? undefined })}
                        />
                      </td>
                    );
                  })
                }
              </tr>
            </tbody>
          </HTMLTable>
        );
      })
    );
  };
}
