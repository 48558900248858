import { HTTPValidationError } from 'wacoplast_wws__api';
import { validationErrorTypeToMessage } from './validationErrorTypeToMessage';
import { ValidationErrorWithContext } from './ValidationErrorWithContext';

export function mapErrorMessageToGerman(errors: HTTPValidationError): HTTPValidationError {
  return { detail: errors.detail?.map?.((error: ValidationErrorWithContext) => {
    const mappedError: ValidationErrorWithContext = {
      msg: validationErrorTypeToMessage(error) ?? error.msg,
      loc: error.loc,
      type: error.loc[1] ?? error.type,
    };
    return mappedError;
  }) ?? errors.detail,
  };
}
