import { DataModels } from '@atlas-engine/atlas_engine_client';
import { Button, Dialog, Spinner } from '@blueprintjs/core';
import React from 'react';
import {
  RueckmeldungAnzahlNesterErfragen,
  RueckmeldungDurchTechnischesProblemUnterbrochenErfragen,
  RueckmeldungIstAbgeschlossenHinweis,
  RueckmeldungIstEnddatumKorrektAbfragen,
  RueckmeldungIstLetzteTeilrueckmeldungAbfragen,
  RueckmeldungIstNichtLetzteTeilrueckmeldungHinweis,
  RueckmeldungIstTeilrueckmeldungAbfragen,
  RueckmeldungMengeErfassenKeineUnterbrechung,
  RueckmeldungMengeErfassenMitUnterbrechung,
  RueckmeldungProudktionswerteValidieren,
} from '../..';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  IdentityProvider,
  ModalProps,
  PropsWithServices,
  PropsWithTransaction,
  RenderSequentialUserTasks,
  withBoundProps,
} from '../../../infrastructure';
import { ProduktionsauftragsDatenAnzeigen } from '../../produktionsauftrag/blasen/custom_forms/ProduktionsauftragsDatenAnzeigen';
import { PlantafelProduktionsauftragEntry } from '../PlantafelProduktionsauftragEntry';




export type PlantafelRueckmeldungModalResult = {
  rueckmeldung_erfolgreich: boolean
};


export type PlantafelRueckmeldungModalProps = PropsWithTransaction<PropsWithServices<ModalProps<PlantafelRueckmeldungModalResult>>> &
{
  plantafelEntry: PlantafelProduktionsauftragEntry;
  getIdentity: IdentityProvider;
};

export class PlantafelRueckmeldungModal extends React.Component<PlantafelRueckmeldungModalProps> {

  private abort = (): void => {
    this.props.onModalFinished({ rueckmeldung_erfolgreich: false });
  };

  private finish = (): void => {
    this.props.onModalFinished({ rueckmeldung_erfolgreich: true });
  };

  public getComponentForCustomForm = (userTask: DataModels.FlowNodeInstances.UserTaskInstance): React.ComponentType<CustomFormProps> | null => {
    const customForm = userTask.userTaskConfig.customForm;

    if (customForm === 'wacoplast-wws.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragen') {
      return RueckmeldungIstTeilrueckmeldungAbfragen;
    }

    if (customForm === 'wacoplast-wws.RückmeldungIstDatumEndeKorrekt') {
      return withBoundProps(RueckmeldungIstEnddatumKorrektAbfragen as any, this.props);
    }

    if (customForm === 'wacoplast-wws.RückmeldungAnzahlNesterErfragen') {
      return RueckmeldungAnzahlNesterErfragen;
    }

    if (customForm === 'wacoplast-wws.RückmeldungProduktionswerteKorrekt') {
      return RueckmeldungProudktionswerteValidieren;
    }

    if (customForm === 'wacoplast-wws.RückmeldungProduktionWurdeTechnischUnterbrochen') {
      return RueckmeldungDurchTechnischesProblemUnterbrochenErfragen;
    }

    if (customForm === 'wacoplast-wws.RückmeldungMengenErfassenKeineUnterbrechung') {
      return RueckmeldungMengeErfassenKeineUnterbrechung;
    }

    if (customForm === 'wacoplast-wws.RückmeldungIstAbgeschlossenHinweis') {
      return withBoundProps(RueckmeldungIstAbgeschlossenHinweis, { finish: this.finish });
    }

    if (customForm === 'wacoplast-wws.RückmeldungMengeErfassenMitUnterbrechung') {
      return RueckmeldungMengeErfassenMitUnterbrechung;
    }

    if (customForm === 'wacoplast-wws.RückmeldungIstLetzteTeilrückmeldungAbfragen') {
      return RueckmeldungIstLetzteTeilrueckmeldungAbfragen;
    }

    if (customForm === 'wacoplast-wws.RückmeldungIstNichtLetzteTeilrückmeldungHinweis') {
      return withBoundProps(RueckmeldungIstNichtLetzteTeilrueckmeldungHinweis, { finish: this.finish });
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragsdatenAnzeigen') {
      return ProduktionsauftragsDatenAnzeigen;
    }

    return null;
  };


  public render(): JSX.Element | null {
    if (!this.props.plantafelEntry.produktionsauftrag_blasen.kundenauftrag.process_correlation_id) {
      return null;
    }

    return (
      <RenderSequentialUserTasks
        atlasEngineClient={this.props.atlasEngineClient}
        correlationId={this.props.plantafelEntry.produktionsauftrag_blasen.kundenauftrag.process_correlation_id}
        getIdentity={this.props.getIdentity}
        getComponent={this.getComponentForCustomForm}
        handleUserTaskAborted={(api, userTask, result) => {
          this.abort();
          return api.finishUserTask(result ?? { response: 'abort' });
        }}
      >
        <Dialog
          isOpen={true}
          canOutsideClickClose={false}
          isCloseButtonShown={false}
          canEscapeKeyClose={false}
          title='Einen Moment bitte'
        >
          <DialogBody>
            <Spinner />
          </DialogBody>
          <DialogFooter>
            <Button onClick={this.abort} intent='warning'>Ausblenden</Button>
          </DialogFooter>
        </Dialog>

      </RenderSequentialUserTasks>
    );
  }
}
