import { Dialog, Spinner, SpinnerSize } from '@blueprintjs/core';

import styles from './TransactionLoadingModal.module.scss';
import { DialogBody } from '../components/Dialog';

export type TransactionLoadingModalProps = {
  loadingMessage?: string;
}

export function TransactionLoadingModal(props: TransactionLoadingModalProps): JSX.Element {
  return (
    <Dialog isOpen={true} isCloseButtonShown={false} canEscapeKeyClose={false} canOutsideClickClose={false}>
      <DialogBody>
        <div className={styles.loadingIndicator}>
          <Spinner size={SpinnerSize.LARGE} />
          <span>{props.loadingMessage ?? 'Daten werden geladen...'}</span>
        </div>
      </DialogBody>
    </Dialog>
  );
}
