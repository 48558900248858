import { GranulatFarbe, GranulatFarbeBestand } from 'wacoplast_wws__api';
import { PropsWithServices, StammdatenHistoryModal } from '../../infrastructure';

export function GranulatFarbeBestandHistoryModal(props: PropsWithServices<{ entity: GranulatFarbe, onModalFinished: () => void }>): JSX.Element {
  return (
    <StammdatenHistoryModal
      entity={{ granulat_farbe_database_id: props.entity.database_id } as GranulatFarbeBestand}
      columnDefs={[
        {
          headerName: 'Bestand [kg]',
          field: 'bestand',
          colId: 'bestand',
          filter: 'agNumberColumnFilter',
        }]}
      hideDefaultColumns={['changed_by_user_name', 'deletion_state']}
      dialogTitle={`Bestandshistorie zu GranulatFarbe: ${props.entity.bezeichnung} (${props.entity.nummer})`}
      onModalFinished={props.onModalFinished}
      getHistory={async (entity) => await props.services.granulatFarben.getBestandHistoryGranulatFarbeBestandHistoryDatabaseIdGet({ database_id: entity.granulat_farbe_database_id })}
    />
  );
}
