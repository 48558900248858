import { MaterialGruppe, VerpackungsEinheit, VerpackungsVorschrift } from 'wacoplast_wws__api';
import {
  StammdatenFieldEditorSimpleRendererProps,
  UseFetchLikeServiceFunctionResult,
  makeStammdatenEntityRelatedDisabledEditor,
  makeSwitchEditorRenderer,
} from '../../../infrastructure';

export function makeSpezialfallKartonReduktionEditor(dataProvider: UseFetchLikeServiceFunctionResult<Array<VerpackungsEinheit>>): any {
  // eslint-disable-next-line react/display-name
  const SwitchEditor = (props: StammdatenFieldEditorSimpleRendererProps<VerpackungsVorschrift, number | null>): JSX.Element => {
    if (props.value !== null && !isSpezialfallPermitted(props.stammdatenEntity, dataProvider)) {
      props.onChange(null);
    }

    const SwitchComponent = makeSwitchEditorRenderer();

    return (
      <SwitchComponent
        disabled={props.disabled}
        onChange={(value: boolean) => props.onChange(value ? 1 : 0)}
        stammdatenEntity={props.stammdatenEntity}
        value={props.value ? true : false}
      />
    );
  };

  return makeStammdatenEntityRelatedDisabledEditor(
    SwitchEditor,
    data => !isSpezialfallPermitted(data, dataProvider));
}

function isSpezialfallPermitted(data: VerpackungsVorschrift, dataProvider: UseFetchLikeServiceFunctionResult<Array<VerpackungsEinheit>>): boolean {
  const verpackungsEinheit = dataProvider.data?.find(v => v.database_id === data.verpackungs_einheit_database_id);
  return verpackungsEinheit !== undefined && [MaterialGruppe.NUMBER_10, MaterialGruppe.NUMBER_30].includes(verpackungsEinheit.material_gruppe);
}
