import {
  Button,
  Dialog,
  H6,
  Intent,
} from '@blueprintjs/core';

import React from 'react';
import { HTTPValidationError, RestoreRequest } from 'wacoplast_wws__api';
import {
  AlertErrorRenderer,
  DialogBody,
  DialogFooter,
  DialogFormField,
  ModalProps,
  getErrorDescription,
  makeTextAreaEditorRenderer,
  removeFieldFromValidationError,
} from '../../infrastructure';
import { StammdatenFieldEditors } from '.';

import styles from './StammdatenModal.module.scss';

export type StammdatenModalResult = boolean;

export type StammdatenModalProps<TEntityType, TCreateEntityType, TEntityTypeUpdate = TEntityType & RestoreRequest> = ModalProps<StammdatenModalResult> & {
  stammdatenEntity: TEntityType;
  fields: StammdatenFieldEditors<TCreateEntityType>;
  onDialogConfirmed: (stammdatenEntity: TEntityTypeUpdate, ignoreIntegrity?: boolean) => Promise<any>;
  dialogTitle: string;
  primaryButtonTitle: string;
  primaryButtonIntent: Intent;
  hideAbortButton?: boolean;
  formFieldsDisabled?: 'all' | 'notEditable';
  hideChangingReason?: boolean;
  className?: string;
};

export type StammdatenModalState<TEntityType> = {
  lastValidationResponse?: HTTPValidationError | undefined | null;
  error?: any;
  loading: boolean;
  stammdatenEntity: TEntityType;
};

export class StammdatenModal<TEntityType extends TCreateEntityType, TCreateEntityType> extends React.Component<StammdatenModalProps<TEntityType, TCreateEntityType>, StammdatenModalState<TEntityType & RestoreRequest>> {

  constructor(props: StammdatenModalProps<TEntityType, TCreateEntityType>) {
    super(props);

    this.state = {
      loading: false,
      stammdatenEntity: {
        ...props.stammdatenEntity,
        aenderungsgrund: undefined as any,
      },
    };
  }

  private onFieldChange(partialUpdate: Partial<TCreateEntityType>): void {
    const stammdatenEntity = { ...this.state.stammdatenEntity, ...partialUpdate };

    const updatedValidationResponse = Object.keys(partialUpdate).reduce((validationResponse, updatedKey) => removeFieldFromValidationError(validationResponse, updatedKey), this.state.lastValidationResponse);

    this.setState({
      lastValidationResponse: updatedValidationResponse,
      stammdatenEntity,
    });
  }

  private async onPrimaryButtonClick(ignoreIntegrity?: boolean): Promise<void> {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
      error: undefined,
      lastValidationResponse: undefined,
    });

    try {
      // TODO (ph): Only save if there are changes and only send changed fields.
      await this.props.onDialogConfirmed(this.state.stammdatenEntity, ignoreIntegrity);
      this.props.onModalFinished(true);
      return;

    } catch (error: any) {
      if (error.status === 422) {
        const httpValidationError = await error.json();
        this.setState({
          lastValidationResponse: httpValidationError,
          loading: false,
        });
      } else {
        const errorBody = error.json ? await error.json() : undefined;
        this.setState({
          error: errorBody?.detail ? errorBody : error,
          loading: false,
        });

        console.error('unhandled error', error);
      }
    }
  }

  private abort = (): void => {
    this.props.onModalFinished(false);
  };

  public render(): JSX.Element {

    const errorDetails = this.state.error ? getErrorDescription(this.state.error) : null;
    const isIntegrityError = errorDetails?.summary === 'Eintrag noch in Verwendung';

    return (
      <Dialog isOpen={true} onClose={this.abort} title={this.props.dialogTitle}>
        <DialogBody>
          { isIntegrityError &&
            <Dialog isOpen onClose={this.abort} title={errorDetails.summary}>
              <DialogBody>{errorDetails.details} Der Eintrag kann nicht gelöscht werden.</DialogBody>
              <DialogFooter>
                <Button onClick={this.abort}>Ok</Button>
              </DialogFooter>
            </Dialog>
          }
          { this.state.error && !isIntegrityError && <AlertErrorRenderer error={this.state.error} /> }
          { this.renderEditors(this.props.fields, this.props.className) }
          {
            !this.props.hideChangingReason &&
            <DialogFormField
              fieldLocation={['body', 'aenderungsgrund']}
              lastValidationResponse={this.state.lastValidationResponse}
              fieldLabel='Änderungsgrund'
            >
              {
                makeTextAreaEditorRenderer()({
                  disabled: this.state.loading,
                  value: this.state.stammdatenEntity.aenderungsgrund,
                  onChange: (value: string) => this.onFieldChange({ aenderungsgrund: value } as any),
                })
              }
            </DialogFormField>
          }
        </DialogBody>
        <DialogFooter>
          <Button
            loading={this.state.loading}
            intent={this.props.primaryButtonIntent}
            onClick={() => this.onPrimaryButtonClick()}
          >
            {this.props.primaryButtonTitle}
          </Button>
          {!this.props.hideAbortButton &&
            <>
              {' '}
              <Button
                disabled={this.state.loading}
                onClick={() => this.abort()}
              >
                Abbrechen
              </Button>
            </>
          }
        </DialogFooter>
      </Dialog>
    );
  }

  private renderEditors(fields: StammdatenFieldEditors<TCreateEntityType>, className?: string): JSX.Element {
    return (
      <div className={className}>
        {
          fields.map((field, index) => {
            if (field.type === 'label') {
              return <H6 key={index} className={styles.label_field_editor} style={{ gridArea: field.gridArea }}>{field.label}</H6>;
            }

            const fieldLocation = field.type === 'simple'
              ? field.field as string
              : field.fields.map((field) => ['body', String(field)]);

            const gridArea = field.gridArea ?? (field.type === 'simple' ? field.field : field.fields[0]) as string;

            const disabled = this.props.formFieldsDisabled === 'all' || this.state.loading || (this.props.formFieldsDisabled === 'notEditable' && field.notEditable);

            return (
              <DialogFormField
                key={index}
                fieldLocation={fieldLocation}
                lastValidationResponse={field.renderValidationResult === false ? undefined : this.state.lastValidationResponse}
                fieldLabel={field.label}
                style={{ gridArea: gridArea }}
              >
                {
                  field.type === 'simple' && (
                    <field.renderer
                      disabled={disabled}
                      key={index}
                      stammdatenEntity={this.state.stammdatenEntity}
                      value={this.state.stammdatenEntity[field.field]}
                      onChange={(value) => this.onFieldChange({ [field.field]: value } as any)}
                    />
                  )
                }
                {
                  field.type === 'complex' && (
                    <field.renderer
                      disabled={disabled}
                      key={index}
                      stammdatenEntity={this.state.stammdatenEntity}
                      onChange={(value) => this.onFieldChange(value)}
                    />
                  )
                }
              </DialogFormField>
            );
          }).flat()}
      </div>
    );
  }
}

