"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RueckmeldungMengeErfassenMitUnterbrechungResultToJSON = exports.RueckmeldungMengeErfassenMitUnterbrechungResultFromJSONTyped = exports.RueckmeldungMengeErfassenMitUnterbrechungResultFromJSON = void 0;
const _1 = require("./");
function RueckmeldungMengeErfassenMitUnterbrechungResultFromJSON(json) {
    return RueckmeldungMengeErfassenMitUnterbrechungResultFromJSONTyped(json, false);
}
exports.RueckmeldungMengeErfassenMitUnterbrechungResultFromJSON = RueckmeldungMengeErfassenMitUnterbrechungResultFromJSON;
function RueckmeldungMengeErfassenMitUnterbrechungResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsphasen': (json['produktionsphasen'].map(_1.ProduktionsphaseFromJSON)),
    };
}
exports.RueckmeldungMengeErfassenMitUnterbrechungResultFromJSONTyped = RueckmeldungMengeErfassenMitUnterbrechungResultFromJSONTyped;
function RueckmeldungMengeErfassenMitUnterbrechungResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsphasen': (value.produktionsphasen.map(_1.ProduktionsphaseToJSON)),
    };
}
exports.RueckmeldungMengeErfassenMitUnterbrechungResultToJSON = RueckmeldungMengeErfassenMitUnterbrechungResultToJSON;
