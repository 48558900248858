"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateFlaschenTypToJSON = exports.UpdateFlaschenTypFromJSONTyped = exports.UpdateFlaschenTypFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateFlaschenTypFromJSON(json) {
    return UpdateFlaschenTypFromJSONTyped(json, false);
}
exports.UpdateFlaschenTypFromJSON = UpdateFlaschenTypFromJSON;
function UpdateFlaschenTypFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'bezeichnung': json['bezeichnung'],
        'volumen': json['volumen'],
        'blindenzeichen_database_id': json['blindenzeichen_database_id'],
        'blindenzeichen_version_id': json['blindenzeichen_version_id'],
    };
}
exports.UpdateFlaschenTypFromJSONTyped = UpdateFlaschenTypFromJSONTyped;
function UpdateFlaschenTypToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'bezeichnung': value.bezeichnung,
        'volumen': value.volumen,
        'blindenzeichen_database_id': value.blindenzeichen_database_id,
        'blindenzeichen_version_id': value.blindenzeichen_version_id,
    };
}
exports.UpdateFlaschenTypToJSON = UpdateFlaschenTypToJSON;
