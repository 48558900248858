"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadToJSON = exports.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSONTyped = exports.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSON = void 0;
function ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSON(json) {
    return ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSON = ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSON;
function ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_database_id': json['produktionsauftrag_database_id'],
        'verpackungs_vorschrift_database_id': json['verpackungs_vorschrift_database_id'],
        'verpackungs_vorschrift_version_id': json['verpackungs_vorschrift_version_id'],
    };
}
exports.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSONTyped = ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSONTyped;
function ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_database_id': value.produktionsauftrag_database_id,
        'verpackungs_vorschrift_database_id': value.verpackungs_vorschrift_database_id,
        'verpackungs_vorschrift_version_id': value.verpackungs_vorschrift_version_id,
    };
}
exports.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadToJSON = ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadToJSON;
