"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersionedSchemaToJSON = exports.VersionedSchemaFromJSONTyped = exports.VersionedSchemaFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function VersionedSchemaFromJSON(json) {
    return VersionedSchemaFromJSONTyped(json, false);
}
exports.VersionedSchemaFromJSON = VersionedSchemaFromJSON;
function VersionedSchemaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
    };
}
exports.VersionedSchemaFromJSONTyped = VersionedSchemaFromJSONTyped;
function VersionedSchemaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'aenderungsgrund': value.aenderungsgrund,
    };
}
exports.VersionedSchemaToJSON = VersionedSchemaToJSON;
