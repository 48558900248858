"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackordnungToJSON = exports.PackordnungFromJSONTyped = exports.PackordnungFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PackordnungFromJSON(json) {
    return PackordnungFromJSONTyped(json, false);
}
exports.PackordnungFromJSON = PackordnungFromJSON;
function PackordnungFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'flaschen_form_database_id': json['flaschen_form_database_id'],
        'flaschen_form_version_id': json['flaschen_form_version_id'],
        'verpackungs_einheit_database_id': json['verpackungs_einheit_database_id'],
        'verpackungs_einheit_version_id': json['verpackungs_einheit_version_id'],
        'prioritaet': !runtime_1.exists(json, 'prioritaet') ? undefined : json['prioritaet'],
        'anzahl_flaschen': json['anzahl_flaschen'],
        'fuellweise': !runtime_1.exists(json, 'fuellweise') ? undefined : json['fuellweise'],
        'packvorschrift_file_hash': !runtime_1.exists(json, 'packvorschrift_file_hash') ? undefined : json['packvorschrift_file_hash'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'database_id': json['database_id'],
        'nummer': json['nummer'],
        'flaschen_form': !runtime_1.exists(json, 'flaschen_form') ? undefined : _1.FlaschenFormFromJSON(json['flaschen_form']),
        'verpackungs_einheit': _1.VerpackungsEinheitFromJSON(json['verpackungs_einheit']),
        'packvorschrift_file': !runtime_1.exists(json, 'packvorschrift_file') ? undefined : _1.FileMetadataFromJSON(json['packvorschrift_file']),
    };
}
exports.PackordnungFromJSONTyped = PackordnungFromJSONTyped;
function PackordnungToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'flaschen_form_database_id': value.flaschen_form_database_id,
        'flaschen_form_version_id': value.flaschen_form_version_id,
        'verpackungs_einheit_database_id': value.verpackungs_einheit_database_id,
        'verpackungs_einheit_version_id': value.verpackungs_einheit_version_id,
        'prioritaet': value.prioritaet,
        'anzahl_flaschen': value.anzahl_flaschen,
        'fuellweise': value.fuellweise,
        'packvorschrift_file_hash': value.packvorschrift_file_hash,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'aenderungsgrund': value.aenderungsgrund,
        'database_id': value.database_id,
        'nummer': value.nummer,
        'flaschen_form': _1.FlaschenFormToJSON(value.flaschen_form),
        'verpackungs_einheit': _1.VerpackungsEinheitToJSON(value.verpackungs_einheit),
        'packvorschrift_file': _1.FileMetadataToJSON(value.packvorschrift_file),
    };
}
exports.PackordnungToJSON = PackordnungToJSON;
