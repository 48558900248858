"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportwegToJSON = exports.TransportwegFromJSONTyped = exports.TransportwegFromJSON = exports.Transportweg = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var Transportweg;
(function (Transportweg) {
    Transportweg["BLDR"] = "BLDR";
    Transportweg["BLKU"] = "BLKU";
    Transportweg["DRKU"] = "DRKU";
})(Transportweg = exports.Transportweg || (exports.Transportweg = {}));
function TransportwegFromJSON(json) {
    return TransportwegFromJSONTyped(json, false);
}
exports.TransportwegFromJSON = TransportwegFromJSON;
function TransportwegFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransportwegFromJSONTyped = TransportwegFromJSONTyped;
function TransportwegToJSON(value) {
    return value;
}
exports.TransportwegToJSON = TransportwegToJSON;
