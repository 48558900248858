"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EinzelDoppelToJSON = exports.EinzelDoppelFromJSONTyped = exports.EinzelDoppelFromJSON = exports.EinzelDoppel = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var EinzelDoppel;
(function (EinzelDoppel) {
    EinzelDoppel["E"] = "E";
    EinzelDoppel["D"] = "D";
})(EinzelDoppel = exports.EinzelDoppel || (exports.EinzelDoppel = {}));
function EinzelDoppelFromJSON(json) {
    return EinzelDoppelFromJSONTyped(json, false);
}
exports.EinzelDoppelFromJSON = EinzelDoppelFromJSON;
function EinzelDoppelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.EinzelDoppelFromJSONTyped = EinzelDoppelFromJSONTyped;
function EinzelDoppelToJSON(value) {
    return value;
}
exports.EinzelDoppelToJSON = EinzelDoppelToJSON;
