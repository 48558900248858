"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportGlueTypeToJSON = exports.ExportGlueTypeFromJSONTyped = exports.ExportGlueTypeFromJSON = void 0;
const _1 = require("./");
function ExportGlueTypeFromJSON(json) {
    return ExportGlueTypeFromJSONTyped(json, false);
}
exports.ExportGlueTypeFromJSON = ExportGlueTypeFromJSON;
function ExportGlueTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'p0': _1.SubscriptionEventFromJSON(json['p0']),
        'p1': _1.BpmnValidationErrorFromJSON(json['p1']),
        'p2': _1.VersionedSchemaFromJSON(json['p2']),
        'p3': _1.RestoreRequestFromJSON(json['p3']),
        'p4': _1.DeleteRequestFromJSON(json['p4']),
        'p5': _1.LockRequestFromJSON(json['p5']),
        'p6': _1.UnlockRequestFromJSON(json['p6']),
        'p7': _1.RahmenauftragIncreaseStatusPayloadFromJSON(json['p7']),
        'p8': _1.RahmenauftragDecreaseStatusPayloadFromJSON(json['p8']),
        'p9': _1.KundenauftragDetailsAngebenResultFromJSON(json['p9']),
        'p10': _1.KundenauftragErstellenPayloadFromJSON(json['p10']),
        'p11': _1.KundenauftragUpdateStatusPayloadFromJSON(json['p11']),
        'p12': _1.KundenauftraegeMitSelbemRahmenauftragPayloadFromJSON(json['p12']),
        'p13': _1.KundenauftragStornierungAbfragenResultFromJSON(json['p13']),
        'p14': _1.AbwicklungspfadFromJSON(json['p14']),
        'p15': _1.MaterialGruppeFromJSON(json['p15']),
        'p16': _1.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSON(json['p16']),
        'p17': _1.ProduktionsauftragBlasenErfassenResultFromJSON(json['p17']),
        'p18': _1.RueckmeldungAnzahlProduktionsphasenErfassenResultFromJSON(json['p18']),
        'p19': _1.RueckmeldungAnzahlNesterAbfragenResultFromJSON(json['p19']),
        'p20': _1.RueckmeldungProduktionsdatenErfassenResultFromJSON(json['p20']),
        'p21': _1.RueckmeldungProudktionswerteValidierenFromJSON(json['p21']),
        'p22': _1.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultFromJSON(json['p22']),
        'p23': _1.ProduktionsauftragBlasenIstLetzteTeilrueckmeldungAbfragenResultFromJSON(json['p23']),
        'p24': _1.ValidatedProduktionsphaseFromJSON(json['p24']),
        'p25': _1.RueckmeldungMengeErfassenMitUnterbrechungResultFromJSON(json['p25']),
        'p26': _1.RueckmeldungMengeErfassenMitUnterbrechungValidatedResultFromJSON(json['p26']),
        'p27': _1.RueckmeldungMengeErfassenMitUnterbrechungOptionalResultFromJSON(json['p27']),
        'p28': _1.RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSON(json['p28']),
        'p29': _1.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultFromJSON(json['p29']),
        'p30': _1.RueckmeldungIstEnddatumKorrektAbfragenResultFromJSON(json['p30']),
        'p31': _1.RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultFromJSON(json['p31']),
        'p32': _1.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON(json['p32']),
        'p33': _1.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSON(json['p33']),
        'p34': _1.ProduktionsauftragExternErfassenResultFromJSON(json['p34']),
        'p35': _1.ProduktionsauftragLagerErfassenResultFromJSON(json['p35']),
        'p36': _1.GranulatFarbeBedarfVorschauDruckenResultFromJSON(json['p36']),
        'p37': _1.PackordnungDateiGenerierenModalResultFromJSON(json['p37']),
        'p38': _1.MaschinenlaufplanErstellenModalResultFromJSON(json['p38']),
        'p39': _1.PapiereBestaetigenModalResultFromJSON(json['p39']),
        'p40': _1.MaschinenlaufplanErstellenResultFromJSON(json['p40']),
        'p41': _1.RueckstellMessprotokollDruckenResultFromJSON(json['p41']),
        'p42': _1.UmbauplanZeitraumErfassenResultFromJSON(json['p42']),
        'p43': _1.UmbauplanSortierkriteriumWaehlenResultFromJSON(json['p43']),
        'p44': _1.UmbauplanDruckenResultFromJSON(json['p44']),
        'p45': _1.LagerpostenAbfragenPayloadFromJSON(json['p45']),
        'p46': _1.LagerpostenAbfragenResultFromJSON(json['p46']),
        'p47': _1.ProduktionsplanBlasmaschinenWaehlenResultFromJSON(json['p47']),
        'p48': _1.ProduktionsplanDruckenResultFromJSON(json['p48']),
        'p49': _1.ProduktionsauftragErfassenResultFromJSON(json['p49']),
        'p50': _1.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadFromJSON(json['p50']),
        'p51': _1.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadFromJSON(json['p51']),
        'p52': _1.ProduktionsauftragBlasenRueckmeldungProduktionsdatenEintragenPayloadFromJSON(json['p52']),
        'p53': _1.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadFromJSON(json['p53']),
    };
}
exports.ExportGlueTypeFromJSONTyped = ExportGlueTypeFromJSONTyped;
function ExportGlueTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'p0': _1.SubscriptionEventToJSON(value.p0),
        'p1': _1.BpmnValidationErrorToJSON(value.p1),
        'p2': _1.VersionedSchemaToJSON(value.p2),
        'p3': _1.RestoreRequestToJSON(value.p3),
        'p4': _1.DeleteRequestToJSON(value.p4),
        'p5': _1.LockRequestToJSON(value.p5),
        'p6': _1.UnlockRequestToJSON(value.p6),
        'p7': _1.RahmenauftragIncreaseStatusPayloadToJSON(value.p7),
        'p8': _1.RahmenauftragDecreaseStatusPayloadToJSON(value.p8),
        'p9': _1.KundenauftragDetailsAngebenResultToJSON(value.p9),
        'p10': _1.KundenauftragErstellenPayloadToJSON(value.p10),
        'p11': _1.KundenauftragUpdateStatusPayloadToJSON(value.p11),
        'p12': _1.KundenauftraegeMitSelbemRahmenauftragPayloadToJSON(value.p12),
        'p13': _1.KundenauftragStornierungAbfragenResultToJSON(value.p13),
        'p14': _1.AbwicklungspfadToJSON(value.p14),
        'p15': _1.MaterialGruppeToJSON(value.p15),
        'p16': _1.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON(value.p16),
        'p17': _1.ProduktionsauftragBlasenErfassenResultToJSON(value.p17),
        'p18': _1.RueckmeldungAnzahlProduktionsphasenErfassenResultToJSON(value.p18),
        'p19': _1.RueckmeldungAnzahlNesterAbfragenResultToJSON(value.p19),
        'p20': _1.RueckmeldungProduktionsdatenErfassenResultToJSON(value.p20),
        'p21': _1.RueckmeldungProudktionswerteValidierenToJSON(value.p21),
        'p22': _1.ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON(value.p22),
        'p23': _1.ProduktionsauftragBlasenIstLetzteTeilrueckmeldungAbfragenResultToJSON(value.p23),
        'p24': _1.ValidatedProduktionsphaseToJSON(value.p24),
        'p25': _1.RueckmeldungMengeErfassenMitUnterbrechungResultToJSON(value.p25),
        'p26': _1.RueckmeldungMengeErfassenMitUnterbrechungValidatedResultToJSON(value.p26),
        'p27': _1.RueckmeldungMengeErfassenMitUnterbrechungOptionalResultToJSON(value.p27),
        'p28': _1.RueckmeldungMengeErfassenKeineUnterbrechungResultToJSON(value.p28),
        'p29': _1.RueckmeldungMengeErfassenKeineUnterbrechungValidatedResultToJSON(value.p29),
        'p30': _1.RueckmeldungIstEnddatumKorrektAbfragenResultToJSON(value.p30),
        'p31': _1.RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultToJSON(value.p31),
        'p32': _1.ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON(value.p32),
        'p33': _1.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadToJSON(value.p33),
        'p34': _1.ProduktionsauftragExternErfassenResultToJSON(value.p34),
        'p35': _1.ProduktionsauftragLagerErfassenResultToJSON(value.p35),
        'p36': _1.GranulatFarbeBedarfVorschauDruckenResultToJSON(value.p36),
        'p37': _1.PackordnungDateiGenerierenModalResultToJSON(value.p37),
        'p38': _1.MaschinenlaufplanErstellenModalResultToJSON(value.p38),
        'p39': _1.PapiereBestaetigenModalResultToJSON(value.p39),
        'p40': _1.MaschinenlaufplanErstellenResultToJSON(value.p40),
        'p41': _1.RueckstellMessprotokollDruckenResultToJSON(value.p41),
        'p42': _1.UmbauplanZeitraumErfassenResultToJSON(value.p42),
        'p43': _1.UmbauplanSortierkriteriumWaehlenResultToJSON(value.p43),
        'p44': _1.UmbauplanDruckenResultToJSON(value.p44),
        'p45': _1.LagerpostenAbfragenPayloadToJSON(value.p45),
        'p46': _1.LagerpostenAbfragenResultToJSON(value.p46),
        'p47': _1.ProduktionsplanBlasmaschinenWaehlenResultToJSON(value.p47),
        'p48': _1.ProduktionsplanDruckenResultToJSON(value.p48),
        'p49': _1.ProduktionsauftragErfassenResultToJSON(value.p49),
        'p50': _1.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAbfragenPayloadToJSON(value.p50),
        'p51': _1.ProduktionsauftragBlasenRueckmeldungProduktionsdatenAktualisierenPayloadToJSON(value.p51),
        'p52': _1.ProduktionsauftragBlasenRueckmeldungProduktionsdatenEintragenPayloadToJSON(value.p52),
        'p53': _1.ProduktionsauftragBlasenVerpackungsVorschriftSetzenPayloadToJSON(value.p53),
    };
}
exports.ExportGlueTypeToJSON = ExportGlueTypeToJSON;
