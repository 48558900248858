"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RueckstellMessprotokollDruckenResultToJSON = exports.RueckstellMessprotokollDruckenResultFromJSONTyped = exports.RueckstellMessprotokollDruckenResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RueckstellMessprotokollDruckenResultFromJSON(json) {
    return RueckstellMessprotokollDruckenResultFromJSONTyped(json, false);
}
exports.RueckstellMessprotokollDruckenResultFromJSON = RueckstellMessprotokollDruckenResultFromJSON;
function RueckstellMessprotokollDruckenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'generated_pdf': !runtime_1.exists(json, 'generated_pdf') ? undefined : _1.GeneratedPDFFromJSON(json['generated_pdf']),
    };
}
exports.RueckstellMessprotokollDruckenResultFromJSONTyped = RueckstellMessprotokollDruckenResultFromJSONTyped;
function RueckstellMessprotokollDruckenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'generated_pdf': _1.GeneratedPDFToJSON(value.generated_pdf),
    };
}
exports.RueckstellMessprotokollDruckenResultToJSON = RueckstellMessprotokollDruckenResultToJSON;
