import React from 'react';
import {
  StammdatenAgGridRenderer,
  StammdatenColDefs,
  StammdatenFieldEditors,
  StammdatenHistoryModal,
  StammdatenModal,
} from '.';
import {
  AuthProviderContext,
  DialogStrings,
  PropsWithTransaction,
  SubscriptionEventService,
  numberValueFormatterGenAGGrid,
} from '../../infrastructure';

import { DeletionState, RestoreRequest } from 'wacoplast_wws__api';
import {
  StammdatenCustomPerformableAction,
  StammdatenCustomPerformableRowAction,
  StammdatenPerformableActions,
  StammdatenPerformableRowActions,
} from './StammdatenAgGridRenderer';
import { ColumnState, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';

export const NUMBER_FILTER_OPTS: {
  filter: 'agNumberColumnFilter',
  valueFormatter: (params: ValueFormatterParams) => string,
  valueGetter: (params: ValueGetterParams) => any,
  filterParams: {
    numberParser: (text: string | null) => number | string | null,
  }
} = {
  filter: 'agNumberColumnFilter',
  valueFormatter: numberValueFormatterGenAGGrid(0),
  valueGetter: (params: ValueGetterParams) => {
    const value = params.data[params.colDef.field ?? ''];

    if (value === undefined || value === null) {
      return '';
    } else if (isNaN(value)) {
      return value;
    }

    return Number(value);
  },
  filterParams: {
    numberParser: (text: string | null): number | string | null => {
      const formatted = text?.replaceAll(',', '.');

      if (formatted === null || formatted === undefined || isNaN(formatted as any)) {
        return text;
      }

      return Number(formatted);
    },
  },
};

export const NUMMER_SORT_OPTS: {
  sort: 'asc',
  sortingOrder: ['asc', 'desc'],
  comparator: (a: any, b: any) => number;
} = {
  sort: 'asc',
  sortingOrder: ['asc', 'desc'],
  comparator: function (valueA, valueB) {
    if (valueA == null) return -1;
    if (valueB == null) return 1;
    if (!valueA.substring || !valueB.substring) return valueA - valueB;
    if (valueA.length < 1 || valueB.length < 1) return valueA - valueB;
    if (!isNaN(valueA) && !isNaN(valueB)) {
      // values will come in as strings
      return Number(valueA) - Number(valueB);
    }
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  },
};

export type StammdatenAgGridProps<TEntityType extends TCreateEntityType & { database_id: number, deletion_state: DeletionState }, TCreateEntityType, TEntityTypeChange = TEntityType & RestoreRequest> = PropsWithTransaction<{
  stammdatenService: {
    create: (entity: TCreateEntityType) => Promise<TEntityType>;
    update: (entity: TEntityTypeChange) => Promise<TEntityType>;
    restore: (entity: TEntityTypeChange) => Promise<TEntityType>;
    getAll: (include_deleted: boolean) => Promise<TEntityType[]>;
    delete?: (entity: TEntityTypeChange, ignoreIntegrity?: boolean) => Promise<void>;
    getHistory?: (entity: TEntityType) => Promise<TEntityType[]>;
    lock?: (entity: TEntityType) => Promise<TEntityType>;
    unlock?: (entity: TEntityType) => Promise<TEntityType>;
    handleSubscription?: (changes: Array<TEntityType>, context: StammdatenAgGrid<TEntityType, TCreateEntityType>) => void;
  };
  columnDefs: StammdatenColDefs<TEntityType>;
  editEditors: StammdatenFieldEditors<TCreateEntityType>;
  createEditors: StammdatenFieldEditors<TCreateEntityType>;
  onCloseStartDialogClicked: () => void;
  defaultEntityForCreation?: Partial<TCreateEntityType>;
  getCustomPerformableRowActions?: (entity: TEntityType) => Array<StammdatenCustomPerformableRowAction<TEntityType>>;
  customPerformableActions?: Array<StammdatenCustomPerformableAction>;
  defaultColumnState?: Array<ColumnState>;
  stammdatenModalCssClass?: string;
  filterHistoryColDefs?: (colDefs: StammdatenColDefs<TEntityType>) => StammdatenColDefs<TEntityType>;
  subscriptionEventService: SubscriptionEventService;
  dialogStrings: typeof DialogStrings[keyof typeof DialogStrings];
}>;

export type StammdatenAgGridState<TEntityType> = {
  stammdaten: Array<TEntityType> | null;
  showModal: 'create' | 'edit' | 'delete' | 'history' | 'restore' | 'lock' | 'unlock' | 'inspect' | null;
  entityRelevantForModal: TEntityType | null;
  displayDeletedEntities: boolean;
}

export class StammdatenAgGrid<TEntityType extends TCreateEntityType & { database_id: number, deletion_state: DeletionState }, TCreateEntityType> extends React.PureComponent<StammdatenAgGridProps<TEntityType, TCreateEntityType>, StammdatenAgGridState<TEntityType>> {

  constructor(props: StammdatenAgGridProps<TEntityType, TCreateEntityType>) {
    super(props);

    if (props.dialogStrings.subscriptionEventName !== undefined) {
      props.subscriptionEventService.subscribe(props.dialogStrings.subscriptionEventName, (data) => this.props.stammdatenService.handleSubscription ?
        this.props.stammdatenService.handleSubscription(data.affected_entities as any, this)
        : this.updateStammdaten(data.affected_entities as Array<TEntityType>));
    }

    this.state = {
      stammdaten: null,
      showModal: null,
      entityRelevantForModal: null,
      displayDeletedEntities: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.loadStammdaten();
  }

  private async loadStammdaten(): Promise<void> {
    await this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const stammdaten = await this.props.stammdatenService.getAll(this.state.displayDeletedEntities);
        this.setState({ stammdaten });
      },
    }).catch(e => {
      console.error('Error while loading Stammdaten', e);
    });
  }

  public updateStammdaten(changes: Array<TEntityType>): void {
    const databaseIdsToFilter = changes.map(change => change.database_id);
    const stammdaten = this.state.stammdaten?.filter(entity => !databaseIdsToFilter.includes(entity.database_id)) ?? [];
    const stammdatenChanges = changes
      .filter((changedEntity, index) => index === changes.findIndex(entity => entity.database_id === changedEntity.database_id))
      .filter((changedEntity) => this.state.displayDeletedEntities || changedEntity.deletion_state !== DeletionState.NUMBER_2);
    this.setState({ stammdaten: [...stammdaten, ...stammdatenChanges] });
  }

  private handleDisplayDeletedEntriesChanged = (value: boolean): void => {
    this.setState({ displayDeletedEntities: value }, () => this.loadStammdaten());
  };

  public render(): JSX.Element {
    const stammdatenPerformableActions = this.buildPerformableActions();

    return (
      <AuthProviderContext.Consumer>
        {(authProvider) => (
          <>
            <StammdatenAgGridRenderer
              columnDefs={this.props.columnDefs}
              stammdaten={this.state.stammdaten}
              title={`${this.props.dialogStrings.title} verwalten`}
              transactionService={this.props.transactionService}
              onCloseStartDialogClicked={this.props.onCloseStartDialogClicked}
              isVersioned={this.props.stammdatenService.getHistory !== undefined}
              isLockable={this.props.stammdatenService.lock !== undefined}
              performableActions={stammdatenPerformableActions}
              getPerformableRowActions={this.getPerformableRowActions}
              defaultColumnState={this.props.defaultColumnState}
              authProvider={authProvider.authProvider}
            />
            {this.renderModals()}
          </>
        )}
      </AuthProviderContext.Consumer>
    );
  }

  private buildPerformableActions(): StammdatenPerformableActions {
    const deleteAction = {
      type: 'displayDeleted',
      action: this.handleDisplayDeletedEntriesChanged,
      requiredClaims: this.props.dialogStrings.readClaims,
    };

    const doesSupportDeletion = this.props.stammdatenService.delete !== undefined;

    return [
      ...(this.props.customPerformableActions?.map((action) => ({ ...action, type: 'custom' as const })) ?? []),
      doesSupportDeletion ? deleteAction : {} as any,
      {
        type: 'create',
        action: this.openCreateModal,
        requiredClaims: this.props.dialogStrings.writeClaims,
      },
    ];
  }

  private renderModals(): JSX.Element | null {
    if (this.state.showModal === null) {
      return null;
    }

    const commonProps = {
      onModalFinished: (result: boolean): void => {
        this.setState({ showModal: null, entityRelevantForModal: null });
      },
    };

    if (this.state.showModal === 'create') {
      return (
        <StammdatenModal
          {...commonProps}
          hideChangingReason
          fields={this.props.createEditors}
          stammdatenEntity={this.props.defaultEntityForCreation ?? {} as any}
          onDialogConfirmed={(entity: TCreateEntityType) => this.props.stammdatenService.create(entity)}
          dialogTitle={`${this.props.dialogStrings.title} erstellen`}
          primaryButtonIntent='primary'
          primaryButtonTitle='Erstellen'
          className={this.props.stammdatenModalCssClass}
        />
      );
    }

    if (this.state.showModal === 'edit' && this.state.entityRelevantForModal !== null) {
      return (
        <StammdatenModal
          {...commonProps}
          hideChangingReason={this.props.stammdatenService.getHistory === undefined || true} // remove ' || true' line as soon as the aenderungsgrund-feature is required again
          fields={this.props.editEditors}
          stammdatenEntity={this.state.entityRelevantForModal}
          onDialogConfirmed={(entity) => this.props.stammdatenService.update(entity)}
          dialogTitle={`${this.props.dialogStrings.title} bearbeiten`}
          primaryButtonIntent='primary'
          primaryButtonTitle='Speichern'
          formFieldsDisabled='notEditable'
          className={this.props.stammdatenModalCssClass}
        />
      );
    }

    if (this.state.showModal === 'delete' && this.state.entityRelevantForModal !== null) {
      return (
        <StammdatenModal
          {...commonProps}
          hideChangingReason={this.props.stammdatenService.getHistory === undefined || true} // remove ' || true' line as soon as the aenderungsgrund-feature is required again
          stammdatenEntity={this.state.entityRelevantForModal}
          fields={this.props.editEditors}
          onDialogConfirmed={(entity, ignoreIntegrity?: boolean) => {
            if (this.props.stammdatenService.delete === undefined) {
              throw new Error('Delete service not initialized');
            }

            return this.props.stammdatenService.delete(entity, ignoreIntegrity);
          }}
          dialogTitle={`${this.props.dialogStrings.title} löschen`}
          primaryButtonIntent='danger'
          primaryButtonTitle='Löschen'
          formFieldsDisabled='all'
          className={this.props.stammdatenModalCssClass}
        />
      );
    }

    if (this.state.showModal === 'restore' && this.state.entityRelevantForModal !== null) {
      return (
        <StammdatenModal
          {...commonProps}
          hideChangingReason={this.props.stammdatenService.getHistory === undefined || true} // remove ' || true' line as soon as the aenderungsgrund-feature is required again
          stammdatenEntity={this.state.entityRelevantForModal}
          fields={this.props.editEditors}
          onDialogConfirmed={(entity) => this.props.stammdatenService.restore(entity)}
          dialogTitle={`${this.props.dialogStrings.title} wiederherstellen`}
          primaryButtonIntent='warning'
          primaryButtonTitle='Wiederherstellen'
          formFieldsDisabled='all'
          className={this.props.stammdatenModalCssClass}
        />
      );
    }

    if (this.state.showModal === 'lock' && this.state.entityRelevantForModal !== null) {
      return (
        <StammdatenModal
          {...commonProps}
          stammdatenEntity={this.state.entityRelevantForModal}
          fields={this.props.editEditors}
          onDialogConfirmed={async (entity) => await this.props.stammdatenService.lock?.(entity)}
          dialogTitle={`${this.props.dialogStrings.title} sperren`}
          primaryButtonIntent='warning'
          primaryButtonTitle='Sperren'
          formFieldsDisabled='all'
          className={this.props.stammdatenModalCssClass}
        />
      );
    }

    if (this.state.showModal === 'unlock' && this.state.entityRelevantForModal !== null) {
      return (
        <StammdatenModal
          {...commonProps}
          hideChangingReason={this.props.stammdatenService.getHistory === undefined || true} // remove ' || true' line as soon as the aenderungsgrund-feature is required again
          stammdatenEntity={this.state.entityRelevantForModal}
          fields={this.props.editEditors}
          onDialogConfirmed={async (entity) => this.props.stammdatenService.unlock?.(entity)}
          dialogTitle={`${this.props.dialogStrings.title} entsperren`}
          primaryButtonIntent='warning'
          primaryButtonTitle='Entsperren'
          formFieldsDisabled='all'
          className={this.props.stammdatenModalCssClass}
        />
      );
    }

    if (this.state.showModal === 'history' && this.state.entityRelevantForModal !== null) {
      if (!this.props.stammdatenService.getHistory) {
        throw new Error('No history function given.');
      }

      return (
        <StammdatenHistoryModal
          {...commonProps}
          columnDefs={this.props.filterHistoryColDefs ? this.props.filterHistoryColDefs(this.props.columnDefs) : this.props.columnDefs}
          dialogTitle='Historie'
          entity={this.state.entityRelevantForModal}
          getHistory={this.props.stammdatenService.getHistory}
          defaultColumnState={this.props.defaultColumnState}
          localStorageKey={this.props.dialogStrings.title}
        />
      );
    }

    if (this.state.showModal === 'inspect' && this.state.entityRelevantForModal !== null) {
      return (
        <StammdatenModal
          {...commonProps}
          hideChangingReason={this.props.stammdatenService.getHistory === undefined || true} // remove ' || true' line as soon as the aenderungsgrund-feature is required again
          stammdatenEntity={this.state.entityRelevantForModal}
          fields={this.props.editEditors}
          onDialogConfirmed={() => new Promise((resolve) => resolve({}))}
          dialogTitle={`${this.props.dialogStrings.title} ansehen`}
          hideAbortButton
          primaryButtonIntent='none'
          primaryButtonTitle='Fertig'
          formFieldsDisabled='all'
          className={this.props.stammdatenModalCssClass}
        />
      );
    }

    return null;
  }

  private getPerformableRowActions = (entity: TEntityType & { deletion_state: DeletionState }): StammdatenPerformableRowActions<TEntityType> => {
    const actions: StammdatenPerformableRowActions<TEntityType> = [];

    if (entity.deletion_state === DeletionState.NUMBER_5 && this.props.stammdatenService.unlock !== undefined) {
      actions.push({
        type: 'unlock',
        onPerform: this.openUnlockModal,
        requiredClaims: this.props.dialogStrings.writeClaims,
      });
    } else if (entity.deletion_state !== DeletionState.NUMBER_2) {
      actions.push({
        type: 'edit',
        onPerform: this.openEditModal,
        requiredClaims: this.props.dialogStrings.writeClaims,
      });

      if (this.props.stammdatenService.lock !== undefined) {
        actions.push({
          type: 'lock',
          onPerform: this.openLockModal,
          requiredClaims: this.props.dialogStrings.writeClaims,
        });
      }
    }

    if (entity.deletion_state === DeletionState.NUMBER_2) {
      actions.push({
        type: 'restore',
        onPerform: this.openRestoreModal,
        requiredClaims: this.props.dialogStrings.writeClaims,
      });
    } else if (this.props.stammdatenService.delete) {
      actions.push({
        type: 'delete',
        onPerform: this.openDeleteModal,
        requiredClaims: this.props.dialogStrings.writeClaims,
      });
    }

    if (this.props.stammdatenService.getHistory !== undefined) {
      actions.push({
        type: 'history',
        onPerform: this.openHistoryModal,
        requiredClaims: this.props.dialogStrings.readClaims,
      });
    }

    actions.push({
      type: 'inspect',
      onPerform: this.openInspectModal,
      requiredClaims: this.props.dialogStrings.readClaims,
    });

    if (this.props.getCustomPerformableRowActions) {
      const customPerformableRowActions = this.props.getCustomPerformableRowActions(entity);
      customPerformableRowActions.map((action) => actions.push({ ...action, type: 'custom' }));
    }
    return actions;
  };

  private openEditModal = (stammdatenEntity: TEntityType): void => {
    this.setState({ showModal: 'edit', entityRelevantForModal: stammdatenEntity });
  };

  private openCreateModal = (): void => {
    this.setState({ showModal: 'create' });
  };

  private openDeleteModal = (stammdatenEntity: TEntityType): void => {
    this.setState({ showModal: 'delete', entityRelevantForModal: stammdatenEntity });
  };

  private openRestoreModal = (stammdatenEntity: TEntityType): void => {
    this.setState({ showModal: 'restore', entityRelevantForModal: stammdatenEntity });
  };

  private openHistoryModal = (stammdatenEntity: TEntityType): void => {
    this.setState({ showModal: 'history', entityRelevantForModal: stammdatenEntity });
  };

  private openLockModal = (stammdatenEntity: TEntityType): void => {
    this.setState({ showModal: 'lock', entityRelevantForModal: stammdatenEntity });
  };

  private openUnlockModal = (stammdatenEntity: TEntityType): void => {
    this.setState({ showModal: 'unlock', entityRelevantForModal: stammdatenEntity });
  };

  private openInspectModal = (stammdatenEntity: TEntityType): void => {
    this.setState({ showModal: 'inspect', entityRelevantForModal: stammdatenEntity });
  }
}
