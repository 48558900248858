import { CreateFirma, FirmaTyp } from 'wacoplast_wws__api';
import { FirmaTypKeyMapping } from '.';
import {
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeEnumDropDownPickerRenderer,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';
import { formatEnumValueToDescription } from '../../infrastructure/filterFormatters';


export type FirmaVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function FirmaVerwalten(props: FirmaVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateFirma> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeNumberEditorRenderer(),
      label: 'Nummer',
    },
    {
      type: 'simple',
      field: 'typ',
      renderer: makeEnumDropDownPickerRenderer(Object.values(FirmaTyp), (data) => FirmaTypKeyMapping[data]),
      label: 'Kunde/Empfänger-Typ',
    },
    {
      type: 'simple',
      field: 'name_kurz',
      renderer: makeTextEditorRenderer(),
      label: 'Name (kurz)',
    },
    {
      type: 'simple',
      field: 'name_lang',
      renderer: makeTextEditorRenderer(),
      label: 'Name (lang)',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
          sort: null,
        },
        {
          headerName: 'Typ',
          field: 'typ',
          colId: 'typ',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: any) => formatEnumValueToDescription(value, FirmaTypKeyMapping),
          },
          valueFormatter: ({ value }) => formatEnumValueToDescription(value, FirmaTypKeyMapping),
        },
        {
          headerName: 'Name',
          field: 'name_kurz',
          colId: 'name_kurz',
          filter: 'agTextColumnFilter',
          sort: 'desc',
        },
        {
          headerName: 'Name (lang)',
          field: 'name_lang',
          colId: 'name_lang',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateFirma) => props.services.firma.postFirmaPost({ CreateFirma: data }),
        update: (data) => props.services.firma.putByDatabaseIdFirmaDatabaseIdPut({ database_id: data.database_id, UpdateFirma: data }),
        delete: (data, ignoreIntegrity) => props.services.firma.deleteByDatabaseIdFirmaDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.firma.restoreByDatabaseIdFirmaDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.firma.getAllFirmaGet({ include_deleted }),
        getHistory: (data) => props.services.firma.getHistoryFirmaDatabaseIdHistoryGet({ database_id: data.database_id }),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Firma}
    />
  );
}
