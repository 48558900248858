/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Tag } from '@blueprintjs/core';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { HalsTeilFileStorageAssociation } from 'wacoplast_wws__api';

export function FileDownloadCellRenderer<TRowType extends { specification_files: Array<HalsTeilFileStorageAssociation> }>(props: Omit<ICellRendererParams, 'data'> & { data: TRowType, restServiceUrl: string }): JSX.Element {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {
        props.data.specification_files.map((fileAssociation, index) => {
          const anchorRef = React.createRef<HTMLAnchorElement>();
          return (
            <div key={index}>
              <a target='_blank' rel='noreferrer' ref={anchorRef} href={`${props.restServiceUrl}/file_storage/${fileAssociation.specification_file.hash}`} style={{ display: 'none' }} />
              <Tag interactive
                minimal
                icon={<FontAwesomeIcon icon={faFileDownload} size='1x' />}
                onClick={async () => {
                  anchorRef.current?.click();
                }}
              />
              {fileAssociation.specification_file.file_name}
            </div>
          );
        })
      }
    </div>
  );
}
