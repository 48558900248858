import { BpmnValidationError, BpmnValidationErrorFromJSON } from 'wacoplast_wws__api';

type BpmnErrorValidationError = {
  name: 'BpmnError';
  code: 'ValidationError';
  message: string;
  additionalInformation: Record<string, unknown>;
}


function tokenIsValidationError(token: any): token is BpmnErrorValidationError {
  return typeof token === 'object' && (token.name === 'BpmnError' || token.name === 'ExternalTaskWorkerError') && token.code === 'ValidationError' && typeof token.message === 'string';
}

export type TokenOrValidationError<TTokenType> = [boolean, TTokenType, null] | [boolean, TTokenType, BpmnValidationError];

export function getPayloadOrBpmnValidationErrorFromToken<TTokenType>(token: any, tokenParseFn: (token: any) => TTokenType = (token) => token): TokenOrValidationError<TTokenType> {
  if (tokenIsValidationError(token)) {
    const validationError = BpmnValidationErrorFromJSON(JSON.parse(token.message));
    const previousPayload = validationError.previous_payload;

    return [true, tokenParseFn(previousPayload) ?? {} as TTokenType, validationError];
  }
  return [false, tokenParseFn(token) ?? {} as TTokenType, null];
}
