"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteRequestToJSON = exports.DeleteRequestFromJSONTyped = exports.DeleteRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function DeleteRequestFromJSON(json) {
    return DeleteRequestFromJSONTyped(json, false);
}
exports.DeleteRequestFromJSON = DeleteRequestFromJSON;
function DeleteRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'ignore_integrity': !runtime_1.exists(json, 'ignore_integrity') ? undefined : json['ignore_integrity'],
    };
}
exports.DeleteRequestFromJSONTyped = DeleteRequestFromJSONTyped;
function DeleteRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'ignore_integrity': value.ignore_integrity,
    };
}
exports.DeleteRequestToJSON = DeleteRequestToJSON;
