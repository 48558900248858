import { Identity } from '@atlas-engine/atlas_engine_client';
import { Button, Dialog, Spinner } from '@blueprintjs/core';

import {
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  RenderSequentialUserTasks,
  withBoundProps,
  withServices,
} from '../../../infrastructure';
import {
  UmbauplanErfolgreichGedrucktHinweis,
  UmbauplanSortierkriteriumWaehlen,
  UmbauplanZeitraumErfasssen,
  UmbauplanZeitraumInvalideHinweis,
} from '../../generate_pdfs';

export type UmbauplanModalProps = PropsWithServices<ModalProps<void>> & { correlation_id: string, getIdentity: () => Identity };

export function UmbauplanModal(props: UmbauplanModalProps): JSX.Element {
  return (
    <RenderSequentialUserTasks
      {...props}
      correlationId={props.correlation_id}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.UmbauplanZeitraumErfassen') {
          return withBoundProps(UmbauplanZeitraumErfasssen, { onModalFinished: props.onModalFinished });
        } else if (userTask.userTaskConfig.customForm === 'wacoplast-wws.UmbauplanSortierkriteriumWaehlen') {
          return withBoundProps(UmbauplanSortierkriteriumWaehlen, { onModalFinished: props.onModalFinished });
        } else if (userTask.userTaskConfig.customForm === 'wacoplast-wws.UmbauplanErfolgreichGedrucktHinweis') {
          return withBoundProps(withServices(UmbauplanErfolgreichGedrucktHinweis), { onModalFinished: props.onModalFinished });
        } else if (userTask.userTaskConfig.customForm === 'wacoplast-wws.UmbauplanZeitraumInvalideHinweis') {
          return withBoundProps(UmbauplanZeitraumInvalideHinweis, { onModalFinished: props.onModalFinished });
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          {<Button onClick={() => props.onModalFinished()} intent='warning'>Ausblenden</Button>}
        </DialogFooter>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

