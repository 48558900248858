import { useEffect, useState } from 'react';

import { AtlasEngineClient, DataModels } from '@atlas-engine/atlas_engine_client';

export function useRunningProcesses(
  atlasEngineClient: AtlasEngineClient,
  processIds: Array<string>,
): Array<DataModels.ProcessInstances.ProcessInstance> {

  const [runningProcesses, setRunningProcesses] = useState<DataModels.ProcessInstances.ProcessInstance[]>([]);

  useEffect(() => {
    const subscriptions = [
      atlasEngineClient.notification.onProcessStarted((e) => {
        if (processIds.includes(e.processModelId)) {
          setRunningProcesses(processes => [...processes, e as any]);
        }
      }),
      atlasEngineClient.notification.onProcessEnded(e => {
        if (processIds.includes(e.processModelId)) {
          setRunningProcesses(processes => processes.filter(process => e.processInstanceId !== process.processInstanceId));
        }
      }),

    ];

    const runningProcessesPromise = atlasEngineClient.processInstances.query({ processModelId: processIds, state: DataModels.ProcessInstances.ProcessInstanceState.running });
    runningProcessesPromise.then((runningProcesses) => setRunningProcesses(runningProcesses.processInstances));

    return () => {
      subscriptions.forEach((subscriptionPromise) => {
        subscriptionPromise.then((subscription) => {
          atlasEngineClient.notification.removeSubscription(undefined as any, subscription);
        });
      });
    };
  }, [
    atlasEngineClient, processIds,
  ]);

  return runningProcesses;
}
