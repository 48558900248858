import { Button, Dialog, H2 } from '@blueprintjs/core';
import { CustomFormProps, DialogBody, DialogFooter } from '../../../../infrastructure';

export function RueckmeldungIstAbgeschlossenHinweis(props: CustomFormProps & { finish: () => void }): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Rückmeldung durchführen'
    >
      <DialogBody>
        <H2>Rückmeldung ist abgeschlossen</H2>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => {
          props.finish();
          return props.finishUserTask({});
        }}
        >
          Okay
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
