import React from 'react';

import useSWR from 'swr';

import { Configuration } from './config';


export function withConfig<TProps>(Component: React.ComponentClass<PropsWithConfig<TProps>> | React.FunctionComponent<PropsWithConfig<TProps>>) {
  // eslint-disable-next-line react/display-name
  return (props: TProps) => {
    const { data: config, error } = useSWR<Configuration>('/config/config.json', async (url) => (await fetch(url)).json());

    if (error) {
      return <p>failed to load: {error}</p>;
    }

    if (!config) {
      return <p>loading...</p>;
    }

    return <Component {...props} config={config} />;
  };
}

export type PropsWithConfig<T> = T & {
  config: Configuration;
}
