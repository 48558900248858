/* eslint-disable react/display-name */
import { DateInput } from '@blueprintjs/datetime';
import React from 'react';
import { StammdatenFieldEditorSimpleRendererProps } from '.';
import { DateEditor, DateTimeEditor } from '../..';

export function makeDateEditorRenderer<TEntityType>(): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, Date | null>) => (
    <DateEditor
      disabled={props.disabled}
      onChange={(date) => props.onChange(date)}
      value={props.value}
      ref={React.createRef<DateInput>()}
    />
  );
}

export function makeDateTimeEditorRenderer<TEntityType>(): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, Date | null>) => (
    <DateTimeEditor
      disabled={props.disabled}
      onChange={(date) => props.onChange(date)}
      value={props.value}
      ref={React.createRef<DateInput>()}
    />
  );
}
