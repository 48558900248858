import { Switch } from '@blueprintjs/core';
import { CreateGewichtstoleranz, Gewichtstoleranz } from 'wacoplast_wws__api';
import {
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenColDefs,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeNumberWithDecimalsEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';
import React from 'react';


export type GewichtstoleranzVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function GewichtstoleranzVerwalten(props: GewichtstoleranzVerwaltenProps): JSX.Element {
  const flaschentypResult = useFetchLikeGetAllServiceFunction(props.services.flaschenTyp.getAllFlaschenTypGet, props.services.flaschenTyp);
  const firmaResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllFirmaGet, props.services.firma);

  const [showAusnahmen, setShowAusnahmen] = React.useState(false);

  const ausnahmeEditors: StammdatenFieldEditors<CreateGewichtstoleranz> = [
    {
      type: 'complex',
      fields: ['kunde_database_id', 'kunde_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(firmaResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
      }, 'kunde'),
      label: 'Kunde',
    },
    {
      type: 'complex',
      fields: ['flaschentyp_database_id', 'flaschentyp_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschentypResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'flaschentyp'),
      label: 'Flaschentyp',
    },
  ];

  const editors: StammdatenFieldEditors<CreateGewichtstoleranz> = [
    {
      type: 'simple',
      field: 'gewicht_von',
      label: 'Gewicht von [g]',
      renderer: makeNumberWithDecimalsEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'gewicht_bis',
      label: 'Gewicht bis [g]',
      renderer: showAusnahmen ? makeNumberWithDecimalsEditorRenderer() : makeTextEditorRenderer('', true),
    },
    {
      type: 'simple',
      field: 'toleranz',
      label: 'Toleranz',
      renderer: makeNumberWithDecimalsEditorRenderer(),
    },
    ...(showAusnahmen ? ausnahmeEditors : []),
  ];

  const ausnahmeColDefs: StammdatenColDefs<Gewichtstoleranz> = [
    {
      headerName: 'Kunde',
      colId: 'kunde',
      ...makeForeignKeyColumn('kunde', (data: Gewichtstoleranz) => {
        return {
          description: data.kunde?.name_kurz ?? '',
          number: data.kunde?.nummer ?? '',
        };
      }),
    },
    {
      headerName: 'Flaschentyp',
      colId: 'flaschen_typ',
      ...makeForeignKeyColumn('flaschentyp', (data: Gewichtstoleranz) => {
        return {
          description: data.flaschentyp?.bezeichnung ?? '',
          number: data.flaschentyp?.nummer ?? '',
        };
      }),
    },
  ];

  return (
    <StammdatenAgGrid
      key={showAusnahmen ? 'ausnahmen' : 'normal'}
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Gewicht von [g]',
          colId: 'gewicht_von',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          valueGetter: ({ data }) => data.gewicht_von,
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Gewicht bis [g]',
          colId: 'gewicht_bis',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          valueGetter: ({ data }) => data.gewicht_bis,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Toleranz',
          colId: 'toleranz',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          valueGetter: ({ data }) => data.toleranz,
          filter: 'agNumberColumnFilter',
        },
        ...(showAusnahmen ? ausnahmeColDefs : []),
      ]}
      stammdatenService={{
        create: (data: CreateGewichtstoleranz) => props.services.gewichtstoleranz.postGewichtstoleranzPost({ CreateGewichtstoleranz: data }),
        update: (data) => props.services.gewichtstoleranz.putByDatabaseIdGewichtstoleranzDatabaseIdPut({ database_id: data.database_id, UpdateGewichtstoleranz: data }),
        delete: (data, ignoreIntegrity) => props.services.gewichtstoleranz.deleteByDatabaseIdGewichtstoleranzDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.gewichtstoleranz.restoreByDatabaseIdGewichtstoleranzDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.gewichtstoleranz.getAllGewichtstoleranzGet({ include_deleted, spezialfaelle: showAusnahmen }),
        getHistory: (data) => props.services.gewichtstoleranz.getHistoryGewichtstoleranzDatabaseIdHistoryGet({ database_id: data.database_id }),
        handleSubscription(changes, context) {
          const changesForCurrentView = changes.filter(change => change.ausnahme === showAusnahmen);
          context.updateStammdaten(changesForCurrentView);
        },
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.Gewichtstoleranz}
      defaultEntityForCreation={{ ausnahme: showAusnahmen }}
      customPerformableActions={[
        {
          element: (<Switch checked={showAusnahmen} onChange={(event) => setShowAusnahmen(event.currentTarget.checked)}>Ausnahmen anzeigen</Switch>),
        },
      ]}
    />
  );
}
