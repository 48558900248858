"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LagerEntryToJSON = exports.LagerEntryFromJSONTyped = exports.LagerEntryFromJSON = void 0;
const runtime_1 = require("../runtime");
function LagerEntryFromJSON(json) {
    return LagerEntryFromJSONTyped(json, false);
}
exports.LagerEntryFromJSON = LagerEntryFromJSON;
function LagerEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pau_nummer': !runtime_1.exists(json, 'pau_nummer') ? undefined : json['pau_nummer'],
        'kuau_nummer': !runtime_1.exists(json, 'kuau_nummer') ? undefined : json['kuau_nummer'],
        'kunde_nummer': !runtime_1.exists(json, 'kunde_nummer') ? undefined : json['kunde_nummer'],
        'kunde_name_kurz': !runtime_1.exists(json, 'kunde_name_kurz') ? undefined : json['kunde_name_kurz'],
        'empfaenger_nummer': !runtime_1.exists(json, 'empfaenger_nummer') ? undefined : json['empfaenger_nummer'],
        'empfaenger_name_kurz': !runtime_1.exists(json, 'empfaenger_name_kurz') ? undefined : json['empfaenger_name_kurz'],
        'bestell_nummer': !runtime_1.exists(json, 'bestell_nummer') ? undefined : json['bestell_nummer'],
        'artikel_nummer_des_kunde': !runtime_1.exists(json, 'artikel_nummer_des_kunde') ? undefined : json['artikel_nummer_des_kunde'],
        'flaschen_form_nummer': !runtime_1.exists(json, 'flaschen_form_nummer') ? undefined : json['flaschen_form_nummer'],
        'flaschen_typ_bezeichnung': !runtime_1.exists(json, 'flaschen_typ_bezeichnung') ? undefined : json['flaschen_typ_bezeichnung'],
        'hat_bezeichnung': !runtime_1.exists(json, 'hat_bezeichnung') ? undefined : json['hat_bezeichnung'],
        'blindenzeichen': !runtime_1.exists(json, 'blindenzeichen') ? undefined : json['blindenzeichen'],
        'gewicht': !runtime_1.exists(json, 'gewicht') ? undefined : json['gewicht'],
        'granulat_farbe_nummer': !runtime_1.exists(json, 'granulat_farbe_nummer') ? undefined : json['granulat_farbe_nummer'],
        'granulat_farbe': !runtime_1.exists(json, 'granulat_farbe') ? undefined : json['granulat_farbe'],
        'einfaerbungs_grad': !runtime_1.exists(json, 'einfaerbungs_grad') ? undefined : json['einfaerbungs_grad'],
        'werkstofftyp': !runtime_1.exists(json, 'werkstofftyp') ? undefined : json['werkstofftyp'],
        'druck_bild_nummer': !runtime_1.exists(json, 'druck_bild_nummer') ? undefined : json['druck_bild_nummer'],
        'druck_bild_bezeichnung': !runtime_1.exists(json, 'druck_bild_bezeichnung') ? undefined : json['druck_bild_bezeichnung'],
        'verpackungseinheit_nummer': !runtime_1.exists(json, 'verpackungseinheit_nummer') ? undefined : json['verpackungseinheit_nummer'],
        'verpackungseinheit': !runtime_1.exists(json, 'verpackungseinheit') ? undefined : json['verpackungseinheit'],
        'flaschen_je_verpackungseinheit': !runtime_1.exists(json, 'flaschen_je_verpackungseinheit') ? undefined : json['flaschen_je_verpackungseinheit'],
        'anzahl_verpackungseinheiten_je_stapel': !runtime_1.exists(json, 'anzahl_verpackungseinheiten_je_stapel') ? undefined : json['anzahl_verpackungseinheiten_je_stapel'],
        'flaschen_je_stapel': !runtime_1.exists(json, 'flaschen_je_stapel') ? undefined : json['flaschen_je_stapel'],
        'hoehe_stapel': !runtime_1.exists(json, 'hoehe_stapel') ? undefined : json['hoehe_stapel'],
        'anzahl_kartons': !runtime_1.exists(json, 'anzahl_kartons') ? undefined : json['anzahl_kartons'],
        'anzahl_stapel': !runtime_1.exists(json, 'anzahl_stapel') ? undefined : json['anzahl_stapel'],
        'bestand_stueckzahl': !runtime_1.exists(json, 'bestand_stueckzahl') ? undefined : json['bestand_stueckzahl'],
        'lagerort': !runtime_1.exists(json, 'lagerort') ? undefined : json['lagerort'],
        'lagerplatz': !runtime_1.exists(json, 'lagerplatz') ? undefined : json['lagerplatz'],
        'liefertermin_soll': !runtime_1.exists(json, 'liefertermin_soll') ? undefined : json['liefertermin_soll'],
        'datum_lagerzugang_pr': !runtime_1.exists(json, 'datum_lagerzugang_pr') ? undefined : json['datum_lagerzugang_pr'],
        'auslieferung_datum_und_menge': !runtime_1.exists(json, 'auslieferung_datum_und_menge') ? undefined : json['auslieferung_datum_und_menge'],
        'bestellmenge': !runtime_1.exists(json, 'bestellmenge') ? undefined : json['bestellmenge'],
        'menge_zugang_pr_in_stueck': !runtime_1.exists(json, 'menge_zugang_pr_in_stueck') ? undefined : json['menge_zugang_pr_in_stueck'],
        'buchung_von': !runtime_1.exists(json, 'buchung_von') ? undefined : json['buchung_von'],
        'buchung_am': !runtime_1.exists(json, 'buchung_am') ? undefined : json['buchung_am'],
        'letzter_vorgang': !runtime_1.exists(json, 'letzter_vorgang') ? undefined : json['letzter_vorgang'],
        'reserviert_fuer_kunde': !runtime_1.exists(json, 'reserviert_fuer_kunde') ? undefined : json['reserviert_fuer_kunde'],
        'reserviert_durch': !runtime_1.exists(json, 'reserviert_durch') ? undefined : json['reserviert_durch'],
        'reserviert_am': !runtime_1.exists(json, 'reserviert_am') ? undefined : json['reserviert_am'],
        'pau_herkunft': !runtime_1.exists(json, 'pau_herkunft') ? undefined : json['pau_herkunft'],
        'gesperrt': !runtime_1.exists(json, 'gesperrt') ? undefined : json['gesperrt'],
        'hinweis': !runtime_1.exists(json, 'hinweis') ? undefined : json['hinweis'],
    };
}
exports.LagerEntryFromJSONTyped = LagerEntryFromJSONTyped;
function LagerEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pau_nummer': value.pau_nummer,
        'kuau_nummer': value.kuau_nummer,
        'kunde_nummer': value.kunde_nummer,
        'kunde_name_kurz': value.kunde_name_kurz,
        'empfaenger_nummer': value.empfaenger_nummer,
        'empfaenger_name_kurz': value.empfaenger_name_kurz,
        'bestell_nummer': value.bestell_nummer,
        'artikel_nummer_des_kunde': value.artikel_nummer_des_kunde,
        'flaschen_form_nummer': value.flaschen_form_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'hat_bezeichnung': value.hat_bezeichnung,
        'blindenzeichen': value.blindenzeichen,
        'gewicht': value.gewicht,
        'granulat_farbe_nummer': value.granulat_farbe_nummer,
        'granulat_farbe': value.granulat_farbe,
        'einfaerbungs_grad': value.einfaerbungs_grad,
        'werkstofftyp': value.werkstofftyp,
        'druck_bild_nummer': value.druck_bild_nummer,
        'druck_bild_bezeichnung': value.druck_bild_bezeichnung,
        'verpackungseinheit_nummer': value.verpackungseinheit_nummer,
        'verpackungseinheit': value.verpackungseinheit,
        'flaschen_je_verpackungseinheit': value.flaschen_je_verpackungseinheit,
        'anzahl_verpackungseinheiten_je_stapel': value.anzahl_verpackungseinheiten_je_stapel,
        'flaschen_je_stapel': value.flaschen_je_stapel,
        'hoehe_stapel': value.hoehe_stapel,
        'anzahl_kartons': value.anzahl_kartons,
        'anzahl_stapel': value.anzahl_stapel,
        'bestand_stueckzahl': value.bestand_stueckzahl,
        'lagerort': value.lagerort,
        'lagerplatz': value.lagerplatz,
        'liefertermin_soll': value.liefertermin_soll,
        'datum_lagerzugang_pr': value.datum_lagerzugang_pr,
        'auslieferung_datum_und_menge': value.auslieferung_datum_und_menge,
        'bestellmenge': value.bestellmenge,
        'menge_zugang_pr_in_stueck': value.menge_zugang_pr_in_stueck,
        'buchung_von': value.buchung_von,
        'buchung_am': value.buchung_am,
        'letzter_vorgang': value.letzter_vorgang,
        'reserviert_fuer_kunde': value.reserviert_fuer_kunde,
        'reserviert_durch': value.reserviert_durch,
        'reserviert_am': value.reserviert_am,
        'pau_herkunft': value.pau_herkunft,
        'gesperrt': value.gesperrt,
        'hinweis': value.hinweis,
    };
}
exports.LagerEntryToJSON = LagerEntryToJSON;
