"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateRahmenauftragToJSON = exports.CreateRahmenauftragFromJSONTyped = exports.CreateRahmenauftragFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateRahmenauftragFromJSON(json) {
    return CreateRahmenauftragFromJSONTyped(json, false);
}
exports.CreateRahmenauftragFromJSON = CreateRahmenauftragFromJSON;
function CreateRahmenauftragFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'bestell_nummer': json['bestell_nummer'],
        'auftragsnummer_des_empfaenger': !runtime_1.exists(json, 'auftragsnummer_des_empfaenger') ? undefined : json['auftragsnummer_des_empfaenger'],
        'datum_eingang': (new Date(json['datum_eingang'])),
        'status': _1.RahmenauftragStatusFromJSON(json['status']),
        'menge': json['menge'],
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenFromJSON(json['liefertermin_kennzeichen']),
        'liefertermin_soll': !runtime_1.exists(json, 'liefertermin_soll') ? undefined : (new Date(json['liefertermin_soll'])),
        'artikel_database_id': json['artikel_database_id'],
        'artikel_version_id': json['artikel_version_id'],
    };
}
exports.CreateRahmenauftragFromJSONTyped = CreateRahmenauftragFromJSONTyped;
function CreateRahmenauftragToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'bestell_nummer': value.bestell_nummer,
        'auftragsnummer_des_empfaenger': value.auftragsnummer_des_empfaenger,
        'datum_eingang': (value.datum_eingang.toISOString()),
        'status': _1.RahmenauftragStatusToJSON(value.status),
        'menge': value.menge,
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenToJSON(value.liefertermin_kennzeichen),
        'liefertermin_soll': value.liefertermin_soll === undefined ? undefined : (value.liefertermin_soll.toISOString()),
        'artikel_database_id': value.artikel_database_id,
        'artikel_version_id': value.artikel_version_id,
    };
}
exports.CreateRahmenauftragToJSON = CreateRahmenauftragToJSON;
