"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RueckmeldungProduktionsdatenErfassenResultToJSON = exports.RueckmeldungProduktionsdatenErfassenResultFromJSONTyped = exports.RueckmeldungProduktionsdatenErfassenResultFromJSON = void 0;
function RueckmeldungProduktionsdatenErfassenResultFromJSON(json) {
    return RueckmeldungProduktionsdatenErfassenResultFromJSONTyped(json, false);
}
exports.RueckmeldungProduktionsdatenErfassenResultFromJSON = RueckmeldungProduktionsdatenErfassenResultFromJSON;
function RueckmeldungProduktionsdatenErfassenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'menge_gut': json['menge_gut'],
        'menge_einricht_ausschuss': json['menge_einricht_ausschuss'],
        'menge_produktion_ausschuss': json['menge_produktion_ausschuss'],
        'menge_gesamt': json['menge_gesamt'],
        'prozent_einricht_ausschuss': json['prozent_einricht_ausschuss'],
        'prozent_produktions_ausschuss': json['prozent_produktions_ausschuss'],
    };
}
exports.RueckmeldungProduktionsdatenErfassenResultFromJSONTyped = RueckmeldungProduktionsdatenErfassenResultFromJSONTyped;
function RueckmeldungProduktionsdatenErfassenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'menge_gut': value.menge_gut,
        'menge_einricht_ausschuss': value.menge_einricht_ausschuss,
        'menge_produktion_ausschuss': value.menge_produktion_ausschuss,
        'menge_gesamt': value.menge_gesamt,
        'prozent_einricht_ausschuss': value.prozent_einricht_ausschuss,
        'prozent_produktions_ausschuss': value.prozent_produktions_ausschuss,
    };
}
exports.RueckmeldungProduktionsdatenErfassenResultToJSON = RueckmeldungProduktionsdatenErfassenResultToJSON;
