"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON = exports.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSONTyped = exports.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSON = void 0;
function ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSON(json) {
    return ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSON = ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSON;
function ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'verpackungs_vorschrift_database_id': json['verpackungs_vorschrift_database_id'],
        'verpackungs_vorschrift_version_id': json['verpackungs_vorschrift_version_id'],
    };
}
exports.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSONTyped = ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultFromJSONTyped;
function ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'verpackungs_vorschrift_database_id': value.verpackungs_vorschrift_database_id,
        'verpackungs_vorschrift_version_id': value.verpackungs_vorschrift_version_id,
    };
}
exports.ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON = ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON;
