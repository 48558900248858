"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EinstellparameterBlasformToJSON = exports.EinstellparameterBlasformFromJSONTyped = exports.EinstellparameterBlasformFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EinstellparameterBlasformFromJSON(json) {
    return EinstellparameterBlasformFromJSONTyped(json, false);
}
exports.EinstellparameterBlasformFromJSON = EinstellparameterBlasformFromJSON;
function EinstellparameterBlasformFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'blasform_database_id': json['blasform_database_id'],
        'blasform_version_id': json['blasform_version_id'],
        'hals_teil_database_id': json['hals_teil_database_id'],
        'hals_teil_version_id': json['hals_teil_version_id'],
        'gewicht': json['gewicht'],
        'schneidring_durchmesser': !runtime_1.exists(json, 'schneidring_durchmesser') ? undefined : json['schneidring_durchmesser'],
        'duesen_bezeichnung': !runtime_1.exists(json, 'duesen_bezeichnung') ? undefined : json['duesen_bezeichnung'],
        'duesen_durchmesser': !runtime_1.exists(json, 'duesen_durchmesser') ? undefined : json['duesen_durchmesser'],
        'profilierung': !runtime_1.exists(json, 'profilierung') ? undefined : json['profilierung'],
        'blasdornsp_l1': !runtime_1.exists(json, 'blasdornsp_l1') ? undefined : json['blasdornsp_l1'],
        'blasdornsp_l2': !runtime_1.exists(json, 'blasdornsp_l2') ? undefined : json['blasdornsp_l2'],
        'blasdornsp_l3': !runtime_1.exists(json, 'blasdornsp_l3') ? undefined : json['blasdornsp_l3'],
        'blasdornsp_d1': !runtime_1.exists(json, 'blasdornsp_d1') ? undefined : json['blasdornsp_d1'],
        'blasdornsp_d2': !runtime_1.exists(json, 'blasdornsp_d2') ? undefined : json['blasdornsp_d2'],
        'blasdornsp_radius': !runtime_1.exists(json, 'blasdornsp_radius') ? undefined : json['blasdornsp_radius'],
        'abschlagbacken_konisch': !runtime_1.exists(json, 'abschlagbacken_konisch') ? undefined : json['abschlagbacken_konisch'],
        'backenhoehe_h1': !runtime_1.exists(json, 'backenhoehe_h1') ? undefined : json['backenhoehe_h1'],
        'steghoehe_h2': !runtime_1.exists(json, 'steghoehe_h2') ? undefined : json['steghoehe_h2'],
        'abschlagbacken_d1': !runtime_1.exists(json, 'abschlagbacken_d1') ? undefined : json['abschlagbacken_d1'],
        'abschlagbacken_d2': !runtime_1.exists(json, 'abschlagbacken_d2') ? undefined : json['abschlagbacken_d2'],
        'unterlegbleche': !runtime_1.exists(json, 'unterlegbleche') ? undefined : json['unterlegbleche'],
        'hinweis': !runtime_1.exists(json, 'hinweis') ? undefined : json['hinweis'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'blasform': _1.BlasformFromJSON(json['blasform']),
        'hals_teil': _1.HalsTeilFromJSON(json['hals_teil']),
    };
}
exports.EinstellparameterBlasformFromJSONTyped = EinstellparameterBlasformFromJSONTyped;
function EinstellparameterBlasformToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'blasform_database_id': value.blasform_database_id,
        'blasform_version_id': value.blasform_version_id,
        'hals_teil_database_id': value.hals_teil_database_id,
        'hals_teil_version_id': value.hals_teil_version_id,
        'gewicht': value.gewicht,
        'schneidring_durchmesser': value.schneidring_durchmesser,
        'duesen_bezeichnung': value.duesen_bezeichnung,
        'duesen_durchmesser': value.duesen_durchmesser,
        'profilierung': value.profilierung,
        'blasdornsp_l1': value.blasdornsp_l1,
        'blasdornsp_l2': value.blasdornsp_l2,
        'blasdornsp_l3': value.blasdornsp_l3,
        'blasdornsp_d1': value.blasdornsp_d1,
        'blasdornsp_d2': value.blasdornsp_d2,
        'blasdornsp_radius': value.blasdornsp_radius,
        'abschlagbacken_konisch': value.abschlagbacken_konisch,
        'backenhoehe_h1': value.backenhoehe_h1,
        'steghoehe_h2': value.steghoehe_h2,
        'abschlagbacken_d1': value.abschlagbacken_d1,
        'abschlagbacken_d2': value.abschlagbacken_d2,
        'unterlegbleche': value.unterlegbleche,
        'hinweis': value.hinweis,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'blasform': _1.BlasformToJSON(value.blasform),
        'hals_teil': _1.HalsTeilToJSON(value.hals_teil),
    };
}
exports.EinstellparameterBlasformToJSON = EinstellparameterBlasformToJSON;
