import {
  Button,
  ControlGroup,
  Dialog,
  H2,
  HTMLTable,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import {
  CreateVerpackungsVorschrift,
  MaterialGruppe,
  ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON,
  VerpackungsVorschrift,
} from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  StammdatenModal,
  filterFetchLikeServiceFunctionResult,
  useFetchLikeGetAllServiceFunction,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import {
  AufdruckArtKeyMapping,
  TransportwegKeyMapping,
  VerpackungZustandKeyMapping,
  createVerpackungsvorschriftFieldEditors,
  getVerpackungsVorschriftNummer,
} from '../../../../stammdaten';
import styles from './blasen.module.scss';

function VerpackungsVorschriftAnlegen(props: PropsWithServices<ModalProps<void>> & { kundenauftrag_database_id: number }): JSX.Element {
  const kundenauftragServiceFunction = useCallback((initOverrides?: RequestInit) => props.services.kundenauftrag.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: props.kundenauftrag_database_id }, initOverrides), [props.services.kundenauftrag, props.kundenauftrag_database_id]);
  const kundenauftragResult = useFetchLikeServiceFunction(kundenauftragServiceFunction, null);

  const verpackungsMaterialResult = useFetchLikeGetAllServiceFunction(props.services.verpackungs_material.getAllVerpackungsMaterialGet, props.services.verpackungs_material);
  const verpackungsEinheitResult = useFetchLikeGetAllServiceFunction(props.services.verpackungs_einheit.getAllVerpackungsEinheitGet, props.services.verpackungs_einheit);

  const lagenResult = filterFetchLikeServiceFunctionResult(verpackungsEinheitResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_50);
  const transportEinheitTraegerResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_80);
  const kantenschutzResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_70);
  const folieResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_90);

  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const empfaengerResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllFirmaGet, props.services.firma);
  const flaschenFormResult = useFetchLikeGetAllServiceFunction(props.services.flaschenForm.getAllFlaschenFormGet, props.services.flaschenForm);

  const editors = createVerpackungsvorschriftFieldEditors(verpackungsEinheitResult, lagenResult, transportEinheitTraegerResult, kantenschutzResult, folieResult, kundenResult, empfaengerResult, flaschenFormResult);


  if (kundenauftragResult.isLoading) {
    return (
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          <Button onClick={() => props.onModalFinished()} intent='warning'>Abbrechen</Button>
        </DialogFooter>
      </Dialog>
    );
  }

  return (
    <StammdatenModal<VerpackungsVorschrift, CreateVerpackungsVorschrift>
      dialogTitle='Verpackungs-Vorschrift erstellen'
      fields={editors}
      onDialogConfirmed={(value) => props.services.verpackungs_vorschrift.postVerpackungsVorschriftPost({ CreateVerpackungsVorschrift: value })}
      onModalFinished={() => props.onModalFinished()}
      primaryButtonIntent='primary'
      primaryButtonTitle='Erstellen'
      stammdatenEntity={kundenauftragResult.data
        ? {
          kunde_database_id: kundenauftragResult.data.artikel.kunde_database_id,
          kunde_version_id: kundenauftragResult.data.artikel.kunde_version_id,
          empfaenger_database_id: kundenauftragResult.data.artikel.empfaenger_database_id ?? kundenauftragResult.data.artikel.kunde_database_id,
          empfaenger_version_id: kundenauftragResult.data.artikel.empfaenger_version_id ?? kundenauftragResult.data.artikel.kunde_version_id,
          flaschen_form_database_id: kundenauftragResult.data.artikel.flaschen_form_database_id,
          flaschen_form_version_id: kundenauftragResult.data.artikel.flaschen_form_version_id,
        }
        : {} as any}
    />
  );
}

export function ProduktionsauftragBlasenVerpackungsVorschriftWaehlen(props: PropsWithServices<CustomFormProps<{ kundenauftrag_database_id: number }>> & { onFinish: () => void, onAbort: () => void }): JSX.Element {

  const verpackungsVorschriftRepository = props.services.verpackungs_vorschrift;
  const serviceFunction = useCallback(
    (initOverrides?: RequestInit) =>
      verpackungsVorschriftRepository.getAllForKundenauftragVerpackungsVorschriftFindKundenauftragDatabaseIdGet({
        kundenauftrag_database_id: props.tokenPayload.kundenauftrag_database_id,
        include_deleted: false,
      }, initOverrides),
    [verpackungsVorschriftRepository, props.tokenPayload.kundenauftrag_database_id],
  );
  const verpackungsVorschriftResult = useFetchLikeServiceFunction(serviceFunction, null);
  const [verpackungsVorschrift, setVerpackungsVorschrift] = useState<VerpackungsVorschrift | null>(null);
  const [showCreateVPVModal, setShowCreateVPVModal] = useState(false);

  useEffect(() => setVerpackungsVorschrift(verpackungsVorschriftResult.data && verpackungsVorschriftResult.data.length > 0 ? verpackungsVorschriftResult.data[0] : null), [verpackungsVorschriftResult.data]);

  return (
    <>
      {showCreateVPVModal && (
        <VerpackungsVorschriftAnlegen
          {...props}
          kundenauftrag_database_id={props.tokenPayload.kundenauftrag_database_id}
          onModalFinished={() => {
            verpackungsVorschriftResult.reload();
            setShowCreateVPVModal(false);
          }}
        />
      )
      }
      <Dialog
        isOpen={true}
        onClose={() => {
          props.finishUserTask({ response: 'abort' });
          props.onAbort();
        }}
        title='Verpackungsvorschrift wählen'
      >
        <DialogBody>
          <H2>Welche Verpackungsvorschrift gilt für diese Produktion?</H2>
          <div style={{ display: 'grid', gap: '5px' }}>
            <DefaultDropDownPicker
              dataProvider={verpackungsVorschriftResult}
              getDisplayData={(data) => {
                return { primaryTitle: getVerpackungsVorschriftNummer(data), secondaryTitle: `Prio: ${data.prioritaet}` };
              }}
              value={verpackungsVorschrift}
              onChange={setVerpackungsVorschrift}
            />
            {
              verpackungsVorschriftResult.data?.length === 0 && (
                <Button onClick={() => setShowCreateVPVModal(true)}>
                  Verpackungsvorschrift anlegen
                </Button>
              )
            }
            <ControlGroup fill>
              <HTMLTable striped className={styles.verpackungs_vorschrift_information_table}>
                <tbody>
                  <tr>
                    <td>VPE</td>
                    <td>{verpackungsVorschrift?.verpackungs_einheit.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift?.verpackungs_einheit.nummer}</Tag></td>
                  </tr>
                  <tr>
                    <td>TET</td>
                    <td>{verpackungsVorschrift?.transport_einheit_traeger.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift?.transport_einheit_traeger.nummer}</Tag></td>
                  </tr>
                  <tr>
                    <td>Unterlage</td>
                    {verpackungsVorschrift?.unterlage
                      ? <td> <Tag minimal>{verpackungsVorschrift.unterlage.nummer}</Tag></td>
                      : <td />
                    }
                  </tr>
                  <tr>
                    <td>Unterlage-Anzahl</td>
                    <td>{verpackungsVorschrift?.unterlagen_anzahl}</td>
                  </tr>
                  <tr>
                    <td>Zwischenlage</td>
                    {verpackungsVorschrift?.zwischenlage
                      ? <td>{verpackungsVorschrift.zwischenlage.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.zwischenlage.nummer}</Tag></td>
                      : <td />
                    }
                  </tr>
                  <tr>
                    <td>Zwischenlage-Anzahl</td>
                    <td>{verpackungsVorschrift?.zwischenlagen_anzahl}</td>
                  </tr>
                  <tr>
                    <td>Abdeckung</td>
                    {verpackungsVorschrift?.abdeckung
                      ? <td>{verpackungsVorschrift.abdeckung.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.abdeckung.nummer}</Tag></td>
                      : <td />
                    }
                  </tr>
                  <tr>
                    <td>Abdeckung-Anzahl</td>
                    <td>{verpackungsVorschrift?.abdeckung_anzahl}</td>
                  </tr>
                  <tr>
                    <td>Kantenschutz</td>
                    {verpackungsVorschrift?.kantenschutz
                      ? <td>{verpackungsVorschrift.kantenschutz.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.kantenschutz.nummer}</Tag></td>
                      : <td />
                    }
                  </tr>
                </tbody>
              </HTMLTable>
              <HTMLTable striped className={styles.verpackungs_vorschrift_information_table}>
                <tbody>
                  <tr>
                    <td>Folie</td>
                    {verpackungsVorschrift?.folie
                      ? <td>{verpackungsVorschrift.folie.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.folie.nummer}</Tag></td>
                      : <td />
                    }
                  </tr>
                  <tr>
                    <td>Zustand</td>
                    <td>{verpackungsVorschrift ? VerpackungZustandKeyMapping[verpackungsVorschrift.zustand] : undefined}</td>
                  </tr>
                  <tr>
                    <td>Aufdruck</td>
                    <td>{verpackungsVorschrift?.aufdruck ? AufdruckArtKeyMapping[verpackungsVorschrift.aufdruck] : undefined}</td>
                  </tr>
                  <tr>
                    <td>Geschlossen</td>
                    <td>{verpackungsVorschrift?.geschlossen === true ? 'Ja' : verpackungsVorschrift?.geschlossen === false ? 'Nein' : undefined}</td>
                  </tr>
                  <tr>
                    <td>Polybeutel</td>
                    <td>{verpackungsVorschrift?.polybeutel === true ? 'Ja' : verpackungsVorschrift?.polybeutel === false ? 'Nein' : undefined}</td>
                  </tr>
                  <tr>
                    <td>Stapelhöhe</td>
                    <td>{verpackungsVorschrift?.stapel_hoehe_1}</td>
                  </tr>
                  <tr>
                    <td>Stapelhöhe 2</td>
                    <td>{verpackungsVorschrift?.stapel_hoehe_2}</td>
                  </tr>
                  <tr>
                    <td>Transportweg-Variante</td>
                    <td>{verpackungsVorschrift?.transportweg ? TransportwegKeyMapping[verpackungsVorschrift.transportweg] : undefined}</td>
                  </tr>
                  <tr>
                    <td><span className={styles['verpackungs_vorschrift_information_table-hidden_row']}>Empty</span></td>
                    <td />
                  </tr>
                </tbody>
              </HTMLTable>
            </ControlGroup>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            disabled={verpackungsVorschrift === null}
            intent='primary'
            onClick={() => {
              props.onFinish();
              props.finishUserTask(ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON({
                verpackungs_vorschrift_database_id: verpackungsVorschrift?.database_id ?? -1,
                verpackungs_vorschrift_version_id: verpackungsVorschrift?.version_id ?? -1,
              }));
            }}
          >Fertig
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
