"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbwicklungspfadProduktionsauftragToJSON = exports.AbwicklungspfadProduktionsauftragFromJSONTyped = exports.AbwicklungspfadProduktionsauftragFromJSON = exports.AbwicklungspfadProduktionsauftrag = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var AbwicklungspfadProduktionsauftrag;
(function (AbwicklungspfadProduktionsauftrag) {
    AbwicklungspfadProduktionsauftrag["blasen"] = "blasen";
    AbwicklungspfadProduktionsauftrag["lager"] = "lager";
    AbwicklungspfadProduktionsauftrag["extern"] = "extern";
})(AbwicklungspfadProduktionsauftrag = exports.AbwicklungspfadProduktionsauftrag || (exports.AbwicklungspfadProduktionsauftrag = {}));
function AbwicklungspfadProduktionsauftragFromJSON(json) {
    return AbwicklungspfadProduktionsauftragFromJSONTyped(json, false);
}
exports.AbwicklungspfadProduktionsauftragFromJSON = AbwicklungspfadProduktionsauftragFromJSON;
function AbwicklungspfadProduktionsauftragFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.AbwicklungspfadProduktionsauftragFromJSONTyped = AbwicklungspfadProduktionsauftragFromJSONTyped;
function AbwicklungspfadProduktionsauftragToJSON(value) {
    return value;
}
exports.AbwicklungspfadProduktionsauftragToJSON = AbwicklungspfadProduktionsauftragToJSON;
