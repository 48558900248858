"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadToJSON = exports.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSONTyped = exports.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSON = void 0;
function ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSON(json) {
    return ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSON = ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSON;
function ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_database_id': json['produktionsauftrag_database_id'],
        'ist_letzte_teilrueckmeldung': json['ist_letzte_teilrueckmeldung'],
        'menge_gut_anstieg': json['menge_gut_anstieg'],
    };
}
exports.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSONTyped = ProduktionsauftragBlasenLagerzugangAktualisierenPayloadFromJSONTyped;
function ProduktionsauftragBlasenLagerzugangAktualisierenPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_database_id': value.produktionsauftrag_database_id,
        'ist_letzte_teilrueckmeldung': value.ist_letzte_teilrueckmeldung,
        'menge_gut_anstieg': value.menge_gut_anstieg,
    };
}
exports.ProduktionsauftragBlasenLagerzugangAktualisierenPayloadToJSON = ProduktionsauftragBlasenLagerzugangAktualisierenPayloadToJSON;
