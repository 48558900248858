/* eslint-disable react/display-name */
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from 'recharts';
import { BlasmaschineZeiten } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps } from '../../../infrastructure';


const yAxisTicks = new Array(11).fill(0).map((_, index) => 0 - index);

export function makeWbkGraphRenderer(): any {
  return (props: StammdatenFieldEditorComplexRendererProps<BlasmaschineZeiten>) => {

    const data = new Array(30).fill(0).map((_, index) => {
      const value = props.stammdatenEntity[`wbk_${index}` as keyof BlasmaschineZeiten] as number;
      return {
        position: index,
        wbk: value,
      };
    });

    return (
      <LineChart
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        width={700}
        height={525}
        data={data}
      >
        <CartesianGrid />
        <XAxis orientation='top' dataKey='position' interval={0} />
        <YAxis dataKey='wbk' interval={0} scale='linear' ticks={yAxisTicks} />
        <Line dot={{ fill: '#7a7a7a', stroke: '#727272' }} animationDuration={200} type='linear' stroke='#000000' dataKey='wbk' />
      </LineChart>
    );
  };
}
