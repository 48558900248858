"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftraegeZuKundenauftragToJSON = exports.ProduktionsauftraegeZuKundenauftragFromJSONTyped = exports.ProduktionsauftraegeZuKundenauftragFromJSON = void 0;
const _1 = require("./");
function ProduktionsauftraegeZuKundenauftragFromJSON(json) {
    return ProduktionsauftraegeZuKundenauftragFromJSONTyped(json, false);
}
exports.ProduktionsauftraegeZuKundenauftragFromJSON = ProduktionsauftraegeZuKundenauftragFromJSON;
function ProduktionsauftraegeZuKundenauftragFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_blasen': (json['produktionsauftrag_blasen'].map(_1.ProduktionsauftragEntryFromJSON)),
        'produktionsauftrag_lager': (json['produktionsauftrag_lager'].map(_1.ProduktionsauftragLagerFromJSON)),
        'produktionsauftrag_extern': (json['produktionsauftrag_extern'].map(_1.ProduktionsauftragExternFromJSON)),
    };
}
exports.ProduktionsauftraegeZuKundenauftragFromJSONTyped = ProduktionsauftraegeZuKundenauftragFromJSONTyped;
function ProduktionsauftraegeZuKundenauftragToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_blasen': (value.produktionsauftrag_blasen.map(_1.ProduktionsauftragEntryToJSON)),
        'produktionsauftrag_lager': (value.produktionsauftrag_lager.map(_1.ProduktionsauftragLagerToJSON)),
        'produktionsauftrag_extern': (value.produktionsauftrag_extern.map(_1.ProduktionsauftragExternToJSON)),
    };
}
exports.ProduktionsauftraegeZuKundenauftragToJSON = ProduktionsauftraegeZuKundenauftragToJSON;
