import { AtlasEngineClient, Identity } from '@atlas-engine/atlas_engine_client';
import React from 'react';
import useSWR from 'swr';
import {
  Configuration as ApiConfig,
  ArtikelApi,
  BlindenzeichenApi,
  FahrzeugApi,
  FahrzeugZuKundeApi,
  FileStorageApi,
  FirmaApi,
  FlaschenFormApi,
  FlaschenTypApi,
  GewichtstoleranzApi,
  GranulatFarbeApi,
  HalsTeilApi,
  KundenauftragApi,
  LieferantApi,
  PackordnungApi,
  PlantafelEntryApi,
  ProduktionsauftragZuKundenauftragApi as ProduktionsauftraegeZuKundenauftragApi,
  ProduktionsauftragApi,
  QHinweisApi,
  RahmenauftragApi,
  StellblattAbstaendeApi,
  StellblattBlasformApi,
  StellblattBlasmaschineApi,
  StellblattBlasmaschineZeitenApi,
  StellblattEinstellparameterBlasformApi,
  StellblattExtruderTemperaturApi,
  StellblattHalsteilZuBlasformApi,
  VerpackungsEinheitApi,
  VerpackungsMaterialApi,
  VerpackungsVorschriftApi,
  WerkstoffApi,
  WerkstoffTypApi,
} from 'wacoplast_wws__api';
import { IdentityProvider } from '../infrastructure';
import { Configuration } from './config';
import { SubscriptionEventService } from '.';

class IdentityProxy implements Identity {
  private identityProvider: IdentityProvider;

  constructor(identityProvider: IdentityProvider) {
    this.identityProvider = identityProvider;
  }

  public get token(): string {
    return this.identityProvider().token;
  }

  public get userId(): string {
    return this.identityProvider().userId;
  }

  public get delegationToken(): string | undefined {
    return this.identityProvider().delegationToken;
  }
}


export function withServices<TProps extends { getIdentity: IdentityProvider }>(Component: React.ComponentClass<PropsWithServices<TProps>> | React.FunctionComponent<PropsWithServices<TProps>>) {
  // eslint-disable-next-line react/display-name
  return (props: TProps) => {
    const { data: config, error } = useSWR<Configuration>('/config/config.json', async (url) => (await fetch(url)).json());

    if (error) {
      return <p>failed to load: {error}</p>;
    }

    if (!config) {
      return <p>loading...</p>;
    }

    const apiConfig = new ApiConfig({
      basePath: config.restServiceUrl,
      accessToken: () => props.getIdentity().token,
    });

    const atlasEngineClient = new AtlasEngineClient(config.engineUrl, new IdentityProxy(props.getIdentity));

    const subscriptionEventService = new SubscriptionEventService(config.restServiceUrl, props.getIdentity);

    const services = {
      artikel: new ArtikelApi(apiConfig),
      blasform: new StellblattBlasformApi(apiConfig),
      blasmaschine: new StellblattBlasmaschineApi(apiConfig),
      blasmaschine_zeiten: new StellblattBlasmaschineZeitenApi(apiConfig),
      blindenzeichen: new BlindenzeichenApi(apiConfig),
      einstellparameter_blasform: new StellblattEinstellparameterBlasformApi(apiConfig),
      extruder_temperatur: new StellblattExtruderTemperaturApi(apiConfig),
      fahrzeug_zu_kunde: new FahrzeugZuKundeApi(apiConfig),
      fahrzeug: new FahrzeugApi(apiConfig),
      fileStorage: new FileStorageApi(apiConfig),
      firma: new FirmaApi(apiConfig),
      flaschenForm: new FlaschenFormApi(apiConfig),
      flaschenTyp: new FlaschenTypApi(apiConfig),
      gewichtstoleranz: new GewichtstoleranzApi(apiConfig),
      granulatFarben: new GranulatFarbeApi(apiConfig),
      halsteil_zu_blasform: new StellblattHalsteilZuBlasformApi(apiConfig),
      halsTeil: new HalsTeilApi(apiConfig),
      kundenauftrag: new KundenauftragApi(apiConfig),
      lieferant: new LieferantApi(apiConfig),
      packordnung: new PackordnungApi(apiConfig),
      plantafelEntryApi: new PlantafelEntryApi(apiConfig),
      produktionsauftraegeZuKundenauftrag: new ProduktionsauftraegeZuKundenauftragApi(apiConfig),
      produktionsauftrag: new ProduktionsauftragApi(apiConfig),
      qHinweis: new QHinweisApi(apiConfig),
      rahmenauftrag: new RahmenauftragApi(apiConfig),
      stellblatt_abstaende: new StellblattAbstaendeApi(apiConfig),
      verpackungs_einheit: new VerpackungsEinheitApi(apiConfig),
      verpackungs_material: new VerpackungsMaterialApi(apiConfig),
      verpackungs_vorschrift: new VerpackungsVorschriftApi(apiConfig),
      werkstoffe: new WerkstoffApi(apiConfig),
      werkstoffTyp: new WerkstoffTypApi(apiConfig),
    };

    return <Component {...props} config={config} services={services} atlasEngineClient={atlasEngineClient} subscriptionEventService={subscriptionEventService} />;
  };
}

export type PropsWithServices<T> = T & {
  config: Configuration;
  atlasEngineClient: AtlasEngineClient;
  subscriptionEventService: SubscriptionEventService;
  services: {
    artikel: ArtikelApi;
    blasform: StellblattBlasformApi;
    blasmaschine: StellblattBlasmaschineApi;
    blasmaschine_zeiten: StellblattBlasmaschineZeitenApi;
    blindenzeichen: BlindenzeichenApi;
    einstellparameter_blasform: StellblattEinstellparameterBlasformApi;
    extruder_temperatur: StellblattExtruderTemperaturApi;
    fahrzeug_zu_kunde: FahrzeugZuKundeApi;
    fahrzeug: FahrzeugApi;
    fileStorage: FileStorageApi;
    firma: FirmaApi;
    flaschenForm: FlaschenFormApi;
    flaschenTyp: FlaschenTypApi;
    gewichtstoleranz: GewichtstoleranzApi;
    granulatFarben: GranulatFarbeApi;
    halsteil_zu_blasform: StellblattHalsteilZuBlasformApi;
    halsTeil: HalsTeilApi;
    kundenauftrag: KundenauftragApi;
    lieferant: LieferantApi;
    packordnung: PackordnungApi;
    plantafelEntryApi: PlantafelEntryApi;
    produktionsauftraegeZuKundenauftrag: ProduktionsauftraegeZuKundenauftragApi;
    produktionsauftrag: ProduktionsauftragApi;
    qHinweis: QHinweisApi;
    rahmenauftrag: RahmenauftragApi;
    stellblatt_abstaende: StellblattAbstaendeApi;
    verpackungs_einheit: VerpackungsEinheitApi;
    verpackungs_material: VerpackungsMaterialApi;
    verpackungs_vorschrift: VerpackungsVorschriftApi;
    werkstoffe: WerkstoffApi;
    werkstoffTyp: WerkstoffTypApi;
  };
}
