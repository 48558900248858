/* eslint-disable react/display-name */
import { StammdatenFieldEditorSimpleRendererProps } from '.';
import { EnumDropDownPicker, getEnumDisplayData } from '../..';

export function makeEnumDropDownPickerRenderer<TEntityType>(data: Array<TEntityType>, getItemData: getEnumDisplayData<TEntityType>, nullable?: boolean): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, TEntityType | null>) => {
    return (
      <EnumDropDownPicker
        nullable={nullable}
        data={data}
        getDisplayData={getItemData}
        onChange={(value) => props.onChange(value)}
        value={props.value}
        disabled={props.disabled}
      />
    );
  };
}
