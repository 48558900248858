"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UmbauplanSortierkriteriumWaehlenResultToJSON = exports.UmbauplanSortierkriteriumWaehlenResultFromJSONTyped = exports.UmbauplanSortierkriteriumWaehlenResultFromJSON = exports.UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum = void 0;
/**
* @export
* @enum {string}
*/
var UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum;
(function (UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum) {
    UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum["Datum"] = "Datum";
    UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum["Blasmaschine"] = "Blasmaschine";
})(UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum = exports.UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum || (exports.UmbauplanSortierkriteriumWaehlenResultSortierkriteriumEnum = {}));
function UmbauplanSortierkriteriumWaehlenResultFromJSON(json) {
    return UmbauplanSortierkriteriumWaehlenResultFromJSONTyped(json, false);
}
exports.UmbauplanSortierkriteriumWaehlenResultFromJSON = UmbauplanSortierkriteriumWaehlenResultFromJSON;
function UmbauplanSortierkriteriumWaehlenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sortierkriterium': json['sortierkriterium'],
    };
}
exports.UmbauplanSortierkriteriumWaehlenResultFromJSONTyped = UmbauplanSortierkriteriumWaehlenResultFromJSONTyped;
function UmbauplanSortierkriteriumWaehlenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sortierkriterium': value.sortierkriterium,
    };
}
exports.UmbauplanSortierkriteriumWaehlenResultToJSON = UmbauplanSortierkriteriumWaehlenResultToJSON;
