"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.KundenauftragArtToJSON = exports.KundenauftragArtFromJSONTyped = exports.KundenauftragArtFromJSON = exports.KundenauftragArt = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var KundenauftragArt;
(function (KundenauftragArt) {
    KundenauftragArt["A"] = "A";
    KundenauftragArt["E"] = "E";
    KundenauftragArt["M"] = "M";
})(KundenauftragArt = exports.KundenauftragArt || (exports.KundenauftragArt = {}));
function KundenauftragArtFromJSON(json) {
    return KundenauftragArtFromJSONTyped(json, false);
}
exports.KundenauftragArtFromJSON = KundenauftragArtFromJSON;
function KundenauftragArtFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.KundenauftragArtFromJSONTyped = KundenauftragArtFromJSONTyped;
function KundenauftragArtToJSON(value) {
    return value;
}
exports.KundenauftragArtToJSON = KundenauftragArtToJSON;
