import { ColumnState } from 'ag-grid-community';

export const defaultColumnState: Array<ColumnState> = [
  {
    colId: 'nummer',
    width: 75,
    hide: false,
    pinned: null,
    sort: 'asc',
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'bezeichnung',
    width: 118,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'batch_nummer',
    width: 133,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'preis_pro_kg',
    width: 70,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'bestand',
    width: 58,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'lieferant',
    width: 336,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'version_id',
    width: 50,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'stammdaten_action_buttons',
    width: 160,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
];
