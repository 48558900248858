"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatedProduktionsphaseToJSON = exports.ValidatedProduktionsphaseFromJSONTyped = exports.ValidatedProduktionsphaseFromJSON = void 0;
function ValidatedProduktionsphaseFromJSON(json) {
    return ValidatedProduktionsphaseFromJSONTyped(json, false);
}
exports.ValidatedProduktionsphaseFromJSON = ValidatedProduktionsphaseFromJSON;
function ValidatedProduktionsphaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'zaehlerstand_umbau_start': json['zaehlerstand_umbau_start'],
        'zaehlerstand_produktion_start': json['zaehlerstand_produktion_start'],
        'zaehlerstand_produktion_ende': json['zaehlerstand_produktion_ende'],
        'menge_gut': json['menge_gut'],
    };
}
exports.ValidatedProduktionsphaseFromJSONTyped = ValidatedProduktionsphaseFromJSONTyped;
function ValidatedProduktionsphaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'zaehlerstand_umbau_start': value.zaehlerstand_umbau_start,
        'zaehlerstand_produktion_start': value.zaehlerstand_produktion_start,
        'zaehlerstand_produktion_ende': value.zaehlerstand_produktion_ende,
        'menge_gut': value.menge_gut,
    };
}
exports.ValidatedProduktionsphaseToJSON = ValidatedProduktionsphaseToJSON;
