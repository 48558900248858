"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialGruppeToJSON = exports.MaterialGruppeFromJSONTyped = exports.MaterialGruppeFromJSON = exports.MaterialGruppe = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var MaterialGruppe;
(function (MaterialGruppe) {
    MaterialGruppe[MaterialGruppe["NUMBER_10"] = 10] = "NUMBER_10";
    MaterialGruppe[MaterialGruppe["NUMBER_30"] = 30] = "NUMBER_30";
    MaterialGruppe[MaterialGruppe["NUMBER_50"] = 50] = "NUMBER_50";
    MaterialGruppe[MaterialGruppe["NUMBER_70"] = 70] = "NUMBER_70";
    MaterialGruppe[MaterialGruppe["NUMBER_80"] = 80] = "NUMBER_80";
    MaterialGruppe[MaterialGruppe["NUMBER_90"] = 90] = "NUMBER_90";
})(MaterialGruppe = exports.MaterialGruppe || (exports.MaterialGruppe = {}));
function MaterialGruppeFromJSON(json) {
    return MaterialGruppeFromJSONTyped(json, false);
}
exports.MaterialGruppeFromJSON = MaterialGruppeFromJSON;
function MaterialGruppeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.MaterialGruppeFromJSONTyped = MaterialGruppeFromJSONTyped;
function MaterialGruppeToJSON(value) {
    return value;
}
exports.MaterialGruppeToJSON = MaterialGruppeToJSON;
