import { Blasform } from 'wacoplast_wws__api';
import { NumberEditor, StammdatenFieldEditorComplexRendererProps } from '../../../infrastructure';

export function makeAnzahlKavitaetenNumberEditorRenderer(): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorComplexRendererProps<Blasform>) => {
    return (
      <NumberEditor
        min={1}
        max={10}
        value={props.stammdatenEntity.anzahl_kavitaeten}
        disabled={props.disabled}
        onChange={(value) => {
          const nest_nummern: Record<string, undefined> = {};
          for (let i = (value ?? 0) + 1; i <= 10; i++) {
            nest_nummern[`nest_nummer_${i}`] = undefined;
          }
          props.onChange({ ...nest_nummern, anzahl_kavitaeten: value ?? undefined });
        }}
      />
    );
  };
}
