"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratedPDFToJSON = exports.GeneratedPDFFromJSONTyped = exports.GeneratedPDFFromJSON = exports.GeneratedPDFFileGroupEnum = void 0;
/**
* @export
* @enum {string}
*/
var GeneratedPDFFileGroupEnum;
(function (GeneratedPDFFileGroupEnum) {
    GeneratedPDFFileGroupEnum["maschinenlaufplan"] = "maschinenlaufplan";
    GeneratedPDFFileGroupEnum["produktionsplan"] = "produktionsplan";
    GeneratedPDFFileGroupEnum["umbauplan"] = "umbauplan";
    GeneratedPDFFileGroupEnum["granulat_farbe_bedarf_vorschau"] = "granulat_farbe_bedarf_vorschau";
    GeneratedPDFFileGroupEnum["rueckstell_messprotokoll"] = "rueckstell_messprotokoll";
})(GeneratedPDFFileGroupEnum = exports.GeneratedPDFFileGroupEnum || (exports.GeneratedPDFFileGroupEnum = {}));
function GeneratedPDFFromJSON(json) {
    return GeneratedPDFFromJSONTyped(json, false);
}
exports.GeneratedPDFFromJSON = GeneratedPDFFromJSON;
function GeneratedPDFFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'file_group': json['file_group'],
        'file_name': json['file_name'],
        'hash': json['hash'],
    };
}
exports.GeneratedPDFFromJSONTyped = GeneratedPDFFromJSONTyped;
function GeneratedPDFToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'file_group': value.file_group,
        'file_name': value.file_name,
        'hash': value.hash,
    };
}
exports.GeneratedPDFToJSON = GeneratedPDFToJSON;
