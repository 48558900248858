"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RueckmeldungMengeErfassenKeineUnterbrechungResultToJSON = exports.RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSONTyped = exports.RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSON = void 0;
const _1 = require("./");
function RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSON(json) {
    return RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSONTyped(json, false);
}
exports.RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSON = RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSON;
function RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsphase': _1.ProduktionsphaseFromJSON(json['produktionsphase']),
    };
}
exports.RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSONTyped = RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSONTyped;
function RueckmeldungMengeErfassenKeineUnterbrechungResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsphase': _1.ProduktionsphaseToJSON(value.produktionsphase),
    };
}
exports.RueckmeldungMengeErfassenKeineUnterbrechungResultToJSON = RueckmeldungMengeErfassenKeineUnterbrechungResultToJSON;
