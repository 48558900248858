/* eslint-disable react/display-name */
import { Dialog } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import {
  BpmnValidationError,
  Kundenauftrag,
  ProduktionsauftragExternErfassenResult,
  ProduktionsauftragExternErfassenResultFromJSON,
  ProduktionsauftragExternErfassenResultToJSON,
  WerkstoffTyp,
} from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  DialogFormField,
  PropsWithServices,
  SidepanelFormCard,
  UseFetchLikeServiceFunctionResult,
  formatWerkstoffTypNummer,
  getPayloadOrBpmnValidationErrorFromToken,
  updateValidationError,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { KundenauftragInformationTable, useWerkstoffTypenServiceFunction } from '../../..';
import { ProduktionsauftragExternErfassenFields } from '.';

export type ProduktionsauftragExternErfassenProps = PropsWithServices<CustomFormProps<ProduktionsauftragExternErfassenResult & { kundenauftrag_database_id: number }>>;
type ProduktionsauftragExternErfassenCardProps = ProduktionsauftragExternErfassenProps & {
  kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag>,
  werkstoff_typen: UseFetchLikeServiceFunctionResult<Array<WerkstoffTyp>>,
};

type ProduktionsauftragExternErfassenState = {
  menge: number | null,
  validationError: BpmnValidationError | null,
  werkstoff_typ: WerkstoffTyp | null,
};

class ProduktionsauftragExternErfassenCard extends React.PureComponent<ProduktionsauftragExternErfassenCardProps, ProduktionsauftragExternErfassenState> {

  constructor(props: ProduktionsauftragExternErfassenCardProps) {
    super(props);

    const [
      ,
      previousValues,
      err,
    ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, ProduktionsauftragExternErfassenResultFromJSON);

    this.state = {
      menge: previousValues.menge ?? null,
      validationError: err,
      werkstoff_typ: previousValues.werkstoff_typ ?? null,
    };
  }

  private submit = async (): Promise<void> => {
    if (this.state.menge === null) {
      // return;
    }

    const payload: ProduktionsauftragExternErfassenResult = {
      menge: this.state.menge,
      werkstoff_typ: this.state.werkstoff_typ,
    } as any;

    return this.props.finishUserTask(ProduktionsauftragExternErfassenResultToJSON(payload));
  };

  public render(): JSX.Element {
    return (
      <Dialog
        isOpen={true}
        onClose={() => this.props.abortUserTask()}
        title='Produktionsauftrag erfassen'
      >
        <DialogBody>
          <SidepanelFormCard
            title='Externen Produktionsauftrag erfassen'
            footer={<></>}
            sidepanel={<KundenauftragInformationTable {...this.props} kundenauftrag={this.props.kundenauftrag} />}
          >
            <DialogFormField
              lastValidationResponse={this.state.validationError}
              fieldLocation={['werkstoff_typ_database_id']}
              fieldLabel='Werkstofftyp'
            >
              <DefaultDropDownPicker
                dataProvider={this.props.werkstoff_typen}
                getDisplayData={(werkstoff_typ) => ({ primaryTitle: werkstoff_typ.bezeichnung, secondaryTitle: formatWerkstoffTypNummer(werkstoff_typ.nummer) })}
                onChange={(data) => this.setState({ werkstoff_typ: data })}
                value={this.state.werkstoff_typ ?? this.props.kundenauftrag.data?.artikel.werkstoff_typ ?? null}
              />
            </DialogFormField>
            <ProduktionsauftragExternErfassenFields
              menge={this.state.menge}
              validationError={this.state.validationError}
              onChange={(obj) => this.setState({ validationError: updateValidationError(obj, this.state.validationError), ...obj as any })}
            />
          </SidepanelFormCard>
        </DialogBody>
        <DialogFooter>
          <AsyncButton intent='primary' label='Speichern' onClick={this.submit} />
        </DialogFooter>
      </Dialog>
    );
  }
}

export function ProduktionsauftragExternErfassen(props: ProduktionsauftragExternErfassenProps): JSX.Element {
  const kundenauftrag_database_id = props.tokenPayload.kundenauftrag_database_id;
  const kundenauftrag_service = props.services.kundenauftrag;
  const serviceFunction = useCallback((initOverrides?: RequestInit) => kundenauftrag_service.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: kundenauftrag_database_id }, initOverrides), [kundenauftrag_database_id, kundenauftrag_service]);
  const kundenauftrag = useFetchLikeServiceFunction(serviceFunction, null);

  const werkstoffTypServiceFunction = useWerkstoffTypenServiceFunction(kundenauftrag, props);
  const werkstoffTypenDieZuWerkstoffPassen = useFetchLikeServiceFunction(werkstoffTypServiceFunction, null);

  return <ProduktionsauftragExternErfassenCard {...props} kundenauftrag={kundenauftrag} werkstoff_typen={werkstoffTypenDieZuWerkstoffPassen} />;
}
