import { WerkstoffTyp } from 'wacoplast_wws__api';
import {
  StammdatenFieldEditorComplexRendererProps,
  UseFetchLikeServiceFunctionResult,
  getDefaultDisplayData,
  makeVersionedDropDownPickerRenderer,
} from '../../infrastructure';

export function makeWerkstoffTypFilteredDropDown<TEntityType>(
  dataProvider: UseFetchLikeServiceFunctionResult<Array<WerkstoffTyp>>,
  getItemData: getDefaultDisplayData<WerkstoffTyp>,
  prefix: string,
  nullable = false,
): any {
  return (props: StammdatenFieldEditorComplexRendererProps<TEntityType>) => {
    const filteredWerkstoffeTypen = !dataProvider.data
      ? dataProvider
      : {
        ...dataProvider,
        data: dataProvider.data.filter((artikel) => artikel.werkstoff_database_id === (props.stammdatenEntity as any).werkstoff_database_id),
      };

    return makeVersionedDropDownPickerRenderer(filteredWerkstoffeTypen, getItemData, prefix, nullable)(props);
  };
}
