"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenErfassenResultToJSON = exports.ProduktionsauftragBlasenErfassenResultFromJSONTyped = exports.ProduktionsauftragBlasenErfassenResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProduktionsauftragBlasenErfassenResultFromJSON(json) {
    return ProduktionsauftragBlasenErfassenResultFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenErfassenResultFromJSON = ProduktionsauftragBlasenErfassenResultFromJSON;
function ProduktionsauftragBlasenErfassenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blasdauer': json['blasdauer'],
        'blasmaschine': _1.BlasmaschineFromJSON(json['blasmaschine']),
        'fertig_vor_liefertermin': json['fertig_vor_liefertermin'],
        'menge': json['menge'],
        'vorgegebene_blasmaschine': !runtime_1.exists(json, 'vorgegebene_blasmaschine') ? undefined : _1.BlasmaschineFromJSON(json['vorgegebene_blasmaschine']),
        'werkstoff_typ': _1.WerkstoffTypFromJSON(json['werkstoff_typ']),
    };
}
exports.ProduktionsauftragBlasenErfassenResultFromJSONTyped = ProduktionsauftragBlasenErfassenResultFromJSONTyped;
function ProduktionsauftragBlasenErfassenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blasdauer': value.blasdauer,
        'blasmaschine': _1.BlasmaschineToJSON(value.blasmaschine),
        'fertig_vor_liefertermin': value.fertig_vor_liefertermin,
        'menge': value.menge,
        'vorgegebene_blasmaschine': _1.BlasmaschineToJSON(value.vorgegebene_blasmaschine),
        'werkstoff_typ': _1.WerkstoffTypToJSON(value.werkstoff_typ),
    };
}
exports.ProduktionsauftragBlasenErfassenResultToJSON = ProduktionsauftragBlasenErfassenResultToJSON;
