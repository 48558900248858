import { ColumnState } from 'ag-grid-community';

export const defaultColumnState: Array<ColumnState> = [
  {
    colId: 'nummer',
    width: 75,
    hide: false,
    pinned: null,
    sort: 'asc',
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'kopf_messer_form_abstand_a',
    width: 166,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'kopf_messer_form_abstand_b',
    width: 166,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'flaschen_form',
    width: 85,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'version_id',
    width: 50,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'deletion_state',
    width: 79,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'aenderungsgrund',
    width: 160,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'stammdaten_action_buttons',
    width: 150,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
];
