import { Button, Dialog, H2 } from '@blueprintjs/core';
import React from 'react';
import {
  BpmnValidationError,
  MaschinenlaufplanErstellenModalResult,
  MaschinenlaufplanErstellenModalResultFromJSON,
  MaschinenlaufplanErstellenModalResultToJSON,
} from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  DialogFormField,
  ModalProps,
  TextEditor,
  getPayloadOrBpmnValidationErrorFromToken,
  removeFieldFromValidationError,
} from '../../../infrastructure';

export function MLPDatenErfassenDialog(props: CustomFormProps<MaschinenlaufplanErstellenModalResult> & ModalProps<void>): JSX.Element {
  const [
    ,
    previousValues,
    err,
  ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, MaschinenlaufplanErstellenModalResultFromJSON);

  const [bemerkung, setBemerkung] = React.useState<string | null>(previousValues.bemerkung ?? null);
  const [validationError, _setValidationError] = React.useState<BpmnValidationError | null>(err);
  const setValidationError = (validationError: BpmnValidationError | null, fieldLocation: string | Array<string>): void => {
    _setValidationError({ ...removeFieldFromValidationError(validationError, fieldLocation) as BpmnValidationError, previous_payload: validationError?.previous_payload ?? {} });
  };

  const abort = (): void => {
    props.finishUserTask({ response: 'abort' });
    props.onModalFinished();
  };

  const finish = (): void => {
    props.finishUserTask(MaschinenlaufplanErstellenModalResultToJSON({
      bemerkung,
    } as any));
  };

  return (
    <Dialog isOpen onClose={abort} title='Papiere drucken'>
      <DialogBody>
        <H2>Maschinenlaufplandetails angeben</H2>
        <DialogFormField fieldLabel='Bemerkung' fieldLocation={['bemerkung']} lastValidationResponse={validationError}>
          <TextEditor value={bemerkung}
            onChange={(data) => {
              setValidationError(validationError, ['bemerkung']);
              setBemerkung(data);
            }}
          />
        </DialogFormField>
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Fertig</Button>
        <Button onClick={abort}>Abbruch</Button>
      </DialogFooter>
    </Dialog>
  );
}
