export const agGridLocaleDE = {
  // Set Filter
  selectAll: '(Alle auswählen)',
  selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
  searchOoo: 'Suche ...',
  noMatches: 'Keine Treffer',

  // Number Filter & Text Filter
  filterOoo: 'Filter ...',
  equals: 'Ist gleich',
  notEqual: 'Ist nicht gleich',
  empty: 'Auswählen',
  blank: 'Leer',
  notBlank: 'Nicht leer',

  // Number Filter
  lessThan: 'Kleiner als',
  greaterThan: 'Größer als',
  lessThanOrEqual: 'Kleiner als oder gleich',
  greaterThanOrEqual: 'Größer als oder gleich',
  inRange: 'Im Bereich',
  inRangeStart: 'von',
  inRangeEnd: 'bis',

  // Text Filter
  contains: 'Enthält',
  notContains: 'Enthält nicht',
  startsWith: 'Beginnt mit',
  endsWith: 'Endet mit',

  // Date Filter
  dateFormatOoo: 'TT.MM.JJJJ',

  // Filter Conditions
  andCondition: 'Und',
  orCondition: 'Oder',

  // Filter Buttons
  applyFilter: 'Anwenden',
  resetFilter: 'Zurücksetzen',
  clearFilter: 'Leeren',
  cancelFilter: 'Abbrechen',

  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Zahl Filter',
  dateFilter: 'Datum Filter',
  setFilter: 'Filter',

  // Other
  loadingOoo: 'Lade ...',
  noRowsToShow: 'Keine Einträge anzuzeigen',
  enabled: 'Aktiviert',

  // Menu
  pinColumn: 'Spalte anheften',
  pinLeft: 'Links anheften',
  pinRight: 'Rechts anheften',
  noPin: 'Nicht anheften',
  expandAll: 'Alle Zeilen öffnen',
  collapseAll: 'Alle Zeilen zuklappen',

  // Menu in History-Dialogs
  copy: 'Zelle kopieren',
  ctrlc: 'Strg+C',
  copyWithHeaders: 'Zelle mit Spaltenname kopieren',
  export: 'Exportieren',
  csvExport: 'Exportieren als CSV',
  excelExport: 'Exportieren als Excel',
};
