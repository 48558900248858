"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlasformBlindenzeichenAssociationToJSON = exports.BlasformBlindenzeichenAssociationFromJSONTyped = exports.BlasformBlindenzeichenAssociationFromJSON = void 0;
function BlasformBlindenzeichenAssociationFromJSON(json) {
    return BlasformBlindenzeichenAssociationFromJSONTyped(json, false);
}
exports.BlasformBlindenzeichenAssociationFromJSON = BlasformBlindenzeichenAssociationFromJSON;
function BlasformBlindenzeichenAssociationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blindenzeichen_database_id': json['blindenzeichen_database_id'],
        'blindenzeichen_version_id': json['blindenzeichen_version_id'],
    };
}
exports.BlasformBlindenzeichenAssociationFromJSONTyped = BlasformBlindenzeichenAssociationFromJSONTyped;
function BlasformBlindenzeichenAssociationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blindenzeichen_database_id': value.blindenzeichen_database_id,
        'blindenzeichen_version_id': value.blindenzeichen_version_id,
    };
}
exports.BlasformBlindenzeichenAssociationToJSON = BlasformBlindenzeichenAssociationToJSON;
