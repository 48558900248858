/* eslint-disable react/display-name */
import { Button, Dialog, H5 } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import {
  Abwicklungspfad,
  Blasmaschine,
  BpmnValidationError,
  Kundenauftrag,
  LagerEntry,
  ProduktionsauftragBlasenErfassenResult,
  ProduktionsauftragErfassenResult,
  ProduktionsauftragErfassenResultFromJSON,
  ProduktionsauftragErfassenResultToJSON,
  ProduktionsauftragExternErfassenResult,
  ProduktionsauftragLagerErfassenResult,
  WerkstoffTyp,
} from 'wacoplast_wws__api';
import {
  AbwicklungspfadKeyMapping,
  LagerpostenDialog,
  hasAbwicklungspfad,
  useWerkstoffTypenServiceFunction,
} from '../..';
import {
  KundenauftragInformationTable,
  ProduktionsauftragBlasenErfassenFields,
  ProduktionsauftragExternErfassenFields,
  ProduktionsauftragLagerErfassenFields,
} from '../../..';
import {
  AsyncButton,
  CustomFormProps,
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  DialogFormField,
  EnumDropDownPicker,
  NullableObject,
  PropsWithServices,
  SidepanelFormCard,
  UseFetchLikeServiceFunctionResult,
  formatWerkstoffTypNummer,
  getNamespacedValidationErrors,
  getPayloadOrBpmnValidationErrorFromToken,
  useFetchLikeGetAllServiceFunction,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import styles from './ProduktionsauftragErfassen.module.scss';

export type ProduktionsauftragErfassenProps = PropsWithServices<CustomFormProps<ProduktionsauftragErfassenResult & { kundenauftrag_database_id: number, lager_entries: Array<LagerEntry> }>>;

type ProduktionsauftragErfassenCardProps = ProduktionsauftragErfassenProps & {
  kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag>;
  blasmaschinen: UseFetchLikeServiceFunctionResult<Array<Blasmaschine>>;
  lagerposten: Array<LagerEntry>;
  werkstoffTypenDieZuWerkstoffPassen: UseFetchLikeServiceFunctionResult<Array<WerkstoffTyp>>;
};

type ProduktionsauftragErfassenState = {
  abwicklungspfad: Abwicklungspfad;
  produktionsauftrag_lager: NullableObject<ProduktionsauftragLagerErfassenResult>;
  produktionsauftrag_extern: NullableObject<ProduktionsauftragExternErfassenResult>;
  produktionsauftrag_blasen: NullableObject<ProduktionsauftragBlasenErfassenResult>;
  werkstoff_typ: WerkstoffTyp | null;
  validationError: BpmnValidationError | null,
  showLagerDialog: boolean;
};

class ProduktionsauftragErfassenCard extends React.PureComponent<ProduktionsauftragErfassenCardProps, ProduktionsauftragErfassenState> {

  constructor(props: ProduktionsauftragErfassenCardProps) {
    super(props);

    const [
      ,
      previousValues,
      err,
    ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, ProduktionsauftragErfassenResultFromJSON);

    this.state = {
      abwicklungspfad: previousValues.abwicklungspfad ?? Abwicklungspfad.NUMBER_3,
      validationError: err,
      produktionsauftrag_lager: {
        menge: previousValues.produktionsauftrag_lager?.menge ?? null,
        werkstoff_typ: previousValues.produktionsauftrag_lager?.werkstoff_typ ?? null,
      },
      produktionsauftrag_extern: {
        menge: previousValues.produktionsauftrag_extern?.menge ?? null,
        werkstoff_typ: previousValues.produktionsauftrag_extern?.werkstoff_typ ?? null,
      },
      produktionsauftrag_blasen: {
        blasdauer: previousValues.produktionsauftrag_blasen?.blasdauer ?? null,
        blasmaschine: previousValues.produktionsauftrag_blasen?.blasmaschine ?? null,
        fertig_vor_liefertermin: previousValues.produktionsauftrag_blasen?.fertig_vor_liefertermin ?? null,
        menge: previousValues.produktionsauftrag_blasen?.menge ?? null,
        vorgegebene_blasmaschine: previousValues.produktionsauftrag_blasen?.vorgegebene_blasmaschine ?? null,
        werkstoff_typ: previousValues.produktionsauftrag_blasen?.werkstoff_typ ?? null,
      },
      werkstoff_typ: previousValues.werkstoff_typ ?? null,
      showLagerDialog: false,
    };
  }

  private submit = async (): Promise<void> => {
    if (this.state.abwicklungspfad === null) {
      return;
    }
    const produktionsauftrag_lager = hasAbwicklungspfad(this.state.abwicklungspfad, Abwicklungspfad.NUMBER_1)
      ? { ...this.state.produktionsauftrag_lager, werkstoff_typ: this.state.werkstoff_typ }
      : undefined;

    const produktionsauftrag_extern = hasAbwicklungspfad(this.state.abwicklungspfad, Abwicklungspfad.NUMBER_5)
      ? { ...this.state.produktionsauftrag_extern, werkstoff_typ: this.state.werkstoff_typ }
      : undefined;

    const produktionsauftrag_blasen = hasAbwicklungspfad(this.state.abwicklungspfad, Abwicklungspfad.NUMBER_3)
      ? { ...this.state.produktionsauftrag_blasen, werkstoff_typ: this.state.werkstoff_typ }
      : undefined;

    const payload: ProduktionsauftragErfassenResult = {
      abwicklungspfad: this.state.abwicklungspfad,
      produktionsauftrag_lager: produktionsauftrag_lager,
      produktionsauftrag_blasen: produktionsauftrag_blasen,
      produktionsauftrag_extern: produktionsauftrag_extern,
      werkstoff_typ: this.state.werkstoff_typ ?? this.props.kundenauftrag.data?.artikel.werkstoff_typ ?? null,
    } as any;

    return this.props.finishUserTask(ProduktionsauftragErfassenResultToJSON(payload));
  };

  private renderProduktionsauftragBlasenErfassenFields(): JSX.Element {
    return (
      <>
        <H5>Blas-Abwicklung</H5>
        <ProduktionsauftragBlasenErfassenFields
          blasmaschinen={this.props.blasmaschinen}
          {...this.state.produktionsauftrag_blasen}
          gewicht={this.props.kundenauftrag.data?.artikel.gewicht}
          einfaerbungs_grad={this.props.kundenauftrag.data?.artikel.einfaerbungs_grad}
          vorgegebene_blasmaschine={this.state.produktionsauftrag_blasen.vorgegebene_blasmaschine ?? null}
          onChange={(obj) => this.setState({ produktionsauftrag_blasen: { ...this.state.produktionsauftrag_blasen, ...obj } })}
          validationError={getNamespacedValidationErrors(this.state.validationError, 'abwicklung_blasen')}
        />
      </>
    );
  }

  private renderProduktionsauftragLagerErfassenFields(): JSX.Element {
    return (
      <>
        <H5>Lager-Abwicklung</H5>
        <ProduktionsauftragLagerErfassenFields
          {...this.state.produktionsauftrag_lager}
          onChange={(obj) => this.setState({ produktionsauftrag_lager: { ...this.state.produktionsauftrag_lager, ...obj } })}
          validationError={getNamespacedValidationErrors(this.state.validationError, 'abwicklung_lager')}
        />
      </>
    );
  }

  private renderProduktionsauftragExternErfassenFields(): JSX.Element {
    return (
      <>
        <H5>Extern-Abwicklung</H5>
        <ProduktionsauftragExternErfassenFields
          {...this.state.produktionsauftrag_extern}
          onChange={(obj) => this.setState({ produktionsauftrag_extern: { ...this.state.produktionsauftrag_extern, ...obj } })}
          validationError={getNamespacedValidationErrors(this.state.validationError, 'abwicklung_extern')}
        />
      </>
    );
  }

  private renderVorhandeneLagerpostenAnzeigen(): JSX.Element {
    return (
      <Button
        className={styles.lagerpostenButton}
        fill
        onClick={() => this.setState({ showLagerDialog: true })}
        disabled={this.props.lagerposten.length === 0}
      >
        Vorhandene Lagerposten anzeigen ({this.props.lagerposten.length})
      </Button>
    );
  }

  public render(): JSX.Element {
    return (
      <Dialog
        isOpen={true}
        onClose={() => this.props.abortUserTask()}
        title='Produktionsauftrag erfassen'
      >
        <DialogBody>
          <SidepanelFormCard
            title='Abwicklungspfad wählen'
            footer={<></>}
            sidepanel={<KundenauftragInformationTable {...this.props} kundenauftrag={this.props.kundenauftrag} />}
          >
            <LagerpostenDialog
              isOpen={this.state.showLagerDialog}
              onClose={() => this.setState({ showLagerDialog: false })}
              data={this.props.lagerposten}
            />
            {this.renderVorhandeneLagerpostenAnzeigen()}

            <DialogFormField
              lastValidationResponse={this.state.validationError}
              fieldLocation={['werkstoff_typ']}
              fieldLabel='Werkstofftyp'
            >
              <DefaultDropDownPicker
                dataProvider={this.props.werkstoffTypenDieZuWerkstoffPassen}
                getDisplayData={(werkstoffTyp) => ({ primaryTitle: werkstoffTyp.bezeichnung, secondaryTitle: formatWerkstoffTypNummer(werkstoffTyp.nummer) })}
                onChange={(data) => this.setState({ werkstoff_typ: data })}
                value={this.state.werkstoff_typ ?? this.props.kundenauftrag.data?.artikel.werkstoff_typ ?? null}
              />
            </DialogFormField>

            <DialogFormField
              lastValidationResponse={this.state.validationError}
              fieldLocation={['abwicklungspfad']}
              fieldLabel='Abwicklungspfad'
            >
              <EnumDropDownPicker
                data={Object.values(Abwicklungspfad)}
                getDisplayData={(data) => (AbwicklungspfadKeyMapping as any)[data]?.toString()}
                onChange={(value) => this.setState({ abwicklungspfad: (typeof value === 'string' ? null : value) ?? Abwicklungspfad.NUMBER_3 })}
                value={this.state.abwicklungspfad}
              />
            </DialogFormField>

            {hasAbwicklungspfad(this.state.abwicklungspfad, Abwicklungspfad.NUMBER_3) && this.renderProduktionsauftragBlasenErfassenFields()}
            {hasAbwicklungspfad(this.state.abwicklungspfad, Abwicklungspfad.NUMBER_1) && this.renderProduktionsauftragLagerErfassenFields()}
            {hasAbwicklungspfad(this.state.abwicklungspfad, Abwicklungspfad.NUMBER_5) && this.renderProduktionsauftragExternErfassenFields()}

          </SidepanelFormCard>
        </DialogBody>
        <DialogFooter>
          <AsyncButton intent='primary' label='Speichern' onClick={this.submit} />
        </DialogFooter>
      </Dialog>
    );
  }
}

export function ProduktionsauftragErfassen(props: ProduktionsauftragErfassenProps): JSX.Element {
  const blasmaschinen = useFetchLikeGetAllServiceFunction(props.services.blasmaschine.getAllStellblattBlasmaschineGet, props.services.blasmaschine);

  const kundenauftrag_database_id = props.tokenPayload.kundenauftrag_database_id;
  const kundenauftrag_service = props.services.kundenauftrag;
  const kundenauftragServiceFunction = useCallback((initOverrides?: RequestInit) => kundenauftrag_service.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: kundenauftrag_database_id }, initOverrides), [kundenauftrag_database_id, kundenauftrag_service]);
  const kundenauftrag = useFetchLikeServiceFunction(kundenauftragServiceFunction, null);
  const werkstoffTypServiceFunction = useWerkstoffTypenServiceFunction(kundenauftrag, props);

  const werkstoffTypenDieZuWerkstoffPassen = useFetchLikeServiceFunction(werkstoffTypServiceFunction, null);

  return (
    <ProduktionsauftragErfassenCard
      {...props}
      kundenauftrag={kundenauftrag}
      blasmaschinen={blasmaschinen}
      lagerposten={props.tokenPayload.lager_entries}
      werkstoffTypenDieZuWerkstoffPassen={werkstoffTypenDieZuWerkstoffPassen}
    />
  );
}
