"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum = exports.FileStorageApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FileStorageApi extends runtime.BaseAPI {
    /**
     * Get File
     */
    getFileFileStorageHashGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.hash === null || requestParameters.hash === undefined) {
                throw new runtime.RequiredError('hash', 'Required parameter requestParameters.hash was null or undefined when calling getFileFileStorageHashGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/file_storage/{hash}`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters.hash))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Get File
     */
    getFileFileStorageHashGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFileFileStorageHashGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Generated Pdf
     */
    getGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.document_group === null || requestParameters.document_group === undefined) {
                throw new runtime.RequiredError('document_group', 'Required parameter requestParameters.document_group was null or undefined when calling getGeneratedPdfFileStorageDocumentGroupDocumentNameHashGet.');
            }
            if (requestParameters.document_name === null || requestParameters.document_name === undefined) {
                throw new runtime.RequiredError('document_name', 'Required parameter requestParameters.document_name was null or undefined when calling getGeneratedPdfFileStorageDocumentGroupDocumentNameHashGet.');
            }
            if (requestParameters.hash === null || requestParameters.hash === undefined) {
                throw new runtime.RequiredError('hash', 'Required parameter requestParameters.hash was null or undefined when calling getGeneratedPdfFileStorageDocumentGroupDocumentNameHashGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/file_storage/{document_group}/{document_name}/{hash}`.replace(`{${"document_group"}}`, encodeURIComponent(String(requestParameters.document_group))).replace(`{${"document_name"}}`, encodeURIComponent(String(requestParameters.document_name))).replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters.hash))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Get Generated Pdf
     */
    getGeneratedPdfFileStorageDocumentGroupDocumentNameHashGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upload File
     */
    uploadFileFileStoragePostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new runtime.RequiredError('file', 'Required parameter requestParameters.file was null or undefined when calling uploadFileFileStoragePost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const consumes = [
                { contentType: 'multipart/form-data' },
            ];
            // @ts-ignore: canConsumeForm may be unused
            const canConsumeForm = runtime.canConsumeForm(consumes);
            let formParams;
            let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
            if (useForm) {
                formParams = new FormData();
            }
            else {
                formParams = new URLSearchParams();
            }
            if (requestParameters.file !== undefined) {
                formParams.append('file', requestParameters.file);
            }
            const response = yield this.request({
                path: `/file_storage/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.UploadFileResponseFromJSON(jsonValue));
        });
    }
    /**
     * Upload File
     */
    uploadFileFileStoragePost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uploadFileFileStoragePostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.FileStorageApi = FileStorageApi;
/**
    * @export
    * @enum {string}
    */
var GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum;
(function (GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum) {
    GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum["maschinenlaufplan"] = "maschinenlaufplan";
    GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum["produktionsplan"] = "produktionsplan";
    GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum["umbauplan"] = "umbauplan";
    GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum["granulat_farbe_bedarf_vorschau"] = "granulat_farbe_bedarf_vorschau";
    GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum["rueckstell_messprotokoll"] = "rueckstell_messprotokoll";
})(GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum = exports.GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum || (exports.GetGeneratedPdfFileStorageDocumentGroupDocumentNameHashGetDocumentGroupEnum = {}));
