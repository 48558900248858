import {
  ColumnApi,
  ColumnState,
  GridApi,
  ProcessCellForExportParams,
  ValueFormatterFunc,
  ValueFormatterParams,
} from 'ag-grid-community';
import { ColumnGroupState } from '.';
import { StammdatenColDefs } from '../stammdaten_renderer';


export const dateValueFormatterGen: (format: Intl.DateTimeFormat) => ValueFormatterFunc = (format) => ({ value }) => {
  if (value === undefined || value === null) {
    return '';
  }

  if (value instanceof Date) {
    return format.format(value);
  }

  return value;
};

export const dateValueFormatter = dateValueFormatterGen(new Intl.DateTimeFormat('DE-de', {
  day: '2-digit', month: '2-digit', year: 'numeric',
}));


export const numberValueFormatterGenAGGrid: (fractionDigits: number | undefined) => ValueFormatterFunc = (fractionDigits) => {
  const format = new Intl.NumberFormat('DE-de', {
    minimumFractionDigits: fractionDigits,
  });

  return ({ value }) => {
    if (value === undefined || value === null) {
      return '';
    } else if (isNaN(value)) {
      return `${value}`;
    } else if (typeof value === 'string') {
      return format.format(Number(value));
    }

    return format.format(value);
  };
};

export const numberValueFormatterGen: (fractionDigits: number | undefined) => (value: any) => string = (fractionDigits) => {
  const format = new Intl.NumberFormat('DE-de', {
    minimumFractionDigits: fractionDigits,
  });

  return (value) => {
    if (typeof value === 'number') {
      return format.format(value);
    }

    if (value === undefined || value === null) {
      return '';
    }

    return `${value}`;
  };
};

function getColumnState(gridTitle: string, defaultColumnState: Array<ColumnState>): Array<ColumnState> {
  const itemKey = `stammdaten-agGrid-${gridTitle}`;
  const localStorageColumnState: Array<ColumnState> = JSON.parse(localStorage.getItem(itemKey) ?? '[]');
  return localStorageColumnState.length > 0 ? localStorageColumnState : defaultColumnState;
}

export function getColumnGroupState(gridTitle: string): ColumnGroupState[] {
  const itemKeyGroup = `stammdaten-agGrid-groupColDef-${gridTitle}`;
  const localStorageColumnGroupState: ColumnGroupState[] = JSON.parse(localStorage.getItem(itemKeyGroup) ?? '[]');
  return localStorageColumnGroupState;
}

export const storeColumnStateOnChanges = (gridTitle: string, gridApi: GridApi, columnApi: ColumnApi): void => {
  const itemKey = `stammdaten-agGrid-${gridTitle}`;
  const itemKeyGroup = `stammdaten-agGrid-groupColDef-${gridTitle}`;

  const storeColumnState = (event: any): void => {
    if (event.finished === false) {
      return;
    }
    localStorage.setItem(itemKey, JSON.stringify(columnApi.getColumnState()));
    localStorage.setItem(itemKeyGroup, JSON.stringify(columnApi.getColumnGroupState()));
  };

  gridApi.addEventListener('columnPinned', storeColumnState);
  gridApi.addEventListener('columnResized', storeColumnState);
  gridApi.addEventListener('sortChanged', storeColumnState);
  gridApi.addEventListener('columnGroupOpened', storeColumnState);
};

export function resetColumnState(gridTitle: string, columnApi: ColumnApi | null): void {
  const itemKey = `stammdaten-agGrid-${gridTitle}`;
  const itemKeyGroup = `stammdaten-agGrid-groupColDef-${gridTitle}`;
  localStorage.removeItem(itemKey);
  localStorage.removeItem(itemKeyGroup);
  columnApi?.resetColumnState();
}

export function applyColumnStateOnColumnDefs<TEntityType>(gridTitle: string, columnDefs: StammdatenColDefs<TEntityType>, defaultColumnState: Array<ColumnState>): StammdatenColDefs<TEntityType> {
  const columnState = getColumnState(gridTitle, defaultColumnState);
  const columnGroupState = getColumnGroupState(gridTitle);

  return columnDefs.map((colDef) => {
    if ((colDef as any).children !== undefined) {
      const columnGroupStateItem: ColumnGroupState | undefined = columnGroupState.find((item) => item.groupId === (colDef as any).groupId);
      return {
        ...colDef,
        openByDefault: columnGroupStateItem?.open,
        children: applyColumnStateOnColumnDefs(gridTitle, (colDef as any).children, defaultColumnState),
      };
    }
    const columnStateItem = columnState.find((item) => item.colId === (colDef as any).colId) ?? {};
    return {
      ...colDef,
      ...columnStateItem,
    } as any;
  });
}

export function applyColumnStateOnHistorieColumnDefs<TEntityType>(gridTitle: string, columnDefs: StammdatenColDefs<TEntityType>, defaultColumnState: Array<ColumnState>): StammdatenColDefs<TEntityType> {
  const columnState = getColumnState(gridTitle, defaultColumnState);

  return columnDefs.map((colDef) => {
    if ((colDef as any).field === 'version_id') {
      return {
        ...colDef,
        width: 100,
        sort: 'desc',
      };
    }

    if ((colDef as any).children !== undefined) {
      return {
        ...colDef,
        sort: null,
        children: applyColumnStateOnColumnDefs(gridTitle, (colDef as any).children, defaultColumnState),
      };
    }
    const columnStateItem = columnState.find((item) => item.colId === (colDef as any).colId) ?? {};
    return {
      ...colDef,
      ...columnStateItem,
      sort: null,
    } as any;
  });
}

export function processCellForClipboard(params: ProcessCellForExportParams): string {
  const colDef = params.column.getColDef();
  if (colDef.valueFormatter && typeof colDef.valueFormatter === 'function') {
    return colDef.valueFormatter({
      ...params,
      data: params.node?.data,
      colDef: colDef,
    } as ValueFormatterParams);
  }
  return params.value;
}

