import React from 'react';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import { LOCALE_DE as DEFAULT_LOCALE } from './inputs';

export type NumericInputProps = {
  min?: number;
  max?: number;
  value: number | null;
  showButtons?: boolean,
  formatDecimalSeparator?: boolean,
  truncateDecimalPoints?: boolean,
  precision?: number,
  placeholder?: string;
  onChange: (data: number | null) => void;
  disabled?: boolean;
  locale?: string;
}

export type NumberInputState = {
  value: number | null;
  valueAsString: string;
}

export class NumberEditor extends React.Component<NumericInputProps, NumberInputState> {

  private altPressed = false;
  private numberParser: NumberParser;
  private locale: string = DEFAULT_LOCALE;
  private decimalSeparator: string;

  constructor(props: NumericInputProps) {
    super(props);
    this.decimalSeparator = this.getDecimalSeparator();
    this.numberParser = new NumberParser(this.props.locale ?? this.locale);
    let value = props.value ? props.value : null;
    if (typeof(value) === 'string') {
      value = Number(value);
    }
    const valueAsString = props.formatDecimalSeparator ? this.convertToLocale(value) : value ? value.toString().replace('.', this.decimalSeparator) : '';

    window.addEventListener('keydown', (event: any) => this.onKeyDownGlobal(event));
    window.addEventListener('keyup', (event: any) => this.onKeyUpGlobal(event));

    this.state = {
      value: value,
      valueAsString: valueAsString,
    };
  }

  public onFocusActive = (event: React.FocusEvent<HTMLElement>): void => {
    if (this.props.formatDecimalSeparator) {
      const decimalSeparator = this.getDecimalSeparator();
      const valueAsString = this.state.value !== null ? this.state.value.toString().replaceAll('.', decimalSeparator) : '';
      this.setState({ valueAsString: valueAsString });
    }
  }

  private getDecimalSeparator = (): string => {
    const numberWithDecimalSeparator = 1.1;

    return numberWithDecimalSeparator
      .toLocaleString(this.props.locale ?? this.locale)
      .substring(1, 2);
  }

  public onFocusLost = (event: React.FocusEvent<HTMLElement>): void => {
    if (this.props.formatDecimalSeparator) {
      this.setState({ valueAsString: this.convertToLocale(this.state.value) });
    }
  }

  private convertToLocale(value: number | null, numberFormatOptions: Intl.NumberFormatOptions = {}): string {
    if (value === null) {
      return '';
    }
    return Intl.NumberFormat(this.props.locale ?? this.locale, numberFormatOptions).format(value);
  }

  private validateNumber = (value: number | null, min = 0, max = 999999999999999): number | null => {
    if (value && value < min) {
      return min;
    } else if (value && value > max) {
      return max;
    }
    return (value !== null && isNaN(value)) ? (min ?? null) : value;
  }

  public onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const eventValue = event.target.value;
    const valueAsNumber = this.numberParser.parse(eventValue);
    const validatedNumber = this.validateNumber(valueAsNumber, this.props.min, this.props.max);

    if (eventValue === '') {
      this.setState({ value: null, valueAsString: '' });
      this.props.onChange(null);
      return;
    }

    const re = /^[\d,-]+$/;
    if (re.test(eventValue)) {
      if (validatedNumber === 0 && eventValue === '') {
        this.props.onChange(null);
        this.setState({ value: null, valueAsString: '' });
      } else {
        if (this.props.truncateDecimalPoints) {
          const truncated = Math.trunc(validatedNumber ?? 0);
          this.props.onChange(truncated);
          this.setState({ value: truncated, valueAsString: truncated.toString() });
        } else {
          this.props.onChange(valueAsNumber);
          this.setState({ valueAsString: eventValue, value: valueAsNumber });
        }
      }
    }
  }

  public addOne = (): void => {
    let newValue = this.state.value ? this.state.value + 1 : 1;
    if (this.altPressed && this.props.truncateDecimalPoints === false) {
      newValue = this.state.value ? this.state.value + 0.01 : +0.01;
    }
    const precisionPlaces = Math.round(Math.pow(10, this.props.precision ?? 2) + Number.EPSILON);
    const roundedValue = Math.round((newValue + Number.EPSILON) * precisionPlaces) / precisionPlaces;
    this.setState({ value: roundedValue });
    if (this.props.formatDecimalSeparator) {
      this.setState({ valueAsString: this.convertToLocale(roundedValue) });
    } else {
      // Der zweite wert tritt nie ein, weil wir oben den wert immer auf eine number setzen
      this.setState({ valueAsString: roundedValue ? roundedValue.toString().replace('.', this.decimalSeparator) : '' });
    }
    this.props.onChange(roundedValue);
  }

  public subtractOne = (): void => {
    let newValue = this.state.value ? this.state.value - 1 : -1;
    if (this.altPressed && this.props.truncateDecimalPoints === false) {
      newValue = this.state.value ? this.state.value - 0.01 : -0.01;
    }
    const precisionPlaces = Math.round(Math.pow(10, this.props.precision ?? 2) + Number.EPSILON);
    const roundedValue = Math.round((newValue + Number.EPSILON) * precisionPlaces) / precisionPlaces;
    this.setState({ value: roundedValue });
    if (this.props.formatDecimalSeparator) {
      this.setState({ valueAsString: this.convertToLocale(roundedValue) });
    } else {
      // Der zweite wert tritt nie ein, weil wir oben den wert immer auf eine number setzen
      this.setState({ valueAsString: roundedValue ? roundedValue.toString().replace('.', this.decimalSeparator) : '' });
    }
    this.props.onChange(roundedValue);
  }

  private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === 'ArrowUp') {
      this.addOne();
    }
    if (event.code === 'ArrowDown') {
      this.subtractOne();
    }
  }

  private onKeyDownGlobal = (event: React.KeyboardEvent): void => {
    if (event.code === 'AltLeft' || event.code === 'AltRight') {
      this.altPressed = true;
    }
  }
  private onKeyUpGlobal = (event: React.KeyboardEvent<any>): void => {
    if (event.code === 'AltLeft' || event.code === 'AltRight') {
      this.altPressed = false;
    }
  }

  render(): JSX.Element {
    return (
      <ButtonGroup fill={true}>
        <input
          className={`${Classes.FILL} ${Classes.INPUT}`}
          type='text'
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          onChange={this.onChange}
          onFocus={this.onFocusActive}
          onBlur={this.onFocusLost}
          value={this.state.valueAsString}
          onKeyDown={this.onKeyDown}
        />
        { this.props.showButtons === true &&
        <>
          <Button tabIndex={-1} className={Classes.BUTTON} onClick={this.addOne}>+</Button>
          <Button tabIndex={-1} className={Classes.BUTTON} onClick={this.subtractOne}>-</Button>
        </>
        }
      </ButtonGroup>
    );
  }
}

class NumberParser {
  private group: RegExp;
  private decimal: RegExp;
  private numeral: RegExp;
  private index: any;

  constructor(locale: string) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = (new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)).split('').reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this.group = new RegExp(`[${(parts.find(d => d.type === 'group') as any).value}]`, 'g');
    this.decimal = new RegExp(`[${(parts.find(d => d.type === 'decimal') as any).value}]`);
    this.numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this.index = (d: any) => index.get(d);
  }

  parse(string: string): number {
    // eslint-disable-next-line no-cond-assign
    return (string = string.trim()
      .replace(this.group, '')
      .replace(this.decimal, '.')
      .replace(this.numeral, this.index))
      ? +string
      : NaN;
  }
}
