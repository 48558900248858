"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlaschenFormToJSON = exports.FlaschenFormFromJSONTyped = exports.FlaschenFormFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FlaschenFormFromJSON(json) {
    return FlaschenFormFromJSONTyped(json, false);
}
exports.FlaschenFormFromJSON = FlaschenFormFromJSON;
function FlaschenFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'hoehe_inkl_hals_teil_mm': json['hoehe_inkl_hals_teil_mm'],
        'flaschentyp_database_id': json['flaschentyp_database_id'],
        'flaschentyp_version_id': json['flaschentyp_version_id'],
        'hals_teil_database_id': json['hals_teil_database_id'],
        'hals_teil_version_id': json['hals_teil_version_id'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'flaschentyp': _1.FlaschenTypFromJSON(json['flaschentyp']),
        'hals_teil': _1.HalsTeilFromJSON(json['hals_teil']),
    };
}
exports.FlaschenFormFromJSONTyped = FlaschenFormFromJSONTyped;
function FlaschenFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'hoehe_inkl_hals_teil_mm': value.hoehe_inkl_hals_teil_mm,
        'flaschentyp_database_id': value.flaschentyp_database_id,
        'flaschentyp_version_id': value.flaschentyp_version_id,
        'hals_teil_database_id': value.hals_teil_database_id,
        'hals_teil_version_id': value.hals_teil_version_id,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'flaschentyp': _1.FlaschenTypToJSON(value.flaschentyp),
        'hals_teil': _1.HalsTeilToJSON(value.hals_teil),
    };
}
exports.FlaschenFormToJSON = FlaschenFormToJSON;
