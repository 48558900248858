/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Tag } from '@blueprintjs/core';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { FileMetadata } from 'wacoplast_wws__api';

export function FileDownloadCellRenderer(props: Omit<ICellRendererParams, 'value'> & { value?: FileMetadata, restServiceUrl: string }): JSX.Element {
  const anchorRef = React.useRef<HTMLAnchorElement>(null);

  if (!props.value) {
    return <></>;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Tag interactive
        minimal
        icon={<FontAwesomeIcon icon={faFileDownload} size='1x' />}
        onClick={async () => {
          anchorRef.current?.click();
        }}
      />
      {props.value.file_name}
      <a target='_blank' rel='noreferrer' ref={anchorRef} href={`${props.restServiceUrl}/file_storage/${props.value.hash}`} style={{ display: 'none' }} />
    </div>
  );
}
