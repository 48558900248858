import { HTTPValidationError } from 'wacoplast_wws__api';
import { DialogFormField, NumberEditor } from '../../../../infrastructure';

export type ProduktionsauftragLagerErfassenFieldsProps = {
  menge: number | null;
  validationError?: HTTPValidationError | null
  onChange: (updatedEntries: Record<string, any>) => void;
}

export function ProduktionsauftragLagerErfassenFields(props: ProduktionsauftragLagerErfassenFieldsProps): JSX.Element {
  return (
    <>
      <DialogFormField
        lastValidationResponse={props.validationError}
        fieldLocation={['menge']}
        fieldLabel='Menge'
      >
        <NumberEditor value={props.menge} min={0} onChange={(value) => props.onChange({ menge: value })} formatDecimalSeparator />
      </DialogFormField>
    </>
  );
}
