/* eslint-disable react/display-name */
import {
  Button,
  ButtonGroup,
  Classes,
  InputGroup,
} from '@blueprintjs/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FileStorageApi, HalsTeilFileStorageAssociation } from 'wacoplast_wws__api';
import { FileUploadDialog } from '../../infrastructure/components';
import { StammdatenFieldEditorSimpleRendererProps } from '../../infrastructure/stammdaten_renderer/field_editors/editors';


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function makeFilePickerRenderer<TEntityType>(fileStorageApi: FileStorageApi) {

  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, Array<HalsTeilFileStorageAssociation>>) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);

    return (
      <div style={ { gap: '5px', display: 'grid' } }>
        {
          props.value.map((current_association, index) => (
            <ButtonGroup key={index} fill>
              <InputGroup disabled value={current_association.specification_file.file_name} fill />
              <Button
                icon={<FontAwesomeIcon size='sm' icon={faTrash} />}
                className={Classes.FIXED}
                disabled={props.disabled}
                onClick={() => {
                  props.onChange(props.value.filter((association) =>
                    association.specification_file_hash !== current_association.specification_file_hash,
                  ));
                }}
              />
            </ButtonGroup>
          ))
        }
        <UploadButton
          disabled={props.disabled}
          setShowModal={setShowModal}
        />
        {
          showModal && !props.disabled &&
          <FileUploadDialog
            onClose={(hash, fileName) => {
              if (hash && fileName) {
                props.onChange([...props.value, {
                  specification_file_hash: hash,
                  hals_teil_database_id: -1,
                  hals_teil_version_id: -1,
                  specification_file: {
                    file_name: fileName,
                    hash: hash,
                    created_at: new Date(),
                    created_by_user_id: '',
                    created_by_user_name: '',
                    content_type: '',
                  },
                } as HalsTeilFileStorageAssociation]);
              }
              setShowModal(false);
            }}
            fileStorageApi={fileStorageApi}
            fileName={undefined}
          />
        }
      </div>
    );
  };
}

type UploadButtonProps = {
  disabled: boolean | undefined
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

function UploadButton(props: UploadButtonProps): JSX.Element {
  return (
    <ButtonGroup fill>
      <InputGroup disabled value={'Neue Datei Hochladen'} fill />
      <Button
        disabled={props.disabled}
        onClick={() => {
          props.setShowModal(true);
        }}
      >
        Hochladen
      </Button>
    </ButtonGroup>
  );
}
