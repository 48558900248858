import { Button, Classes } from '@blueprintjs/core';
import { PropsWithChildren } from 'react';

export function DialogHeader(props: PropsWithChildren<{ onClick: () => void }>): JSX.Element {
  return (
    <div className={Classes.DIALOG_HEADER}>
      <div className={Classes.HEADING}>
        {props.children}
      </div>
      <Button className={Classes.DIALOG_CLOSE_BUTTON} minimal={true} icon='cross' onClick={props.onClick} />
    </div>
  );
}

export function DialogBody(props: PropsWithChildren<{ scrollHorizontalOverflow?: boolean }>): JSX.Element {
  return (
    <div className={Classes.DIALOG_BODY} style={props.scrollHorizontalOverflow ? { overflowX: 'scroll' } : { overflow: 'visible' }}>
      {props.children}
    </div>
  );
}

export function DialogFooter(props: PropsWithChildren<any>): JSX.Element {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        {props.children}
      </div>
    </div>
  );
}
