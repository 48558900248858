"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbwicklungspfadToJSON = exports.AbwicklungspfadFromJSONTyped = exports.AbwicklungspfadFromJSON = exports.Abwicklungspfad = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var Abwicklungspfad;
(function (Abwicklungspfad) {
    Abwicklungspfad[Abwicklungspfad["NUMBER_1"] = 1] = "NUMBER_1";
    Abwicklungspfad[Abwicklungspfad["NUMBER_3"] = 3] = "NUMBER_3";
    Abwicklungspfad[Abwicklungspfad["NUMBER_4"] = 4] = "NUMBER_4";
    Abwicklungspfad[Abwicklungspfad["NUMBER_5"] = 5] = "NUMBER_5";
    Abwicklungspfad[Abwicklungspfad["NUMBER_6"] = 6] = "NUMBER_6";
    Abwicklungspfad[Abwicklungspfad["NUMBER_8"] = 8] = "NUMBER_8";
})(Abwicklungspfad = exports.Abwicklungspfad || (exports.Abwicklungspfad = {}));
function AbwicklungspfadFromJSON(json) {
    return AbwicklungspfadFromJSONTyped(json, false);
}
exports.AbwicklungspfadFromJSON = AbwicklungspfadFromJSON;
function AbwicklungspfadFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.AbwicklungspfadFromJSONTyped = AbwicklungspfadFromJSONTyped;
function AbwicklungspfadToJSON(value) {
    return value;
}
exports.AbwicklungspfadToJSON = AbwicklungspfadToJSON;
