"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LieferterminKennzeichenToJSON = exports.LieferterminKennzeichenFromJSONTyped = exports.LieferterminKennzeichenFromJSON = exports.LieferterminKennzeichen = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var LieferterminKennzeichen;
(function (LieferterminKennzeichen) {
    LieferterminKennzeichen["KW"] = "KW";
    LieferterminKennzeichen["T"] = "T";
    LieferterminKennzeichen["W"] = "W";
})(LieferterminKennzeichen = exports.LieferterminKennzeichen || (exports.LieferterminKennzeichen = {}));
function LieferterminKennzeichenFromJSON(json) {
    return LieferterminKennzeichenFromJSONTyped(json, false);
}
exports.LieferterminKennzeichenFromJSON = LieferterminKennzeichenFromJSON;
function LieferterminKennzeichenFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.LieferterminKennzeichenFromJSONTyped = LieferterminKennzeichenFromJSONTyped;
function LieferterminKennzeichenToJSON(value) {
    return value;
}
exports.LieferterminKennzeichenToJSON = LieferterminKennzeichenToJSON;
