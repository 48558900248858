import { ColumnState } from 'ag-grid-community';

export const defaultColumnState: Array<ColumnState> = [
  {
    colId: 'nummer',
    width: 119,
    hide: false,
    pinned: null,
    sort: 'asc',
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'bezeichnung',
    width: 137,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'ladehoehe',
    width: 111,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'eu_pal_plaetze',
    width: 128,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'version_id',
    width: 50,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'stammdaten_action_buttons',
    width: 120,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
];
