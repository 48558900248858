"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorDict2ToJSON = exports.ErrorDict2FromJSONTyped = exports.ErrorDict2FromJSON = void 0;
function ErrorDict2FromJSON(json) {
    return ErrorDict2FromJSONTyped(json, false);
}
exports.ErrorDict2FromJSON = ErrorDict2FromJSON;
function ErrorDict2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'loc': json['loc'],
        'msg': json['msg'],
        'type': json['type'],
    };
}
exports.ErrorDict2FromJSONTyped = ErrorDict2FromJSONTyped;
function ErrorDict2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'loc': value.loc,
        'msg': value.msg,
        'type': value.type,
    };
}
exports.ErrorDict2ToJSON = ErrorDict2ToJSON;
