import { Button, Dialog, FileInput } from '@blueprintjs/core';
import React from 'react';
import { FileStorageApi } from 'wacoplast_wws__api';
import { DialogBody, DialogFooter } from './Dialog';

export type FileUploadDialogProps = {
  onClose: (hash?: string, fileName?: string) => void,
  fileName?: string,
  fileStorageApi: FileStorageApi
};

export function FileUploadDialog(props: FileUploadDialogProps): JSX.Element {

  const [uploading, setUploading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null>(null);

  return (
    <Dialog title='Datei hochladen' isOpen onClose={() => props.onClose()} canOutsideClickClose={!uploading} canEscapeKeyClose={!uploading}>
      <DialogBody>
        <FileInput
          disabled={uploading}
          onInputChange={(event) => {
            const file = event.currentTarget.files?.[0];
            if (file) {
              setFile(file);
            }
          }}
          fill
          text={file?.name ?? props.fileName ?? 'Datei auswählen'}
          buttonText='Suchen'
          hasSelection={file?.name !== undefined || props.fileName !== undefined}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          intent='primary'
          disabled={!file}
          loading={uploading}
          onClick={async () => {
            if (file) {
              setUploading(true);
              const uploadResponse = await props.fileStorageApi.uploadFileFileStoragePost({ file: file });
              setUploading(false);
              props.onClose(uploadResponse.hash, file.name);
            }
          }}
        >Fertig
        </Button>
        <Button
          disabled={uploading}
          onClick={() => {
            props.onClose();
          }}
        >Abbrechen
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
