import {
  Button,
  ButtonGroup,
  Dialog,
  Divider,
  H2,
} from '@blueprintjs/core';
import { MaschinenlaufplanErstellenResult } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
} from '../../../infrastructure';

export function PapiereErfolgreichGedrucktHinweis(props: PropsWithServices<CustomFormProps<MaschinenlaufplanErstellenResult> & ModalProps<void>>): JSX.Element {
  const finish = (): void => {
    props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog isOpen onClose={finish} title='Papiere drucken'>
      <DialogBody>
        <H2>Die Papiere wurden erfolgreich gedruckt.</H2>
        <ButtonGroup vertical>
          { props.tokenPayload.generated_pdfs.filter(pdf => pdf.file_name !== 'Eingabemaske.pdf').map((pdf, index) =>
            (
              <>
                <a
                  href={`${props.config.restServiceUrl}/file_storage/${pdf.file_group}/${pdf.file_name}/${pdf.hash}`}
                  key={index}
                  rel='noreferrer'
                  target='_blank'
                >
                  {pdf.file_name}
                </a>
                <Divider />
              </>
            ))
          }
        </ButtonGroup>
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Okay</Button>
      </DialogFooter>
    </Dialog>
  );
}
