import { Button, Dialog, H2 } from '@blueprintjs/core';
import { addDays } from 'date-fns';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  EmptyObject,
  ModalProps,
  formatDateString,
} from '../../../infrastructure';

export function UmbauplanZeitraumInvalideHinweis(props: CustomFormProps<EmptyObject> & ModalProps<void>): JSX.Element {

  const finish = (): void => {
    props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog isOpen onClose={finish} title='Umbauplan drucken'>
      <DialogBody>
        <H2>Zeitraum ungültig</H2>
        Der Zeitraum für den Umbauplan muss zwischen dem {formatDateString(new Date())} und dem {formatDateString(addDays(new Date(), 10))} liegen.
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={finish}>Okay</Button>
      </DialogFooter>
    </Dialog>
  );
}
