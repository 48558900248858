"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtikelToJSON = exports.ArtikelFromJSONTyped = exports.ArtikelFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ArtikelFromJSON(json) {
    return ArtikelFromJSONTyped(json, false);
}
exports.ArtikelFromJSON = ArtikelFromJSON;
function ArtikelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gewicht': json['gewicht'],
        'werkstoff_database_id': json['werkstoff_database_id'],
        'werkstoff_version_id': json['werkstoff_version_id'],
        'granulat_farbe_database_id': json['granulat_farbe_database_id'],
        'granulat_farbe_version_id': json['granulat_farbe_version_id'],
        'werkstoff_typ_database_id': !runtime_1.exists(json, 'werkstoff_typ_database_id') ? undefined : json['werkstoff_typ_database_id'],
        'werkstoff_typ_version_id': !runtime_1.exists(json, 'werkstoff_typ_version_id') ? undefined : json['werkstoff_typ_version_id'],
        'einfaerbungs_grad': !runtime_1.exists(json, 'einfaerbungs_grad') ? undefined : json['einfaerbungs_grad'],
        'nummer': json['nummer'],
        'artikel_nummer_des_kunden': !runtime_1.exists(json, 'artikel_nummer_des_kunden') ? undefined : json['artikel_nummer_des_kunden'],
        'artikel_nummer_des_empfaengers': !runtime_1.exists(json, 'artikel_nummer_des_empfaengers') ? undefined : json['artikel_nummer_des_empfaengers'],
        'kunde_database_id': json['kunde_database_id'],
        'kunde_version_id': json['kunde_version_id'],
        'empfaenger_database_id': !runtime_1.exists(json, 'empfaenger_database_id') ? undefined : json['empfaenger_database_id'],
        'empfaenger_version_id': !runtime_1.exists(json, 'empfaenger_version_id') ? undefined : json['empfaenger_version_id'],
        'flaschen_form_database_id': json['flaschen_form_database_id'],
        'flaschen_form_version_id': json['flaschen_form_version_id'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'database_id': json['database_id'],
        'kunde': _1.FirmaFromJSON(json['kunde']),
        'empfaenger': !runtime_1.exists(json, 'empfaenger') ? undefined : _1.FirmaFromJSON(json['empfaenger']),
        'flaschen_form': _1.FlaschenFormFromJSON(json['flaschen_form']),
        'werkstoff': _1.WerkstoffFromJSON(json['werkstoff']),
        'granulat_farbe': _1.GranulatFarbeFromJSON(json['granulat_farbe']),
        'werkstoff_typ': !runtime_1.exists(json, 'werkstoff_typ') ? undefined : _1.WerkstoffTypFromJSON(json['werkstoff_typ']),
    };
}
exports.ArtikelFromJSONTyped = ArtikelFromJSONTyped;
function ArtikelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gewicht': value.gewicht,
        'werkstoff_database_id': value.werkstoff_database_id,
        'werkstoff_version_id': value.werkstoff_version_id,
        'granulat_farbe_database_id': value.granulat_farbe_database_id,
        'granulat_farbe_version_id': value.granulat_farbe_version_id,
        'werkstoff_typ_database_id': value.werkstoff_typ_database_id,
        'werkstoff_typ_version_id': value.werkstoff_typ_version_id,
        'einfaerbungs_grad': value.einfaerbungs_grad,
        'nummer': value.nummer,
        'artikel_nummer_des_kunden': value.artikel_nummer_des_kunden,
        'artikel_nummer_des_empfaengers': value.artikel_nummer_des_empfaengers,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'empfaenger_database_id': value.empfaenger_database_id,
        'empfaenger_version_id': value.empfaenger_version_id,
        'flaschen_form_database_id': value.flaschen_form_database_id,
        'flaschen_form_version_id': value.flaschen_form_version_id,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'aenderungsgrund': value.aenderungsgrund,
        'database_id': value.database_id,
        'kunde': _1.FirmaToJSON(value.kunde),
        'empfaenger': _1.FirmaToJSON(value.empfaenger),
        'flaschen_form': _1.FlaschenFormToJSON(value.flaschen_form),
        'werkstoff': _1.WerkstoffToJSON(value.werkstoff),
        'granulat_farbe': _1.GranulatFarbeToJSON(value.granulat_farbe),
        'werkstoff_typ': _1.WerkstoffTypToJSON(value.werkstoff_typ),
    };
}
exports.ArtikelToJSON = ArtikelToJSON;
