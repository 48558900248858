import { DataModels } from '@atlas-engine/atlas_engine_client';
import React from 'react';
import { CustomFormProps, IdentityProvider } from '.';
import { PropsWithServices } from '..';

export function renderUserTaskAsComponent<TComponentProps extends CustomFormProps>(
  CustomForm: React.ComponentType<TComponentProps> | null,
  userTask: DataModels.FlowNodeInstances.UserTaskInstance,
  onUserTaskFinished: () => void,
  onUserTaskAborted: () => void,
): React.FunctionComponent<PropsWithServices<Omit<TComponentProps, keyof CustomFormProps>> & { getIdentity: IdentityProvider }> {

  const tokenPayload = userTask?.tokens.find((t) => t.type === DataModels.FlowNodeInstances.ProcessTokenType.onEnter)?.payload;

  const wrappedFn = (props: PropsWithServices<Omit<TComponentProps, keyof CustomFormProps>> & { getIdentity: IdentityProvider }): JSX.Element | null => {

    const abortUserTask = async (): Promise<void> => {
      props.atlasEngineClient.userTasks.finishUserTask(userTask.flowNodeInstanceId, { response: 'abort' });
      onUserTaskAborted();
    };

    const suspendUserTask = async (): Promise<void> => {
      onUserTaskAborted();
    };

    const finishUserTask = async (result: DataModels.FlowNodeInstances.UserTaskResult): Promise<void> => {
      props.atlasEngineClient.userTasks.finishUserTask(userTask.flowNodeInstanceId, result);
      onUserTaskFinished();
    };

    const customFormsProps: CustomFormProps = {
      getIdentity: props.getIdentity,
      abortUserTask,
      finishUserTask,
      suspendUserTask,
      suspendState: {},
      tokenPayload,
      userTask,
    };

    if (!CustomForm) {
      return null;
    }

    return (
      <CustomForm
        {...props}
        {...customFormsProps as any}
      />
    );
  };
  return wrappedFn;

}
