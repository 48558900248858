import { CreatePackordnung, Packordnung } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatFlaschenFormNummer,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';
import { FileDownloadCellRenderer } from '../../../infrastructure/stammdaten_renderer/FileDownloadCellRenderer';
import { defaultColumnState } from './defaultColumnConfiguration';
import { makePackordnungFilePickerRenderer } from './PackordnungFilePicker';


export type PackordnungVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function PackordnungVerwalten(props: PackordnungVerwaltenProps): JSX.Element {
  const flaschenFormResult = useFetchLikeGetAllServiceFunction(props.services.flaschenForm.getAllFlaschenFormGet, props.services.flaschenForm);
  const verpackungsEinheitResult = useFetchLikeGetAllServiceFunction(props.services.verpackungs_einheit.getAllVerpackungsEinheitGet, props.services.verpackungs_einheit);

  const editors: StammdatenFieldEditors<CreatePackordnung> = [
    {
      type: 'complex',
      fields: [],
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Packordnungs-Nummer',
    },
    {
      type: 'complex',
      fields: ['flaschen_form_database_id', 'flaschen_form_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschenFormResult, (data) => {
        return { primaryTitle: formatFlaschenFormNummer(data) };
      }, 'flaschen_form'),
      label: 'Flaschenform',
    },
    {
      type: 'complex',
      fields: ['verpackungs_einheit_database_id', 'verpackungs_einheit_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(verpackungsEinheitResult, (data) => {
        return { primaryTitle: data.bezeichnung_kurz, secondaryTitle: String(data.nummer) };
      }, 'verpackungs_einheit'),
      label: 'Verpackungseinheit',
    },
    {
      type: 'simple',
      field: 'anzahl_flaschen',
      renderer: makeNumberEditorRenderer({ formatDecimalSeparator: true }),
      label: 'Anzahl Flaschen',
    },
    {
      type: 'simple',
      field: 'fuellweise',
      renderer: makeTextEditorRenderer(),
      label: 'Füllweise',
    },
    {
      type: 'simple',
      field: 'prioritaet',
      renderer: makeNumberEditorRenderer(),
      label: 'Priorität',
    },
    {
      type: 'simple',
      field: 'packvorschrift_file_hash',
      renderer: makePackordnungFilePickerRenderer(props.services.fileStorage, props.atlasEngineClient),
      label: 'Packordnungs-Datei',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Packordnungs-Nummer',
          field: 'nummer',
          colId: 'verpackungs_nummer',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Flaschenform',
          colId: 'flaschen_form',
          valueGetter: (params) => formatFlaschenFormNummer(params.data.flaschen_form),
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Verpackungseinheit',
          colId: 'verpackungs_einheit',
          ...makeForeignKeyColumn('verpackungs_einheit', (data: Packordnung) => {
            return {
              description: data.verpackungs_einheit.bezeichnung_kurz,
              number: String(data.verpackungs_einheit.nummer),
            };
          }),
        },
        {
          headerName: 'Anzahl Flaschen',
          field: 'anzahl_flaschen',
          colId: 'anzahl_flaschen',
          filter: 'agNumerColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Füllweise',
          field: 'fuellweise',
          colId: 'fuellweise',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Priorität',
          field: 'prioritaet',
          colId: 'prioritaet',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Packordnungs-Datei',
          field: 'packvorschrift_file',
          colId: 'packvorschrift_file',
          valueGetter: (params) => params.data.packvorschrift_file,
          cellRenderer: FileDownloadCellRenderer,
          cellRendererParams: {
            restServiceUrl: props.config.restServiceUrl,
          },
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreatePackordnung) => props.services.packordnung.postPackordnungPost({ CreatePackordnung: data }),
        update: (data) => props.services.packordnung.putByDatabaseIdPackordnungDatabaseIdPut({ database_id: data.database_id, UpdatePackordnung: data }),
        delete: (data, ignoreIntegrity) => props.services.packordnung.deleteByDatabaseIdPackordnungDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.packordnung.restoreByDatabaseIdPackordnungDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.packordnung.getAllPackordnungGet({ include_deleted }),
        getHistory: (data: Packordnung) => props.services.packordnung.getHistoryPackordnungDatabaseIdHistoryGet({ database_id: data.database_id }),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Packordnung}
    />
  );
}
