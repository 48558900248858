"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateBlasformToJSON = exports.CreateBlasformFromJSONTyped = exports.CreateBlasformFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateBlasformFromJSON(json) {
    return CreateBlasformFromJSONTyped(json, false);
}
exports.CreateBlasformFromJSON = CreateBlasformFromJSON;
function CreateBlasformFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'nummer_intern': !runtime_1.exists(json, 'nummer_intern') ? undefined : json['nummer_intern'],
        'flaschen_typ_database_id': json['flaschen_typ_database_id'],
        'flaschen_typ_version_id': json['flaschen_typ_version_id'],
        'stich': json['stich'],
        'anzahl_kavitaeten': json['anzahl_kavitaeten'],
        'nest_nummer_1': !runtime_1.exists(json, 'nest_nummer_1') ? undefined : json['nest_nummer_1'],
        'nest_nummer_2': !runtime_1.exists(json, 'nest_nummer_2') ? undefined : json['nest_nummer_2'],
        'nest_nummer_3': !runtime_1.exists(json, 'nest_nummer_3') ? undefined : json['nest_nummer_3'],
        'nest_nummer_4': !runtime_1.exists(json, 'nest_nummer_4') ? undefined : json['nest_nummer_4'],
        'nest_nummer_5': !runtime_1.exists(json, 'nest_nummer_5') ? undefined : json['nest_nummer_5'],
        'nest_nummer_6': !runtime_1.exists(json, 'nest_nummer_6') ? undefined : json['nest_nummer_6'],
        'nest_nummer_7': !runtime_1.exists(json, 'nest_nummer_7') ? undefined : json['nest_nummer_7'],
        'nest_nummer_8': !runtime_1.exists(json, 'nest_nummer_8') ? undefined : json['nest_nummer_8'],
        'nest_nummer_9': !runtime_1.exists(json, 'nest_nummer_9') ? undefined : json['nest_nummer_9'],
        'nest_nummer_10': !runtime_1.exists(json, 'nest_nummer_10') ? undefined : json['nest_nummer_10'],
        'wechselbares_blindenzeichen': json['wechselbares_blindenzeichen'],
        'blindenzeichen': (json['blindenzeichen'].map(_1.BlasformBlindenzeichenAssociationFromJSON)),
        'prioritaet': json['prioritaet'],
        'lagerplatz_nummer': !runtime_1.exists(json, 'lagerplatz_nummer') ? undefined : json['lagerplatz_nummer'],
        'hinweis': !runtime_1.exists(json, 'hinweis') ? undefined : json['hinweis'],
    };
}
exports.CreateBlasformFromJSONTyped = CreateBlasformFromJSONTyped;
function CreateBlasformToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'nummer_intern': value.nummer_intern,
        'flaschen_typ_database_id': value.flaschen_typ_database_id,
        'flaschen_typ_version_id': value.flaschen_typ_version_id,
        'stich': value.stich,
        'anzahl_kavitaeten': value.anzahl_kavitaeten,
        'nest_nummer_1': value.nest_nummer_1,
        'nest_nummer_2': value.nest_nummer_2,
        'nest_nummer_3': value.nest_nummer_3,
        'nest_nummer_4': value.nest_nummer_4,
        'nest_nummer_5': value.nest_nummer_5,
        'nest_nummer_6': value.nest_nummer_6,
        'nest_nummer_7': value.nest_nummer_7,
        'nest_nummer_8': value.nest_nummer_8,
        'nest_nummer_9': value.nest_nummer_9,
        'nest_nummer_10': value.nest_nummer_10,
        'wechselbares_blindenzeichen': value.wechselbares_blindenzeichen,
        'blindenzeichen': (value.blindenzeichen.map(_1.BlasformBlindenzeichenAssociationToJSON)),
        'prioritaet': value.prioritaet,
        'lagerplatz_nummer': value.lagerplatz_nummer,
        'hinweis': value.hinweis,
    };
}
exports.CreateBlasformToJSON = CreateBlasformToJSON;
