/* eslint-disable react/display-name */
import { Dialog } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import { Kundenauftrag } from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  PropsWithTransaction,
  SidepanelFormCard,
  UseFetchLikeServiceFunctionResult,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { KundenauftragInformationTable } from '../../..';

export type ProduktionsauftragLagerRueckmeldenProps = PropsWithTransaction<PropsWithServices<CustomFormProps<{ kundenauftrag_database_id: number }>>>;
type ProduktionsauftragLagerRueckmeldenCardProps = ProduktionsauftragLagerRueckmeldenProps & { kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag>};

class ProduktionsauftragLagerRueckmeldenCard extends React.PureComponent<ProduktionsauftragLagerRueckmeldenCardProps> {

  private submit = async (): Promise<void> => {
    return this.props.finishUserTask({});
  };

  public render(): JSX.Element {
    return (
      <Dialog
        isOpen={true}
        onClose={() => this.props.abortUserTask()}
        title='Lagerauftrag rückmelden'
      >
        <DialogBody>
          <SidepanelFormCard
            title='Lagerauftrag rückmelden'
            footer={<AsyncButton intent='primary' label='Fertig' onClick={this.submit} />}
            sidepanel={<KundenauftragInformationTable {...this.props} kundenauftrag={this.props.kundenauftrag} />}
          />
        </DialogBody>
      </Dialog>
    );
  }
}

export function ProduktionsauftragLagerRueckmelden(props: ProduktionsauftragLagerRueckmeldenProps): JSX.Element {
  const kundenauftrag_database_id = props.tokenPayload.kundenauftrag_database_id;
  const kundenauftrag_service = props.services.kundenauftrag;
  const serviceFunction = useCallback((initOverrides?: RequestInit) => kundenauftrag_service.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: kundenauftrag_database_id }, initOverrides), [kundenauftrag_database_id, kundenauftrag_service]);
  const kundenauftrag = useFetchLikeServiceFunction(serviceFunction, null);

  return <ProduktionsauftragLagerRueckmeldenCard {...props} kundenauftrag={kundenauftrag} />;
}
