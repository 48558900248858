import { DataModels } from '@atlas-engine/atlas_engine_client';
import { ValueFormatterParams } from 'ag-grid-community';
import { Spinner } from '@blueprintjs/core';
import React from 'react';
import {
  EmitableEventNames,
  KundenauftragArt,
  Produktionsauftrag,
  ProduktionsauftragBlasen,
  ProduktionsauftragStatus,
} from 'wacoplast_wws__api';
import { AbwicklungspfadKeyMapping, ProduktionsauftragAbwickelnModal, ProduktionsauftragStatusKeyMapping } from '.';
import { KundenauftragArtKeyMapping } from '..';
import {
  AuthProviderContext,
  DATE_COLUMN_OPTIONS,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGridRenderer,
  StammdatenPerformableRowActions,
  StartDialogProps,
  dateValueFormatter,
  formatLiefertermin,
  formatWerkstoffTypNummer,
  makeForeignKeyColumn,
  numberValueFormatterGenAGGrid,
  useSubProcesses,
  useUserTasks,
} from '../../infrastructure';
import { PauDurchfuehrenModels, ProcessConstants } from '../../processes';
import { StornierenModals, renderStornierenModal, stornieren } from '../auftragStornieren';
import { defaultColumnState } from './defaultColumnConfiguration';
import { formatBooleanToJaNein } from '../../infrastructure/filterFormatters';


const QUERY_WAITING_USER_TASKS_FOR_ERFASSEN = {
  state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
  processModelId: [
    ProcessConstants['Produktionsauftrag durchführen Lager'].processId,
    ProcessConstants['Produktionsauftrag durchführen Extern'].processId,
  ],
};

export function ProduktionsauftragVerwalten(props: PropsWithTransaction<PropsWithServices<StartDialogProps>>): JSX.Element {
  const waitingUserTasksForDurchfuehren = useUserTasks(props.atlasEngineClient, QUERY_WAITING_USER_TASKS_FOR_ERFASSEN);
  const runningSubprocesses = useSubProcesses(props.atlasEngineClient);

  return <ProduktionsauftragVerwaltenView {...props} waitingUserTasksForDurchfuehren={waitingUserTasksForDurchfuehren} runningSubprocesses={runningSubprocesses} />;
}

export type ProduktionsauftragVerwaltenViewProps = PropsWithTransaction<PropsWithServices<StartDialogProps>> & {
  waitingUserTasksForDurchfuehren: DataModels.FlowNodeInstances.UserTaskInstance[];
  runningSubprocesses: DataModels.FlowNodeInstances.FlowNodeInstance[];
};

type ShownModal = null | {
  modal: 'continue';
  userTask: DataModels.FlowNodeInstances.UserTaskInstance;
  correlationId: string;
} | {
  modal: StornierenModals,
};

type ProduktionsauftragVerwaltenViewState = {
  produktionsauftraege: Produktionsauftrag[] | null;
  showModal: ShownModal;
};

class ProduktionsauftragVerwaltenView extends React.Component<ProduktionsauftragVerwaltenViewProps, ProduktionsauftragVerwaltenViewState> {

  constructor(props: ProduktionsauftragVerwaltenViewProps) {
    super(props);
    this.state = {
      produktionsauftraege: null,
      showModal: null,
    };
  }

  private updateStammdaten(changes: Array<Produktionsauftrag>): void {
    const databaseIdsToFilter = changes.map(change => change.database_id);
    const stammdaten = this.state.produktionsauftraege?.filter(entity => !databaseIdsToFilter.includes(entity.database_id)) ?? [];
    const stammdatenChanges = changes
      .filter((changedEntity, index) => index === changes.findIndex(entity => entity.database_id === changedEntity.database_id));
    this.setState({ produktionsauftraege: [...stammdaten, ...stammdatenChanges.map(entry => ({ ...entry, pau_start: (entry as any).datum_start, pau_ende: (entry as any).datum_ende }))] });
  }

  public componentDidMount(): void {
    this.props.subscriptionEventService.subscribe(EmitableEventNames.Produktionsauftrag, (data) => this.updateStammdaten(data.affected_entities as any));
    this.loadData();
  }

  public loadData(): Promise<void> {
    return this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const produktionsauftraege = await this.props.services.produktionsauftraegeZuKundenauftrag.getAllProduktionsauftragZuKundenauftragGet({});
        this.setState({
          produktionsauftraege: produktionsauftraege.flatMap(produktionsauftraegeZuKUAU => {
            const pau_lager = (produktionsauftraegeZuKUAU.produktionsauftrag_lager as Array<Produktionsauftrag>);
            const pau_extern = (produktionsauftraegeZuKUAU.produktionsauftrag_extern as Array<Produktionsauftrag>);
            const pau_blasen = produktionsauftraegeZuKUAU.produktionsauftrag_blasen.map((produktionsauftragBlasen) => ({
              ...produktionsauftragBlasen.produktionsauftrag_blasen,
              pau_start: produktionsauftragBlasen?.datum_start,
              pau_ende: produktionsauftragBlasen?.datum_ende,
            }));
            return [...pau_lager, ...pau_extern, ...pau_blasen];
          }),
        });
      },
    }).catch(e => {
      console.error('Error while loading PAUs', e);
    });
  }

  public render(): JSX.Element {
    if (this.state.produktionsauftraege === null) {
      return <Spinner />;
    }

    return (
      <>
        {this.state.showModal?.modal === 'continue' && (
          <ProduktionsauftragAbwickelnModal
            onModalFinished={(result) => {
              this.setState({
                showModal: null,
              });
            }}
            userTask={this.state.showModal.userTask}
            subscriptionEventService={this.props.subscriptionEventService}
            transactionService={this.props.transactionService}
            atlasEngineClient={this.props.atlasEngineClient}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlationId={this.state.showModal.correlationId}
          />
        )}
        {this.state.showModal?.modal !== 'continue' && renderStornierenModal(this.state.showModal?.modal ?? null, (modal) => this.setState({ showModal: { modal } }))}
        <AuthProviderContext.Consumer>
          {(authProvider) => (
            <StammdatenAgGridRenderer
              title='Produktionsauftrag verwalten'
              onCloseStartDialogClicked={() => this.props.closeStartDialog()}
              stammdaten={this.state.produktionsauftraege}
              isVersioned={false}
              performableActions={[]}
              getRowId={({ data }) => data.database_id.toString()}
              defaultColumnState={defaultColumnState}
              authProvider={authProvider.authProvider}
              getPerformableRowActions={(row) => {
                const { matchingUserTask, matchingSubProcess, stornierenSignal } = this.findMatchingFlowNodes(row);
                const actions: StammdatenPerformableRowActions<Produktionsauftrag> = [];
                const rueckmeldenLabel = matchingUserTask?.flowNodeId === ProcessConstants['Produktionsauftrag durchführen Extern'].flowNodes['Bestellung extern aufgeben'] ? 'Bestellung aufgeben' : 'Rückmelden';

                if (matchingUserTask) {
                  actions.push({
                    label: rueckmeldenLabel,
                    type: 'custom',
                    onPerform: () => this.rueckmelden(matchingUserTask),
                    intent: 'none',
                    requiredClaims: ['manipulate_produktionsauftrag'],
                  });
                }
                if (matchingSubProcess) {
                  actions.push({
                    label: 'Stornieren',
                    type: 'custom',
                    onPerform: () => stornieren(matchingSubProcess, stornierenSignal, this.props.atlasEngineClient, (modal) => this.setState({ showModal: { modal } })),
                    intent: 'warning',
                    requiredClaims: ['manipulate_produktionsauftrag'],
                  });
                }

                return actions;
              }}
              columnDefs={[
                {
                  headerName: 'Nummer',
                  colId: 'nummer',
                  valueGetter: ({ data }) => data.nummer,
                  filter: 'agTextColumnFilter',
                  ...NUMMER_SORT_OPTS,
                },
                {
                  headerName: 'Kundenauftrag',
                  colId: 'kundenauftrag_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.nummer,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Rahmenauftrag',
                  colId: 'rahmenauftrag_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.rahmenauftrag?.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Auftragsart',
                  colId: 'auftragsart',
                  valueGetter: ({ data }) => KundenauftragArtKeyMapping[KundenauftragArt[data.kundenauftrag.auftragsart]],
                  filter: 'agSetColumnFilter',
                },
                {
                  headerName: 'Datum-Eingang',
                  colId: 'datum_eingang',
                  valueGetter: ({ data }) => data.kundenauftrag.datum_eingang,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Datum Anlage',
                  colId: 'datum_anlage',
                  valueGetter: ({ data }) => data.datum_anlage,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Abwicklungspfad',
                  colId: 'abwicklungspfad',
                  valueGetter: ({ data }) => data.abwicklungspfad,
                  valueFormatter: (val: ValueFormatterParams) => (AbwicklungspfadKeyMapping as any)[val.value],
                  filter: 'agSetColumnFilter',
                },
                {
                  headerName: 'Status',
                  colId: 'status',
                  valueGetter: ({ data }) => data.status,
                  valueFormatter: (val: ValueFormatterParams) => `${(ProduktionsauftragStatusKeyMapping as any)[val.value]} (${val.value})`,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    valueFormatter: (val: ValueFormatterParams) => `${(ProduktionsauftragStatusKeyMapping as any)[val.value]} (${val.value})`,
                  },
                },
                {
                  headerName: 'Liefertermin-Soll',
                  colId: 'liefertermin_soll',
                  valueGetter: ({ data }) => data.kundenauftrag.liefertermin_soll,
                  valueFormatter: ({ data }) =>
                    formatLiefertermin(data.kundenauftrag.liefertermin_kennzeichen, data.kundenauftrag.liefertermin_soll ?? null),
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Kunde',
                  colId: 'kunde',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.kunde.name_kurz,
                      number: data.kundenauftrag.artikel.kunde.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Bestell-Nummer',
                  colId: 'bestell_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.bestell_nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'KU-Art-Nr.',
                  colId: 'artikel_nummer_des_kunden',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.artikel_nummer_des_kunden,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Bestell Menge (KUAU)',
                  colId: 'kundenauftrag_menge',
                  valueGetter: ({ data }) => data.kundenauftrag.menge,
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge (PAU)',
                  colId: 'produktion_auftrag_menge',
                  valueGetter: ({ data }) => data.menge,
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Flaschentyp',
                  colId: 'flaschen_typ',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.flaschen_form.flaschentyp.bezeichnung,
                      number: data.kundenauftrag.artikel.flaschen_form.flaschentyp.nummer,
                    };
                  }),
                },
                {
                  headerName: 'BLZ',
                  colId: 'blinden_zeichen',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.flaschen_form.flaschentyp.blindenzeichen.bezeichnung,
                      number: data.kundenauftrag.artikel.flaschen_form.flaschentyp.blindenzeichen.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Volumen [ml]',
                  colId: 'volumen_ml',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.flaschen_form.flaschentyp.volumen,
                },
                {
                  headerName: 'Halsteil',
                  colId: 'hals_teil',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.flaschen_form.hals_teil.bezeichnung,
                      number: data.kundenauftrag.artikel.flaschen_form.hals_teil.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Gewicht [g]',
                  colId: 'gewicht_g',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.gewicht,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Farbe',
                  colId: 'farbe',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.granulat_farbe.bezeichnung,
                      number: data.kundenauftrag.artikel.granulat_farbe.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Batch-Nr.',
                  colId: 'granulat_farbe_batch_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.granulat_farbe.batch_nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Farb-%',
                  colId: 'einfaerbungs_grad',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.einfaerbungs_grad,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Werkstoff',
                  colId: 'werkstoff',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.werkstoff.bezeichnung,
                      number: data.kundenauftrag.artikel.werkstoff.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Werkstoff Typ',
                  colId: 'werkstoff_typ',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.werkstoff_typ?.bezeichnung ?? '',
                      number: formatWerkstoffTypNummer(data.werkstoff_typ?.nummer),
                    };
                  }),
                },
                {
                  headerName: 'Empfänger',
                  colId: 'empfaenger',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.empfaenger?.name_kurz ?? '',
                      number: data.kundenauftrag.artikel.empfaenger?.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Blasdauer [Tage]',
                  colId: 'blasdauer',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).blasdauer : null,
                },
                {
                  headerName: 'Fertig vor LT [Tage]',
                  colId: 'fertig_vor_liefertermin',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).fertig_vor_liefertermin : null,
                },
                {
                  headerName: 'BLM',
                  colId: 'blasmaschine_nummer',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).blasmaschine.nummer : null,
                },
                {
                  headerName: 'M,nnn',
                  colId: 'blasmaschine_nnn',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? `${(data as ProduktionsauftragBlasen).blasmaschine.nummer},${(data as ProduktionsauftragBlasen).nnn}` : null,
                },
                {
                  headerName: 'PAU-Start',
                  colId: 'pau_start',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen & { pau_start: Date, pau_ende: Date }).pau_start : null,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'PAU-Ende',
                  colId: 'pau_ende',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen & { pau_start: Date, pau_ende: Date }).pau_ende : null,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'mögliche BLM (WACO)',
                  colId: 'vorgegebene_blasmaschine_nummer',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).vorgegebene_blasmaschine?.nummer : null,
                },
                {
                  headerName: 'Menge Gesamt',
                  colId: 'menge_gesamt',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_gesamt : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge Gut',
                  colId: 'menge_gut',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_gut : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Zähler-Stand (Umbau-Start)',
                  colId: 'zaehlerstand_umbau_start',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).zaehlerstand_umbau_start : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Zähler-Stand (Prod. Start) {99=Unterbr.}',
                  colId: 'zaehlerstand_produktion_start',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).zaehlerstand_produktion_start : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Zähler-Stand (Prod. Ende)',
                  colId: 'zaehlerstand_produktion_ende',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).zaehlerstand_produktion_ende : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge Einricht Ausschuss',
                  colId: 'menge_einricht_ausschuss',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_einricht_ausschuss : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge Produktions Ausschuss',
                  colId: 'menge_produktion_ausschuss',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_produktion_ausschuss : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                // Datum letzte Aktualisierung
                // LT KZ
                {
                  headerName: 'Artikel',
                  colId: 'artikel_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Empfänger-Auftragsnummer',
                  colId: 'auftragsnummer_des_empfaenger',
                  valueGetter: ({ data }) => data.kundenauftrag.auftragsnummer_des_empfaenger,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Empfänger Artikel Nr',
                  colId: 'artikel_nummer_des_empfaengers',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.artikel_nummer_des_empfaengers,
                  filter: 'agTextColumnFilter',
                },
                // Anzahlnester (alte BLM)
                {
                  headerName: 'Einricht Ausschuss [%]',
                  colId: 'prozent_einricht_ausschuss',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).prozent_einricht_ausschuss?.toFixed(2) : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Prod. Ausschuss [%]',
                  colId: 'prozent_produktions_ausschuss',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).prozent_produktions_ausschuss?.toFixed(2) : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Invalide Ausschusswerte',
                  colId: 'invalide_ausschusswerte',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? formatBooleanToJaNein((data as ProduktionsauftragBlasen).invalide_ausschusswerte, false) : null,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    valueFormatter: ({ data: { invalide_ausschusswerte } }: { data: ProduktionsauftragBlasen}) => formatBooleanToJaNein(invalide_ausschusswerte),
                  },
                },
                {
                  headerName: 'Technische Unterbrechung',
                  colId: 'technische_unterbrechung',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? formatBooleanToJaNein((data as ProduktionsauftragBlasen).technische_unterbrechung, false) : null,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    valueFormatter: ({ data: { technische_unterbrechung } }: { data: ProduktionsauftragBlasen}) => formatBooleanToJaNein(technische_unterbrechung),
                  },
                },
              ]}
              transactionService={this.props.transactionService}
            />
          )}
        </AuthProviderContext.Consumer>
      </>
    );
  }

  private rueckmelden(userTask: DataModels.FlowNodeInstances.UserTaskInstance): void {
    this.setState({ showModal: { modal: 'continue', correlationId: userTask.correlationId, userTask } });
  }

  private findMatchingFlowNodes(produktionsauftrag: Produktionsauftrag): { matchingUserTask: DataModels.FlowNodeInstances.UserTaskInstance | undefined, matchingSubProcess: DataModels.FlowNodeInstances.FlowNodeInstance | undefined, stornierenSignal: string } {
    const process_correlation_id = produktionsauftrag.kundenauftrag.process_correlation_id;
    const noProcessFound = !process_correlation_id;
    const isStorniert = produktionsauftrag.status === ProduktionsauftragStatus.NUMBER_9;
    const isFertigBlasenPau = produktionsauftrag.abwicklungspfad === 'blasen' && [ProduktionsauftragStatus.NUMBER_5, ProduktionsauftragStatus.NUMBER_6].includes(produktionsauftrag.status);

    if (noProcessFound || isStorniert || isFertigBlasenPau) {
      return {
        matchingUserTask: undefined,
        matchingSubProcess: undefined,
        stornierenSignal: '',
      };
    }

    const matchingUserTask = this.props.waitingUserTasksForDurchfuehren.find(userTask => userTask.correlationId === process_correlation_id && userTask.processModelId === PauDurchfuehrenModels[produktionsauftrag.abwicklungspfad].processId);
    const matchingSubProcess = this.props.runningSubprocesses.find(subProcess => subProcess.correlationId === process_correlation_id && subProcess.processModelId === PauDurchfuehrenModels[produktionsauftrag.abwicklungspfad].processId);

    return {
      matchingUserTask,
      matchingSubProcess,
      stornierenSignal: PauDurchfuehrenModels[produktionsauftrag.abwicklungspfad].signals.stornieren,
    };
  }
}
