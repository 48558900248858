export function formatEmptyStringToLeer(value: string | undefined | null): string {
  if (value === undefined || value === null) return '(Leer)';
  return value;
}

export function formatEnumValueToDescription(value: string | undefined | null, enumType: any, showValue = false): string {
  if (value === undefined || value === null) return '(Leer)';
  if (showValue) return `${enumType[value]} (${value})`;
  return enumType[value];
}

export function formatBooleanToJaNein(value: boolean | undefined | null, showLeer = true): string {
  if (value === undefined || value === null) return showLeer ? '(Leer)' : '';
  return value ? 'Ja' : 'Nein';
}

export function formatBooleanStringToJaNein(value: string | undefined | null, showLeer = true): string {
  if (value === undefined || value === null) return showLeer ? '(Leer)' : '';
  return value === 'true' ? 'Ja' : 'Nein';
}
