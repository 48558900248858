"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateGranulatFarbeToJSON = exports.UpdateGranulatFarbeFromJSONTyped = exports.UpdateGranulatFarbeFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateGranulatFarbeFromJSON(json) {
    return UpdateGranulatFarbeFromJSONTyped(json, false);
}
exports.UpdateGranulatFarbeFromJSON = UpdateGranulatFarbeFromJSON;
function UpdateGranulatFarbeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': json['nummer'],
        'bezeichnung': json['bezeichnung'],
        'batch_nummer': !runtime_1.exists(json, 'batch_nummer') ? undefined : json['batch_nummer'],
        'preis_pro_kg': !runtime_1.exists(json, 'preis_pro_kg') ? undefined : json['preis_pro_kg'],
        'lieferant_database_id': json['lieferant_database_id'],
        'lieferant_version_id': json['lieferant_version_id'],
        'bestand': json['bestand'],
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
    };
}
exports.UpdateGranulatFarbeFromJSONTyped = UpdateGranulatFarbeFromJSONTyped;
function UpdateGranulatFarbeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'bezeichnung': value.bezeichnung,
        'batch_nummer': value.batch_nummer,
        'preis_pro_kg': value.preis_pro_kg,
        'lieferant_database_id': value.lieferant_database_id,
        'lieferant_version_id': value.lieferant_version_id,
        'bestand': value.bestand,
        'aenderungsgrund': value.aenderungsgrund,
    };
}
exports.UpdateGranulatFarbeToJSON = UpdateGranulatFarbeToJSON;
