import { Callout } from '@blueprintjs/core';
import { getErrorDescription } from '..';


export type AlertErrorRendererProps = {
  error: Error;
}

export function AlertErrorRenderer({ error }: AlertErrorRendererProps): JSX.Element {
  const {
    summary,
    details,
  } = getErrorDescription(error);

  return (
    <Callout intent='danger' title={summary}>
      <span className='alert-danger'>{details}</span>
    </Callout>
  );
}
