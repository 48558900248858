/* eslint-disable react/display-name */
import { HTMLTable } from '@blueprintjs/core';
import { BlasmaschineZeiten } from 'wacoplast_wws__api';
import { NumberEditor, StammdatenFieldEditorComplexRendererProps } from '../../../infrastructure';

export function makeExtruderSondertemperaturenEditorRenderer(): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorComplexRendererProps<BlasmaschineZeiten>) => {
    return (
      <HTMLTable bordered condensed>
        <tbody>
          <tr>
            <th>
              <span>Zone-Nr.</span>
            </th>
            {
              new Array(10).fill(0).map((_, index) => {
                return (
                  <td key={index}>
                    <NumberEditor
                      value={(props.stammdatenEntity as any)[`zone_${index + 1}`] ?? null}
                      disabled={props.disabled}
                      onChange={(value) => props.onChange({ [`zone_${index + 1}`]: value ?? undefined })}
                    />
                  </td>
                );
              })
            }
          </tr>
          <tr>
            <th>
              <span>Zone-Temp.</span>
            </th>
            {
              new Array(10).fill(0).map((_, index) => {
                return (
                  <td key={index}>
                    <NumberEditor
                      value={(props.stammdatenEntity as any)[`zone_${index + 1}_temp`] ?? null}
                      disabled={props.disabled}
                      onChange={(value) => props.onChange({ [`zone_${index + 1}_temp`]: value ?? undefined })}
                    />
                  </td>
                );
              })
            }
          </tr>
        </tbody>
      </HTMLTable>
    );
  };
}
