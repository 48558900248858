"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON = exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped = exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON = void 0;
function ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON(json) {
    return ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON = ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON;
function ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_database_id': json['produktionsauftrag_database_id'],
        'ist_teilrueckmeldung': json['ist_teilrueckmeldung'],
    };
}
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped = ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped;
function ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_database_id': value.produktionsauftrag_database_id,
        'ist_teilrueckmeldung': value.ist_teilrueckmeldung,
    };
}
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON = ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON;
