import { CreateFahrzeug } from 'wacoplast_wws__api';

import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  numberValueFormatterGenAGGrid,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type FahrzeugVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function FahrzeugVerwalten(props: FahrzeugVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateFahrzeug> = [
    {
      type: 'complex',
      fields: [],
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
    {
      type: 'simple',
      field: 'ladehoehe',
      renderer: makeNumberEditorRenderer({ formatDecimalSeparator: true }),
      label: 'Ladehöhe [mm]',
    },
    {
      type: 'simple',
      field: 'eu_pal_plaetze',
      renderer: makeNumberEditorRenderer(),
      label: 'EU-Pal-Plätze',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Bezeichnung',
          field: 'bezeichnung',
          colId: 'bezeichnung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Ladehöhe [mm]',
          field: 'ladehoehe',
          colId: 'ladehoehe',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'EU-Pal-Plätze',
          field: 'eu_pal_plaetze',
          colId: 'eu_pal_plaetze',
          filter: 'agNumberColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateFahrzeug) => props.services.fahrzeug.postFahrzeugPost({ CreateFahrzeug: data }),
        update: (data) => props.services.fahrzeug.putByDatabaseIdFahrzeugDatabaseIdPut({ database_id: data.database_id, UpdateFahrzeug: data }),
        delete: (data, ignoreIntegrity) => props.services.fahrzeug.deleteByDatabaseIdFahrzeugDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.fahrzeug.restoreByDatabaseIdFahrzeugDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.fahrzeug.getAllFahrzeugGet({ include_deleted }),
        getHistory: (data) => props.services.fahrzeug.getHistoryFahrzeugDatabaseIdHistoryGet({ database_id: data.database_id }),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      defaultColumnState={defaultColumnState}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.Fahrzeug}
    />
  );
}
