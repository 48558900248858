import React from 'react';

import styles from './Sidebar.module.scss';
import logo from './assets/logo.png';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { stammdatenDialogs, stellblattDialogs, verpackungDialogs } from '../stammdatenDialogs';
import { AuthProviderContext, DialogStrings } from '../../infrastructure';
import { RequireClaims } from '../../infrastructure/RequireClaims';

export type SidebarProps = {
  openStartDialog: (startDialogId: string) => void
}

export class Sidebar extends React.Component<SidebarProps> {

  public render(): JSX.Element {

    const DialogSelect = Select.ofType<typeof DialogStrings[keyof typeof DialogStrings]>();

    const auftragsabwicklung = {
      Plantafel: DialogStrings.Plantafel,
      Kundenaufträge: DialogStrings.Kundenauftrag,
      Produktionsaufträge: DialogStrings.Produktionsauftrag,
      Rahmenaufträge: DialogStrings.Rahmenauftrag,
    };

    return (
      <div className={styles.sidebar}>
        <div className={styles.navigation}>
          <img className={styles.logo} src={logo} alt='Wacoplast Logo' onClick={() => this.props.openStartDialog(DialogStrings.Dashboard.route)} />
          <div className={styles.navbuttons}>
            <RequireClaims claims={['read_kundenauftrag', 'read_produktionsauftrag']} hideOnError>
              <Button className={styles.navbutton} onClick={() => this.props.openStartDialog(auftragsabwicklung.Plantafel.route)}>Plantafel</Button>
            </RequireClaims>
            <RequireClaims claims={['read_kundenauftrag']} hideOnError>
              <Button className={styles.navbutton} onClick={() => this.props.openStartDialog(auftragsabwicklung.Kundenaufträge.route)}>Kundenaufträge</Button>
            </RequireClaims>
            <RequireClaims claims={['read_produktionsauftrag']} hideOnError>
              <Button className={styles.navbutton} onClick={() => this.props.openStartDialog(auftragsabwicklung.Produktionsaufträge.route)}>Produktionsaufträge</Button>
            </RequireClaims>
            <RequireClaims claims={['read_rahmenauftrag']} hideOnError>
              <Button className={styles.navbutton} onClick={() => this.props.openStartDialog(auftragsabwicklung.Rahmenaufträge.route)}>Rahmenaufträge</Button>
            </RequireClaims>

            <DialogSelect
              items={[...stammdatenDialogs, ...verpackungDialogs, ...stellblattDialogs]}
              itemRenderer={(item, itemProps): JSX.Element => (
                <RequireClaims key={item.title} claims={item.readClaims} hideOnError>
                  <MenuItem
                    text={item.title}
                    onClick={itemProps?.handleClick}
                    key={itemProps?.index}
                    selected={itemProps.modifiers.active}
                  />
                </RequireClaims>
              )}
              onItemSelect={(item) => this.props.openStartDialog(item.route)}
              fill={true}
              itemPredicate={(query, data) => data.title.toLowerCase().includes(query.toLowerCase())}
              noResults={<>Keine Einträge gefunden</>}
            >
              <Button
                className={styles.navbutton}
                fill={true}
                rightIcon='caret-down'
              >
                Stammdaten
              </Button>
            </DialogSelect>
          </div>
          <div className={styles.bottom_buttons}>
            <AuthProviderContext.Consumer>
              {({ authProvider }) => (
                <Button
                  className={styles.navbutton}
                  fill
                  onClick={() => authProvider.logout()}
                >
                  Verlassen
                </Button>
              )}
            </AuthProviderContext.Consumer>
          </div>
        </div>
      </div>
    );
  }
}
