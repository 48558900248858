import { AtlasEngineClient, DataModels } from '@atlas-engine/atlas_engine_client';
import { Button, Dialog, Spinner } from '@blueprintjs/core';
import { DialogBody, DialogFooter } from '../infrastructure';
import { ProcessConstants } from '../processes';

export type StornierenModals = 'stornieren' | 'stornieren-confirm' | 'stornieren-error' | null;

// TODO as soon as the engine client supports throwing a correlation_id bound signal, replace this with a signal throwing and delete the process https://github.com/5minds/wacoplast_wws/issues/520
export function stornieren(
  flowNodeInstance: DataModels.FlowNodeInstances.FlowNodeInstance,
  signal: string, atlasEngineClient: AtlasEngineClient,
  setResult: (modal: StornierenModals) => void,
): void {
  setResult('stornieren');
  atlasEngineClient.processModels.startProcessInstanceAndAwaitEndEvent({
    processModelId: ProcessConstants.throw_signal_event.processId,
    correlationId: flowNodeInstance.correlationId,
    initialToken: { signal },
  }).then(() => {
    setResult('stornieren-confirm');
  }).catch(() => {
    setResult('stornieren-error');
  });
}

export function renderStornierenModal(modal: StornierenModals, setResult: (modal: StornierenModals) => void): JSX.Element {
  if (modal === 'stornieren') {
    return (
      <Dialog title='Stornierung wird gestartet' isOpen onClose={() => {}} isCloseButtonShown={false} canEscapeKeyClose={false} canOutsideClickClose={false}>
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    );
  } else if (modal === 'stornieren-confirm') {
    return (
      <Dialog title='Stornierung erfolgreich gestartet' isOpen onClose={() => setResult(null)}>
        <DialogBody>Die Stornierung wurde erfolgreich gestartet.</DialogBody>
        <DialogFooter>
          <Button onClick={() => setResult(null)}>Okay</Button>
        </DialogFooter>
      </Dialog>
    );
  } else if (modal === 'stornieren-error') {
    return (
      <Dialog title='Stornierung konnte nicht gestartet werden' isOpen onClose={() => setResult(null)}>
        <DialogBody>Die Stornierung konnte leider nicht gestartet werden.</DialogBody>
        <DialogFooter>
          <Button onClick={() => setResult(null)}>Okay</Button>
        </DialogFooter>
      </Dialog>
    );
  }
  return <></>;
}
