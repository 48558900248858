import {
  Checkbox,
  ControlGroup,
  Radio,
  RadioGroup,
  Spinner,
} from '@blueprintjs/core';
import { BlasformBlindenzeichenAssociation, Blindenzeichen } from 'wacoplast_wws__api';
import { StammdatenFieldEditorSimpleRendererProps, UseFetchLikeServiceFunctionResult, getDefaultDisplayData } from '../../../infrastructure';

export function makeBlindenzeichenSelector<TEntityType>(
  dataProvider: UseFetchLikeServiceFunctionResult<Array<Blindenzeichen>>,
  getItemData: getDefaultDisplayData<Blindenzeichen>,
): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType & { wechselbares_blindenzeichen: boolean }, Array<BlasformBlindenzeichenAssociation> | null>) => {

    if (dataProvider.isLoading) {
      return <Spinner />;
    }

    if (props.stammdatenEntity.wechselbares_blindenzeichen) {
      return (
        <ControlGroup>
          {
            dataProvider.data?.map((blindenzeichen, index) => {
              return (
                <Checkbox
                  style={{ marginRight: '20px' }}
                  disabled={props.disabled}
                  inline
                  key={index}
                  label={getItemData(blindenzeichen).primaryTitle}
                  checked={props.value !== null && props.value !== undefined && props.value.findIndex((association) => association.blindenzeichen_database_id === blindenzeichen.database_id && association.blindenzeichen_version_id === blindenzeichen.version_id) !== -1}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      props.onChange([...(props.value ?? []), { blindenzeichen_database_id: blindenzeichen.database_id, blindenzeichen_version_id: blindenzeichen.version_id }]);
                    } else {
                      props.onChange(props.value?.filter((association) => association.blindenzeichen_database_id !== blindenzeichen.database_id || association.blindenzeichen_version_id !== blindenzeichen.version_id) ?? []);
                    }
                  }}
                />
              );
            })
          }
        </ControlGroup>
      );
    }

    return (
      <RadioGroup
        inline
        onChange={(event) => {
          const database_id = Number((event.target as any).value);
          const blindenzeichen = dataProvider.data?.find((item) => item.database_id === database_id) as Blindenzeichen;
          props.onChange([{ blindenzeichen_database_id: blindenzeichen.database_id, blindenzeichen_version_id: blindenzeichen.version_id }]);
        }}
        selectedValue={props.value && props.value.length > 0 ? getBlindenzeichenByBlasformBlindenzeichenAssociation(dataProvider, props.value[0]) : undefined}
      >
        {
          dataProvider.data?.map((blindenzeichen) => {
            return <Radio disabled={props.disabled} key={blindenzeichen.database_id} label={getItemData(blindenzeichen).primaryTitle} value={blindenzeichen.database_id} />;
          })
        }
      </RadioGroup>
    );
  };
}

function getBlindenzeichenByBlasformBlindenzeichenAssociation(dataProvider: UseFetchLikeServiceFunctionResult<Array<Blindenzeichen>>, value: BlasformBlindenzeichenAssociation): number | undefined{
  const blindenzeichen = dataProvider.data?.find((item) => item.database_id === value.blindenzeichen_database_id && item.version_id === value.blindenzeichen_version_id);
  return blindenzeichen?.database_id;
}
