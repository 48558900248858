import { useCallback, useEffect, useState } from 'react';

export type FetchLikeFunction<TType> = (args: RequestInit) => Promise<TType>;

export type FetchLikeGetAllFunction<TType> = (requestParameters: { include_deleted?: boolean }, args: RequestInit) => Promise<TType>

export type UseFetchLikeServiceFunctionResult<TValue> = {
  isLoading: true;
  data: null;
  error: null;
  reload: () => void;
} | {
  isLoading: false;
  data: TValue;
  error: null;
  reload: () => void;
} | {
  isLoading: false;
  data: null;
  error: Error;
  reload: () => void;
}

export function useFetchLikeServiceFunction<TType>(fn: FetchLikeFunction<TType>, service: any): UseFetchLikeServiceFunctionResult<TType> {
  const [{ data, error }, setData] = useState<{
    data: TType | null;
    error: Error | null;
  }>({ data: null, error: null });

  const load = useCallback((): () => void => {
    setData({ data: null, error: null });

    const controller = new AbortController();

    fn.bind(service)({ signal: controller.signal })
      .then(data => {
        setData({
          data,
          error: null,
        });
      })
      .catch(error => {
        setData({
          error,
          data: null,
        });
      });

    return () => {
      controller.abort();
    };
  }, [fn, service]);

  useEffect(() => load(), [load]);

  const isLoading = data === null && error === null;

  return {
    isLoading,
    data,
    error,
    reload: () => load(),
  } as any;
}

export function useFetchLikeGetAllServiceFunction<TType>(fn: FetchLikeGetAllFunction<TType>, service: any, include_deleted = false): UseFetchLikeServiceFunctionResult<TType> {
  const serviceFunction = useCallback((initOverrides: RequestInit) => fn.bind(service)({ include_deleted }, initOverrides), [fn, service, include_deleted]);
  return useFetchLikeServiceFunction(serviceFunction, null);
}

export function filterFetchLikeServiceFunctionResult<T>(dataProvider: UseFetchLikeServiceFunctionResult<Array<T>>, filter: (value: T, index: number, allValues: Array<T>) => boolean): UseFetchLikeServiceFunctionResult<Array<T>> {
  return {
    ...dataProvider,
    data: dataProvider.data?.filter(filter) ?? null as any,
  };
}
