"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateArtikelToJSON = exports.UpdateArtikelFromJSONTyped = exports.UpdateArtikelFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateArtikelFromJSON(json) {
    return UpdateArtikelFromJSONTyped(json, false);
}
exports.UpdateArtikelFromJSON = UpdateArtikelFromJSON;
function UpdateArtikelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'gewicht': json['gewicht'],
        'werkstoff_database_id': json['werkstoff_database_id'],
        'werkstoff_version_id': json['werkstoff_version_id'],
        'granulat_farbe_database_id': json['granulat_farbe_database_id'],
        'granulat_farbe_version_id': json['granulat_farbe_version_id'],
        'werkstoff_typ_database_id': !runtime_1.exists(json, 'werkstoff_typ_database_id') ? undefined : json['werkstoff_typ_database_id'],
        'werkstoff_typ_version_id': !runtime_1.exists(json, 'werkstoff_typ_version_id') ? undefined : json['werkstoff_typ_version_id'],
        'einfaerbungs_grad': !runtime_1.exists(json, 'einfaerbungs_grad') ? undefined : json['einfaerbungs_grad'],
        'nummer': json['nummer'],
    };
}
exports.UpdateArtikelFromJSONTyped = UpdateArtikelFromJSONTyped;
function UpdateArtikelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'gewicht': value.gewicht,
        'werkstoff_database_id': value.werkstoff_database_id,
        'werkstoff_version_id': value.werkstoff_version_id,
        'granulat_farbe_database_id': value.granulat_farbe_database_id,
        'granulat_farbe_version_id': value.granulat_farbe_version_id,
        'werkstoff_typ_database_id': value.werkstoff_typ_database_id,
        'werkstoff_typ_version_id': value.werkstoff_typ_version_id,
        'einfaerbungs_grad': value.einfaerbungs_grad,
        'nummer': value.nummer,
    };
}
exports.UpdateArtikelToJSON = UpdateArtikelToJSON;
