import { useEffect, useState } from 'react';

import { AtlasEngineClient, DataModels } from '@atlas-engine/atlas_engine_client';

export function useUserTasks(
  atlasEngineClient: AtlasEngineClient,
  query: DataModels.FlowNodeInstances.UserTaskQuery,
): DataModels.FlowNodeInstances.UserTaskInstance[] {

  const [userTasks, setUserTasks] = useState<DataModels.FlowNodeInstances.UserTaskInstance[]>([]);

  useEffect(() => {
    let shouldUpdate = true;

    const fetchUserTasks = async (): Promise<void> => {
      const userTasks = await atlasEngineClient.userTasks.query(query);
      if (shouldUpdate){
        setUserTasks(userTasks.userTasks);
      }
    };

    const subscriptions = [
      atlasEngineClient.userTasks.onUserTaskWaiting((e) => {
        fetchUserTasks();
      }),
      atlasEngineClient.userTasks.onUserTaskFinished((e) => {
        setUserTasks((userTasks) => userTasks.filter((userTask) => userTask.flowNodeInstanceId !== e.flowNodeInstanceId));
      }),
      atlasEngineClient.notification.onProcessEnded((e) => {
        setUserTasks((userTasks) => userTasks.filter((userTask) => userTask.processInstanceId !== e.processInstanceId));
      }),
    ];

    fetchUserTasks();

    return () => {
      shouldUpdate = false;
      subscriptions.forEach((subscriptionPromise) => {
        subscriptionPromise.then((subscription) => {
          atlasEngineClient.userTasks.removeSubscription(undefined as any, subscription);
          atlasEngineClient.notification.removeSubscription(undefined as any, subscription);
        });
      });
    };
  }, [
    atlasEngineClient,
    query,
  ]);

  return userTasks;
}
